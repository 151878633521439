<template>
  <div>
    <h1 class="heading-container">
      {{ title }}
      <slot>
      </slot>
    </h1>

    <hr />
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>
<style scoped>
.heading-container {
  display: flex;
  align-items: center;
  margin-top: auto;
  height: 3.5vh;
}

hr {
  border: none;
  border-top: 1px solid #5a5e6280;
  margin: 0.7em 0;
}
</style>