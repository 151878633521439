import Colours from '../../utils/Colours';
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
const rehabChartDaily = {
    sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
    peakSpeedUnit: getMeasurementUnit(MeasurementTypes.PeakSpeed),
    svg: null,
    rehabData: null,
    top: 390,
    left: 20,
    width: 1250,
    rowHeight: 112,
    xAxisOffset: 120,
    xAxisWidth: 500,
    xAxis100Percent: 0, // pixels along x axis that represent 100 percent of target (calculated in drawAxis)
    xAxis90Percent: 0, // pixels along x axis that represent 90 percent of target (calculated in drawAxis)
    init: function (svg, rehabData) {
        this.svg = svg.group();
        this.rehabData = rehabData;
    },
    render: function () {
        this.svg.clear(); // clear any existing chart contents

        if (this.rehabData.targets == null) {
            this.svg.text("Player has no targets set").font({ family: "Barlow", size: 12, anchor: "start", weight: "bold" }).attr({ x: 20, y: this.top - 10, fill: Colours.WHITE });
        } else {
            this.drawAxis();
            this.drawRows();
            
            if(this.rehabData.sessionKpis.length > 0) {
                this.renderRow(`Peak Speed (${this.peakSpeedUnit.unitShort})`, 0, "peakSpeedMPerS");
                this.renderRow(`HSR Distance (${this.sdUnit.unitShort})`, 1, "highSpeedDistanceM");
                this.renderRow("High Accelerations", 2, "highAccelerations");
                this.renderRow(`Sprint Distance (${this.sdUnit.unitShort})`, 3, "sprintDistanceM");
                this.render90PercentLine();
                this.renderLegend();
            }
            this.svg.text("Daily").font({ family: "Barlow", size: 12, anchor: "start", weight: "bold" }).attr({ x: 20, y: this.top - 10, fill: Colours.WHITE }); // chart title
        }
    },
    drawAxis: function () {

        // calculate scale for the x axis
        // find the highest percentage of target from all of the session data
        const rehabDate = new Date(this.rehabData.rehabDate);
        let maxPercent = 100;
        this.rehabData.sessionKpis.forEach(k => {
            const sessionDate = new Date(k.sessionDate);
            if (sessionDate > rehabDate) {
                const p1 = (k.highSpeedDistanceM / this.rehabData.targets.highSpeedDistanceM) * 100;
                const p2 = (k.peakSpeedMPerS / this.rehabData.targets.peakSpeedMPerS) * 100;
                const p3 = (k.highAccelerations / this.rehabData.targets.highAccelerations) * 100;
                const p4 = (k.sprintDistanceM / this.rehabData.targets.sprintDistanceM) * 100;
                if (p1 > maxPercent)
                    maxPercent = p1;
                if (p2 > maxPercent)
                    maxPercent = p2;
                if (p3 > maxPercent)
                    maxPercent = p3;
                if (p4 > maxPercent)
                    maxPercent = p4;
            }
        });

        // now calculate how far along the x axis 100 and 90 percent are
        this.xAxis100Percent = (this.xAxisWidth / maxPercent) * 100;
        this.xAxis90Percent = (this.xAxisWidth / maxPercent) * 90;

        const segmentWidth = (this.xAxis100Percent / 5) ; // width of 20% increment        

        // draw the horizontal line for the x axis
        this.svg.line(this.left + this.xAxisOffset, this.top, this.left + this.xAxisOffset + this.xAxisWidth, this.top).attr({ stroke: Colours.SECONDARY_LIGHT_GREY, "stroke-width": 1 });

        for (var x = 0; x < 6; x++) {
            // draw the x axis values in 20% increments
            const xPos = this.left + this.xAxisOffset + (x * segmentWidth);
            this.svg.line(xPos, this.top, xPos, this.top - 7).attr({ stroke: Colours.SECONDARY_LIGHT_GREY, "stroke-width": 1 });
            const label = (x * 20).toString() + "%";
            this.svg.text(label).font({ family: "Barlow", size: 10, anchor: "middle" }).attr({ x: xPos, y: this.top - 10, fill: Colours.WHITE });
        }

        // draw the rightmost extent of the axis
        this.svg.line(this.left + this.xAxisOffset + this.xAxisWidth, this.top, this.left + this.xAxisOffset + this.xAxisWidth, this.top - 7).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
    },
    drawRows: function () {
        // draw the row background rectangles
        this.svg.rect(this.width, this.rowHeight).attr({ fill:"none", stroke: Colours.WHITE, "stroke-width": 0.1, x: this.left, y: this.top });
        this.svg.rect(this.width, this.rowHeight).attr({ fill:"none", stroke: Colours.WHITE, "stroke-width":  0.1, x: this.left, y: this.top + this.rowHeight });
        this.svg.rect(this.width, this.rowHeight).attr({ fill:"none", stroke: Colours.WHITE, "stroke-width":  0.1, x: this.left, y: this.top + (this.rowHeight * 2) });
        this.svg.rect(this.width, this.rowHeight).attr({ fill:"none", stroke: Colours.WHITE, "stroke-width":  0.1, x: this.left, y: this.top + (this.rowHeight * 3) });
    },
    renderRow: function (label, rowPos, metric) {
        // render a row using the supplied metric

        const targetValue = this.rehabData.targets[metric]; // target value for supplied metric

        const baseY = this.top + (this.rowHeight * rowPos) + (this.rowHeight / 2); // Y position of centre of row
        const rehabDate = new Date(this.rehabData.rehabDate); // rehab date from the data

        // variables to hold max and latest values
        let maxValue = 0;
        let maxX = 0;
        let maxY = 0;

        let latestDate = new Date(1980, 1, 1);
        let latestX = 0;
        let latestY = baseY;

        this.rehabData.sessionKpis.forEach(k => {
            // loop through each session in the data
            const sessionDate = new Date(k.sessionDate);
            if (sessionDate > rehabDate) { // after the rehab date, so is valid
                
                // x-positioning for this session

                const value = k[metric]; // this value
                const fractionOfTarget = (value / targetValue); // the value as a fraction of the target value
                const x = this.left + this.xAxisOffset + (fractionOfTarget * this.xAxis100Percent); // x position based on % of target

                // y positioning for this session
                // we need to look and see if any other sessions have the same value, and offset the Y position so that they aren't rendered on top of each other
                const valueKpis = this.rehabData.sessionKpis.filter(f => f[metric] === value && new Date(f.sessionDate) > rehabDate);

                // reposition based on where this session falls in the list of sessions with the same value
                const valueIdx = valueKpis.indexOf(k);
                const sessionY = baseY - (((valueKpis.length - 1) * 8) / 2) + (valueIdx * 8); 
               
                // update latest and max values if required
                if (sessionDate > latestDate) {
                    latestDate = sessionDate;
                    latestX = x;
                    latestY = sessionY;
                }
                
                if (value >= maxValue) {
                    maxValue = value;
                    maxX = x;
                    maxY = sessionY;
                }

                // draw the circle, with gaussian blur
                const circle = this.svg.circle(20, 20).attr({ class: "session", fill: Colours.SPORTLIGHT_TEAL, opacity: 0.4, cx: x, cy: sessionY, sessionId: k.sessionId });
                circle.filterWith(function (add) {
                    add.gaussianBlur(2.5);
                });
            }
        });

        // now plot the latest and max values
        if (maxValue > 0) {
            this.svg.circle(25, 25).attr({ fill: Colours.SPORTLIGHT_TEAL, stroke: "#dbad07", "stroke-width": 3, cx: maxX, cy: maxY });
        }

        if (latestX > 0) {
            this.svg.circle(25, 25).attr({ stroke: Colours.WHITE, "stroke-width": 3, fill: "none", cx: latestX, cy: latestY });
        }

        // finally draw the label
        var labelSplit = label.split(" ");
        for (var l = 0; l < labelSplit.length; l++) {
            const textY = baseY - (((labelSplit.length - 1) * 12) / 2) + (l * 12);
            this.svg.text(labelSplit[l]).font({ family: "Barlow", size: 12, anchor: "start" }).attr({ x: this.left + 10, y: textY, fill: Colours.WHITE });
        }
    },
    render90PercentLine() {
        // render the vertical line at 90%
        this.svg.line(this.left + this.xAxisOffset + this.xAxis90Percent, this.top, this.left + this.xAxisOffset + this.xAxis90Percent, this.top + (this.rowHeight * 4)).attr({ stroke: Colours.WHITE, opacity: "1", "stroke-width": 1, "stroke-dasharray": "4 6" });
        this.svg.text("90%").font({ family: "Barlow", size: 12, anchor: "middle" }).attr({ x: this.left + this.xAxisOffset + this.xAxis90Percent, y: this.top + (this.rowHeight * 4) + 12, fill: Colours.WHITE });
    },
    renderLegend() {
        
        const legendY = this.top + (this.rowHeight * 4) + 15;
        this.svg.circle(15, 15).attr({ fill: Colours.SPORTLIGHT_TEAL, cx: this.xAxisOffset, cy: legendY });
        this.svg.circle(15, 15).attr({ stroke: "#dbad07", "stroke-width": 3, fill: "none", cx: this.xAxisOffset, cy: legendY });
        this.svg.text("Highest").font({ family: "Barlow", size: 12, anchor: "start" }).attr({ x: this.xAxisOffset + 15, y: legendY + 5, fill: Colours.WHITE });


        this.svg.circle(15, 15).attr({ class: "session", fill: Colours.SPORTLIGHT_TEAL, cx: this.xAxisOffset + 100, cy: legendY });
        this.svg.circle(15, 15).attr({ stroke: Colours.WHITE, "stroke-width": 3, fill: "none", cx: this.xAxisOffset + 100, cy: legendY });
        this.svg.text("Latest").font({ family: "Barlow", size: 12, anchor: "start" }).attr({ x: this.xAxisOffset + 115, y: legendY + 5, fill: Colours.WHITE });

        const circle = this.svg.circle(15, 15).attr({ class: "session", fill: Colours.SPORTLIGHT_TEAL, opacity: 0.4, cx: this.xAxisOffset + 200, cy: legendY });
        circle.filterWith(function (add) {
            add.gaussianBlur(2.5);
        });
        this.svg.text("Previous").font({ family: "Barlow", size: 12, anchor: "start" }).attr({ x: this.xAxisOffset + 215, y: legendY + 5, fill: Colours.WHITE });
    },
    highlightSessions(week) {
        // highlight all sessions for the specified week no

        // build a list of session ids for the specified week
        const sessionIds = [];
        this.rehabData.weeklyKpis.forEach(k => {
            
            if (k.weekNumber === parseInt(week))
                Array.prototype.push.apply(sessionIds, k.sessionIds);
        });

        // now loop through each circle element and determine if it is in the list
        const allSessions = document.getElementsByClassName("session");
        
            Array.from(allSessions).forEach(s => {
            const sessionId = s.getAttribute("sessionId");
            if (sessionIds.includes(sessionId)) {

                // draw a new circle in orange over the top
                const x = s.getAttribute("cx");
                const y = s.getAttribute("cy");
                const circle = this.svg.circle(20, 20).attr({ class: "highlight", fill: "#ffa343", cx: x, cy: y });
                circle.filterWith(function (add) {
                    add.gaussianBlur(1.5);
                });
            }
        });
    },
    unHighlightSessions() {
        // remove all highlight elements
        const elements = document.getElementsByClassName("highlight");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

};

export { rehabChartDaily };