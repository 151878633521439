<template>
    <ChangePasswordModal id="change-password-screen-modal" :persistOnClickBackdrop=true :cancel="logOut"
        cancelTitle="Sign Out" cancelVariant="danger standard-btn" />
</template>

<script>

import ChangePasswordModal from '@/components/ChangePasswordModal.vue';
import { UserData } from '@/components/UserData';

export default {
    components: {
        ChangePasswordModal
    },
data() {
    return {
        passwordChangeRequired: UserData.data.passwordChangeRequired
    };
},
    methods: {
        logOut() {
            this.$root.logOut(1);
        }
    },
    mounted() {
        if (!this.passwordChangeRequired) {
            this.$router.push("/calendar").catch(() => { });
        }
        this.$bvModal.show("change-password-screen-modal");
        console.log("ChangePassword.vue mounted");
    }
};
</script>
