import { MeasurementTypes, getMeasurementUnit } from "./MeasurementSystem";

const sdUnit = getMeasurementUnit(MeasurementTypes.ShortDistance);

const SprintThresholdCountOptions = [
    { text: 'Total', value: 'TOTAL' },
    { text: '0-' + sdUnit.formatCoarseUnit(10) + ' Counts', value: '_0_TO_10' },
    { text: sdUnit.formatCoarse(10) + '-' + sdUnit.formatCoarseUnit(20) + ' Counts', value: '_10_TO_20' },
    { text: sdUnit.formatCoarse(20) + '-' + sdUnit.formatCoarseUnit(30) + ' Counts', value: '_20_TO_30' },
    { text: sdUnit.formatCoarseUnit(30) + '+ Counts', value: 'ABOVE_30' }
];

export default SprintThresholdCountOptions; 