<template>
    <div class="container">
        <div class="card">
            <h4>Documentation</h4>
            <p>Access a wide range of white papers and resources to help you get the most out of the portal.</p>
            <span @click="closeModal">
                <router-link class="learn-more" to="/whitepaper">
                    <span> Explore Documentation →</span>
                </router-link>
            </span>
        </div>
        <div class="card">
            <h4>Account Support</h4>
            <p>Get in touch with your dedicated account managers for personalised assistance and support.</p>
            <a href="mailto:account-support@sportlight.ai " class="learn-more">Contact Account Team →</a>
        </div>
        <div class="card">
            <h4>Technical Support</h4>
            <p>Report minor technical issues directly to our support team for a quick resolution.</p>
            <a href="mailto:support@sportlight.ai" class="learn-more">Report An Issue →</a>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        closeModal() {
            this.$bvModal.hide('customer-support-modal');
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin: auto;
}

.card {
    display: flex;
    justify-content: space-evenly;
    width: 33%;
    background-color: var(--input-box-bg);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 0 10px;
}

.card p {
    margin-bottom: 20px;
}

a {
    color: var(--sportlight-teal);
}

</style>