<template>
    <b-button :id="id" :class="type" @click="handleClick" :disabled="isDisabled"> {{ title }}
    </b-button>
</template>

<script>
export default {
    props: {
        id: String,
        title: String,
        onClick: Function,
        type: {
            type: String,
            default: "primary",
            validator:  value => ["primary", "secondary", "danger"].indexOf(value) !== -1
        },
        isDisabled: Boolean
    },
    methods: {
        handleClick() {
            this.onClick();
        }
    }
};
</script>

<style scoped>

button {
    font-size: 13px;
    border-radius: 0;
    font-weight: bold;
    border: none;
    padding: auto;
}

.primary, .primary:hover {
    background-color: var(--sportlight-teal) !important;
    color: var(--bg-primary);
}

.primary:hover {
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
}

.secondary {
    background-color: transparent !important;
    color: var(--sportlight-teal);
}

.secondary:hover {
    background-color: rgba(0, 175, 170, 0.2) !important;
    color: var(--sportlight-teal);
}

.danger {
    background-color: #DC3545 !important;
    color: white;
}

.danger:hover {
    background-color: #C82333 !important;
}

</style>