<!-- 
PeakThresholdCounts are displayed in rows - 1 row for each kpi type -  with 3 box whisker plot 'columns' - 1 column
for each epoch (30s, 60s, 120s) 
-->
<template>
    <div>
        <div class="row w-100 mx-auto d-none d-lg-flex" v-if="!label">
            <div class="row col-12 col-lg-4 pb-2 pb-lg-0 mx-auto">
                <div class="col-2 col-lg-2 row-label"></div>
                <div class="col-2 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">30s</div>
            </div>
            <div class="row col-12 col-lg-4 pb-2 pb-lg-0 mx-auto">
                <div class="col-2 col-lg-2 d-lg-none row-label"></div>
                <div class="col-2 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">60s</div>
            </div>
            <div class="row col-12 col-lg-4 pb-2 pb-lg-0 mx-auto">
                <div class="col-2 col-lg-2 d-lg-none row-label"></div>
                <div class="col-2 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">120s</div>
            </div>
        </div>
        <div>
            <div class="row w-100 mx-auto" v-if="counts">
                <span class="d-block d-lg-none text-center w-100 plot-heading">30s</span>
                <div class="row col-12 col-lg-4 pb-2 pb-lg-0 mx-auto">
                    <div class="col-1 col-lg-2 row-label">{{ label }}</div>
                    <div class="col-1 col-lg-2 value">{{ counts.countData30s.value.toFixed(this.precision) }}</div>
                    <div class="col-10 col-lg-8">
                        <BoxWhiskerPlot
                            :id="label + 'bw1'" 
                            :width="columnWidth" 
                            :height="columnHeight"
                            :values="counts.countData30s" 
                            :options="peakDemandCountOptions" 
                            :normalizeBox="true"
                            :precision="precision"/>
                    </div>
                </div>
                <span class="d-block d-lg-none text-center w-100 plot-heading">60s</span>
                <div class="col-12 col-lg-4 row pb-2 pb-lg-0 mx-auto">
                    <div class="col-1 col-lg-2 d-lg-none row-label">{{ label }}</div>
                    <div class="col-1 col-lg-2 value">{{ counts.countData60s.value.toFixed(this.precision) }}</div>
                    <div class="col-10 col-lg-8">
                        <BoxWhiskerPlot 
                            :id="label + 'bw2'" 
                            :width="columnWidth" 
                            :height="columnHeight"                       
                            :values="counts.countData60s" 
                            :options="peakDemandCountOptions" 
                            :normalizeBox="true"
                            :precision="precision"/> 
                    </div>
                </div>
                <span class="d-block d-lg-none text-center w-100 plot-heading">120s</span>
                <div class=" col-12 col-lg-4 row pb-2 pb-lg-0 mx-auto">
                    <div class="col-1 col-lg-2 d-lg-none row-label">{{ label }}</div>
                    <div class="col-1 col-lg-2 value">{{ counts.countData120s.value.toFixed(this.precision) }}</div>
                    <div class="col-10 col-lg-8">
                        <BoxWhiskerPlot 
                            :id="label + 'bw3'" 
                            :width="columnWidth" 
                            :height="columnHeight"
                            :values="counts.countData120s" 
                            :options="peakDemandCountOptions" 
                            :normalizeBox="true"
                            :precision="precision"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BoxWhiskerPlot from "../components/BoxWhiskerPlot.vue";
export default {
    props: {
        /* if no label is defined then output the 'headings' for the table, otherwise output a row. */
        label: String,
        counts: Object,
        units: String
    },
    components: {
        BoxWhiskerPlot,
    },
    data() {
        return {
            /* 
            the columnWidth value does not appear to affect the width of the plots. To change the widths update the
            'width' style of containing div.
            NOTE: the _columnHeight_ value _DOES_ affect the height of the plots.         
             */
            columnWidth: 400, 
            columnHeight: 73, 
            precision: 2
        };
    },
    computed: {
        peakDemandCountOptions: function() {
            return {
                units:this.units,
                tooltipLabel:"Player Average",
                showTicks:false,
            };
        }
    },
};
</script>

<style scoped>
.row-container {
    display: flex;
}
.row-label {
    width: 6vw;

    /* vertically align the text in the centre of the div */
    text-align: center;
    justify-content: center;
    display:flex;
    align-items:center;

    font-size: 12px;
}
.plot-heading {
    text-align: center;
    width: 21vw; /* this should be the same value as the plot-container width */
    font-weight: bold;
    font-size: 12px;
}
.plot-container {
    width: 21vw; /* this should be the same value as the plot-heading width */
}
.value {
    border-right: 1px solid white;

    /* ensure that there is a small gap between the value and the box whisker plot. */
    padding-right: 3px;

    /* ensure that the previous box whisker plot does not sit too close to the value of the next one. */
    padding-left: 1vw;
    width: 6em;

    /* vertically align the text in the centre of the div */
    text-align: center;
    justify-content: center;
    display:flex;
    align-items:center;

    font-style: italic;
    font-size: 12px;
}
</style>