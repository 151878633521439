<template>
  <span>{{unitLong ? measurementUnit.unitLong: measurementUnit.unitShort}}</span>
</template>
<script>
import {getMeasurementUnit} from "../utils/MeasurementSystem";

export default {
  data() {
    return {
      measurementUnit: getMeasurementUnit(this.type)
    }
  },
  props: {
    type: Symbol,
    unitLong: {
      type: Boolean,
      default: false
    }
  }
}
</script>