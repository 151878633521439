/**
 * Models a Season as a period of time when sessions can occur. Allows objects to be used as options in a Radio Button.
 */
class Season {

    static ALL = new Season("All", null, null);

    startDate;
    endDate;

    // For use in radio buttons
    text;
    value;

    constructor(displayText, startDate, endDate) {
        this.text = displayText;
        this.startDate = startDate;
        this.endDate = endDate;
        this.value = displayText;
    }

    contains(date) {
        if (this.text === Season.ALL.text) {
            return true;
        }
        return date >= this.startDate && date <= this.endDate;
    }
}

/**
 * Return a list of seasons from start date until the current season in descending order.
 * Currently, seasons are coded to approximate the English football season, which usually runs from 1st July to 30th
 * June the following year (UTC).
 * There is a known exception to this (2019/2020 ran to the end of July because of COVID). However, there is only one
 * match that is mis-labelled as a result of this, which is a trade-off we can accept.
 * Longer term, we will need to improve our modelling of Seasons to accommodate other leagues and sports.
 *
 * @param startDate - the earliest Date for which to return a season. Should be earlier than today!
 * @param includeAll - whether to include All Seasons in the result. Will be the first element if present.
 */
function getSeasons(startDate, includeAll = false) {
    const result = [];

    if (includeAll) {
        result.push(Season.ALL);
    }

    const today = new Date();
    let season = getSeasonForDate(today);
    result.push(season);

    while (startDate < season.startDate) {
        season = getSeasonForDate(new Date(season.startDate.valueOf() - 1));
        result.push(season);
    }
    return result;
}

/**
 * Return a season object for the provided date, based on the same English Football Season logic described above.
 *
 * @param date - a Date object
 */
function getSeasonForDate(date) {
    const startYear = (date.getUTCMonth() < 6) ? // July
        date.getUTCFullYear() - 1 : date.getUTCFullYear();
    return new Season(
        `${startYear}/${(startYear + 1).toString().substring(2)}`,
        new Date(Date.UTC(startYear, 6, 1)),
        new Date(Date.UTC(startYear + 1, 5, 30, 23, 59, 59, 999))
    );
}

export { Season, getSeasons };