<template>
    <div :style="{opacity: !$root.isLoadingData ? 1 : 0}" id="content-wrap" style="margin-left:90px">
        <SessionHeader/>
        <PageLayout>
            <div class="section-container">
                <SectionTitle :title="getTitle">
                    <MoreInfo :id="'rainbows-info-modal'" :title="getTitle">
                        <p> {{ getInfoText }} </p>
                    </MoreInfo>
                </SectionTitle>

                <MissingDataText v-if="!hasRainbowCurvesData" :message="'Peak Demands Unavailable'"/>
                <div v-else>
                    <button class="export-button" :class="['button', 'button-export', 'float-right']" 
                        style="color:var(--sportlight-teal);" @click="exportToCSV()" >
                        <span class="button-text">
                            <svg class="download" id="Layer_0" data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                <path class="cls-1"
                                    d="M7.76,12.57a.33.33,0,0,0,.47,0L12.9,7.9a.33.33,0,0,0-.24-.57h-2v-7A.33.33,0,0,0,10.33,0H5.67a.33.33,0,0,0-.33.33v7h-2a.33.33,0,0,0-.24.57Z">
                                </path>
                                <path class="cls-1"
                                    d="M15.33,11.67a.67.67,0,0,0-.67.67v2.33H1.33V12.33a.67.67,0,1,0-1.33,0v3A.67.67,0,0,0,.67,16H15.33a.67.67,0,0,0,.67-.67v-3A.67.67,0,0,0,15.33,11.67Z">
                                </path>
                            </svg>
                            Export
                        </span>
                    </button>
                    <div class="full-width-radio-button-container" v-if="positionGroupSelected">
                        <RadioButton
                            :id="'peak-analysis-radio-group'"
                            :modal="positionGroupSelected"
                            :options="positionGroupOptions"
                            :name="'position-group-options'"
                            @update-modal="positionGroupSelected = $event"
                            :task="positionGroupChange"
                        />
                    </div>
                
                    <div class="peak-analysis-rainbow-chart-legend-container">
                        <div class="rainbow-chart-legend" :style="{background:RainbowColours.blue}"> </div>
                            <span class="rainbow-legend"> Player Values </span>
                        <div class="rainbow-chart-legend" :style="{background:RainbowColours.green}"> </div> 85%-100%
                        <div class="rainbow-chart-legend" :style="{background:RainbowColours.lime}"> </div> 65%-85%
                        <div class="rainbow-chart-legend" :style="{background:RainbowColours.yellow}"> </div> 35%-65%
                        <div class="rainbow-chart-legend" :style="{background:RainbowColours.orange}"> </div> 15%-35%
                        <div class="rainbow-chart-legend" :style="{background:RainbowColours.red}"> </div> 0%-15%
                    </div>
                
                    <div id="peak-analysis-rainbow-curves-titles" class="peak-analysis-rainbow-curves-titles-container">
                        <h3 class="peak-analysis-rainbow-curves-title">Peak Distance Per Epoch</h3>
                        <h3 class="peak-analysis-rainbow-curves-title">Peak HSR Per Epoch</h3>
                        <h3 class="peak-analysis-rainbow-curves-title" v-if="showPeakAnalysisSprintRainbow">Peak Sprint
                            Per Epoch</h3>
                        <h3 class="peak-analysis-rainbow-curves-title">Peak Turns Per Epoch</h3>
                    </div>
                    <div id="peak-analysis-rainbow-curves-container"
                        :style="showPeakAnalysisSprintRainbow ? 'grid-template-columns: repeat(5, 1fr); width: 22vw;' :
                                'grid-template-columns: repeat(4, 1fr); width: 28vw;'"
                        class="container">
                    </div>

                </div>
            </div>

            <div class="section-container">
                <SectionTitle :title="'Peak Threshold Counts (Within Player)'">
                    <MoreInfo :id="'box-whisker-info-modal'" :title="'Peak Threshold Counts (Within Player)'">
                        <ul class="info-text">
                            <li>Peak Threshold Counts shows each player’s and the team total number of efforts completed 
                                above a set absolute peak threshold, for three epoch lengths. The coloured line represents 
                                the current session, while the box and whisker plot represents the distribution of previous 
                                {{playersSessionReferenceGroup}} matches with at least 85 minutes played {{getPeakThresholdText}}.</li>
                            <BoxWhiskerAdditionalInfoText currentDataText="current session" referenceDataText="team's & players'" />
                            <li>Thresholds are set to determine a peak period within each epoch, derived from league-wide representative data.</li>
                            <b-table hover :items="epochCountThresholdsTable" dark="dark"> </b-table>
                        </ul>
                    </MoreInfo>
                </SectionTitle>

                <MissingDataText v-if="!allPlayerPeakThresholds || allPlayerPeakThresholds.length === 0" :message="'Peak Threshold Counts Unavailable'"/>
                <div v-else>
                    <MatchTimeIndicator/>
                    
                    <div class="full-width-radio-button-container">
                        <RadioButton
                            :id="'peak-analysis-radio-group'"
                            :modal="epochSelected"
                            :options="epochRadioOptions"
                            :label="'Epoch Length'"
                            :name="'epoch-options'"
                            @update-modal="epochSelected = $event"
                            :task="epochChange"
                        />
                    </div>

                    <div style="display: flex; margin: auto; width: 85vw" v-if="teamPeakThresholds.length > 0 && sessionIsAMatch">
                        <div>
                            <BoxWhiskerLabel 
                                :id="'peak-thresholds-team-bw-label'" 
                                :data="['Team Total']"
                                :options="teamLabelOptions" 
                                :width="labelWidth"
                                :bwHeight="90">
                            </BoxWhiskerLabel>
                        </div>
                        <div>
                            <BoxWhiskerChart 
                                :id="'team-distance'" :data="teamPeakThresholds[0]" 
                                :options="teamDistanceOptions" :width="plotWidth"
                                :bwHeight="90">
                            </BoxWhiskerChart>
                        </div>
                        <div>
                            <BoxWhiskerChart 
                                :id="'team-hsr'" :data="teamPeakThresholds[1]" 
                                :options="teamHsrOptions" :width="plotWidth"
                                :bwHeight="90">
                            </BoxWhiskerChart>
                        </div>
                        <div>
                            <BoxWhiskerChart 
                                :id="'team-sprint'" :data="teamPeakThresholds[2]" 
                                :options="teamSprintOptions" :width="plotWidth"
                                :bwHeight="90">
                            </BoxWhiskerChart>
                        </div>
                        <div>
                            <BoxWhiskerChart 
                                :id="'team-turns'" :data="teamPeakThresholds[3]" 
                                :options="teamTurnsOptions" :width="plotWidth"
                                :bwHeight="90">
                            </BoxWhiskerChart>
                        </div>
                    </div>

                    <div style="display: flex; margin: auto; width: 85vw" v-if="playerPeakThresholds.length > 0">
                        <div>
                            <BoxWhiskerLabel
                                :id="'peak-thresholds-player-bw-label'"
                                :data="playerPeakThresholds[0].labels"
                                :options="playersOptions"
                                :width="labelWidth"
                                :bwHeight="bwHeight">
                            </BoxWhiskerLabel>
                        </div>
                        <div>
                            <BoxWhiskerChart
                                :id="'player-distance'" :data="playerPeakThresholds[0].data"
                                :options="playerDistanceOptions" :width="plotWidth"
                                :tooltipAdditionalData="playerPeakThresholds[0].matchTimes"
                                :tooltip-additional-data-formatter="MatchClocksFormatter"
                                :bwHeight="bwHeight">
                            </BoxWhiskerChart>
                        </div>
                        <div>
                            <BoxWhiskerChart
                                :id="'player-hsr'" :data="playerPeakThresholds[1].data"
                                :options="playerHsrOptions" :width="plotWidth"
                                :tooltipAdditionalData="playerPeakThresholds[1].matchTimes"
                                :tooltip-additional-data-formatter="MatchClocksFormatter"
                                :bwHeight="bwHeight">
                            </BoxWhiskerChart>
                        </div>
                        <div>
                            <BoxWhiskerChart
                                :id="'player-sprint'" :data="playerPeakThresholds[2].data"
                                :options="playerSprintOptions" :width="plotWidth"
                                :tooltipAdditionalData="playerPeakThresholds[2].matchTimes"
                                :tooltip-additional-data-formatter="MatchClocksFormatter"
                                :bwHeight="bwHeight">
                            </BoxWhiskerChart>
                        </div>
                        <div>
                            <BoxWhiskerChart
                                :id="'player-turns'" :data="playerPeakThresholds[3].data"
                                :options="playerTurnsOptions" :width="plotWidth"
                                :tooltipAdditionalData="playerPeakThresholds[3].matchTimes"
                                :tooltip-additional-data-formatter="MatchClocksFormatter"
                                :bwHeight="bwHeight">
                            </BoxWhiskerChart>
                        </div>
                    </div>
                    
                </div>
            </div>
        </PageLayout>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import GetChartContext from "@/components/GetChartContext";
import DrawRainbowCurves from "@/components/RainbowCurves";
import { RainbowColours } from "@/components/RainbowColours";
import { KpiTypes } from "@/utils/KpiTypes";
import { getEpochCountThresholdsTable } from "@/utils/EpochCountThresholdsTable";
import {getMeasurementUnit, MeasurementTypes, convertBwData, convertRainbowComparisonData} from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";
import store from "@/store/session";
import DateUtils from '@/utils/DateUtils';
import MatchClocksFormatter from "@/utils/MatchClocksFormatter";
import download from "@/utils/Download";

export default {
    data() {
        return {
            shortDistanceMeasurementUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            data: [],
            positionGroupSelected: '',
            positionGroupOptions: [],
            turns: 1,
            customerId: UserData.customerId(),
            hasRainbowCurvesData: true,
            getAllPositionGroups: true,
            epochSelected: '30',
            epochRadioOptions: [
                { text: '30s', value: '30' },
                { text: '60s', value: '60' },
                { text: '120s', value: '120' },
            ],
            labelWidth: 180,
            plotWidth: 18,
            rainbowCurves: null,
            allTeamPeakThresholds: null,
            allPlayerPeakThresholds: null,
            teamPeakThresholds: [],
            playerPeakThresholds: [],
            useAllPlayers: UserData.useAllPlayers(),
            showPeakAnalysisSprintRainbow: UserData.showPeakAnalysisSprintRainbow(),
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.sessionId = this.$route.params.id;
                await this.getData();
                await this.getPeakThresholdData();
            });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/peakanalysis?customerId=${this.customerId}&sessionId=${this.sessionId}&useAllPlayers=${this.useAllPlayers}`
            );

            if (response.status === 200) {
                //First page load checks for available position groups, necessary for rehab sessions
                if (this.getAllPositionGroups) {
                    this.positionGroupOptions = response.data.rainbowPositionGroups;
                    if (this.positionGroupOptions.length > 0) { this.positionGroupSelected = this.positionGroupOptions[0].value; }
                    this.getAllPositionGroups = false;
                }

                this.rainbowCurves = response.data.rainbowCurves;
                this.hasRainbowCurvesData = this.rainbowCurves.playersData.length !== 0;
                await this.getRainbowCurveData();
                this.allTeamPeakThresholds = response.data.teamPeakThresholdCounts.kpiEpochCounts;
                this.allPlayerPeakThresholds = response.data.playerPeakThresholdCounts;
                await this.getPeakThresholdData();
                
            } else {
                errorHandler.error(response, this);
            }
        },
        async getRainbowCurveData() {
            const className = 'rainbow-plot';
            const chartContainer = 'peak-analysis-rainbow-curves-container';
            
            //Filter players by selected position group 
            const playersData = this.rainbowCurves.playersData.filter(t=> t.positionGroupId === this.positionGroupSelected);
            //Sort the players by session time, most at the top
            playersData.sort((a,b) => b.timePlayed - a.timePlayed);
            
            for (let i = 0; i < playersData.length; i++) {
                const playerData = playersData[i].playerValues.playerValuesByKpiId;
                const comparisonData = playersData[i].comparisonData;
                const playerName = playersData[i].playerName;
                
                const distanceLimit = Math.ceil(this.shortDistanceMeasurementUnit.convert(this.rainbowCurves.distanceLimit));
                const hsrLimit = Math.ceil(this.shortDistanceMeasurementUnit.convert(this.rainbowCurves.hsrLimit));
                const sprintLimit = Math.ceil(this.shortDistanceMeasurementUnit.convert(this.rainbowCurves.sprintLimit));
                const turnsLimit = Math.ceil(this.rainbowCurves.turnsLimit);
                
                const label = document.createElement("h1");
                label.className = 'peak-analysis-label';
                label.textContent = playerName;
                
                if (comparisonData === null) {
                    label.textContent = label.textContent.concat("*");
                    label.id = 'label' + i;
                    const hover = document.createElement("span");
                    hover.textContent = "Player requires minimum of 4 matches";
                    hover.className = 'peak-analysis-hover';

                    label.onmouseover = () => {
                        hover.style.display = "block";
                    };

                    label.onmouseleave = () => {
                        hover.style.display = "none";
                    };

                    document.getElementById(chartContainer).appendChild(label);
                    document.getElementById(label.id).appendChild(hover);
                } else {
                    document.getElementById(chartContainer).appendChild(label);
                }
                
                const distanceCanvas = document.createElement("canvas");
                const hsrCanvas = document.createElement("canvas");
                const turnsCanvas = document.createElement("canvas");

                distanceCanvas.id = "distance" + i;
                hsrCanvas.id = "hsr" + i;
                turnsCanvas.id = "turns" + i;
                
                const distanceChartCtx = GetChartContext(distanceCanvas.id, chartContainer, className);
                const hsrChartCtx = GetChartContext(hsrCanvas.id, chartContainer, className);

                // the ordering of the construction of the chartCtx variables is significant. They need to be created 
                // in the same order that they are displayed on the page.
                if (this.showPeakAnalysisSprintRainbow) {
                    const sprintCanvas = document.createElement("canvas");
                    sprintCanvas.id = "sprint" + i;
                    const sprintChartCtx = GetChartContext(sprintCanvas.id, chartContainer, className);
                    const sprintComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.kpiEpochValues.SPRINT, this.shortDistanceMeasurementUnit) : null;
                    DrawRainbowCurves(sprintChartCtx, null, sprintComparisonData,
                        playerData.SPRINT.map(v => this.shortDistanceMeasurementUnit.convert(v)), false, sprintLimit, 25,
                        this.shortDistanceMeasurementUnit.unitShort + '/min', 'Distance (' + this.shortDistanceMeasurementUnit.unitShort + '/min)', null);
                }
                
                const turnsChartCtx = GetChartContext(turnsCanvas.id, chartContainer, className);

                
                const distanceComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.kpiEpochValues.DISTANCE, this.shortDistanceMeasurementUnit) : null;
                const hsrComparisonData = comparisonData ? convertRainbowComparisonData(comparisonData.kpiEpochValues.HSR, this.shortDistanceMeasurementUnit) : null;
                const turnsComparisonData = comparisonData ? comparisonData.kpiEpochValues.TURNS : null;

                DrawRainbowCurves(distanceChartCtx, null, distanceComparisonData,
                    playerData.DISTANCE.map(v => this.shortDistanceMeasurementUnit.convert(v)), false, distanceLimit, 50, this.shortDistanceMeasurementUnit.unitShort + '/min', 'Distance ('+this.shortDistanceMeasurementUnit.unitShort + '/min)', null);
                DrawRainbowCurves(hsrChartCtx, null, hsrComparisonData,
                    playerData.HSR.map(v => this.shortDistanceMeasurementUnit.convert(v)), false, hsrLimit, 50, this.shortDistanceMeasurementUnit.unitShort + '/min', 'Distance ('+this.shortDistanceMeasurementUnit.unitShort + '/min)', null);
                DrawRainbowCurves(turnsChartCtx, null, turnsComparisonData,
                    playerData.TURNS, false, turnsLimit, 2, 'N/min', 'Turns (N/min)', null);
            }
        },
        async getPeakThresholdData() {
            const epochCountOptions = {
                30: 'countData30s',
                60: 'countData60s',
                120: 'countData120s'
            };

            const epochMatchTimeOptions = {
                30: '_30S',
                60: '_60S',
                120: '_120S',
            };

            const epochCountOptionSelected = epochCountOptions[this.epochSelected];
            const epochMatchTimeOptionSelected = epochMatchTimeOptions[this.epochSelected];
            
            for (let kpi in KpiTypes) {
                if (this.allTeamPeakThresholds[kpi] !== undefined) {
                    let peakThreshold;
                    if (KpiTypes.TURNS === KpiTypes[kpi] ) {
                        peakThreshold = this.allTeamPeakThresholds[kpi][epochCountOptionSelected];
                    } else {
                        peakThreshold = convertBwData(this.allTeamPeakThresholds[kpi][epochCountOptionSelected], this.shortDistanceMeasurementUnit);
                    }
                    const { value, average, standardDeviation } = peakThreshold;
                    peakThreshold.belowBounds = value < (average - standardDeviation);
                    peakThreshold.aboveBounds = value > (average + standardDeviation);
                    this.teamPeakThresholds.push([peakThreshold]);
                }
            }
            
            if (this.allPlayerPeakThresholds !== null && this.allPlayerPeakThresholds.length > 0) {
                const allPlayers = this.allPlayerPeakThresholds;

                //Sort players by position groups
                const sortedPlayers = allPlayers.sort(
                    (a, b) =>
                        a.player.positionGroupSortOrder - b.player.positionGroupSortOrder
                );
                
                //Inserts an array between position groups
                const groupedPlayers = sortedPlayers.reduce((t, i) => {
                        t[i.player.positionGroupSortOrder] = t[i.player.positionGroupSortOrder] || [];
                        t[i.player.positionGroupSortOrder].push(i);
                        return t;
                    }, Object.create(null));
                
                const playersData = [];
                const playersNames = [];
                const playersMatchTimes = [];
                const sessionTimes = sortedPlayers.map(p => p.sessionTime);
                const maxTime = Math.max(...sessionTimes) - 5;

                //Restructures the data and adds * for player names where required. Arrays containing numbers are added
                //between position groups to make gaps appear between the groups of players
                Object.values(groupedPlayers).forEach(player => {
                    playersData.push(player.map(t => playersData.push(t.peakThresholdCountMetrics)));
                    playersNames.push(player.map(t => playersNames.push(((t.sessionTime < maxTime) && this.sessionIsAMatch 
                    ? '* ' : '') + t.player.name)));
                    playersMatchTimes.push(player.map(t => playersMatchTimes.push(t.peakThresholdMatchTimes)));
                });
                
                //Remove the final empty arrays as this causes a gap at the bottom of the charts
                playersData.pop();
                playersNames.pop();
                playersMatchTimes.pop();

                //Blank data and empty name used to create a visible gap between groups of names/charts
                const blankData = { value: null, minimum: null, maximum: null, standardDeviation: null, average: null };
                const labels = playersNames.map(name => (typeof name) === 'string' ? name : '');
                
                for (let kpi in KpiTypes) {
                    const data = playersData
                        .map(player => {
                            if (player.kpiEpochCounts === undefined){
                              return blankData;
                            } else if (KpiTypes.TURNS === KpiTypes[kpi]) {
                              return player.kpiEpochCounts[kpi][epochCountOptionSelected];
                            } else {
                              return convertBwData(player.kpiEpochCounts[kpi][epochCountOptionSelected], this.shortDistanceMeasurementUnit);
                            }
                        })
                        .map(d => {
                            return {
                                belowBounds: (d.value < (d.average - d.standardDeviation)),
                                aboveBounds: (d.value > (d.average + d.standardDeviation)),
                                ...d
                            };
                        });
                    
                    const matchTimes = playersMatchTimes
                        .map(t => {
                            if (t && t.kpiEpochMatchTimes && t.kpiEpochMatchTimes[kpi]) {
                                return t.kpiEpochMatchTimes[kpi].epochMatchTimes[epochMatchTimeOptionSelected];
                            } else {
                                return null;
                            }
                        });
                    this.playerPeakThresholds.push({ data, labels, matchTimes });
                }
            }
        },
        positionGroupChange() {
            //Clear old plots and player name labels before loading new ones
            Array.from(document.getElementsByClassName('rainbow-plot')).forEach(e => e.remove());
            Array.from(document.getElementsByClassName('peak-analysis-label')).forEach(e => e.remove());
            this.getRainbowCurveData();
        },
        epochChange() {
            this.teamPeakThresholds = [];
            this.playerPeakThresholds = [];
            this.getPeakThresholdData();
        },
        exportToCSV() {
            this.rainbowCurves.playersData.sort((a, b) => {
                // first sort by position then by minuted played within position group
                const positionGroupComparison = a.positionGroupId - b.positionGroupId;
                return positionGroupComparison !== 0 ? positionGroupComparison : b.timePlayed - a.timePlayed;
            });

            const unit = `(${this.shortDistanceMeasurementUnit.unitShort}/min)`;
            const csvHeader = `Players,Session Title,Session Date,Epoch (s),Peak Distance ${unit},Peak HSR ${unit},` +
                `Peak Sprint ${unit},Peak Turns (N/min)`;
            const session = store.state.session.session;

            const csvContent = this.rainbowCurves.playersData.flatMap(p => {
                const playerName = p.playerName;
                const playerValues = p.playerValues.playerValuesByKpiId;
                const epochs = ["30", "60", "120", "180", "300", "600"];

                return epochs.reduce((acc, epoch) => {
                    if (!playerValues.DISTANCE[epochs.indexOf(epoch)]) {
                        return acc;
                    }
                    const distance = this.shortDistanceMeasurementUnit.formatFine(playerValues.DISTANCE[epochs.indexOf(epoch)]);
                    const sprint = this.shortDistanceMeasurementUnit.formatFine(playerValues.SPRINT[epochs.indexOf(epoch)]);
                    const hsr = this.shortDistanceMeasurementUnit.formatFine(playerValues.HSR[epochs.indexOf(epoch)]);
                    const turns = playerValues.TURNS[epochs.indexOf(epoch)];
                    const sessionDate = DateUtils.getOrdinalDate(session.date);
                    const row = `${playerName}, ${session.title.replace(/,/g, ';')}, ${sessionDate}, ${epoch}, ${distance}, ${hsr}, ${sprint}, ${turns}`;
                    acc.push(row);
                    return acc;
                }, []);
            }).join('\n');

            const csvData = `${csvHeader}\n${csvContent}`;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const file = `Peak Demands - (${session.title}-${session.date}).csv`;
            download(blob, file);
        },
        MatchClocksFormatter,
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            console.log("PeakAnalysis.vue mounted");
            this.$root.newPageView("Peak Analysis Page", UserData.userName());
            await this.refreshData();
            UpdateSetting();
        });
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        getPeakThresholdText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 85 minutes of match time for the team`;
            }
            return null;
        },
        RainbowColours() {
            return RainbowColours;
        },
        epochCountThresholdsTable() {
            return getEpochCountThresholdsTable();
        },
        teamLabelOptions() {
            return {
                ...this.defaultOptions,
                title: '',
            };
        },
        playersOptions() {
            return {
                ...this.defaultOptions,
                showTitle: !this.sessionIsAMatch,
                showTicks: true,
            };
        },
        defaultOptions() {
            return {
                tooltipLabel: this.sessionIsAMatch ? "Current Match" : "Current Session",
                showTicks: false,
                showTitle: true,
                precision: this.shortDistanceMeasurementUnit.decimalPlacesCoarse,
                averagePrecision: this.shortDistanceMeasurementUnit.decimalPlacesFine,
                labelPrecision: this.shortDistanceMeasurementUnit.decimalPlacesCoarse,

            };
        },
        titles() {
            return {
                distance: 'Distance (' + this.shortDistanceMeasurementUnit.unitShort + ')',
                hsr: 'HSR (' + this.shortDistanceMeasurementUnit.unitShort + ')',
                sprint: 'Sprint Distance (' + this.shortDistanceMeasurementUnit.unitShort + ')',
                turns: 'Turns (N)',
            };
        },
        teamDistanceOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.distance,
            };
        },
        teamHsrOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.hsr,
            };
        },
        teamSprintOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.sprint,
            };
        },
        teamTurnsOptions() {
            return {
                ...this.defaultOptions,
                precision: 0,
                averagePrecision: 1,
                labelPrecision: 0,
                title: this.titles.turns,
            };
        },
        playerDistanceOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.distance,
            };
        },
        playerHsrOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.hsr,
            };
        },
        playerSprintOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.sprint,
            };
        },
        playerTurnsOptions() {
            return {
                ...this.playersOptions,
                title: this.titles.turns,
            };
        },

        getTitle: function() {
            return this.useAllPlayers ? 'Peak Demands (All Players)' : 'Peak Demands (Within Player)';
        },
        getInfoText: function() {
            return this.useAllPlayers ? 'Peak demands compares an individual athletes peaks from this session with data ' +
                'from all other athletes. The blue line shows the current sessions data and the rainbow is the comparison data, ' +
                'which comes from all athletes data across all sessions' 
                : 
                'Peak Demands compares each players peaks from this session with ' +
                'their own previous data. The blue line shows current session data and the rainbow is comparison data, which ' +
                `comes from all previous ${this.playersSessionReferenceGroup} matches with at least 85 minutes of match time. A player requires at ` +
                'least 4 full matches to have comparison data.';
        },
        bwHeight() {
            return this.playerPeakThresholds[0].data.length <= 2 ? 110 : 80;
        }
    }
};
</script>

<style>
#peak-analysis-rainbow-curves-container {
    display: grid;
    padding-top: 0;
    justify-content: center;
    padding: 1.5em;
}

.peak-analysis-rainbow-chart-legend-container {
    width: 40%;
}

.peak-analysis-label {
    font-size:14px;
    text-align:left;
    padding:15px;
    white-space:nowrap;
    position: relative;
    margin: auto 0;
    height: 10vh;
}

.peak-analysis-hover {
    display: none;
    position: absolute;
    top: -40px;
    width: 100px;
    font-size: 14px;
    text-align: center;
    background-color: #383E42;
    white-space:pre-wrap;
}

.peak-analysis-rainbow-curves-titles-container {
    display: flex;
    justify-content: space-evenly;
    width: 107%;
    align-items: center;
    padding-top: 6vh;
    margin-left: 3vh;
}

.peak-analysis-rainbow-curves-title {
    font-size: 16px;
    align-items: center;
    font-weight: bold;
}
</style>