<template>
    <div :id="'parent' + id" :style="'width:' + width + 'vw'">
        <canvas :height="height" :id="id"> </canvas>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import Colours from "../utils/Colours";

export default {
    data() {
        return {
          height: this.data.length * this.bwHeight //resize plot height depending on number of datasets
        };
    },
    props: {
        id: String,
        data: Array,
        additionalData: Array,
        options: Object,
        width: Number,
        bwHeight: {   // the default height of each box whisker.
          type: Number,
          default: 90,
        },
        tooltipAdditionalData: {
          type: Array,
          default: () => [] //For charts without additional tooltip data
        },
        tooltipAdditionalDataFormatter: {
          type: Function,
          default: () => [] //For charts without additional tooltip data
        },
    },
    async mounted() {
        await drawBWPlot(this.id, this.data, this.additionalData, this.options, this.width, this.tooltipAdditionalData,
            this.tooltipAdditionalDataFormatter);
        this.$watch("data", async function () {
            replaceCanvas(this.id, this.width, this.height);
            await drawBWPlot(this.id, this.data, this.additionalData, this.options, this.width, this.tooltipAdditionalData,
            this.tooltipAdditionalDataFormatter);
        });
    }
};

/**
 * Calculates the min and max Tick size.
 * Figure out the min and max values from the data (NOTE: a value in a box-whisker can greater than the max or less
 * than the min, so needs to be considered in these comparisons, similarly with value + standardDev - which will be the
 * edge of the box in the bw plot). The algorithm then rounds up or down to the nearest multiple of ten in the 'right'
 * scale based on the difference between the max and min values.
 *
 * For example, with min = 1.7 and max = 8.4, the minTick and maxTick values would be 1 and 9.
 * However, with values like min=1.83 and max = 1.97, the minTick and maxTick values would be 1.8 and 2.0
 *
 * @param {Array} data - An array of data points. Each value in the array should have 'maximum', 'minimum' and 'value' properties (minimum may be null).
 * @param {boolean} isMinTickCalculated - true if the min Tick should be calculated based on the data, otherwise minTick is set to 0.
 * @returns {Object} minTick and maxTick values.
 */
function calculateTicks(data, isMinTickCalculated){
    const calculateGranularity = (min, max) => {
      const diff = max - min;
      const digits = Math.floor(Math.log10((diff))) + 1;
      return Math.pow(10, digits - 1);
    };

    // Floating point arithmetic can be a bit messy (i.e. you can end up with values with 0.00000004 appearing in them).
    // This is a crude attempt to sort it out when it occurs.
    const fixFloat = (value) => {
      return parseFloat(value.toPrecision(3));
    };

    const maxValue = Math.max(...data.map(t => Math.max(t.value, t.maximum, (t.value + t.standardDeviation))));
    if (maxValue === 0 ) {
      // Having maxTick = minTick = 0 breaks the boxWhisker chart.
      return { minTick: 0, maxTick: 1 };
    }

    let minTick = 0;
    if (isMinTickCalculated) {
      const minVal = Math.min(...data.map(t => Math.min(t.value, (t.value - t.standardDeviation))));
      const minMin = Math.min(...data.filter(t => t.minimum).map(t => t.minimum)); // missing values need to be filtered otherwise they show up as 0.
      const minValue = Math.min(minMin, minVal);
      const granularity = calculateGranularity(minValue, maxValue);
      minTick = minValue - (minValue % granularity);
      minTick = fixFloat(minTick);
    }

    const tickGranularity = calculateGranularity(minTick, maxValue);
    let maxTick = maxValue + tickGranularity - (maxValue % tickGranularity);

    maxTick = fixFloat(maxTick);
    return { minTick, maxTick };
}

const replaceCanvas = (id, width, height) => {
    document.getElementById(id).remove();
    const canvas = document.createElement('canvas');
    canvas.id = id;
    canvas.width = width;
    canvas.height = height;
    document.getElementById("parent" + id).appendChild(canvas);
};

const formatLabel = value => {
    // use a space as the thousandth separator
    return value.map(t => t.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" "));
};

//For the player value lines
const createDataset = (label, data, backgroundColor, barPercentage, categoryPercentage) => {
  return { label, data, backgroundColor, barPercentage, categoryPercentage };
};

//To build the box and whisker parts
const createDatasetWithBorder = (label, data, backgroundColor, borderColor, borderWidth, barPercentage, categoryPercentage) => {
  return { label, data, backgroundColor, borderColor, borderWidth, barPercentage, categoryPercentage };
};

const drawBWPlot = (id, data, additionalData, options, width, tooltipAdditionalData, tooltipAdditionalDataFormatter) => {
    const chartCtx = document.getElementById(id).getContext("2d");
    const plotValue = [];
    const valueColours = [];
    const plotLabel = [];
    const minLower = [];
    const stdDev = [];
    const upperMax = [];
    const avg = [];
    const thinBar = 0.15; // the width of the 'whisker' sticking out from the main box
    const thickBar = 0.8; // the width of the 'box' part of the plot
    const minHeight = [];
    const maxHeight = [];

    const {maxTick, minTick} = calculateTicks(data, options.calculateMinTick);

    // The thickness of the 'value' line in the plot. This value is relative to the actual data in the plot, but
    // also needs to be thicker for smaller plots (otherwise the line is too thin to see).
    // The width of the line should be roughly 1/500th of the with of the screen. Therefore we take the 1/500th of the
    // difference between the ticks and then normalise it to the screen using the width value which is in 'vw' -
    // a unit relative to the size of the viewport.
    const lineThickness = ((maxTick - minTick) / 500) * 100 / width ;

    for (const i in data) {
        let { value, minimum, average, standardDeviation, maximum, belowBounds, aboveBounds } = data[i];
        maximum = minimum === null ? null : maximum; //set max value to null when min is null to prevent single grey line
        const kpiValue = value;
        const lowerBounds = average - standardDeviation;
        const upperBounds = average + standardDeviation;

        plotLabel.push((kpiValue ?? null)?.toFixed(options.labelPrecision) || ''); //If value is null then label to return ''
        plotValue.push([kpiValue, kpiValue + lineThickness]);
        minHeight.push(minimum <= lowerBounds ? thinBar : 0);
        maxHeight.push(maximum >= lowerBounds ? thinBar : 0);
        minLower.push([minimum, lowerBounds]);
        stdDev.push([lowerBounds, upperBounds]);
        upperMax.push([upperBounds, maximum]);
        avg.push([average, average]);

        const hasPreviousData = average !== null;

        //Only show green/red for players who played the full game and has data from previous sessions
        const valueColour = (() => {
            if (aboveBounds && hasPreviousData) {
                return Colours.BRIGHT_GREEN;
            } else if (belowBounds && hasPreviousData) {
                return Colours.RED;
            } else {
                return Colours.SPORTLIGHT_TEAL;
            }
        })();

        valueColours.push(valueColour);

        options.displayAxes = options.displayAxes ?? true;
    }

    //Player value
    const datasets = [
      createDataset(options.tooltipLabel, plotValue, valueColours, thickBar, thickBar)
    ];

    //Extra player values (SDN)
    if (additionalData) {
      for (const data of additionalData) {
        const value = [[data.value, data.value + (lineThickness / 2)]];
        datasets.push(createDataset(data.name, value, Colours.BLUE, thickBar, thickBar));
      }
    }

    //Box and whisker
    datasets.push(createDatasetWithBorder("Min", minLower, Colours.SECONDARY_LIGHT_GREY, Colours.SECONDARY_LIGHT_GREY, 1, minHeight, minHeight));
    datasets.push(createDatasetWithBorder("stdDev", stdDev, Colours.PRIMARY_GREY, Colours.SECONDARY_LIGHT_GREY, 1, thickBar, thickBar));
    datasets.push(createDatasetWithBorder("Average", avg, Colours.PRIMARY_GREY, Colours.PRIMARY_GREY, 1, 0, 0));
    datasets.push(createDatasetWithBorder("Max", upperMax, Colours.SECONDARY_LIGHT_GREY, Colours.SECONDARY_LIGHT_GREY, 1, maxHeight, maxHeight));

    const tooltipLabelText = (tooltipItem, data) => {
        const barLabel = data.datasets[tooltipItem.datasetIndex].label;
        const index = tooltipItem.index;
        const value = plotValue[index][0];
        const minimum = minLower[index][0];
        const average = avg[index][0];
        const maximum = upperMax[index][1];

        const units = options.units ?? "";
        if (barLabel === options.tooltipLabel && value !== null) {
            return barLabel + ": " + value.toFixed(options.precision) + units;
        } else if (barLabel === "Max" && maximum !== null) {
            return barLabel + ": " + maximum.toFixed(options.precision) + " " + units;
        } else if (barLabel === "Min" && minimum !== null && maximum !== null) {
            return barLabel + ": " + minimum.toFixed(options.precision) + " " + units;
        } else if (barLabel === "Average" && average !== null && maximum !== null) {
            return barLabel + ": " + average.toFixed(options.averagePrecision) + " " + units;
        } else if (barLabel !== "stdDev" && value) {
            const sessionValue = data.datasets[tooltipItem.datasetIndex].data[index][0];
            return sessionValue ? barLabel + ": " + sessionValue.toFixed(options.precision) + units : null;
        } else {
            return null;
        }
    };

    const tooltipAfterBody = (tooltipItem, data) => {
      if (data.tooltipAdditionalData) {
        return tooltipAdditionalDataFormatter(data.tooltipAdditionalData[tooltipItem[0].index]);
      }
    };

    let gridLineColours = null;
    if (options.calculateMinTick) {
        // minTick and maxTick will be 'round' numbers (i.e. multiples of 10^x). ChartJs will try to use a sensible
        // stepSize, and with data that is a multiple of 10 it always chooses <=10 ticks.
        const verticalGridLineCount = 10;
        gridLineColours = [Colours.WHITE].concat(Array(verticalGridLineCount).fill(null));
    }

    new Chart(chartCtx, {
        type: "horizontalBar",
        data: {
            labels: formatLabel(plotLabel),
            datasets: datasets,
            tooltipAdditionalData: tooltipAdditionalData
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: options.showTitle,
                text: options.title,
                maintainAspectRatio: false,
            },
            legend: {
                display: false,
            },
            tooltips: {
                enabled: true,
                position: 'nearest',
                mode: additionalData ? 'dataset' : 'index', //If we have extra players then make each hover separate
                callbacks: {
                    title: () => { }, // no title
                    label: tooltipLabelText,
                    afterBody: tooltipAfterBody
                },
                //Charts with a single set of data need to be centered, else let chartjs choose optimal position so they don't get cut off
                yAlign: (data.length === 1 ? 'center' : '')
            },
            scales: {
                xAxes: [
                    {
                        display: options.displayAxes,
                        stacked: false,
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            zeroLineColor: 'white',
                            color: gridLineColours,
                            lineWidth: 1,
                            z: 1,
                        },
                        ticks: {
                            display: options.showTicks,
                            maxRotation: 0,
                            min: minTick,
                            max: maxTick,
                        },
                    },
                ],
                yAxes: [
                    {   
                        display: options.displayAxes,
                        stacked: true,
                        afterFit: scaleInstance => scaleInstance.width = 55,
                        gridLines: {
                            display: false,
                            drawBorder: false,
                            lineWidth: 1,
                        },
                        ticks: {
                            display: true,
                            padding: 0,
                            suggestedMin: 0,
                            beginAtZero: false,
                            fontStyle: navigator.userAgent.indexOf('Firefox') !== -1  ? 'normal italic' : 'bold italic'
                        },
                    },
                ],
            },
        },
    });
};
</script>