<template>
    <div class="kpi-chart-container" v-if="playersData && teamData">
        <MissingDataText v-if="!playersData.hasValues && !teamData.hasValues" :message="'Chart Data Unavailable'" />
        <div v-else style="margin: auto;">
            <div class="main-container" v-if="teamData.hasValues && sessionIsAMatch">
                <div style="display: flex;">
                    <div>
                        <BoxWhiskerLabel :id="teamLabelId" :data="teamData.labels" :options="teamOptions" :bwHeight="bwHeight"
                            :width="labelWidth"></BoxWhiskerLabel>
                    </div>
                    <div>
                        <BoxWhiskerChart :id="teamChartId" :data="teamData.kpi" :options="teamOptions" :width="plotWidth" :bwHeight="bwHeight">
                        </BoxWhiskerChart>
                    </div>
                </div>
            </div>
            
            <MissingDataText v-if="!playersData.hasValues" :message="'Players Chart Unavailable'" />
            <div class="main-container" v-else>
                <div style="display: flex;">
                    <div>
                        <BoxWhiskerLabel :id="playersLabelId" :data="playersData.labels" :options="playersOptions" :bwHeight="bwHeight" :width="labelWidth">
                        </BoxWhiskerLabel>
                    </div>
                    <div>
                        <BoxWhiskerChart :id="playersChartId" :data="playersData.kpi" :options="playersOptions" :bwHeight="bwHeight" :width="plotWidth">
                        </BoxWhiskerChart>
                    </div>
                </div>
            </div>
    </div>
</div></template>

<script>
import MissingDataText from "../components/MissingDataText";
import BoxWhiskerChart from "../components/BoxWhiskerChart.vue";
import BoxWhiskerLabel from "../components/BoxWhiskerLabel.vue";
import store from "../store/session";

export default {
    data() {
        return {
            plotWidth: 30,
            labelWidth: 180
        };
    },
    props: {
        teamLabelId: String,
        teamChartId: String,
        playersLabelId: String,
        playersChartId: String,
        teamOptions: Object,
        teamData: Object,
        playersData: Object,
        playersOptions: Object,
    },
    components: {
        MissingDataText,
        BoxWhiskerChart,
        BoxWhiskerLabel,
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        bwHeight() {
            return this.playersData.kpi.length <= 2 ? 110 : 80;
        }
    },
};
</script>

<style scoped>
.main-container {
    display: flex;
    margin: auto;
    width: 42vw;
}
</style>