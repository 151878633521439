<template>
    <div>
        <EditModal
            id='player-identification-edit-modal'
            :ok="displayPreviewChangesModal"
            cancelVariant='secondary standard-btn'
            title='Player Identification'
            size="lg"
            okTitle='Preview Changes'
            :okDisabled="isOkDisabled"
            v-if="hasUnidentifiedPlayers"
        >
            <div :class="unidentifiedThumbnails.length === 1 ? 'player-identification-container' : ''">
                <div class="alert alert-danger" id="incorrect-warning" v-if="hasDuplicateSelection">
                    <span>You cannot assign the same player to multiple thumbnails.</span>
                </div>
                <Button class="mb-2" type="primary" title="Restore Discards" :onClick="restoreDiscards" />
                <b-container>
                    <div v-for="(playerThumbnail, i) in thumbnailsToIdentify" :key="playerThumbnail.playerId">
                        <b-row v-if="!isDiscarded(playerThumbnail.playerId)" class="mt-3">
                            <b-col class="pl-0">
                                <b-dropdown class="mr-2 mb-2" id="player-dropdown" variant="outline-primary"
                                    :text="selectedPlayersChangeTracker && selectedPlayers.get(playerThumbnail.playerId)"
                                    right block>
                                    <b-dropdown-item v-for="unusedPlayer in unusedPlayers" :key="unusedPlayer.playerId"
                                        @click="assignPlayer(i, playerThumbnail.playerId, unusedPlayer.playerId, unusedPlayer.name, playerThumbnail.base64EncodedThumbnail)">
                                        {{ unusedPlayer.name }}
                                    </b-dropdown-item>
                                </b-dropdown>
                                <Button class="mb-2" type="danger" title="Discard"
                                    :onClick="() => discardPlayer(playerThumbnail)" />

                            </b-col>
                            <b-col>
                                <Base64EncodedImage class="" width="300px" height="300px"
                                    :base64EncodedImage="playerThumbnail.base64EncodedThumbnail" />
                            </b-col>
                        </b-row>
                    </div>
                </b-container>
            </div>
        </EditModal>

        <EditModal
            id='save-id-edit-modal'
            :ok="saveIdentifiedPlayers"
            cancelVariant='secondary standard-btn'
            title='Confirm Changes'
            okTitle='Submit'
            :okDisabled= "!hasConfirmedChanges"
        >
            <b-alert  v-model="identificationFailed" variant="danger" >
                Unable to update player details. Please try again later.
            </b-alert>
            <h5 v-if="!noPlayerIdentified">Identified Players</h5>
            <div v-for="(player) in identifiedPlayers.values()" :key="player.playerId" class="mt-2">
                <div>
                    <div class="pb-2">
                        {{ player.name }}
                    </div>
                    <Base64EncodedImage width="300px" height="300px" :base64EncodedImage="player.thumbnail" />
                </div>
            </div>
            <h5 v-if="!noDiscards" class="mt-3">Discarded Players</h5>
            <div v-for="(discardedPlayer) in discardedPlayers" :key="discardedPlayer.playerId" class="mt-2" >
                <div class="mt-3">
                    <Base64EncodedImage width="300px" height="300px" :base64EncodedImage="discardedPlayer.base64EncodedThumbnail" />
                </div>
            </div>
            <Button :onClick="displayPlayerIdentificationModal" type="primary" title="Continue Editing" class="mt-4"/>
            <b-form-checkbox v-model="hasConfirmedChanges" plain class="mt-4">
                Yes, I have reviewed the changes and I'm satisfied.
            </b-form-checkbox>
        </EditModal>
    </div>
</template>

<script>
import {errorHandler} from "@/components/ErrorHandler";

export default {
    data() {
        return {
            identifiedPlayers: new Map(),
            hasConfirmedChanges: false,
            hasDuplicateSelection: false,
            selectedPlayers: new Map(),
            discardedPlayers: [],
            // the following is a bit of a hack to get Vue2 reactivity to work with a Map. See https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
            identifiedPlayersChangeTracker: 1,
            selectedPlayersChangeTracker: 1,
            identificationFailed: false,
        };
    },
    props: {
        customerId: String,
        sessionId: String,
        // list of players (playerId, base64EncodedThumbnail) involved in the session that have not been identified by ProcessFlow 
        unidentifiedThumbnails: Array,
        // list of players (playerId, name) that are not included in the session (i.e. the user may recognise them as one of the unidentified players)
        unusedPlayers: Array,
        // hook to allow the calling page to be notified when the user has finished identifying players. Can be used to refresh the page
        onSaveSuccess: Function 
    },
    methods: {
        assignPlayer(i, playerId, assignedPlayerId, name, thumbnail) {
            // check for duplicates
            for (let value of  this.identifiedPlayers.values()) {
                if (value.assignedPlayerId === assignedPlayerId && value.playerId !== playerId){
                    this.hasDuplicateSelection = true;
                    return;
                }
            }

            this.hasDuplicateSelection = false;

            this.identifiedPlayers.set(playerId, {playerId, assignedPlayerId, name, thumbnail });
            this.identifiedPlayersChangeTracker+=1;
            
            this.selectedPlayers.set(playerId, name);
            this.selectedPlayersChangeTracker+=1;
        },
        discardPlayer(playerThumbnail) {
            this.discardedPlayers.push(playerThumbnail);
            this.identifiedPlayers.delete(playerThumbnail.playerId);
            this.identifiedPlayersChangeTracker+=1;
        },
        isDiscarded(playerThumbnail) {
            //  non-empty result will be considered truthy
            return this.discardedPlayers.find(p => playerThumbnail === p);
        },
        restoreDiscards(){
            this.discardedPlayers = [];
        },
        displayPreviewChangesModal() {
            this.$bvModal.show('save-id-edit-modal');
        },
        displayPlayerIdentificationModal() {
            this.hasConfirmedChanges = false;
            this.$bvModal.hide('save-id-edit-modal');
            this.$bvModal.show('player-identification-edit-modal');
        },
        async saveIdentifiedPlayers() {
            const toPlayerIdentification = (unidentifiedPlayerId, identifiedPlayerId) => {
                return {unidentifiedPlayerId, identifiedPlayerId};
            };
            
            const discardedPlayerIds = this.discardedPlayers.map(t => t.playerId);
            const identifiedPlayers = Array.from(this.identifiedPlayers.values())
                .map(p => toPlayerIdentification(p.playerId, p.assignedPlayerId));
            
            const json = {customerId: this.customerId, sessionId: this.sessionId, discardedPlayerIds, identifiedPlayers};
            
            const response = await this.$root.webApiPost(
                `/playeridentifications?customerId=${this.customerId}&sessionId=${this.sessionId}`,
                json
            );
            
            if (response.status === 200) {
                await this.onSaveSuccess();
            } else if (response.status === 409) {
                this.identificationFailed = true;
                this.$bvModal.show('save-id-edit-modal');
            } else {
                errorHandler.error(response, this);
            }
        },

    },
    computed: {
        hasUnidentifiedPlayers: function() { return this.unidentifiedThumbnails.length > 0; },
        noPlayerIdentified: function() { return this.identifiedPlayersChangeTracker && this.identifiedPlayers.size === 0; },
        noDiscards: function() { return this.discardedPlayers.length === 0; },
        thumbnailsToIdentify: function() { return this.unidentifiedThumbnails.filter( t => !this.isDiscarded(t) ); },
        isOkDisabled: function() {return this.hasDuplicateSelection || (this.noPlayerIdentified && this.noDiscards); }
    },
    async mounted() {
        for(let i = 0; i < this.unidentifiedThumbnails.length; i++ ) {
            this.selectedPlayers.set(this.unidentifiedThumbnails[i].playerId, "Unidentified Player " + (i+1));
        }
        this.selectedPlayersChangeTracker +=1;
    }
};
</script>

<style scoped>
#player-dropdown /deep/ .dropdown-menu {
    height: 25vh;
    overflow-y: auto;
}
.player-identification-container {
    height: 35vh;
    overflow: hidden;
}
</style>