<template>
    <PageHeader v-if="hasPlayers">
        <PageHeaderTitle v-if="selectedPlayer">
            <h1> {{ selectedPlayer.name }} <span> {{ additionalTitle }} </span> </h1>
        </PageHeaderTitle>
        <div class="d-flex justify-content-between">
            <ul class="nav nav-tabs" id="sub-page" role="tablist">
                <li class="nav-item">
                    <router-link :class="'sessionoverview' === (this.$route.name) ? 'nav-link active' : 'nav-link'"
                        id="rehab-tab" data-toggle="tab" to="/sessionoverview" role="tab">
                        <span>Session Overview</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :class="'rehab' === (this.$route.name) ? 'nav-link active' : 'nav-link'" id="rehab-tab"
                        data-toggle="tab" to="/rehab" role="tab">
                        <span>Longitudinal Analysis</span>
                    </router-link>
                </li>
            </ul>
            <DropdownSelector @item-selected="selectPlayer" :items="players" :getItemName="selectorDisplayText"
                :index="selectedPlayerIdx" />
        </div>
    </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageHeaderTitle from "@/components/PageHeaderTitle";
import DropdownSelector from "@/components/DropdownSelector";
import store from './store';

export default {
    props: {
        updateSelection: Function,
        additionalTitle: String
    },
    components: {
        PageHeader,
        DropdownSelector,
        PageHeaderTitle
    },
    data() {
        return {
            hasPlayers: false,
            store: store,
        };
    },
    methods: {
        async getPlayers() {
            await store.dispatch('getPlayers', this);
        },
        async getRehabSessions() {
            await store.dispatch('getRehabSessions', this);
        },
        async selectPlayer(p) {
            await this.$root.executeTaskWithProgressBar(async () => {
                await store.dispatch('updateSelectedPlayer', p);
                this.updateSelection();
            });
        },
        selectorDisplayText(player) {
            return player.name;
        },
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getRehabSessions();
            await this.getPlayers();
            this.hasPlayers = this.players.length > 0;
        });
    },
    computed: {
        players() {
            return store.state.players;
        },
        selectedPlayerIdx() {
            return store.state.selectedPlayerIdx;
        },
        selectedPlayer() {
            return store.state.selectedPlayer;
        }
    },
    beforeDestroy() {
        //Reset the state when user navigates away from either of the rehab screens
        const rehabRoutes = ['rehab', 'sessionoverview'];
        if (!rehabRoutes.includes(this.$route.name)) {
            store.commit("resetState");
        }
    },
};
</script>
<style scoped>
</style>