const RainbowColours = {
	blue: '#0000FF',
	red: '#f5404c',
	orange: '#faa44d',
	yellow: '#faf87f',
	lime: '#77ed82',
	green: '#219e2e',
	dark_red: 'rgba(245, 64, 76, 0.5)',
	dark_orange: 'rgba(250, 164, 77, 0.5)',
	dark_yellow: 'rgba(250, 248, 127, 0.5)',
	dark_lime: 'rgba(119, 237, 130, 0.5)',
	dark_green: 'rgba(33, 158, 46, 0.5)',
};

export { RainbowColours };