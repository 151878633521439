<template>
    <div class="container">
        <p class="filter-description">Player Sessions: {{ selectedPlayerCriteria }}</p>
        <p class="filter-description">Reference Group: {{ selectedReferenceGroup }}</p>
        <p class="filter-description">Position: {{ selectedPositionAverage }}</p>
        <template v-if="this.$route.name === 'longitudinalanalysis'">
            <p class="filter-description">Time Period: {{ selectedTimePeriod }}</p>
            <p class="filter-description">Rolling Match Average: {{ selectedMatchCount }}</p>
        </template>
    </div>
</template>

<script>
import filterStore from './filterStore';
export default {
    computed: {
        selectedPlayerCriteria() {
            return filterStore.state.selectedPlayerCriteria.text;
        },
        selectedReferenceGroup() {
            return filterStore.state.selectedReferenceGroup.text;
        },
        selectedPositionAverage() {
            return filterStore.state.averageOptions.find(t => t.value === filterStore.state.selectedPositionAverage)?.text;
        },
        selectedTimePeriod() {
            return filterStore.state.selectedPeriod?.text;
        },
        selectedMatchCount() {
            return filterStore.state.selectedMatchCount;
        }
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 1vh auto;
    max-width: 91vw;
    padding: 0;
}

.filter-description {
    border: 0.5px solid var(--sportlight-teal);
    padding: 3px;
    font-size: 12px;
    margin: 0;
    margin-right: 10px;
    white-space: nowrap;
    display: inline-block; 
    margin-top: 5px;
}
</style>
