<template>
    <div>
        <PageHeader v-if="hasPlayers">
            <PageHeaderTitle v-if="selectedPlayer">
                <h1> {{ selectedPlayer.name }} </h1>
                <p class="pb-2"> {{ selectedPlayer.position }} </p>
            </PageHeaderTitle>
            <div class="d-flex justify-content-between">
                <ul class="nav nav-tabs" id="sub-page" role="tablist">
                    <li class="nav-item">
                        <router-link
                            :class="'playerdevelopment' === (this.$route.name) ? 'nav-link active' : 'nav-link'"
                            id="player-development-tab" data-toggle="tab" to="/playerdevelopment" role="tab">
                            <span>Overall Analysis</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link
                            :class="'longitudinalanalysis' === (this.$route.name) ? 'nav-link active' : 'nav-link'"
                            id="player-development-tab" data-toggle="tab" to="/longitudinalanalysis" role="tab">
                            <span>Longitudinal Analysis</span>
                        </router-link>
                    </li>
                </ul>
                <div class="d-flex">
                    <div class="mx-4">
                        <Button title="Filter" :onClick="displayFilterOptions" />
                    </div>
                    <DropdownSelector @item-selected="selectPlayer" :items="players" :getItemName="selectorDisplayText"
                        :index="selectedPlayerIdx" />
                </div>
            </div>
        </PageHeader>
        <FilterModal />
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageHeaderTitle from "@/components/PageHeaderTitle";
import DropdownSelector from "@/components/DropdownSelector";
import Button from "@/components/Button";
import store from './store';
import filterStore from './filterStore';
import FilterModal from './FilterModal';

export default {
    props: {
        updateSelection: Function,
        additionalTitle: String
    },
    components: {
        PageHeader,
        DropdownSelector,
        FilterModal,
        PageHeaderTitle,
        Button
    },
    data() {
        return {
            hasPlayers: false,
            store: store,
        };
    },
    methods: {
        async getPlayers() {
            await store.dispatch('getPlayers', this);
        },
        async getPeriods() {
            await filterStore.dispatch('updatePeriods', this);
        },
        async selectPlayer(p) {
            await this.$root.executeTaskWithProgressBar(async () => {
                await store.dispatch('updateSelectedPlayer', p);
                await this.updateSelection();
                filterStore.commit("resetState");
                await this.getPeriods();
            });
        },
        selectorDisplayText(player) {
            return player.name;
        },
        displayFilterOptions() {
            this.$bvModal.show("player-dev-filter-modal");
        },
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getPlayers();
            this.hasPlayers = this.players !== null && this.players.length > 0;
            //prevents the player's periods from resetting when using same player 
            if (!filterStore.state.periods) { 
                await this.getPeriods();
            }
            console.log('Header.vue mounted');
        });
    },
    computed: {
        players() {
            return store.state.players;
        },
        selectedPlayerIdx() {
            return store.state.selectedPlayerIdx;
        },
        selectedPlayer() {
            return store.state.selectedPlayer;
        },
    },
    beforeDestroy() {
        //reset states when user navigates away from the player dev module
        const playerDevRoutes = ['playerdevelopment', 'longitudinalanalysis'];
        if (!playerDevRoutes.includes(this.$route.name)) {
            store.commit("resetState");
            filterStore.commit("resetState");
        }
    },
};
</script>