<template>
    <div class="legends">
        <div v-for="classification in turnEntrySpeedClassifications" :key="classification.name" class="legend-item">
            <div class="legend" :style="{'background-color': legendColours[classification.name.toLowerCase()] }"> </div>
            <span>{{ classification.name }}<br> {{ classification.text }}</span>
        </div>
    </div>
</template>

<script>
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
import { getTurnEntrySpeedClassifications } from "@/utils/TurnEntrySpeedBoundaryHelpers";

export default {
    props: {
        turnEntrySpeedBoundaries: Object,
        legendColours: Object
    },
    data() {
        return {
            Speed: MeasurementTypes.Speed,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            turnEntrySpeedClassifications: []
        };
    },
    mounted() {
        this.turnEntrySpeedClassifications = getTurnEntrySpeedClassifications(this.turnEntrySpeedBoundaries, this.speedUnit);
    }

};
</script>

<style scoped>
.legends{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: 0.8em;
    width: 42vw;
    max-width: 30vw;
}
.legend-item {
    display: flex;
    align-items: center;
}
.legend{
    padding: 1.2em;
    margin: 2em 0.8em;
    text-align: center;
}

</style>