<template>
    <div class="plot-container">
        <canvas :id="id + '-local-intensity-plot'"></canvas>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import Colours from "@/utils/Colours";
import { MeasurementTypes, getMeasurementUnit, MeasurementUnit as MU } from "@/utils/MeasurementSystem";

export default {
    props: {
        id: String,
        data: Object,
        maxSessionTime: Number
    },
    data() {
        return {
            formatStandard: MU.prototype.formatStandard,
            formatFine: MU.prototype.formatFine,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
        };
    },
    methods: {
        async getChart() {
            if (this.data) {

                const sessionTimes = [];
                const data = [];
                const barColours = [];
                for (let i = 0;i <= this.maxSessionTime;i++) {
                    // Generate session times from 0 to the max session time
                    const key = i.toFixed(1);
                    sessionTimes.push(key);
                    // Check if data exists for the current session time and handle accordingly
                    if (this.data[key] !== undefined) { 
                        // Data exists: Push actual data and bar colours to array
                        data.push(this.speedUnit.formatStandard(this.data[key]));
                        barColours.push(this.data[key] < 0 ? Colours.RED : Colours.BRIGHT_GREEN);
                    } else {
                        // Data missing: Push null to represent missing data and bar colours
                        data.push(null);
                        barColours.push(null);
                    }
                }

                const ctx = document.getElementById(this.id + '-local-intensity-plot').getContext('2d');
                new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: sessionTimes,
                        datasets: [{
                            data: data,
                            backgroundColor: barColours,
                            borderColor: barColours,
                            borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        title: {
                            display: false,
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    display: false,
                                    beginAtZero: true
                                },
                                gridLines: {
                                    display: false,
                                    drawBorder: false,
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    display: false,
                                    min: -1.5,
                                    max: 1.5,
                                    stepSize: 1.5
                                },
                                gridLines: {
                                    display: false,
                                    drawBorder: false,
                                },
                                scaleLabel: {
                                    display: false,
                                }
                            }]
                        },
                        tooltips: {
                            enabled: false,
                        }
                    }
                });
            }
        }
    },
    async mounted() {
        await this.getChart();
    },
};
</script>
<style scoped>
.plot-container {
    height: 6vh; 
    margin: auto;
}
canvas {
    width: 8vw; 
    height: 6vh;
}

</style>