class KpiOption {
	constructor() {
		this.defaultSelection = "DISTANCE";
		this.options = [
			{ text: "Distance", value: "DISTANCE" },
			{ text: "HSR", value: "HSR" },
			{ text: "Sprint", value: "SPRINT" },
			{ text: "Turns", value: "TURNS" },
		];
	}
}
export default  new KpiOption();
