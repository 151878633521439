<template>
    <div class="legend-container my-4">
        <div class="legend-item match"></div> Player Session
        <div class="legend-item rolling-average"></div> Rolling Average
        <template v-if="displayComparisonKeys">
            <div class="legend-item position-league-average"></div>
            {{ referenceGroupText }} Average
            <div class="legend-item position-sd"></div>
            {{ referenceGroupText }} +/- 1 S.D
        </template>
    </div>
</template>

<script>
import Colours from "@/utils/Colours";
import filterStore from './filterStore';
import store from './store';

export default {
    props: {
        displayComparisonKeys: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            Colours: Colours,
        };
    },
    computed: {
        selectedPlayerPosition() {
            return store.state.selectedPlayer.position;
        },
        selectedPositionAverage() {
            return filterStore.state.selectedPositionAverage;
        },
        selectedReferenceGroup() {
            return filterStore.state.selectedReferenceGroup;
        },
        referenceGroupText() {
            return `${this.selectedReferenceGroup.text} ${this.selectedPositionAverage === "posAvg" ? this.selectedPlayerPosition : ''}`;
        }
    }
};
</script>

<style scoped>
.legend-container {
    display: flex;
    justify-content: space-around;
    margin: auto;
    width: 75%;
    font-size: 12px;
    align-items: center;
}

.legend-item {
    width: 1.4vw;
    height: 1.4vh;
}

.match {
    background-color: var(--sportlight-teal);
    height: 0.2vh;
}

.rolling-average {
    background-color: var(--sportlight-teal);
}

.position-league-average {
    height: 0.3vh;
    background: repeating-linear-gradient(-90deg,
        var(--white), var(--white) 3px, var(--bg-primary) 3px, var(--bg-primary) 7px
    );
}

.position-sd {
    background: var(--bg-secondary-light);
}
</style>
