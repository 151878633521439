<template>
    <div id="content-wrap" style="margin-left:90px">
        <div style="text-align:left; margin-top: 2vh; margin-left: 2vw">
            <h1 style="font-size: 7vh"><strong>Internal Server Error</strong></h1>
            <h3>Oops, something went wrong.</h3>
            <p>The server encountered an internal error and was unable to complete your request. <a href="/calendar"
                    style="color:var(--sportlight-teal)">Click to go back home</a></p>
        </div>

    </div>
</template>

<script>
import {UserData} from "@/components/UserData";

export default {
    data() { return {} },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        }
    },
    async goHome() {
        this.$router.push("/").catch(() => { });
    },
    async mounted() {
        console.log("ErrorPage.vue mounted");
        this.$root.newPageView("Error Page", UserData.userName());
        this.$router.push("/errorpage").catch(() => { });
    }
};
</script>