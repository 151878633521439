const GetChartContext = (canvasId, canvasParent, className) => {
    const canvasElement = document.getElementById(canvasId)
    if (canvasElement !== null) {
        return canvasElement.getContext("2d")
    } else {
        const canvas = document.createElement('canvas')
        canvas.id = canvasId
        canvas.className = className
        const canvasParentElement = document.getElementById(canvasParent)
        if (canvasParentElement) { canvasParentElement.appendChild(canvas) }
        return canvas.getContext("2d")
    }
}

export default GetChartContext