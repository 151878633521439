import Vue from "vue";
import Vuex from "vuex";
import { UserData } from "../components/UserData";

Vue.use(Vuex);

const state = {
	session: null,
	sessionIsAMatch: null,
	playersSessionReferenceGroup: null,
	teamSessionReferenceGroup: null,
	oppositionPeakAnalysisReferenceGroup: null,
	sessionIsAcademyMatch: null,
	sessionHasEnhancedTurns: false,
};

const mutations = {
	setSession(state, data) {
		state.session = data;
	},
	setSessionIsAMatch(state, sessionIsAMatch) {
		state.sessionIsAMatch = sessionIsAMatch;
	},
	setPlayersSessionReferenceGroup(state, text) {
		state.playersSessionReferenceGroup = text;
	},
	setTeamSessionReferenceGroup(state, text) {
		state.teamSessionReferenceGroup = text;
	},
	setOppositionPeakAnalysisReferenceGroup(state, text) {
		state.oppositionPeakAnalysisReferenceGroup = text;
	},
	setSessionIsAcademyMatch(state, sessionIsAcademyMatch) {
		state.sessionIsAcademyMatch = sessionIsAcademyMatch;
	},
	setSessionHasEnhancedTurns(state, sessionHasEnhancedTurns) {
		state.sessionHasEnhancedTurns = sessionHasEnhancedTurns;
	},
};

const actions = {
	loadSession({ commit }, data) {
		commit("setSession", data);
        commit("setSessionIsAMatch", data.session.sessionType.sessionIsAMatch);
        commit("setSessionHasEnhancedTurns", data.session.hasTurnsEnhanced);
        if (data.session.sessionType.isAcademyMatch) {
            commit("setPlayersSessionReferenceGroup", "competitive and academy");
            commit("setTeamSessionReferenceGroup", "academy");
            commit("setOppositionPeakAnalysisReferenceGroup", "academy");
            commit("setSessionIsAcademyMatch", data.session.sessionType.isAcademyMatch);
        } else {
            commit("setPlayersSessionReferenceGroup", "competitive");
            commit("setTeamSessionReferenceGroup", "competitive");
            commit("setOppositionPeakAnalysisReferenceGroup", UserData.teamType() === "m" ? "EPL" : "WSL");
            commit("setSessionIsAcademyMatch", false);
        }
	},
};

export default new Vuex.Store({ state, mutations, actions });