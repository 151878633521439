import Colours from "../../utils/Colours";
const rehabChartStages = {
    svg: null,
    rehabData: null,
    top: 390,
    left: 690,
    width: 265,
    rowHeight: 112,
    fontFamily: "Barlow",
    init: function (svg, rehabData) {
        this.svg = svg.group();
        this.rehabData = rehabData;
    },
    render: function () {
        this.svg.clear(); // clear any existing chart contents
        if (this.rehabData.targets != null) {
            this.drawStageHeader();
            if(this.rehabData.sessionKpis.length > 0) {
                this.drawMetric("peakSpeedMPerS",0);
                this.drawMetric("highSpeedDistanceM",1);
                this.drawMetric("highAccelerations",2);
                this.drawMetric("sprintDistanceM",3);
            }
        }
    },
    drawStageHeader() {
        const columnWidth = this.width/4;
        const labels = ["S1", "S2", "S3", "S4"];
        const rectHeight = 25;
        const rectAttrs = { fill: "none", stroke: Colours.WHITE, "stroke-width": 1 };
        const textAttrs = { family: this.fontFamily, size: 12, anchor: "middle", fill: Colours.WHITE };
        for (let i = 0; i < labels.length; i++) {
            const x = this.left + (columnWidth * i) + (columnWidth / 2);
            const rectX = this.left + (columnWidth * i);
            const textY = this.top - 8;
            this.svg.rect(columnWidth, rectHeight).attr({ ...rectAttrs, x: rectX, y: this.top - rectHeight });
            this.svg.text(labels[i]).font(textAttrs).attr({ x: x, y: textY });
        }
    },
    drawMetric(metric, row) {
        const columnWidth = this.width/4;
        const target = this.rehabData.targets[metric];
        const s = this.rehabData.sessionKpis;
        const rehabDate = new Date(this.rehabData.rehabDate);
        let maxValue = 0;

        s.forEach(s=>{
            const sessionDate = new Date(s.sessionDate);
            if (sessionDate > rehabDate && s[metric]>maxValue) {
                maxValue = s[metric];
            }
        });

        const s1Met = maxValue>=target * 0.4;
        const s2Met = maxValue>=target * 0.6;
        const s3Met = maxValue>=target * 0.75;
        const s4Met = maxValue>=target * 0.9;
        const rowTop = this.top + (this.rowHeight * row);

        const renderMetric = (value, index) => {
            const fill = sMet[index] ? Colours.SPORTLIGHT_TEAL : "none";
            const stroke = sMet[index] ? Colours.PRIMARY_GREY : Colours.WHITE;
            const x = this.left + columnWidth * index;
            this.svg.rect(columnWidth, this.rowHeight).attr({ fill, x, y: rowTop, stroke, "stroke-width": 1 });
            this.svg.text(value).font({ family: this.fontFamily, size: 12, anchor: "middle" }).attr({ x: x + columnWidth / 2, y: rowTop + this.rowHeight / 2, fill: stroke });
        };
        
        const sMet = [s1Met, s2Met, s3Met, s4Met];
        const values = ["40%", "60%", "75%", "90%"];
        
        values.forEach((value, index) => {
        renderMetric(value, index);
        });
    }

};

export { rehabChartStages };