import Colours from "../../utils/Colours";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
const rehabChartMini = {
    sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
    peakSpeedUnit: getMeasurementUnit(MeasurementTypes.PeakSpeed),
    svg: null,
    rehabData: null,
    top: 390,
    left: 950,
    width: 312,
    rowHeight: 112,
    xAxisOffset: 64,
    xAxisWidth: 200,
    yAxisHeight: 90,
    fontFamily: "Barlow",
    init: function (svg, rehabData) {
        this.svg = svg.group();
        this.rehabData = rehabData;
    },
    render: function () {
        this.svg.clear(); // clear any existing chart contents
        if (this.rehabData.targets !== null && this.rehabData.sessionKpis.length > 0) {
            this.renderMetric(0, "peakSpeedMPerS", this.peakSpeedUnit);
            this.renderMetric(1, "highSpeedDistanceM", this.sdUnit);
            this.renderMetric(2, "highAccelerations");
            this.renderMetric(3, "sprintDistanceM", this.sdUnit);
        }
    },
    renderMetric: function (rowPos, metric, unit = null) {
        const r = this.rehabData; // shortcut
        const rehabDate = new Date(r.rehabDate);

        let maxValue = this.convertValue(this.rehabData.targets[metric], unit);
        
        let maxDataValue = 0;

        r.sessionKpis.forEach(s => {
            const sessionDate = new Date(s.sessionDate);
            if (sessionDate > rehabDate) {
                const value = this.convertValue(s[metric], unit);
                if (value > maxValue)
                    maxValue = value;

                if (value > maxDataValue)
                    maxDataValue = value;
            }
        });

        maxValue = Math.ceil(maxValue);
        const yInterval = this.yAxisHeight / maxValue; // number of pixels per unit in y direction

        const targetY = this.renderTarget(rowPos, metric, yInterval, unit);

        this.renderMetricAverage(rowPos, metric, yInterval, unit);

        // sort the session KPIs by date
        r.sessionKpis.sort((a, b) => (new Date(a.sessionDate) > new Date(b.sessionDate)) ? 1 : -1);

        const inDate = r.sessionKpis.filter(s => new Date(s.sessionDate) > rehabDate);

        let minDate = rehabDate;
        let maxDate = rehabDate;
    
        if (inDate.length > 0) {
            minDate = new Date(inDate[0].sessionDate);
            maxDate = new Date(inDate[inDate.length - 1].sessionDate);
        }

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const widthDays = Math.round(Math.abs((maxDate - minDate) / oneDay)); // number of days for the x scale
        const xInterval = this.xAxisWidth / widthDays; // how many pixels each day takes

        const baseY = this.top + ((rowPos + 1) * this.rowHeight) - 6;


        for (var i = 0; i < inDate.length - 1; i++) {
            const leftSessionDate = new Date(inDate[i].sessionDate);
            const rightSessionDate = new Date(inDate[i + 1].sessionDate);
            const leftDays = Math.round(Math.abs((leftSessionDate - minDate) / oneDay));
            const rightDays = Math.round(Math.abs((rightSessionDate - minDate) / oneDay));
            
            const leftValue = this.convertValue(inDate[i][metric], unit);
            const rightValue = this.convertValue(inDate[i + 1][metric], unit);
            const leftValueY = baseY - (yInterval * leftValue);
            const rightValueY = baseY - (yInterval * rightValue);
            const sessionId = inDate[i].sessionId;
            this.svg.line(this.left + this.xAxisOffset + (xInterval * leftDays), leftValueY, this.left + this.xAxisOffset + (xInterval * rightDays), rightValueY).attr({ class: "session-line", stroke: Colours.WHITE, "stroke-width": 1.5, sessionId: sessionId });

            if (i === 0) {
                let yOffset = 4;
                const dy = leftValueY-targetY;
                if(dy<0 && dy>-10) {
                    yOffset = -6; // move up so that we don't overwrite the target
                } else if(dy>0 && dy<10) {
                    yOffset = 14; // move down so that we don't overwrite the target
                }
                this.svg.text(leftValue).font({ family: this.fontFamily, size: 12, anchor: "end" }).attr({ x: this.left + this.xAxisOffset + (xInterval * leftDays) - 4, y: leftValueY + yOffset, fill: Colours.WHITE, opacity: 0.8 });
            }

            if (i === inDate.length - 2) {
                this.svg.text(rightValue).font({ family: this.fontFamily, size: 12, anchor: "start" }).attr({ x: this.left + this.xAxisOffset + (xInterval * rightDays) + 4, y: rightValueY + 4, fill: Colours.WHITE, opacity: 0.8 });
            }

            if (leftValue === maxDataValue) {
                const xOffset = 0;
                let maxX = this.left + this.xAxisOffset + (xInterval * leftDays) + xOffset;
                if(maxX<this.left + this.xAxisOffset + 6) {
                    maxX=maxX+10; // adjust positioning so we don't overlap
                }
                this.svg.text(leftValue).font({ family: this.fontFamily, size: 12, anchor: "middle" }).attr({ x: maxX, y: leftValueY - 4, fill: Colours.WHITE, opacity: 0.8 });
            }
        }


    },
    renderMetricAverage: function (rowPos, metric, yInterval, unit) {
        const r = this.rehabData; // shortcut
        const rehabDate = new Date(r.rehabDate);
        const weeklyData = [];
        r.weeklyKpis.forEach(w => {
            // get the sessions for this week
            const sessions = r.sessionKpis.filter(s => new Date(s.sessionDate) > rehabDate && w.sessionIds.includes(s.sessionId));
            if (sessions.length > 0) {
                sessions.sort((a, b) => (new Date(a.sessionDate) > new Date(b.sessionDate)) ? 1 : -1);
                let total = 0;
                sessions.forEach(s => total += s[metric]);

                weeklyData.push({
                    startDate: sessions[0].sessionDate,
                    average: this.convertValue(total / sessions.length, unit)
                });
            }

        });

        let minDate = rehabDate;
        let maxDate = rehabDate;
        
        if (weeklyData.length > 0) {
            minDate = new Date(weeklyData[0].startDate);
            maxDate = new Date(weeklyData[weeklyData.length - 1].startDate);
        }

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const widthDays = Math.round(Math.abs((maxDate - minDate) / oneDay)); // number of days for the x scale
        const xInterval = this.xAxisWidth / widthDays; // how many pixels each day takes

        const baseY = this.top + ((rowPos + 1) * this.rowHeight) - 6;

        for (var i = 0; i < weeklyData.length - 1; i++) {
            const leftWeekDate = new Date(weeklyData[i].startDate);
            const rightWeekDate = new Date(weeklyData[i + 1].startDate);
            const leftDays = Math.round(Math.abs((leftWeekDate - minDate) / oneDay));
            const rightDays = Math.round(Math.abs((rightWeekDate - minDate) / oneDay));
            const leftValue = weeklyData[i].average;
            const rightValue = weeklyData[i + 1].average;
            const leftValueY = baseY - (yInterval * leftValue);
            const rightValueY = baseY - (yInterval * rightValue);
            this.svg.line(this.left + this.xAxisOffset + (xInterval * leftDays), leftValueY, this.left + this.xAxisOffset + (xInterval * rightDays), rightValueY).attr({ stroke: Colours.SPORTLIGHT_TEAL, "stroke-width": 2 });
        }
    },
    renderTarget: function (rowPos, metric, yInterval, unit) {
        const targetValue = this.convertValue(this.rehabData.targets[metric], unit);
        const baseY = this.top + ((rowPos + 1) * this.rowHeight) - 6;
        const targetY = baseY - (yInterval * targetValue);
        this.svg.line(this.left + this.xAxisOffset, targetY, this.left + this.xAxisOffset + this.xAxisWidth, targetY).attr({ stroke: Colours.WHITE, "stroke-width": 1, "stroke-dasharray": "4 4", opacity: 0.8 });
        this.svg.text("T " + targetValue).font({ family: this.fontFamily, size: 12, anchor: "end" }).attr({ x: this.left + this.xAxisOffset - 4, y: targetY, fill: Colours.WHITE, opacity: 0.8 });
        return targetY;
    },

    highlightSessions(week) {
        // highlight all sessions for the specified week no

        // build a list of session ids for the specified week
        const sessionIds = [];
        this.rehabData.weeklyKpis.forEach(k => {
            if (k.weekNumber === parseInt(week))
                Array.prototype.push.apply(sessionIds, k.sessionIds);
        });

        // now loop through each line element and determine if it is in the list
        const allSessions = document.getElementsByClassName("session-line");
        Array.from(allSessions).forEach(s => {
            const sessionId = s.getAttribute("sessionId");
            if (sessionIds.includes(sessionId)) {

                // draw a new line in orange over the top
                const x1 = s.getAttribute("x1");
                const y1 = s.getAttribute("y1");
                const x2 = s.getAttribute("x2");
                const y2 = s.getAttribute("y2");
                this.svg.line(x1, y1, x2, y2).attr({ class: "highlight-line", stroke: "#ffa343", "stroke-width": 3 });
            }
        });
    },
    unHighlightSessions() {
        // remove all highlight elements
        const elements = document.getElementsByClassName("highlight-line");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    },
    convertValue(value, unit) {
        return unit === null ? value : parseFloat(unit.formatStandard(value));
    }
};

export { rehabChartMini };