<template>
    <div>
        <SectionTitle :title="'Significant Turn Summary'">
            <MoreInfo :id="'turn-info-modal'" :title="'Significant Turn Summary Description'">
                <SignificantTurnSummaryInfoText :includeAbnormalTurn="true" :includeEffortClassification="true" />
            </MoreInfo>
        </SectionTitle>
        <div>
            <EnhancedTurnIndicator />
            <p style="font-size: 12px; margin-left: 1vw;">(*) - abnormal turn asymmetry</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customerId: String,
    }
};

</script>