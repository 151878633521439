import * as msal from '@azure/msal-browser';

let msalApp;

export default {

    async configure(clientId) {
        //Return if already configured or missing clientId
        if (msalApp || !clientId) {
            return;
        }

        const config = {
            auth: {
                clientId: clientId,
                authority: 'https://login.microsoftonline.com/common'
            },
            cache: {
                cacheLocation: 'localStorage'
            }
        };
        msalApp = new msal.PublicClientApplication(config);
    },

    //Login with a popup
    async login(scopes = ['user.read', 'openid', 'profile', 'email']) {
        if (!msalApp) {
            return;
        }

        return msalApp.loginPopup({scopes, prompt: 'select_account'}).then((response) => {
            // After a successful login set the active account to be the user that just logged in
            msalApp.setActiveAccount(response.account);
        });
    },

    //Get logged-in user details
    getUser() {
        if (!msalApp) {
            return null;
        }

        const activeAccount = msalApp.getActiveAccount();
        console.log(activeAccount);
        return activeAccount;
    },

    //Clear user entry from localStorage
    clearLocal() {
        if (msalApp) {
            for (let entry of Object.entries(localStorage)) {
                let key = entry[0];
                if (key.includes('login.windows')) {
                    localStorage.removeItem(key);
                }
            }
        }
    }
}