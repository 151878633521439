<template>
    <img :width="width" :height="height"    :src="'data:image/jpeg;base64,'+base64EncodedImage" />
</template>

<script>

export default {
    props: {
        width: String,
        height: String,
        base64EncodedImage: String
    },
}
</script>

<style scoped>

</style>