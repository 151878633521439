<template>
    <div>
        <SectionTitle :title="'Individual Significant Turns'">
            <MoreInfo :id="'turn-scatters-more-info'" :title="'Individual Significant Turns'">
                Individual Significant Turns shows every significant turn made in the current session.
                The plots show the entry speed, turn angle and time taken for each turn where the time is measured
                between the end of the deceleration phase and the start of the acceleration phase. <br>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText v-if="!hasData" :message="'Turn Scatters Unavailable'"/>
        <div v-else>
            <EnhancedTurnIndicator />
            <TurnScattersLegend class="mb-3 w-25"/>
            <div id="scatter-container" class="container-fluid">
                <div v-for="(player, index) in this.sortedPlayers" :key="player.player.name">
                    <div class="text-center mb-0" style="font-size: 12px;">{{player.player.name}}</div>
                    <TurnScattersChart 
                        :id="'chart' + index" 
                        :turns="scatterTurns(player.turns)"
                        :additionalTooltipText="turnsScattersAdditionalTooltipText" 
                        :minSpeed="1"
                        :maxSpeed="scatterMaxSpeed" 
                        :displayYAxis="!(index % 3)" 
                        :maintainAspectRatio="false"
                        :maxAngle=scatterMaxAngle />
                    <p class="text-center pt-0 mt-0" style="font-size: 12px; height: 5vh;">Entry speed (<MeasurementUnit :type="MeasurementTypes.Speed" />)
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import {getMeasurementUnit, MeasurementTypes} from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";
import calculateMaxTurn from "@/utils/CalculateMaxTurn";

export default {
    data () {
        return {
            MeasurementTypes: MeasurementTypes,
            customerId: UserData.customerId(),
            hasData: true,
            sortedPlayers: [],
            scatterMaxSpeed: null,
            scatterMaxAngle: null,
            measurementUnit: getMeasurementUnit(MeasurementTypes.Speed)
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage);
        },
        async refreshData() {
            // get query string here
            this.sessionId = this.$route.params.id;
            await this.$root.executeTaskWithProgressBar(async () => await this.getTurns());
        },

        async getTurns() {
            const response = await this.$root.webApiGet(
                `/turns?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );

            if (response.status === 200) {
                const data = response.data;
                this.hasData = data.length > 0;
                if (this.hasData) {
                    const maxSpeeds = [];
                    const maxAngles = [];

                    data.forEach(playerData => {
                        const maxEntrySpeed = calculateMaxTurn(playerData.turns, 'entrySpeedMPerS');
                        const maxAngle = calculateMaxTurn(playerData.turns, 'angleDegrees');
                        maxSpeeds.push(maxEntrySpeed);
                        maxAngles.push(maxAngle);
                    });

                    const maxSpeed = Math.max(...maxSpeeds);
                    this.scatterMaxSpeed = this.measurementUnit.convert(maxSpeed >= 8 ? maxSpeed + 0.3 : 8);
                    this.scatterMaxAngle = Math.max(...maxAngles);

                    let tmpPlayersList = data.map(p => p);
                    tmpPlayersList.sort(
                        (a, b) =>
                            a.player.positionSortOrder - b.player.positionSortOrder
                    );
                    // ensure that there is only one update to the sortedPlayers array so that the template doesn't get rendered twice.
                    this.sortedPlayers = tmpPlayersList;
                }
            } else {
                errorHandler.error(response, this);
            }
        },
        turnsScattersAdditionalTooltipText(tooltipItem, data) {
            const timestamp = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].matchTime;
            return [timestamp && UserData.hasWipTurnScattersMatchTimes() ? ("Match Time: " + timestamp) : ""];
        },
        scatterTurns(turns) {
            return turns.map(turn => {
                return {
                    time: turn.twistingTimeS,
                    x: this.measurementUnit.formatFine(turn.entrySpeedMPerS),
                    y: turn.angleDegrees,
                    matchTime: turn.turnMatchTime
                };
            });
        },
    },
    async mounted() {
        console.log("TurnScatters.vue mounted");
        this.$root.newPageView("Turn Scatters Page", UserData.userName());
        await this.refreshData();
        UpdateSetting();
    }
};
</script>