<template>
    <div id="content-wrap" style="margin-left:90px;" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <MissingDataText v-if="!players" :message="'Selected squad is empty'" />
        <div v-else>
            <Header :updateSelection="refreshData" />
            <ActiveFilter />
            <div class="row mx-auto" style="width: 90vw;">
                <div class="col-12 w-100 mx-auto p-lg-0">
                    <div class="section-container w-100 mx-auto">
                        <SectionTitle :title="'Player KPI Benchmarking'">
                            <MoreInfo :id="'kpi-benchmarking-more-info'" title='Player KPI Benchmarking'>
                                <ul class="info-text">
                                    <li>Player KPI benchmarking displays a player's average full-match KPIs relative to
                                        {{ selectedReferenceGroupText }}. The coloured line represents the player’s
                                        {{ selectedPlayerCriteriaText }} full-match
                                        average and position specific benchmarks, while
                                        the box and whisker plot represents the distribution of {{
                                        selectedReferenceGroupText }}
                                        matches. Each metric includes data from every player in the match excluding
                                        goalkeepers.</li>
                                    <BoxWhiskerAdditionalInfoText currentDataText="selected player"
                                        :referenceDataText="selectedReferenceGroupText" />
                                </ul>
                            </MoreInfo>
                        </SectionTitle>
                        <MissingDataText v-if="!hasKpiBenchmarking" :message="'Insufficient data for player'" />
                        <div class="row mx-auto" v-else>
                            <KpiBenchmarkingPlot id='distance' :kpiAverage=kpiAverages[0]
                                :kpiPercentage=kpiPercentages[0] :rankingTotal=rankingTotal
                                :leagueRankingPercentage=leagueRankingPercentages[0] :ranking=ranking[0]
                                :rankingHoverClass=rankingHoverClass />

                            <KpiBenchmarkingPlot id='hsr' :kpiAverage=kpiAverages[1] :kpiPercentage=kpiPercentages[1]
                                :rankingTotal=rankingTotal :leagueRankingPercentage=leagueRankingPercentages[1]
                                :ranking=ranking[1] :rankingHoverClass=rankingHoverClass />

                            <KpiBenchmarkingPlot id='sprint-distance' :kpiAverage=kpiAverages[2]
                                :kpiPercentage=kpiPercentages[2] :rankingTotal=rankingTotal
                                :leagueRankingPercentage=leagueRankingPercentages[2] :ranking=ranking[2]
                                :rankingHoverClass=rankingHoverClass />

                            <KpiBenchmarkingPlot id='turns' :kpiAverage=kpiAverages[3] :kpiPercentage=kpiPercentages[3]
                                :rankingTotal=rankingTotal :leagueRankingPercentage=leagueRankingPercentages[3]
                                :ranking=ranking[3] :rankingHoverClass=rankingHoverClass />
                        </div>
                    </div>
                </div>
                <div class="col-12 w-100 mx-auto mt-2 px-lg-0">
                    <div class="section-container w-100 mx-auto">
                        <SectionTitle :title="'Peak Physical Capacity Profile'">
                            <MoreInfo :id="'peak-physical-capacity-profile-more-info'"
                                title='Peak Physical Capacity Profile'>
                                <ul>
                                    <li> Peak Physical Capacity displays the player's all time average/max peak periods
                                        compared to the {{ selectedReferenceGroupText }} data.
                                        The blue line shows current selected player's data and the rainbow is
                                        comparison data, which comes from all previous {{ selectedReferenceGroupText }}
                                        matches with at least 85 minutes of match time. A player requires at least 4
                                        full matches to have comparison data.
                                    </li>
                                </ul>
                            </MoreInfo>
                        </SectionTitle>
                        <div class="toggle-options">
                            <RadioButton :id="'epoch-options'" :modal="selectedEpochOption" :options="epochOptions"
                                :name="'epoch-radio-options'" @update-modal="selectedEpochOption = $event"
                                :task="toggleEpochValues" />
                        </div>
                        <MissingDataText v-if="!hasRainbowData" :message="'Insufficient data for player'" />
                        <div v-else>
                            <div class="mx-auto mb-4">
                                <div class="rainbow-chart-legend-container">
                                    <div class="rainbow-chart-legend" :style="{ background: RainbowColours.blue }">
                                    </div>
                                    <span class="rainbow-legend">{{ selectedPlayerName }} <span
                                            v-if="!hasValidMatches">*</span>
                                        <span :class="!hasValidMatches ? 'rainbow-legend-text' : 'hide-label'">Player
                                            has less than 4 matches</span>
                                    </span>
                                    <div class="rainbow-chart-legend" :style="{ background: RainbowColours.green }">
                                    </div>{{ rainbowLegendText }} 85%-100%
                                    <div class="rainbow-chart-legend" :style="{ background: RainbowColours.lime }">
                                    </div>{{rainbowLegendText }} 65%-85%
                                    <div class="rainbow-chart-legend" :style="{ background: RainbowColours.yellow }">
                                    </div>{{ rainbowLegendText }} 35%-65%
                                    <div class="rainbow-chart-legend" :style="{ background: RainbowColours.orange }">
                                    </div>{{ rainbowLegendText }} 15%-35%
                                    <div class="rainbow-chart-legend" :style="{ background: RainbowColours.red }">
                                    </div> {{rainbowLegendText }}0%-15%
                                </div>
                            </div>
                            <div class="row mt-4 p-0">
                                <div class="col-12 col-lg-6" id="distance-rainbow-container">
                                    <canvas id="distance-rainbow" class="rainbow-plot"> </canvas>
                                </div>
                                <div class="col-12 col-lg-6 mt-4 mt-lg-0" id="hsr-rainbow-container">
                                    <canvas id="hsr-rainbow" class="rainbow-plot"> </canvas>
                                </div>
                            </div>
                            <div class="row mt-4 p-0">
                                <div class="col-12 col-lg-6" id="sprint-rainbow-container">
                                    <canvas id="sprint-rainbow" class="rainbow-plot"> </canvas>
                                </div>
                                <div class="col-12 col-lg-6 mt-4 mt-lg-0" id="turns-rainbow-container">
                                    <canvas id="turns-rainbow" class="rainbow-plot"> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 w-100 mx-auto mt-4 px-lg-0">
                    <PeakThresholdCounts class="w-100 mx-auto" :distanceCounts="distanceCounts" :hsrCounts="hsrCounts"
                        :sprintCounts="sprintCounts" :turnCounts="turnCounts" :leagueText="selectedReferenceGroupText"
                        :title="'Peak Threshold Counts'"></PeakThresholdCounts>
                </div>
                <div class="col-12 w-100 mx-auto mt-2 px-lg-0">
                    <SprintCounts class="w-100 mx-auto" :hsr="kpiCountHsr" :sprint="kpiCountSprint"></SprintCounts>
                </div>
                <EditModal :id="'longitudinal-edit-modal'" :title="'Longitudinal View'" :cancel="clearFilter"
                    :cancelTitle="'Reset'" :okTitle="'Apply Filter'" :ok="applyFilter"
                    :cancelVariant="'danger standard-btn'" v-if="addModal">
                    <RadioButton :id="'player-radio-group'" :modal="monthSelected" :options="monthOptions"
                        :label="'Player View'" :name="'player-radio-options'" @update-modal="monthSelected = $event" />

                    <RadioButton :id="'team-radio-group'" :modal="teamViewSelected" :options="teamViewOptions"
                        :label="'Team View'" :name="'team-radio-options'" @update-modal="teamViewSelected = $event" />

                    <RadioButton :id="'team-radio-group'" :modal="leagueViewSelected" :options="leagueViewOptions"
                        :label="'League View'" :name="'league-radio-options'"
                        @update-modal="leagueViewSelected = $event" />
                </EditModal>
                <div class="col-12 w-100 mx-auto mt-2 px-lg-0">
                    <div class="section-container w-100 mx-auto">
                        <SectionTitle :title="'Force Velocity Profiling'">
                            <MoreInfo :id="'force-velocity-profiling-more-info'" title='Force Velocity Profiling'>
                                The Force Velocity Profiling shows the selected player's match derived "In-Situ" Force
                                Velocity
                                Profiles (Morin, 2021)
                                compared to the {{ selectedReferenceGroupText }} data.
                            </MoreInfo>
                        </SectionTitle>
                        <MissingDataText v-if="!hasFVData" :message="'Insufficient data for player'" />
                        <div v-else>
                            <span style="float: right; white-space: nowrap;">
                                <Button :id="'add-button'" :type="'primary'" :title="'Longitudinal View'"
                                    v-b-modal.longitudinal-edit-modal :onClick="filter" />
                            </span>
                            <div class="row w-100 mx-auto pt-4">
                                <div class="col-12 col-lg-9">
                                    <div>
                                        <p style="position:absolute; top: 0; left:70px; font-size: 10px">High Force <br>
                                            Low
                                            Velocity
                                        </p>
                                        <p style="position:absolute; top: 0; right:0; font-size: 10px">High Force <br>
                                            High
                                            Velocity
                                        </p>
                                        <p style="position:absolute; bottom: 80px; left:70px; font-size: 10px">Low Force
                                            <br>
                                            Low
                                            Velocity
                                        </p>
                                        <p style="position:absolute; bottom: 80px; right:0; font-size: 10px">Low Force
                                            <br>
                                            High
                                            Velocity
                                        </p>
                                        <canvas id="fv-scatter-container" style="height: 60vh; width: 65vw">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="row col-10 col-lg-3 mx-auto fv-kpi-summary p-4 d-flex flex-column mt-2"
                                    style="font-size: 12px;" id="fv-summary">
                                    <table class="mx-auto w-100 p-2 mt-auto" id="force-velocity-table">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <td>F0</td>
                                                <td>V0</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Player's All Time Average </td>
                                                <td>{{ teamData.allTimeAverageForce }}</td>
                                                <td>{{ teamData.allTimeAverageVelocity }}</td>
                                            </tr>
                                            <tr :style="{ display: displayToggledText }">
                                                <td>Team Average </td>
                                                <td>{{ teamData.teamAverageForce }}</td>
                                                <td>{{ teamData.teamAverageVelocity }}</td>
                                            </tr>
                                            <tr :style="{ display: displayToggledText }">
                                                <td>{{ leagueText }} Average </td>
                                                <td>{{ teamData.leagueAverageForce }}</td>
                                                <td>{{ teamData.leagueAverageVelocity }}</td>
                                            </tr>
                                            <tr :style="{ display: displayToggledPositionText }">
                                                <td>Team {{ positionText() }} Average </td>
                                                <td>{{ teamData.teamPositionAverageForce }}</td>
                                                <td>{{ teamData.teamPositionAverageVelocity }}</td>
                                            </tr>
                                            <tr :style="{ display: displayToggledPositionText }">
                                                <td>{{ leagueText }} {{ positionText() }} Average </td>
                                                <td>{{ teamData.leaguePositionAverageForce }}</td>
                                                <td>{{ teamData.leaguePositionAverageVelocity }}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="edit-session-modal" title="Edit Player" v-model="editModal" centered hide-header-close
                @ok="acceptEditPlayer" @cancel="cancelEditPlayer">
                <div class="alert alert-danger" v-if="this.editWarningText">
                    {{ this.editWarningText }}
                </div>
                <fieldset>
                    <div class="form-group">
                        <label for="edit-title">Player</label> <br>
                        <label for="edit-title">{{ selectedPlayerName }}</label>
                    </div>
                    <div class="form-group">
                        <label for="edit-type">Position</label>
                        <b-form-select id="edit-type" v-model="editPlayerPosition" :options="editPositionOptions">
                        </b-form-select>
                    </div>
                </fieldset>
            </b-modal>
        </div>
    </div>
</template>
<script>
import {Chart} from "chart.js";
import {errorHandler} from "@/components/ErrorHandler";
import {UserData} from "@/components/UserData";
import percentile from "percentile";
import GetChartContext from "@/components/GetChartContext";
import UpdateSetting from "@/utils/UpdateSetting";
import EditModal from "@/components/EditModal.vue";
import RadioButton from "@/components/RadioButton.vue";
import MissingDataText from "@/components/MissingDataText.vue";
import DrawRainbowCurves from "@/components/RainbowCurves";
import {RainbowColours} from "@/components/RainbowColours";
import Button from "@/components/Button.vue";
import PeakThresholdCounts from "@/views/PeakThresholdCounts.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Colours from "@/utils/Colours";
import SprintCounts from "./SprintCounts.vue";
import configStore from "@/store/config";
import DateUtils from "@/utils/DateUtils";
import {
    convertBwData,
    getMeasurementUnit,
    MeasurementTypes,
    convertRainbowComparisonData,
} from '@/utils/MeasurementSystem';
import ActiveFilter from "./ActiveFilter.vue";
import Header from './Header';
import store from './store';
import filterStore from './filterStore';
import KpiBenchmarkingPlot from './KpiBenchmarkingPlot';

export default {
    components: {
        SectionTitle,
        EditModal,
        RadioButton,
        MissingDataText,
        Button,
        PeakThresholdCounts,
        SprintCounts,
        Header,
        ActiveFilter,
        KpiBenchmarkingPlot
    },
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            points: null,
            playerId: null,
            positionId: null,
            teamData: [],
            data: null,
            positionToggled: filterStore.state.selectedPositionAverage === "posAvg",
            displayToggledText: '',
            displayToggledPositionText: 'none',
            rankingTotal: null,
            positionTotal: null,
            ranking: [],
            kpiAverages: [],
            kpiPercentages: [],
            leagueRankingPercentages: [],
            editModal: this.positionId === 999,
            positionData: [],
            editPositionOptions: [],
            editId: 0,
            editWarningText: "",
            editPlayerPosition: "",
            editSquadId: null,
            rankingHoverClass: "",
            addModal: false,
            monthSelected: null,
            monthOptions: [
                { text: '12 Months', value: 0 },
                { text: '6 Months', value: 1 },
            ],
            teamViewSelected: 0,
            teamViewOptions: [
                { text: 'Fixed', value: 0 },
                { text: 'Longitudinal', value: 1 },
                { text: 'Remove', value: 2 },
            ],
            leagueViewSelected: 0,
            leagueViewOptions: [
                { text: 'Show', value: 0 },
                { text: 'Remove', value: 1 },
            ],
            customerId: UserData.customerId(),
            rainbowCurveData: [],
            maxEpochActive: false,
            teamFV: null,
            leagueFV: null,
            selectedPlayerFV: null,
            datasets: null,
            rainbowLegendText: 'League ',
            hasValidMatches: null,
            greyBackground: Colours.PRIMARY_GREY,
            distanceCounts: null,
            hsrCounts: null,
            sprintCounts: null,
            turnCounts: null,
            hasKpiBenchmarking: true,
            hasRainbowData: true,
            hasFVData: true,
            teamType: UserData.teamType(),
            kpiCountHsr: null,
            kpiCountSprint: null,
            epochOptions: [
                { text: 'Epoch Average', value: 'avg' },
                { text: 'Epoch Max', value: 'max' }
            ],
            selectedEpochOption: 'avg'
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage);
        },
        async refreshData() {
            this.$root.executeTaskWithProgressBar(async () => {
                this.hasFVData = true;
                await this.getPlayers();
                filterStore.dispatch('initialiseFilters', { teamType: this.teamType, position: this.selectedPlayer.position });
                await this.getPlayer(this.selectedPlayer.playerId);
                this.positionId = this.selectedPlayer.positionSortOrder;
                this.editPlayerPosition = this.selectedPlayer.position;
                this.editSquadId = this.selectedPlayer.squadId;
                this.editModal = this.positionId === 999;
                this.hasRainbowData = true;
                this.hasKpiBenchmarking = true;
                this.resetPlots();
                await this.getForceVelocityData();
                await this.getKpis();
                await this.getCounts();
                await this.getRanking();
                await this.getRainbowCurveData();
                await this.getSprintCounts();
            });
        },
        async getPlayers() {
            await store.dispatch('getPlayers', this);
        },
        async getPlayer(playerId) {
            const url = `/playerdevelopment?customerId=${this.customerId}` +
                `&playerId=${playerId}` +
                `&referenceCriteriaId=${this.selectedReferenceGroup.value}` +
                `&playerCriteriaId=${this.selectedPlayerCriteria.value}`;
            
            const response = await this.$root.webApiGet(url);

            if (response.status === 200) {
                this.data = response.data;
                this.playerId = playerId;
                const teamData = this.data.forceVelocityAggregates;
                const playerData = this.data.playerAverageForceVelocity;
                this.rainbowCurveData = this.data.rainbowCurves;
                const getValue = (group, metric) => { return teamData[group][metric].toFixed(2); };
                this.teamData = {
                    allTimeAverageForce: playerData === null ? 'N/A' : playerData.force.toFixed(2),
                    allTimeAverageVelocity: playerData === null ? 'N/A' : playerData.velocity.toFixed(2),
                    teamAverageForce: getValue('teamAverageForceVelocity', 'force'),
                    teamAverageVelocity: getValue('teamAverageForceVelocity', 'velocity'),
                    leagueAverageForce: getValue('leagueAverageForceVelocity', 'force'),
                    leagueAverageVelocity: getValue('leagueAverageForceVelocity', 'velocity'),
                    teamPositionAverageForce: getValue('teamPositionAverageForceVelocity', 'force'),
                    teamPositionAverageVelocity: getValue('teamPositionAverageForceVelocity', 'velocity'),
                    leaguePositionAverageForce: getValue('leaguePositionAverageForceVelocity', 'force'),
                    leaguePositionAverageVelocity: getValue('leaguePositionAverageForceVelocity', 'velocity')
                };
                this.positionId = this.data.player.positionGroupId;
            } else {
                errorHandler.error(response, this);
            }
        },
        async updateFVPlot() {
            this.forceVelocityPlot.update();
        },
        drawForceVelocityPlot(datasets) {
            try {
                if (this.hasFVData) {
                    //get min & max fv values and add padding 
                    const minF = Math.ceil(this.data.minimumForce - 1);
                    const maxF = Math.ceil(this.data.maximumForce + 1);
                    const minV = Math.ceil(this.data.minimumVelocity - 2); // -2 here rather than -1 to deal with lopsidedness & points covering text
                    const maxV = Math.ceil(this.data.maximumVelocity + 1);

                    const combinedPlayersFV = this.teamFV.concat(this.leagueFV, this.selectedPlayerFV); //combine team, league & player fv values & get fv values 
                    const velocityValues = combinedPlayersFV.map(t => parseFloat(t.x)); //get velocity values
                    const forceValues = combinedPlayersFV.map(t => parseFloat(t.y)); //get force values

                    const getAverageFV = metric => this.positionToggled ?
                        this.data.forceVelocityAggregates.leaguePositionAverageForceVelocity[metric] :
                        this.data.forceVelocityAggregates.leagueAverageForceVelocity[metric]; //get leagues/position averages

                    const valuesAreEqual = (valueA, valueB) => Math.abs(valueA - valueB) < 0.005; //check if values are equal

                    let f0Percentiles;
                    let v0Percentiles;
                    const getAxes = (fv, min, max, values) => {
                        const array = [];

                        for (let i = min; i <= max; i += 0.01) { array.push(i); } //get values between min and max increase by 0.01

                        //get fv averages and 15, 35,... percentile values
                        const average = getAverageFV(fv);
                        const average15 = percentile(15, values);
                        const average35 = percentile(35, values);
                        const average65 = percentile(65, values);
                        const average85 = percentile(85, values);

                        fv === 'force' ? f0Percentiles = { average15, average35, average65, average85 } :
                            v0Percentiles = { average15, average35, average65, average85 }; //store fv percentiles

                        return array.map(t => valuesAreEqual(average, t) ? 'white' : valuesAreEqual(average15, t)
                            || valuesAreEqual(average35, t) || valuesAreEqual(average65, t) || valuesAreEqual(average85, t) ? '#474747' : ''); // return array with grid line colours
                    };

                    const v0Colours = getAxes('velocity', minV, maxV, velocityValues);
                    const f0Colours = getAxes('force', minF, maxF, forceValues).reverse();

                    if (this.forceVelocityPlot !== undefined) this.forceVelocityPlot.destroy(); //destroy any existing fv plot if it exists

                    Chart.Tooltip.positioners.custom = () => { return { x: 300, y: 90 }; };
                    const chartCtx = document.getElementById('fv-scatter-container').getContext("2d");
                    this.forceVelocityPlot = new Chart(chartCtx, {
                        data: {
                            datasets: datasets
                        },
                        options: {
                            plugins: {
                                tooltip: {
                                    position: 'myCustomPositioner'
                                }
                            },
                            onHover: (evt) => {
                                const hoveredElement = this.forceVelocityPlot.getElementAtEvent(evt);
                                if (hoveredElement.length === 0) this.getForceVelocityData();
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            spanGaps: true,
                            animation: false,
                            hover: { mode: null },
                            title: {
                                display: true,
                                text: 'Force Velocity Profiling',
                            },
                            legend: {
                                position: 'bottom',
                                labels: {
                                    filter: (legendItem) => (this.teamViewSelected === 1 && this.leagueViewSelected === 1 ? legendItem.datasetIndex <= 1 : legendItem.datasetIndex <= 2)
                                },
                                onClick: (e) => e.stopPropagation()
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        min: minF,
                                        stepSize: 0.01,
                                        max: maxF,
                                        maxRotation: 0,
                                        autoSkip: false,
                                        callback: value => { return value % 1 === 0 ? value : ''; }
                                    },
                                    gridLines: {
                                        display: true,
                                        color: f0Colours,
                                        lineWidth: 1.5,
                                        zeroLineColor: 'red',
                                        zeroLineWidth: 0,
                                        drawBorder: false,
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Average F0",
                                        fontColor: 'white',
                                        fontSize: 14,
                                    },
                                },
                                {
                                    position: 'right',
                                    ticks: {
                                        min: minF,
                                        stepSize: 0.01,
                                        max: maxF,
                                        maxRotation: 0,
                                        autoSkip: false,
                                        padding: 20,
                                        fontColor: 'grey',
                                        callback: value => {
                                            if (valuesAreEqual(value, f0Percentiles.average15)) {
                                                return '15%';
                                            } else if (valuesAreEqual(value, f0Percentiles.average35)) {
                                                return '35%';
                                            } else if (valuesAreEqual(value, f0Percentiles.average65)) {
                                                return '65%';
                                            }
                                            else if (valuesAreEqual(value, f0Percentiles.average85)) {
                                                return '85%';
                                            }
                                            else {
                                                return null;
                                            }
                                        }
                                    },
                                    gridLines: {
                                        display: false,
                                    },
                                    scaleLabel: {
                                        display: false,
                                    },
                                }
                                ],
                                xAxes: [{
                                    type: 'linear',
                                    position: 'bottom',
                                    ticks: {
                                        min: minV,
                                        max: maxV,
                                        stepSize: 0.01,
                                        maxRotation: 0,
                                        autoSkip: false,
                                        callback: value => { return value % 1 === 0 ? value : ''; }
                                    },
                                    gridLines: {
                                        display: true,
                                        color: v0Colours,
                                        lineWidth: 1.5,
                                        borderColor: 'red',
                                        zeroLineWidth: 0,
                                        drawBorder: false,
                                    },
                                    scaleLabel: {
                                        display: true,
                                        labelString: "Average V0",
                                        fontColor: 'white',
                                        fontSize: 14,
                                    },
                                },
                                {
                                    type: 'linear',
                                    position: 'top',
                                    ticks: {
                                        min: minV,
                                        stepSize: 0.01,
                                        max: maxV,
                                        padding: 0,
                                        minRotation: 90,
                                        autoSkip: false,
                                        fontColor: 'grey',
                                        callback: value => {
                                            if (valuesAreEqual(value, v0Percentiles.average15)) {
                                                return '15%';
                                            } else if (valuesAreEqual(value, v0Percentiles.average35)) {
                                                return '35%';
                                            } else if (valuesAreEqual(value, v0Percentiles.average65)) {
                                                return '65%';
                                            }
                                            else if (valuesAreEqual(value, v0Percentiles.average85)) {
                                                return '85%';
                                            }
                                            else {
                                                return null;
                                            }
                                        }
                                    },
                                    gridLines: {
                                        display: false,
                                    },
                                    scaleLabel: {
                                        display: false,
                                    },
                                }]
                            },
                            tooltips: {
                                enabled: true,
                                position: 'custom',
                                caretSize: 0,
                                filter: (tooltipItem) => {
                                    return !this.forceVelocityPlot.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].isMostRecent;
                                },
                                callbacks: {
                                    title: () => { },
                                    label: (tooltipItem) => {
                                        return ("(F0: " + tooltipItem.yLabel.toFixed(2) + ", V0: " + tooltipItem.xLabel.toFixed(2) + ")");
                                    },
                                    afterLabel: (tooltipItem) => {
                                        const data = this.forceVelocityPlot.data.datasets;
                                        const timePeriod = data[tooltipItem.datasetIndex].data[tooltipItem.index].timePeriod;

                                        if (timePeriod) {
                                            const name = data[tooltipItem.datasetIndex].data[tooltipItem.index].player;
                                            const team = data.filter(t => t.label === 'Team Longitudinal');

                                            for (const key in team) {
                                                team[key].data.map((t, i) => {
                                                    if (t.timePeriod === timePeriod) {
                                                        team[key].backgroundColor[i] = 'yellow';
                                                        this.forceVelocityPlot.update();
                                                    } else {
                                                        team[key].backgroundColor[i] = t.isMostRecent ? this.$data.greyBackground : Colours.SPORTLIGHT_TEAL;
                                                    }
                                                });
                                            }
                                            const player = data.filter(t => t.label.includes('Longitudinal'));
                                            if (player.length > 0) {
                                                player[0].data.map((t, i) => {
                                                    if (t.timePeriod === timePeriod) {
                                                        return player[0].backgroundColor[i] = 'yellow';
                                                    } else {
                                                        return player[0].backgroundColor[i] = t.isMostRecent ? this.$data.greyBackground : '#ffa343';
                                                    }
                                                });
                                            }
                                            return name + '\n' + timePeriod;
                                        } else {
                                            return data[tooltipItem.datasetIndex].data[tooltipItem.index].name;
                                        }
                                    }
                                },
                            },
                        }
                    });
                    this.updateFVPlot();
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getForceVelocityData() {
            try {
                this.hasFVData = this.data.playerAverageForceVelocity !== null;
                if (this.hasFVData) {
                    const playerName = this.players[this.selectedPlayerIdx].name;
                    const getFV = (fvData, isLeagueData) => {
                        const data = this.positionToggled ? fvData.filter(t => t.isSamePosition) : fvData;
                        return data.map(t => { return { name: isLeagueData ? undefined : t.playerName, x: t.averageForceVelocity.velocity, y: t.averageForceVelocity.force }; });
                    };
                    this.selectedPlayerFV = this.data.playerAverageForceVelocity === null ? { x: null, y: null }
                        : { x: this.data.playerAverageForceVelocity.velocity, y: this.data.playerAverageForceVelocity.force, id: this.data.player.playerId, name: this.data.player.name };
                    this.teamFV = getFV(this.data.teamPlayerForceVelocities, false);
                    this.leagueFV = getFV(this.data.playersForceVelocities, true);

                    const currentYear = DateUtils.getYear(new Date());
                    const currentMonth = DateUtils.getMonth(new Date());
                    const currentSeason = currentMonth >= 6 ? currentYear + '-' + (currentYear + 1) : (currentYear - 1) + '-' + currentYear;

                    const getQuarterText = (q, y) => {
                        const date = new Date(y);
                        const year = DateUtils.getYear(date);
                        const quarters = ['Jan - Mar ', 'Apr - Jun ', 'Jul - Sep ', 'Oct - Dec '];
                        return quarters[q - 1] + year;
                    };
                    const getSemiAnnualText = (q, y) => { return q === 1 ? 'Jan - Jun ' + y : 'Jul - Dec ' + y; };
                    const getQuarter = () => Math.floor(currentMonth / 3 + 1);

                    const currentQuarter = getQuarterText(getQuarter(), new Date());
                    const currentYearHalf = getSemiAnnualText(Math.ceil(currentMonth / 6), currentYear);

                    const teamPlayerQuarterlyForceVelocities = this.data.teamPlayerQuarterlyForceVelocities;
                    const playerQuarterlyForceVelocities = this.data.playerQuarterlyForceVelocities;
                    const teamPlayerSeasonForceVelocities = this.data.teamPlayerSeasonForceVelocities;
                    const playerSeasonForceVelocities = this.data.playerSeasonForceVelocities;
                    const teamPlayerSemiAnnualForceVelocities = this.data.teamPlayerSemiAnnualForceVelocities;
                    const playerSemiAnnualForceVelocities = this.data.playerSemiAnnualForceVelocities;

                    const playerLongitudinalFV = [];
                    const teamLongitudinalFV = [];

                    const getLongitudinalFV = (container, allFvData, currentPeriod, property, isPlayer) => {

                        const fvDataToDisplay = !isPlayer && this.positionToggled ? allFvData.filter(t => t.isSamePosition) : allFvData;
                        for (const key in fvDataToDisplay) {
                            const fv = isPlayer ? fvDataToDisplay[key] : fvDataToDisplay[key][property];
                            const player = isPlayer ? this.data.player : fvDataToDisplay[key];
                            const data = fv.map(t => { return { x: t.averageForceVelocity.velocity, y: t.averageForceVelocity.force, timePeriod: getTimePeriod(t), player: player.name, count: t.count, style: t.style = 'circle', isSamePosition: player.isSamePosition }; });
                            const currentPeriodFV = data.filter(t => t.timePeriod === currentPeriod);
                            const previousPeriodFV = data.filter(t => t.timePeriod !== currentPeriod);
                            container.push(previousPeriodFV.concat(currentPeriodFV));
                        }
                    };
                    let getTimePeriod;
                    if (this.monthSelected === 0) {
                        getTimePeriod = t => t['season'];
                        getLongitudinalFV(teamLongitudinalFV, teamPlayerSeasonForceVelocities, currentSeason, 'seasonForceVelocities', false);
                        getLongitudinalFV(playerLongitudinalFV, [playerSeasonForceVelocities], currentSeason, null, true);

                    } else if (this.monthSelected === 1) {
                        getTimePeriod = t => getSemiAnnualText(t['yearHalf'], t['year']);
                        getLongitudinalFV(teamLongitudinalFV, teamPlayerSemiAnnualForceVelocities, currentYearHalf, 'semiAnnualForceVelocities', false);
                        getLongitudinalFV(playerLongitudinalFV, [playerSemiAnnualForceVelocities], currentYearHalf, null, true);

                    } else {
                        getTimePeriod = t => getQuarterText(t['quarterId'], t['quarterStartDate']);
                        getLongitudinalFV(teamLongitudinalFV, teamPlayerQuarterlyForceVelocities, currentQuarter, 'quarterlyForceVelocities', false);
                        getLongitudinalFV(playerLongitudinalFV, [playerQuarterlyForceVelocities], currentQuarter, null, true);
                    }

                    const highlightMostRecentData = data => {
                        if (data.length > 0) {
                            const mostRecentXValue = data[data.length - 1].x;
                            const mostRecentYValue = data[data.length - 1].y;
                            data.splice([data.length - 1], 0, { x: mostRecentXValue, y: mostRecentYValue, style: 'circle', isMostRecent: true });
                        }
                    };

                    for (const key in teamLongitudinalFV) highlightMostRecentData(teamLongitudinalFV[key], 'circle');
                    highlightMostRecentData(playerLongitudinalFV.flat(), 'circle');

                    const getFixedDataset = (chartLabel, values, colour, style, orderStack, width, radius) => {
                        return {
                            type: 'scatter',
                            label: chartLabel,
                            data: values,
                            fill: false,
                            borderColor: colour,
                            backgroundColor: colour,
                            borderWidth: width,
                            pointStyle: style,
                            order: orderStack,
                            pointRadius: radius,
                        };
                    };

                    const getLongitudinalDataset = (chartLabel, values, colour, orderStack) => {
                        return {
                            type: 'scatter',
                            label: chartLabel,
                            data: values,
                            fill: false,
                            borderColor: colour,
                            backgroundColor: values.map(t => t.isMostRecent ? this.greyBackground : colour),
                            borderWidth: 1,
                            pointRadius: values.map(t => t.isMostRecent ? 6 : 4),
                            order: orderStack,
                            borderDash: [10, 0],
                            showLine: true,
                            tension: 0,
                        };
                    };

                    const datasets = [];

                    if (this.monthSelected === null) { datasets.push(getFixedDataset(playerName, [this.selectedPlayerFV], '#ffa343', 'cross', 2, 6, 9)); }
                    else { datasets.push(getLongitudinalDataset(`${playerName} Longitudinal`, playerLongitudinalFV.flat(), '#ffa343', 1)); }

                    if (this.leagueViewSelected === 0) datasets.push(getFixedDataset(this.leagueText, this.leagueFV, 'grey', 'triangle', 5, 2, 3));

                    if (this.teamViewSelected !== 2) {
                        if (this.teamViewSelected === 0) {
                            datasets.push(getFixedDataset('Team', this.teamFV, Colours.SPORTLIGHT_TEAL, 'circle', 2, 2, 4));
                        } else {
                            for (const key in teamLongitudinalFV) datasets.push(getLongitudinalDataset('Team Longitudinal', teamLongitudinalFV[key], Colours.SPORTLIGHT_TEAL, 4));
                        }
                    }
                    this.drawForceVelocityPlot(datasets);
                }
            } catch (error) {
                console.log(error);
            }
        },
        metricNullChecker(kpi, decimalPlaces) {
            return kpi === null ? 0 : parseFloat(kpi.toFixed(decimalPlaces));
        },
        drawBoxPlot(chartCtx, kpi, title, decimalPlaces, padding) {
            const playerKpi = this.metricNullChecker(kpi.value, decimalPlaces);
            const playerPadding = playerKpi + padding * 0.1;
            const min = this.metricNullChecker(kpi.minimum, decimalPlaces);
            const std = kpi.standardDeviation || 0;
            const avg = this.metricNullChecker(kpi.average, decimalPlaces);
            const max = this.metricNullChecker(kpi.maximum, decimalPlaces);

            const lowerBound = avg - std;
            const upperBound = avg + std;
            const minAvg = [min, lowerBound];
            const stdAvg = [lowerBound, upperBound];
            const avgMax = [upperBound, max];
            const minValues = kpi.minimum === null || kpi.average === null || kpi.standardDeviation === null;
            const maxValue = Math.max(playerKpi, max) + padding;

            const colourIndicator = playerKpi > upperBound && minValues !== true ? '#39FF14' :
                playerKpi < lowerBound && minValues !== true ? '#FF0000' : Colours.SPORTLIGHT_TEAL;

            const minBorderWidth = min <= lowerBound ? 0.1 : 0;
            const maxBorderWidth = max >= lowerBound ? 0.1 : 0;

            const roundMaxDistance = maxValue > 0 ? Math.ceil(maxValue / padding) * padding : maxValue + padding;

            new Chart(chartCtx, {
                type: 'horizontalBar',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: title,
                        maintainAspectRatio: false,
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        position: 'nearest',
                        callbacks: {
                            title: () => { },
                            label: function (tooltipItem, data) {
                                const barLabel = data.datasets[tooltipItem.datasetIndex].label;
                                const barValue = JSON.parse(tooltipItem.xLabel);
                                if (barLabel === 'Lower') {
                                    return null;
                                }
                                if (barLabel === 'Max') {
                                    return barLabel + ": " + barValue[1];
                                } else {
                                    return barLabel + ": " + barValue[0];
                                }
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            position: 'bottom',
                            stacked: false,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                                beginAtZero: true,
                                min: 0,
                                max: roundMaxDistance,
                                stepSize: padding
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            gridLines: {
                                display: false,
                                lineWidth: 0,
                            },
                            ticks: {
                                display: false,
                            }
                        }]
                    }
                },
                data: {
                    datasets: [
                        {
                            label: 'Player Average',
                            data: [[playerKpi, playerPadding]],
                            backgroundColor: colourIndicator,
                            barPercentage: 0.5,
                            categoryPercentage: 0.5,
                        },
                        {
                            label: 'Min',
                            data: [minAvg],
                            backgroundColor: this.$data.greyBackground,
                            borderColor: '#8F979B',
                            borderWidth: 1,
                            barPercentage: minBorderWidth,
                            categoryPercentage: minBorderWidth,
                        },
                        {
                            label: 'Lower',
                            data: [stdAvg],
                            backgroundColor: this.$data.greyBackground,
                            borderColor: '#8F979B',
                            borderWidth: 1,
                            barPercentage: 0.5,
                            categoryPercentage: 0.5,
                        },
                        {
                            label: 'Average',
                            data: [[avg, avg]],
                            backgroundColor: this.$data.greyBackground,
                            borderColor: '#8F979B',
                            borderWidth: 1,
                            barPercentage: 0,
                            categoryPercentage: 0,
                        },
                        {
                            label: 'Max',
                            data: [avgMax],
                            backgroundColor: this.$data.greyBackground,
                            borderColor: '#8F979B',
                            borderWidth: 1,
                            barPercentage: maxBorderWidth,
                            categoryPercentage: maxBorderWidth,
                        }],
                }
            });
        },
        async getKpis() {
            try {
                if (this.data != null) {
                    const kpis = this.positionToggled ? this.data.positionKpis : this.data.leagueKpis;
                    this.hasKpiBenchmarking = kpis.distance.value !== null;
                    if (this.hasKpiBenchmarking) {
                        const className = 'player-development-kpi-plot';
                        const distanceChartCtx = GetChartContext('distance-container', 'distance-main-container', className);
                        const hsrChartCtx = GetChartContext('hsr-container', 'hsr-main-container', className);
                        const turnsChartCtx = GetChartContext('turns-container', 'turns-main-container', className);
                        const sprintDistanceChartCtx = GetChartContext('sprint-distance-container', 'sprint-distance-main-container', className);

                        this.drawBoxPlot(distanceChartCtx, convertBwData(kpis.distance, this.ldUnit), `Distance (${this.ldUnit.unitShort})`,
                            this.ldUnit.decimalPlacesStandard, this.ldUnit.convert(1200));
                        this.drawBoxPlot(hsrChartCtx, convertBwData(kpis.hsd, this.sdUnit), `HSR (${this.sdUnit.unitShort})`,
                            this.sdUnit.decimalPlacesStandard, this.sdUnit.convert(250));
                        this.drawBoxPlot(turnsChartCtx, kpis.turns, 'Turns', 1, 10);
                        this.drawBoxPlot(sprintDistanceChartCtx, convertBwData(kpis.sprintDistance, this.sdUnit), `Sprint Distance (${this.sdUnit.unitShort})`,
                            this.sdUnit.decimalPlacesStandard, this.sdUnit.convert(50));
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        getCounts() {
            try {
                if (this.data != null) {
                    const countMetrics = this.positionToggled ? this.data.positionPeakThresholdCountMetrics.kpiEpochCounts : this.data.averagePeakThresholdCountMetrics.kpiEpochCounts;
                    this.distanceCounts = countMetrics.DISTANCE;
                    this.hsrCounts = countMetrics.HSR;
                    this.sprintCounts = countMetrics.SPRINT;
                    this.turnCounts = countMetrics.TURNS;
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getRanking() {
            if (this.data != null) {
                const kpis = this.positionToggled ? this.data.positionKpis : this.data.leagueKpis;
                const { distance, hsd, turns, sprintDistance } = kpis;

                const playerAverageDistance = this.metricNullChecker(this.ldUnit.convert(distance.value), this.ldUnit.decimalPlacesStandard);
                const maxDistance = this.metricNullChecker(this.ldUnit.convert(distance.maximum), this.ldUnit.decimalPlacesStandard);
                const playerAverageHSR = this.metricNullChecker(this.sdUnit.convert(hsd.value), this.sdUnit.decimalPlacesStandard);
                const maxHSR = this.metricNullChecker(this.sdUnit.convert(hsd.maximum), this.sdUnit.decimalPlacesStandard);
                const playerAverageSprintDistance = this.metricNullChecker(this.sdUnit.convert(sprintDistance.value), this.sdUnit.decimalPlacesStandard);
                const maxSprintDistance = this.metricNullChecker(this.sdUnit.convert(sprintDistance.maximum), this.sdUnit.decimalPlacesStandard);
                const playerAverageTurns = this.metricNullChecker(turns.value, 1);
                const maxTurns = this.metricNullChecker(turns.maximum, 1);

                const hasRanks = this.data.leagueRanks !== null;
                this.rankingHoverClass = hasRanks ? 'hide-label' : 'ranking-label-text';
                const label = this.positionToggled ? this.positionText() + ' Ranking: ' : this.leagueText.concat(' Ranking: ');

                const getPercentage = (round, avg, max) => { return round((avg / max) > 1 ? 100 : (avg / max) * 100) + '%'; };
                const distancePercent = getPercentage(Math.ceil, playerAverageDistance, maxDistance);
                const hsrPercent = getPercentage(Math.ceil, playerAverageHSR, maxHSR);
                const sprintDistancePercent = getPercentage(Math.ceil, playerAverageSprintDistance, maxSprintDistance);
                const turnsPercent = getPercentage(Math.floor, playerAverageTurns, maxTurns);

                this.kpiPercentages = [distancePercent, hsrPercent, sprintDistancePercent, turnsPercent];
                this.kpiAverages = [playerAverageDistance, playerAverageHSR, playerAverageSprintDistance, playerAverageTurns];

                if (hasRanks) {
                    const ranks = this.positionToggled ? this.data.positionRanks : this.data.leagueRanks;
                    const numberOfPlayers = ranks.count;
                    this.rankingTotal = ranks.count;

                    //stop each rank from being higher than total, causing negative percentage rank
                    ranks.distanceRank = Math.min(this.rankingTotal, ranks.distanceRank);
                    ranks.hsdRank = Math.min(this.rankingTotal, ranks.hsdRank);
                    ranks.turnRank = Math.min(this.rankingTotal, ranks.turnRank);
                    ranks.speedDistanceRank = Math.min(this.rankingTotal, ranks.speedDistanceRank);

                    this.ranking = [label + ranks.distanceRank + '/', label + ranks.hsdRank + '/', label + ranks.speedDistanceRank + '/', label + ranks.turnRank + '/'];

                    const getRankPercentage = (rank, total) => {
                        return rank === 1 ? '(100%)' : '(' + Math.floor((((total - rank) / total) * 100)) + '%)';
                    };

                    const distanceRank = getRankPercentage(ranks.distanceRank, numberOfPlayers);
                    const hsrRank = getRankPercentage(ranks.hsdRank, numberOfPlayers);
                    const turnsRank = getRankPercentage(ranks.turnRank, numberOfPlayers);
                    const sprintDistanceRank = getRankPercentage(ranks.speedDistanceRank, numberOfPlayers);

                    this.leagueRankingPercentages = [distanceRank, hsrRank, sprintDistanceRank, turnsRank];
                } else {
                    this.ranking = [label + 'N/A', label + 'N/A', label + 'N/A', label + 'N/A'];
                    this.leagueRankingPercentages = [];
                    this.rankingTotal = '';
                }
            }
        },
        toggleAverageOption() {
            this.positionToggled = !this.positionToggled;
            this.resetPlots();
            this.getForceVelocityData();
            this.getKpis();
            this.getCounts();
            this.getRanking();
            this.getRainbowCurveData();
            this.getSprintCounts();
            this.displayToggledText = this.positionToggled ? 'none' : '';
            this.rainbowLegendText = this.positionToggled ? this.positionText() : this.leagueText.concat(' ');
            this.displayToggledPositionText = this.positionToggled === false ? 'none' : '';
        },
        async getPositions() {
            configStore.state.positions.forEach(p => {
                this.positionData.push({
                    positionId: p.positionId,
                    position: p.description
                });
            });
            this.editPositionOptions = this.positionData.map(m => m.position);
        },
        async acceptEditPlayer() {
            this.editWarningText = "";
            for (let i = 0; i < this.positionData.length; i++) {
                const positions = this.positionData[i];
                if (positions.position === this.editPlayerPosition) {
                    this.positionId = positions.positionId;
                }
            }
            const json = { playerId: this.playerId, positionId: this.positionId, customerId: this.customerId, squadId: this.editSquadId };
            const response = await this.$root.webApiPost(`/player`, json);
            if (response.status === 200) {
                this.editModal = false;
                this.session = [];
                await this.getPlayers();
            } else {
                errorHandler.error(response, this);
            }
        },
        cancelEditPlayer() {
            this.editId = 0;
            this.editModal = false;
        },
        async filter() {
            this.addModal = true;
        },
        async applyFilter() {
            await this.getPlayer(this.playerId);
            await this.getForceVelocityData();
        },
        async clearFilter() {
            this.teamViewSelected = 0;
            this.leagueViewSelected = 0;
            this.monthSelected = null;
        },
        toggleEpochValues() {
            this.maxEpochActive = !this.maxEpochActive;
            Array.from(document.getElementsByClassName('rainbow-plot')).forEach(e => e.remove()); //remove any existing rainbow plot
            this.getRainbowCurveData();
        },
        async getRainbowCurveData() {
            const playerData = this.rainbowCurveData.playerData;
            const leagueData = this.rainbowCurveData.leagueComparisonData;
            const positionData = this.rainbowCurveData.positionComparisonData;
            const data = this.positionToggled ? positionData : leagueData;
            const epochValues = this.maxEpochActive ? data.maxData : data.averageData;
            const playerEpochValues = this.maxEpochActive ? playerData.maxData : playerData.averageData;
            this.hasValidMatches = playerData.validMatchCount >= 4;
            this.hasRainbowData = Object.values(playerEpochValues).map(arr => arr.length).every(len => len !== 0);
            if (this.hasRainbowData) {
                const className = 'rainbow-plot';
                const hsrChartCtx = GetChartContext('hsr-rainbow', 'hsr-rainbow-container', className);
                const distanceChartCtx = GetChartContext('distance-rainbow', 'distance-rainbow-container', className);
                const sprintChartCtx = GetChartContext('sprint-rainbow', 'sprint-rainbow-container', className);
                const turnsChartCtx = GetChartContext('turns-rainbow', 'turns-rainbow-container', className);

                const convertPlayerEpochValues = data => data.map(v => this.sdUnit.convert(v));
                const unit = `${this.sdUnit.unitShort}/min`;
                const yAxesLabel = `Distance (${unit})`;

                DrawRainbowCurves(hsrChartCtx, 'Peak HSR Per Epoch', convertRainbowComparisonData(epochValues.hsrPeakPerEpoch, this.sdUnit),
                    convertPlayerEpochValues(playerEpochValues.hsrPeakPerEpoch), true, null, 50, unit, yAxesLabel, null);
                DrawRainbowCurves(distanceChartCtx, 'Peak Distance Per Epoch', convertRainbowComparisonData(epochValues.distancePeakPerEpoch, this.sdUnit),
                    convertPlayerEpochValues(playerEpochValues.distancePeakPerEpoch), true, null, 50, unit, yAxesLabel, null);
                DrawRainbowCurves(sprintChartCtx, 'Peak Sprint Per Epoch', convertRainbowComparisonData(epochValues.sprintPeakPerEpoch, this.sdUnit),
                    convertPlayerEpochValues(playerEpochValues.sprintPeakPerEpoch), true, null, 50, unit, yAxesLabel, null);
                DrawRainbowCurves(turnsChartCtx, 'Peak Turns Per Epoch', epochValues.turnsPeakPerEpoch,
                    playerEpochValues.turnsPeakPerEpoch, true, null, 1, 'N/min', 'Turns (N/min)', null);
            }
        },
        async getSprintCounts() {
            if (this.data.positionKpiCounts !== null && this.data.averageKpiCounts !== null) {
                this.kpiCountHsr = this.positionToggled ? this.data.positionKpiCounts.HSR : this.data.averageKpiCounts.HSR;
                this.kpiCountSprint = this.positionToggled ? this.data.positionKpiCounts.SPRINT : this.data.averageKpiCounts.SPRINT;
            } else {
                this.kpiCountHsr = null;
                this.kpiCountSprint = null;
            }
        },
        resetPlots() {
            Array.from(document.getElementsByClassName('player-development-kpi-plot')).forEach(e => e.remove()); //remove existing kpi plots
            Array.from(document.getElementsByClassName('rainbow-plot')).forEach(e => e.remove()); //remove existing rainbow curve plots
        },
        lookupCriteria(value) {
            return this.criteriaOptions.find(option => option.value === value);
        },
        positionText() {
            return this.selectedPlayer.position;
        }
    },
    async mounted() {
        this.$root.executeTaskWithProgressBar(async () => {

            console.log("PlayerDevelopment.vue mounted");
            this.$root.newPageView("Player Development Page", UserData.userName());
            await this.getPlayers();
            filterStore.dispatch('initialiseFilters', { teamType: this.teamType, position: this.selectedPlayer.position });

            // load the page with the first enabled player
            if (this.players) {
                this.$root.executeTaskWithProgressBar(async () => {
                    this.hasFVData = true;
                    await this.getPlayer(this.selectedPlayer.playerId);
                    await this.getPositions();
                    await this.getForceVelocityData();
                    await this.getKpis();
                    await this.getCounts();
                    await this.getRanking();
                    await this.getRainbowCurveData();
                    await this.getSprintCounts();
                });
                this.editPlayerPosition = this.players[this.selectedPlayerIdx].position;
                this.editSquadId = this.players[this.selectedPlayerIdx].squadId;
                // only enable the editPlayer modal when the players position is unknown
                this.editModal = this.selectedPlayer.positionSortOrder === 999;
            } else {
                this.editPlayerPosition = 0;
                this.editModal = false;
            }

            UpdateSetting();
        });
    },
    computed: {
        UserData() {
            return UserData;
        },
        RainbowColours() {
            return RainbowColours;
        },
        selectedPlayerName() {
            return this.players.length > 0 ? this.players[this.selectedPlayerIdx].name : '';
        },
        leagueText() {
            return this.selectedReferenceGroup.text;
        },
        players() {
            return store.state.players;
        },
        selectedPlayerIdx() {
            return store.state.selectedPlayerIdx;
        },
        selectedPlayer() {
            return store.state.selectedPlayer;
        },
        selectedPlayerCriteria() {
            return filterStore.state.selectedPlayerCriteria;
        },
        selectedReferenceGroup() {
            return filterStore.state.selectedReferenceGroup;
        },
        selectedPlayerCriteriaText() {
            return filterStore.state.selectedPlayerCriteria.text;
        },
        selectedReferenceGroupText() {
            return this.selectedReferenceGroup.text;
        },
        selectedPositionAverage() {
            return filterStore.state.selectedPositionAverage;
        },
        combinedCriteria() {
            //ensure refreshData gets called once when player data & reference group data change
            return `${this.selectedPlayerCriteria.value}-${this.selectedReferenceGroup.value}`;
        }
    },
    watch: {
        combinedCriteria() {
            this.refreshData();
        },
        selectedPositionAverage() {
            this.toggleAverageOption();
        }
    },
};
</script>
<style scoped>
#player-dropdown /deep/ .dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}

.criteria-options-container {
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
}
.toggle-options{
    margin: 2vh auto auto;
    width:90vw
}
</style>