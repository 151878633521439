<template>
    <div id="content-wrap" style="margin-left:90px" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <div>
            <PageHeader>
                <PageHeaderTitle>
                    <h1 class="d-flex align-content-center">
                        Player Performance Standards 
                        <MoreInfo :id="'team-info-modal'" :title="'Player Performance Standards'">
                            <p>
                                Player Performance Standards displays a player’s average full-match
                                physical outputs relative to {{ teamType === 'm' ? 'EPL' : 'WSL' }}-wide, position-specific benchmarks, presented across the squad for comparison.
                            </p>
                        </MoreInfo> 
                    </h1>
                </PageHeaderTitle>
            </PageHeader>
            <PageLayout>
                <MissingDataText :message="'Squad Data Unavailable'" v-if="!hasData" />
                <div v-else>
                    <RainbowLegend :showBlueKey=false :keyText="'Position'" />
                    <div>
                        <SquadRainbowTable v-for="positionData in filteredPositions" :key="positionData.id" :id="positionData.id" :positionLabel="positionData.label" :data="positionData.data" />
                    </div>
                </div>
            </PageLayout>
        </div>
    </div>
</template>

<script>
import { UserData } from "../components/UserData";
import { errorHandler } from "../components/ErrorHandler";
import PageLayout from '../components/PageLayout.vue';
import PageHeader from '../components/PageHeader.vue';
import PageHeaderTitle from '../components/PageHeaderTitle.vue';
import MissingDataText from '../components/MissingDataText.vue';
import MoreInfo from '../components/MoreInfo.vue';
import SquadRainbowTable from "./SquadRainbowTable.vue";
import RainbowLegend from "../components/RainbowLegend.vue";
import UpdateSetting from "@/utils/UpdateSetting";
import { MeasurementTypes, getMeasurementUnit, convertRainbowPercentileComparisonData } from "@/utils/MeasurementSystem";

export default {
    components: {
        PageHeader,
        PageLayout,
        MissingDataText,
        MoreInfo,
        SquadRainbowTable,
        RainbowLegend,
        PageHeaderTitle
    },
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            customerId: UserData.customerId(),
            teamType: UserData.teamType(),
            hasData: true,
            data: null,
            positionData: [],
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
            console.log(newPage);
        },
        async refreshData() {
            this.$root.executeTaskWithProgressBar(async () => {
                await this.getData();
                await this.getSquadRainbowTable();
            });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/squad?customerId=${this.customerId}`
            );
            if (response.status === 200) {
                this.data = response.data;
                this.hasData = Object.keys(this.data.playerKpiValuesByPositionId).length > 0;
            } else {
                errorHandler.error(response, this);
            }
        },
        async getSquadRainbowTable() {
            if (this.hasData) {
                const playersData = this.data.playerKpiValuesByPositionId;
                const positionData = this.data.positionKpiPercentileValuesByPositionId;
                this.hasData = playersData !== null && positionData !== null;

                const convertPercentileValues = (data, measurementUnit) => {
                    data.average = measurementUnit.convert(data.average);
                    return data;
                };

                const getPositionData = positionSelected => {
                    if (playersData[positionSelected] !== undefined) {
                        const positionalData = [];
                        for (const i in playersData[positionSelected]) {
                            const data = playersData[positionSelected][i];
                            const comparisonData = positionData[positionSelected].kpiPercentileValues;
                            const { player, distance, hsd, sprintDistance, turns, accelerations, decelerations } = data;
                            const distanceData = {
                                value: convertPercentileValues(distance, this.ldUnit),
                                comparisonData: convertRainbowPercentileComparisonData(comparisonData.distance, this.ldUnit)
                            };
                            const hsdData = {
                                value: convertPercentileValues(hsd, this.sdUnit),
                                comparisonData: convertRainbowPercentileComparisonData(comparisonData.hsd, this.sdUnit)
                            };
                            const sprintDistanceData = {
                                value: convertPercentileValues(sprintDistance, this.sdUnit),
                                comparisonData: convertRainbowPercentileComparisonData(comparisonData.sprintDistance, this.sdUnit)
                            };
                            const turnsData = { value: turns, comparisonData: comparisonData.turns };
                            const accelerationsData = { value: accelerations, comparisonData: comparisonData.accelerations };
                            const decelerationsData = { value: decelerations, comparisonData: comparisonData.decelerations };
                            const playerData = { player, distanceData, hsdData, sprintDistanceData, turnsData, accelerationsData, decelerationsData };
                            positionalData.push(playerData);
                        }
                        return positionalData;
                    }
                    else {
                        return null;
                    }
                };

                const positions = Object.keys(positionData);
                for (let i = 0; i < positions.length; i++) {
                    const positionId = positions[i];
                    this.positionData.push({ id: positionId, label: positionData[positionId].position, data: getPositionData(positionId) });
                }
            }
        }
    },
    computed: {
        filteredPositions() {
            return this.positionData.filter(position => position.data);
        }
    },
    async mounted() {
        console.log("Squad.vue mounted");
        this.refreshData();
        this.$root.newPageView("Squad Page", UserData.userName());
        UpdateSetting();
    },
};
</script>