<template >
    <div class="admin-container">

        <h1>Add New User</h1>
        <div class="alert alert-success" v-if="form.addSuccessText">
            {{ form.addSuccessText }}
        </div>
        <div class="alert alert-danger" v-if="form.addWarningText">
            {{ form.addWarningText }}
        </div>
        <b-form @submit="onSubmit">
            <label for="add-type">First Name</label>
            <b-form-input v-model="form.firstName" required></b-form-input>
            <label for="add-type">Last Name</label>
            <b-form-input v-model="form.lastName" required></b-form-input>
            <label for="add-type">Customer Id</label>
            <b-form-input v-model="form.customerId" required></b-form-input>
            <label for="add-type">Additional Customer Id</label>
            <b-form-input v-model="form.additionalCustomerId"></b-form-input>
            <label for="add-type" required style="margin-top:1vh">Role Id</label>
            <b-form-select style="background:none; color: white;" v-model="form.roleSelected" :options="form.roleOptions">
            </b-form-select>
            <label style="margin-top:1vh" for="add-type" required>Enable MFA</label>
                <b-form-radio-group id="checkbox-group-1" v-model="form.mfaSelected"
                    :options="form.mfaOptions" name="radio-options" plain>
            </b-form-radio-group>
            <label for="add-type" required style="margin-top:1vh">Email</label>
            <b-form-input v-model="form.email" type="email" required></b-form-input>
            <label for="add-type" required>Username</label>
            <b-form-input v-model="form.username" required></b-form-input>
            <label for="add-type" required>Password</label>
            <b-form-input v-model="form.password" type="password" required></b-form-input>

            <div style="margin-top:2vh">
                
                <b-button type="submit" style="background-color:var(--sportlight-teal); color: var(--bg-primary); border:none; margin-right:2vw "
                    variant="primary">Add User</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
            </div>

        </b-form>
    </div>

</template>
<script>
import { errorHandler } from "../components/ErrorHandler"
import { UserData } from "../components/UserData";

export default {
    data() {
        return {
            form: {
                addSuccessText: "",
                addWarningText: "",
                firstName: null,
                lastName: null,
                customerId: null,
                additionalCustomerId: null,
                roleSelected: 2,
                roleOptions: [
                    { value: 2, text: 'Customer' },
                    { value: 1, text: 'Administrator' }],
                email: null,
                username: null,
                password: null,
                mfaSelected: false,
                mfaOptions: [
                { value: false, text: 'No' },
                { value: true, text: 'Yes' }],
            }
        };

    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
            console.log(newPage)
        },
        async onSubmit(event) {
            event.preventDefault()
            if (this.form.additionalCustomerId != null && this.form.additionalCustomerId.replace(/\s/g, "") == "") { //checks to see if additional customer field is not null and is empty
                this.form.additionalCustomerId = null //set additional customer id to null
            }
            const json = {
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                roleId: this.form.roleSelected,
                customerId: this.form.customerId,
                additionalCustomerId: this.form.additionalCustomerId,
                email: this.form.email,
                username: this.form.username,
                password: this.form.password,
                mfaRequired: this.form.mfaSelected
            }

            const response = await this.$root.webApiPost("/adduser", json);

            if (response.status == 200) {
                this.form.addSuccessText = "New user added successfully";
                this.form.firstName = null
                this.form.lastName = null
                this.form.customerId = null
                this.form.additionalCustomerId = null
                this.form.roleSelected = 2
                this.form.email = null
                this.form.username = null
                this.form.password = null
                this.form.mfaSelected = false

                event.preventDefault()

            } else {
                errorHandler.error(response, this);
            }


        }
    },
    async mounted() {
        console.log("Admin.vue mounted");
        if (UserData.userName() != 'sportlight-admin') {
            this.$router.push("/calendar").catch(() => { });
        } else {
            this.$root.newPageView("Admin Page", UserData.userName());
        }
    }
};
</script>