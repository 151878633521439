const FLAGS = { RED: 2, AMBER: 1, GREEN: 0 };
const getFlagColour = flagValue => {
    if (flagValue === FLAGS.RED) {
        return "red-flag";
    } else if (flagValue === FLAGS.AMBER) {
        return "amber-flag";
    } else if (flagValue === FLAGS.GREEN) {
        return "green-flag";
    } else {
    return "no-flag";
    }
};
export { FLAGS, getFlagColour };
