<template>
    <div :style="{ 'min-height': height + 'vh' }" style="margin-top: 2vh;">
        <div class="main-heading-container">
            <span class="position-label">{{ positionLabel }}</span>
            <span class="column-headings-container">
                <h6 class="column-heading">Total Distance (<MeasurementUnit :type="LongDistance"/>)</h6> 
                <h6 class="column-heading">High Speed Distance (<MeasurementUnit :type="ShortDistance"/>)</h6> 
                <h6 class="column-heading">Sprint Distance (<MeasurementUnit :type="ShortDistance"/>)</h6> 
                <h6 class="column-heading">Turns (N)</h6> 
                <h6 class="column-heading">Accelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /> (N)</h6> 
                <h6 class="column-heading">Decelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /> (N)</h6> 
            </span>
        </div>
        <hr>
        <div :id="id">
            <div v-for="(playerData, index) in data" :key="index">
                <MissingDataText :message="positionLabel + 's Data Unavailable'" v-if="metrics.every(metric => playerData[metric].comparisonData === null)"/> 
                <div class="rainbow-group-container" v-else>
                    <p class="chart-player-name">{{ playerData.player.name }}</p>
                <RainbowBarChart class="rainbow-bar-charts" v-for="metric in metrics" :key="metric" :id="metric + playerData.player.playerId" :data="playerData[metric]"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RainbowBarChart from "../components/RainbowBarChart.vue";
import MissingDataText from "../components/MissingDataText.vue";
import MeasurementUnit from "../components/MeasurementUnit.vue";
import MeasurementValue from "../components/MeasurementValue.vue";
import { MeasurementTypes, MeasurementUnit as MU } from "../utils/MeasurementSystem";

export default {
    components: {
        RainbowBarChart,
        MissingDataText,
        MeasurementUnit,
        MeasurementValue 
    },
    props: {
        positionLabel: String,
        data: Array,
        id: String
    },
    data() {
        return {
            Acceleration: MeasurementTypes.Acceleration,
            ShortDistance: MeasurementTypes.ShortDistance,
            LongDistance: MeasurementTypes.LongDistance, 
            formatCoarse: MU.prototype.formatCoarse,
            height: (this.data.length + 0.8) * 7,  //Add 0.8 to the number of players in each position for padding then x 7 to get vertical height
            metrics: ['distanceData', 'hsdData', 'sprintDistanceData', 'turnsData', 'accelerationsData', 'decelerationsData'],
        };
    },
};
</script>

<style>
.main-heading-container {
    display: flex;
    width: 90vw;
}

.rainbow-group-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: auto;
    height: 7vh;
    align-items: center;
    width: 13vw;
    justify-content: center;
}
.rainbow-bar-charts {
    padding: 0 0.1em;
}

.position-label {
    font-size: 13px;
    width: 10vw;
}

.column-headings-container {
    display: flex;
    padding-left: 3%;
    width: 82vw;
    margin: auto;
    justify-content: space-between
}

.column-heading {
    width: 12vw;
    text-align: center;
}

.chart-player-name {
    width: 12vw;
    font-size: 12px;
    font-weight: 400;
}

hr {
    border: none;
    border-top: 1px solid #5a5e6280;
    margin: 0.7em 0;
}
</style>