<template>
    <div :id="'parent' + id">
        <canvas :width="width" :height="height" :id="id"> </canvas>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import Colours from "../utils/Colours";

export default {
    data() {
        return {
            height: this.data.length * this.bwHeight //resize plot height depending on number of datasets
        };
    },
    props: {
        id: String,
        data: Array,
        options: Object,
        width: Number,
        bwHeight: {   // the default height of each box whisker.
          type: Number,
          default: 90,
        },
    },
    async mounted() {
        await drawBWLabel(this.id, this.data, this.options);
        this.$watch("data", async function () {
            replaceCanvas(this.id, this.width, this.height);
            await drawBWLabel(this.id, this.data, this.options);
        });
    },
};
const replaceCanvas = (id, width, height) => {
    document.getElementById(id).remove();
    const canvas = document.createElement('canvas');
    canvas.id = id;
    canvas.width = width;
    canvas.height = height;
    document.getElementById("parent" + id).appendChild(canvas);
};
const drawBWLabel = (id, data, options) => {
    const chartLabelCtx = document.getElementById(id).getContext("2d");
    new Chart(chartLabelCtx, {
        type: 'horizontalBar',
        options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: options.showTitle,
                text: '',
                maintainAspectRatio: false,
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: options.showTicks,
                    ticks: {
                        fontColor: Colours.PRIMARY_GREY,
                        max: 1,
                        min: 0,
                    },
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    stacked: true,
                    afterFit: scaleInstance => scaleInstance.width = 5,
                    position: "right",
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        lineWidth: 1,
                    },
                    ticks: {
                        display: true,
                        mirror: true,
                    }
                }]
            }
        },
        data: {
            labels: data
        }
    });
};

</script>