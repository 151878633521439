<template>
    <!-- prettier-ignore -->
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px">
        <SessionHeader />
        <PageLayout>
            <TurnReport class="section-container" />
            <TurnScatters class="section-container" />
            <TurnTimeTable class="section-container" />
        </PageLayout>
    </div>
</template>

<script>
import TurnScatters from "./TurnScatters.vue";
import TurnReport from "./TurnReport.vue";
import { UserData } from "@/components/UserData";
import TurnTimeTable from "./TurnTimeTable.vue";

export default {
    components: {
        TurnReport,
        TurnScatters,
        TurnTimeTable,
    },
    data() {
        return {
            isWIP: UserData.isWIP(),
        };
    },
    async mounted() {
        console.log("TurnAnalysis.vue mounted");
        this.$root.newPageView("Turn Analysis Page", UserData.userName());
    }
};
</script>