<template>
    <EditModal id="player-dev-filter-modal" cancelVariant='danger standard-btn' :ok="updateStore" :cancel="clearFilter"
        cancelTitle="Reset" title="Player Development Filter" okTitle="Apply">
        <RadioButton :id="'average-position-average'" :modal="formData.selectedPositionAverage"
            :options="averageOptions" label="Position" :name="'average-options'"
            @update-modal="setSelectedPositionFilter($event)" />
        <RadioButton :id="'player-criteria-radio-group'" :modal="formData.selectedPlayerCriteria.value"
            :options="criteriaOptions" label="Player Sessions" :name="'player-criteria-radio-options'"
            @update-modal="setPlayerDataFilter($event)" />
        <RadioButton :id="'reference-criteria-radio-group'" :modal="formData.selectedReferenceGroup.value"
            :options="criteriaOptions" label="Reference Group" :name="'reference-criteria-radio-options'"
            @update-modal="setReferenceGroupFilter($event)" />

        <div v-if="(this.$route.name) === 'longitudinalanalysis'">
            <b-form-group label='Time Period'>
                <DropdownSelector @item-selected="setSelectedPeriodFilter($event)" :items="periods"
                    :getItemName="periodSelectorDisplayText" :index="tempSelectedPeriodIdx" />
            </b-form-group>
            <b-form-group label='Rolling Match Average'>
                <div class="counter-container">
                    <div class="counter-box">
                        <Button :onClick="decrementMatchCount" title="-" :isDisabled="tempSelectedMatchCount === 1" />
                        <span class="counter-text">{{ tempSelectedMatchCount }}</span>
                        <Button :onClick="incrementMatchCount" title="+" />
                    </div>
                </div>
            </b-form-group>
        </div>
    </EditModal>
</template>

<script>
import DropdownSelector from "@/components/DropdownSelector";
import EditModal from "@/components/EditModal";
import RadioButton from "@/components/RadioButton";
import Button from "@/components/Button";
import filterStore from './filterStore';
import store from './store';

export default {
    components: {
        DropdownSelector,
        EditModal,
        RadioButton,
        Button,
    },
    data() {
        return {
            tempSelectedPeriodIdx: filterStore.state.selectedPeriodIdx,
            tempSelectedMatchCount: filterStore.state.selectedMatchCount,
        };
    },
    computed: {
        selectedPlayer() {
            return store.state.selectedPlayer;
        },
        criteriaOptions() {
            return filterStore.state.criteriaOptions;
        },
        averageOptions() {
            return filterStore.state.averageOptions;
        },
        selectedPeriod() {
            return filterStore.state.selectedPeriod;
        },
        periods() {
            return filterStore.state.periods;
        },
        formData() {
            return {
                selectedPlayerCriteria: filterStore.state.selectedPlayerCriteria,
                selectedReferenceGroup: filterStore.state.selectedReferenceGroup,
                selectedPositionAverage: filterStore.state.selectedPositionAverage,
                selectedPeriodIdx: filterStore.state.selectedPeriodIdx,
                selectedMatchCount: filterStore.state.selectedMatchCount
            };
        },
        selectedPlayerId() {
            return store.state.selectedPlayer.playerId;
        }
    },
    methods: {
        periodSelectorDisplayText(period) {
            return period.text;
        },
        lookupCriteria(value) {
            return this.criteriaOptions.find(option => option.value === value);
        },
        setPlayerDataFilter(value) {
            const criteria = this.lookupCriteria(value);
            this.formData['selectedPlayerCriteria'] = criteria ? criteria : value;
        },
        incrementMatchCount() {
            this.tempSelectedMatchCount++;
            this.formData['selectedMatchCount'] = this.tempSelectedMatchCount;
        },
        decrementMatchCount() {
            this.tempSelectedMatchCount--;
            this.formData['selectedMatchCount'] = this.tempSelectedMatchCount;
        },
        setReferenceGroupFilter(value) {
            const criteria = this.lookupCriteria(value);
            this.formData['selectedReferenceGroup'] = criteria ? criteria : value;
        },
        setSelectedPeriodFilter(value) {
            this.formData['selectedPeriodIdx'] = value;
            this.tempSelectedPeriodIdx = value;
        },
        setSelectedPositionFilter(value) {
            this.formData['selectedPositionAverage'] = value;
        },
        updateStore() {
            filterStore.dispatch('updateFilters', {
                selectedPlayerCriteria: this.formData.selectedPlayerCriteria,
                selectedReferenceGroup: this.formData.selectedReferenceGroup,
                positionAverage: this.formData.selectedPositionAverage,
                selectedPeriodIdx: this.formData.selectedPeriodIdx,
                selectedMatchCount: this.formData.selectedMatchCount
            });
        },
        async clearFilter() {
            filterStore.commit("resetState");
            this.tempSelectedPeriodIdx = filterStore.state.selectedPeriodIdx;
            this.tempSelectedMatchCount = filterStore.state.selectedMatchCount;
            await filterStore.dispatch('updatePeriods', this);
            
        }
    },
    watch: {
        selectedPlayerId() {
            this.clearFilter();
        }
    }
};
</script>

<style>
.counter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4vh;
    max-width: 10vw;
}

.counter-box {
    display: flex;
    background-color: var(--input-box-bg);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 10vw;
}

.counter-text {
    flex-grow: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
}
</style>