import Vue from "vue";
import Vuex from "vuex";
import { UserData } from "@/components/UserData";
import { PlayerDevelopmentCriteriaOption } from "@/utils/PlayerDevelopmentCriteria";
import { getSeasons } from "@/utils/Seasons";
import DateUtils from "@/utils/DateUtils";
import KpiOption from "@/utils/KpiOption";
import { errorHandler } from "@/components/ErrorHandler";

Vue.use(Vuex);

const DEFAULT_SELECTED_PERIOD_INDEX = 2; //default index of 12 months
const DEFAULT_SELECTED_MATCH_COUNT = 6; //default rolling average match count to 6

const state = {
    selectedPlayerCriteria: PlayerDevelopmentCriteriaOption.ALL,
    selectedReferenceGroup: "",
    criteriaOptions: [],
    selectedPositionAverage: "avg",
    averageOptions: [],
    selectedPeriodIdx: DEFAULT_SELECTED_PERIOD_INDEX,
    selectedPeriod: null,
    periods: null,
    selectedMatchCount: DEFAULT_SELECTED_MATCH_COUNT,
    kpiOptions: KpiOption.options
};

const mutations = {
    setPeriods(state, periods) {
        state.periods = periods;
    },
    setSelectedReferenceGroup(state, group) {
        state.selectedReferenceGroup = group;
    },
    setSelectedPositionAverage(state, average) {
        state.selectedPositionAverage = average;
    },
    setAverageOptions(state, options) {
        state.averageOptions = options;
    },
    setAllFilters(
        state,
        {
            selectedPlayerCriteria,
            selectedReferenceGroup,
            positionAverage,
            selectedPeriodIdx,
            selectedMatchCount
        }
    ) {
        state.selectedPlayerCriteria = selectedPlayerCriteria;
        state.selectedReferenceGroup = selectedReferenceGroup;
        state.selectedPositionAverage = positionAverage;
        state.selectedPeriodIdx = selectedPeriodIdx;
        state.selectedPeriod = state.periods[selectedPeriodIdx];
        state.selectedMatchCount = selectedMatchCount;
    },
    setCriteriaOptions(state, options) {
        state.criteriaOptions = options;
    },
    setReferenceGroup(state, group) {
        state.selectedReferenceGroup = group;
    },
    setSelectedPeriod(state, index) {
        state.selectedPeriodIdx = index;
        state.selectedPeriod = state.periods[index];
    },

    resetState(state) {
        state.selectedPlayerCriteria = PlayerDevelopmentCriteriaOption.ALL;
        state.selectedReferenceGroup = "";
        state.selectedPositionAverage = "avg";
        state.averageOptions = [];
        state.criteriaOptions = [];
        state.selectedPeriodIdx = DEFAULT_SELECTED_PERIOD_INDEX;
        state.selectedPeriod = null;
        state.periods = null;
        state.selectedMatchCount = DEFAULT_SELECTED_MATCH_COUNT;
    },
};

const actions = {
    async initialiseFilters({ commit }, {teamType, position}) {
        commit("setCriteriaOptions", PlayerDevelopmentCriteriaOption.getCriteria(teamType));
        if (state.selectedReferenceGroup === "") {
            const leagueText =
                teamType === "m"
                    ? PlayerDevelopmentCriteriaOption.EPL
                    : PlayerDevelopmentCriteriaOption.WSL;
            commit("setReferenceGroup", leagueText);
        }
        const positionOptions = [{ text: "All Positions", value: "avg" }, { text: position, value: "posAvg" }];
        commit("setAverageOptions", positionOptions);
    },
    updateFilters({ commit }, criteria) {
        commit("setAllFilters", criteria);
    },

    async updatePeriods({ commit }, context) {
        const customerId = UserData.customerId();
        const playerId = context.selectedPlayer.playerId;
        const response = await context.$root.webApiGet(
            `/playerstartdate?customerId=${customerId}&playerId=${playerId}`
        );
        if (response.status === 200) {
            const earliestSessionDate = response.data;
            let seasonOptions = [];
            
            if (earliestSessionDate) {
                // only get seasons if player has at least one qualifying match
                seasonOptions = getSeasons(new Date(earliestSessionDate), false);
            }
            
            const getMonthOption = months => {
                return {
                    text: `Last ${months} months`,
                    value: months,
                    startDate: DateUtils.subtractMonths(
                        DateUtils.getToday(),
                        months
                    ),
                    endDate: DateUtils.getToday()
                };
            };
            
            const monthOptions = [
                getMonthOption(3),
                getMonthOption(6),
                getMonthOption(12),
                getMonthOption(18),
                getMonthOption(24)
            ];

            const periods = [...monthOptions, ...seasonOptions];
            commit("setPeriods", periods);
            commit("setSelectedPeriod", DEFAULT_SELECTED_PERIOD_INDEX);
        } else {
            errorHandler.error(response, context);
        }
    },
};

export default new Vuex.Store({ state, mutations, actions });
