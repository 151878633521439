<template>
    <div class="section-container" style="justify-content: start;" v-if="!$root.isLoadingData">
        <SectionTitle :title="'Sprint Counts Summary'">
            <MoreInfo :id="'sprint-count-summary-more-info'" title='Sprint Counts Summary'>
                <ul class="info-text">
                    <li> The number of high speed running efforts completed in four distance categories within the HSR and SD thresholds.
                        The coloured line represents the player’s {{ selectedPlayerCriteriaText }} average, while the box
                        and whisker plot represents the distribution of {{ selectedReferenceGroupText }} matches.</li>
                    <BoxWhiskerAdditionalInfoText currentDataText="selected player" :referenceDataText="selectedReferenceGroupText" />
                </ul>
            </MoreInfo>
        </SectionTitle>
        <div v-if="hsr && sprint">
            <SprintCountRow :titles="titles" />
            <SprintCountRow :data="hsr" :label="'HSR'" :titles="titles" />
            <SprintCountRow :data="sprint" :label="'Sprint'" :titles="titles" />
        </div>
        <MissingDataText v-else :message="'Insufficient data for player'"/>
    </div>
</template>

<script>
import MissingDataText from "@/components/MissingDataText.vue";
import SprintCountRow from "./SprintCountRow.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import { getMeasurementUnit, MeasurementTypes } from "@/utils/MeasurementSystem";
import filterStore from './filterStore';

export default {
    props: {
        hsr: Object,
        sprint: Object
    },
    components: {
        SprintCountRow,
        MissingDataText,
        SectionTitle
    },
    data() {
        return {
            measurementUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
        };
    },
    computed: {
        titles() {
            return {
                total: 'Total',
                _0_to_10: '0-' + this.measurementUnit.formatCoarseUnit(10),
                _10_to_20: this.measurementUnit.formatCoarse(10) + '-' + this.measurementUnit.formatCoarseUnit(20),
                _20_to_30: this.measurementUnit.formatCoarse(20) + '-' + this.measurementUnit.formatCoarseUnit(30),
                _30_plus: this.measurementUnit.formatCoarseUnit(30) + '+',
            };
        },
        selectedPlayerCriteriaText() {
            return filterStore.state.selectedPlayerCriteria.text;
        },
    }
};
</script>

<style scoped>
</style>
