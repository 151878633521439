const errorHandler = {

    error: (response, router) => {
        router.$root.showWait = false;
        switch (response.status) {
            case 401:
                router.$root.logOut(3);
                break;
            case 403:
                router.$root.logOut(3);
                break;
            default:
                router.$router.push("/errorpage")
                break;
        }
    }
}

export { errorHandler }