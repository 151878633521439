<template>
    <div>
        <SectionTitle :title="'KPI Summary (Within Player)'">
            <MoreInfo :id="'team-info-modal'" :title="'KPI Summary (Within Player) Description'">
                <ul class="info-text">
                <li>KPI Summary provides a visual comparison between the team’s & players’ current session and their previous ones. 
                The coloured line represents the current session, while the box and whisker plot represents the distribution of previous matches.</li>
                <li>Total: Current session compared with all previous {{playersSessionReferenceGroup}} matches with at least 85 minutes of match time {{getTotalText}}</li>
                <li>Per Minute: Current session divided by match time compared with all previous {{playersSessionReferenceGroup}} matches with at least 30 minutes of match time {{getPerMinuteText}}</li>
                <BoxWhiskerAdditionalInfoText currentDataText="current session" referenceDataText="team's & players'" />
                <li>(*) - played &lt; 90mins</li>
                </ul>
            </MoreInfo>
        </SectionTitle>
        <div class="full-width-radio-button-container">
            <RadioButton :id="'kpi-analysis-radio-group'" :modal="enablePerMinute" :options="perMinuteOptions"
                :name="'kpi-options'" @update-modal="enablePerMinute = $event" :task="toggleEnablePerMinute" />
        </div>
        <MatchTimeIndicator/>
        <div style="display:flex">
            <KPIAnalysisChartLayout :teamLabelId="'team-distance-label'" :teamChartId="'team-distance-chart'" :playersData="distanceData" :playersOptions="distanceOptions"
                :teamData="teamDistanceData" :teamOptions="teamDistanceOptions"
                :playersLabelId="'distance-label'" :playersChartId="'distance-chart'" />
            <KPIAnalysisChartLayout :teamLabelId="'team-hsd-label'" :teamChartId="'team-hsd-chart'" 
                :playersData="hsdData" :playersOptions="hsdOptions" :teamData="teamHsdData" :teamOptions="teamHsdOptions"
                :playersLabelId="'hsd-label'" :playersChartId="'hsd-chart'" />
        </div>
        <div style="display:flex; margin-top:10vh">
            <KPIAnalysisChartLayout :teamLabelId="'team-sprint-distance-label'" :teamChartId="'team-sprint-distance-chart'"
                :playersData="sprintDistanceData" :playersOptions="sprintDistanceOptions" :teamData="teamSprintDistanceData" :teamOptions="teamSprintDistanceOptions"
                :playersLabelId="'sprint-distance-label'" :playersChartId="'sprint-distance-chart'" />
            <KPIAnalysisChartLayout :teamLabelId="'team-turns-label'" :teamChartId="'team-turns-chart'" :playersData="turnData" :playersOptions="turnOptions"
                :teamData="teamTurnData" :teamOptions="teamTurnOptions" playersLabelId="'turns-label'" 
                :playersChartId="'turns-chart'" />
        </div>
        <div style="display:flex; margin-top:10vh">
            <KPIAnalysisChartLayout :teamLabelId="'team-peak-speed-label'" :teamChartId="'team-peak-speed-chart'"
                :playersData="peakSpeedData" :playersOptions="peakSpeedOptions" :teamData="teamPeakSpeedData" :teamOptions="teamPeakSpeedOptions"
                :playersLabelId="'peak-speed-label'" :playersChartId="'peak-speed-chart'" />
            <KPIAnalysisChartLayout :teamLabelId="'team-hpd-label'" :teamChartId="'team-hpd-chart'" 
                :playersData="hpdData" :playersOptions="hpdOptions" :teamData="teamHpdData" :teamOptions="teamHpdOptions"
                :playersLabelId="'hpd-label'" :playersChartId="'hpd-chart'" />
        </div>
    </div>
</template>

<script>
import {errorHandler} from "@/components/ErrorHandler";
import {UserData} from "@/components/UserData";
import UpdateSetting from "@/utils/UpdateSetting";
import { getSortedPlayerKpiBwData, getTeamKpiBwData } from "@/utils/BoxWhiskerKpiInfoGenerator";
import {
    convertBwData,
    getMeasurementUnit,
    MeasurementTypes,
} from '@/utils/MeasurementSystem';
import store from "@/store/session";

export default {
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            peakSpUnit: getMeasurementUnit(MeasurementTypes.PeakSpeed),
            customerId: UserData.customerId(),
            isWIP: UserData.isWIP(),
            enablePerMinute: false,
            perMinuteOptions: [
                { text: 'Total', value: false },
                { text: 'Per Minute', value: true }
            ],
            distanceData: null,
            hsdData: null,
            sprintDistanceData: null,
            turnData: null,
            hpdData: null,
            peakSpeedData: null,
            teamDistanceData: null,
            teamHsdData: null,
            teamSprintDistanceData: null,
            teamTurnData: null,
            teamHpdData: null,
            teamPeakSpeedData: null,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            // get query string here
            await this.$root.executeTaskWithProgressBar(async () => {
                this.sessionId = this.$route.params.id;
                await this.getPlayersData();
                await this.getTeamData();
            });

        },
        async getTeamData() {
            const response = await this.$root.webApiGet(
                `/teamkpis?customerId=${this.customerId}&sessionId=${this.sessionId}&enablePerMinute=${this.enablePerMinute}`
            );

            if (response.status === 200) {
                const data = response.data;
                const teamLabel = this.enablePerMinute ? 'TEAM TOTAL PER MINUTE' : 'TEAM TOTAL';
       
                this.teamDistanceData = getTeamKpiBwData([convertBwData(data.totalDistanceM, this.ldUnit)], [teamLabel]);
                this.teamHsdData = getTeamKpiBwData([convertBwData(data.totalHighSpeedDistanceM, this.sdUnit)], [teamLabel]);
                this.teamSprintDistanceData = getTeamKpiBwData([convertBwData(data.totalSprintDistanceM, this.sdUnit)], [teamLabel]);
                this.teamPeakSpeedData = getTeamKpiBwData([convertBwData(data.averagePeakSpeedMPerS, this.peakSpUnit)], ['TEAM AVERAGE ']);
                this.teamTurnData = getTeamKpiBwData([data.totalTurns], [teamLabel]);
                this.teamHpdData = getTeamKpiBwData([convertBwData(data.totalHighPowerDistanceM, this.sdUnit)], [teamLabel]);
            } else {
                errorHandler.error(response, this);
            }
        },
        async getPlayersData() {
            const response = await this.$root.webApiGet(
                `/kpis?customerId=${this.customerId}&sessionId=${this.sessionId}&enablePerMinute=${this.enablePerMinute}`
            );

            if (response.status === 200) {
                const playersKpis = response.data;
                this.distanceData = getSortedPlayerKpiBwData(playersKpis, 'activeDistanceM', (d) => convertBwData(d, this.ldUnit));
                this.hsdData = getSortedPlayerKpiBwData(playersKpis, 'highSpeedDistanceM', (d) => convertBwData(d, this.sdUnit));
                this.sprintDistanceData = getSortedPlayerKpiBwData(playersKpis, 'sprintDistanceM', (d) => convertBwData(d, this.sdUnit));
                this.turnData = getSortedPlayerKpiBwData(playersKpis, 'totalTurns', d => d);
                this.hpdData = getSortedPlayerKpiBwData(playersKpis, 'highPowerDistanceM', (d) => convertBwData(d, this.sdUnit));
                this.peakSpeedData = getSortedPlayerKpiBwData(playersKpis, 'peakSpeedMPerS', (d) => convertBwData(d, this.peakSpUnit));

            } else {
                errorHandler.error(response, this);
            }
        },
        toggleEnablePerMinute() {
            Array.from(document.getElementsByClassName('session-analysis-kpi-plot')).forEach(e => e.remove());
            this.refreshData();
        },
        getPlayerOptions(title, precision) {
            return {
                tooltipLabel: this.sessionIsAMatch ? "Current Match" : "Current Session",
                showTicks: true,
                showTitle: !this.sessionIsAMatch || (this.teamDistanceData && !this.teamDistanceData.hasValues),
                title: title,
                precision: precision,
                labelPrecision: precision,
                averagePrecision: precision
            };
        },
        getTeamOptions(title, precision) {
            return {
                tooltipLabel: this.sessionIsAMatch ? "Current Match" : "Current Session",
                showTicks: false,
                showTitle: true,
                title: title,
                precision: precision,
                labelPrecision: precision,
                averagePrecision: precision
            };
        },
        getDecimalPlaces(unit) {
            return this.enablePerMinute ? unit.decimalPlacesFine : unit.decimalPlacesStandard;
        }
        
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        getTotalText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 85 minutes of match time for the team`;
            }
            return null;
        },
        getPerMinuteText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 30 minutes of match time for the team`;
            }
            return null;
        },
        titles() {
            const ldUnitTime = this.ldUnit.unitShort + (this.enablePerMinute ? '/min' : '');
            const sdUnitTime = this.sdUnit.unitShort + (this.enablePerMinute ? '/min' : '');
            const turnUnit = this.enablePerMinute ? 'n/min' : 'n';
            const peakSpUnitTime = this.peakSpUnit.unitShort;

            return {
                distance: `Total Distance (${ldUnitTime})`,
                hsd: `High Speed Distance (${sdUnitTime})`,
                sprint: `Sprint Distance (${sdUnitTime})`,
                hpd: `High Power Distance (${sdUnitTime})`,
                turns: `Total Turns (${turnUnit})`,
                peakSpeed: `Peak Speed (${peakSpUnitTime})`,
            };
        },
        peakSpeedOptions() {
            const peakSpOptions = this.getPlayerOptions(this.titles.peakSpeed, this.getDecimalPlaces(this.peakSpUnit) );
            peakSpOptions.calculateMinTick = true;
            return peakSpOptions;
        },
        distanceOptions() {
            return this.getPlayerOptions(this.titles.distance, this.getDecimalPlaces(this.ldUnit) );
        },
        sprintDistanceOptions() {
            return this.getPlayerOptions(this.titles.sprint, this.getDecimalPlaces(this.sdUnit) );
        },
        hsdOptions() {
            return this.getPlayerOptions(this.titles.hsd, this.getDecimalPlaces(this.sdUnit) );
        },
        turnOptions() {
            return this.getPlayerOptions(this.titles.turns, this.enablePerMinute ? 2 : 0 );
        },
        hpdOptions() {
            return this.getPlayerOptions(this.titles.hpd, this.getDecimalPlaces(this.sdUnit) );
        },
        teamDistanceOptions() {
            return this.getTeamOptions(this.titles.distance, this.getDecimalPlaces(this.ldUnit));
        },
        teamPeakSpeedOptions() {
            const teamPeakSpOptions = this.getTeamOptions(this.titles.peakSpeed, this.getDecimalPlaces(this.peakSpUnit) );
            teamPeakSpOptions.calculateMinTick = true;
            return teamPeakSpOptions;
        },
        teamHsdOptions() {
            return this.getTeamOptions(this.titles.hsd, this.getDecimalPlaces(this.sdUnit) );
        },
        teamTurnOptions() {
            return this.getTeamOptions(this.titles.turns, this.enablePerMinute ? 2 : 0 );
        },
        teamSprintDistanceOptions() {
            return this.getTeamOptions(this.titles.sprint, this.getDecimalPlaces(this.sdUnit) );
        },
        teamHpdOptions() {
            return this.getTeamOptions(this.titles.hpd, this.getDecimalPlaces(this.sdUnit) );
        }
    },
    async mounted() {
        console.log("KPIAnalysis.vue mounted");
        this.$root.newPageView("KPI Analysis Page", UserData.userName());
        await this.refreshData();
        UpdateSetting();
    }
};
</script>

<style scoped>
li {
    margin-bottom: 10px;
}
</style>