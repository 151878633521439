const Colours = {
    SPORTLIGHT_TEAL: '#1AD5ED',
    PRIMARY_GREY: '#2F3234',
    SECONDARY_DARK_GREY: '#878787',
    SECONDARY_LIGHT_GREY:  '#CCCCCC',
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    BRIGHT_GREEN: '#39FF14',
    RED: '#FF0000',
    DARK_GREEN: '#018a01',
	DARK_RED: '#820a01',
    BLUE: 'blue',
    YELLOW: 'yellow',
    TRANSLUCENT_SPORTLIGHT_TEAL: 'rgba(26, 213, 237, 0.1)',
    TRANSLUCENT_SECONDARY_LIGHT_GREY: 'rgba(204, 204, 204, 0.1)',
    AMBER: '#FFBF00',
    SECONDARY_DARK_GREY_1: '#474747',
    ORANGE: '#ffa343',
    TURN_RATES: {
        byTotalTurns: { low: '#1AD5ED', medium: '#1AD5ED', high: '#1AD5ED' },
        byAngleClass: { low: '#2DE5E0', medium: '#00AFAA', high: '#167471' },
        byEntrySpeed: { low: '#7FCAFD',  medium: '#2797E0', high: '#1E6796', extreme: '#033da1',
            walking: '#7FCAFD',  jogging: '#2797E0', running: '#1E6796', sprinting: '#033da1'},
        byAccelerationPeakClass: { low: '#87aff5', medium: '#276de6', high: '#033da1' },
        byDecelerationPeakClass: { low: '#6AFA75', medium: '#0CD124', high: '#16A020' },
        byEffortClass: { low: '#7FCAFD', medium: '#2797E0', high: '#1E6796' },
    },
    TURN_CLASSIFICATIONS: {
        Low: '#82e8f5', Medium: '#1AD5ED', High: '#10b4c9', Extreme: '#047180',
        Walking: '#82e8f5', Jogging: '#1AD5ED', Running: '#10b4c9', Sprinting: '#047180'
    }
};

export default Colours;