<template>
    <div>
    </div>
</template>

<script>
import {UserData} from "@/components/UserData";

export default {
    data() { return {} },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        }
    },
    async mounted() {
        console.log("Notfound.vue mounted");
        this.$root.newPageView("Not Found Page", UserData.userName());
        this.navigateTo("/");
    }
};
</script>