const getTurnRatesPercentages = turns => {
    const leftTurnPercentages = [];
    const rightTurnPercentages = [];
    
    for (let i = 0; i < turns.length; i++) {
        const leftCounts = turns[i].leftCount;
        const rightCounts = turns[i].rightCount;
        const totalCounts = turns[i].totalCount;

        const getTurnPercentages = (turnCounts, totalCounts) => Math.round((turnCounts / totalCounts) * 100);
        leftTurnPercentages.push(getTurnPercentages(leftCounts, totalCounts));
        rightTurnPercentages.push(getTurnPercentages(rightCounts, totalCounts));
    }
    
    return { leftTurnPercentages, rightTurnPercentages };
};

const getTurnRatesLabels = (initialLabels, hasNormalTurnRange, hasZScores, useMatchTimeFilter) => {
        const labels = [];
        //add * only when player has zScores, match times and is outside normal range
        for (let i = 0; i < initialLabels.length; i++) {
            const checkedLabels = hasZScores && useMatchTimeFilter && hasNormalTurnRange[i] !== null ? initialLabels[i] + '*' : initialLabels[i];
            labels.push(checkedLabels)
        }
        return labels;
}

const getTurnRatesRanges = (turns, zScores, hasInsufficientMatchTime) => {
    const leftTurnNormalRange = [];
    const rightTurnNormalRange = [];

    for (let i = 0; i < turns.length; i++) {
        if (zScores) {
            const multipleZScores = zScores[i];
            const getBounds = category => (multipleZScores ? zScores[i][category] : zScores[0][category]);
            const leftTurnsLowerBound = getBounds('leftTurnsLowerBound');
            const leftTurnsUpperBound = getBounds('leftTurnsUpperBound');
            const rightTurnsLowerBound = getBounds('rightTurnsLowerBound');
            const rightTurnsUpperBound = getBounds('rightTurnsUpperBound');

            const getTurnRange = (percentages, lowerBound, upperBound) => {
                const roundedLowerBound = Math.round(lowerBound);
                const roundedUpperBound = Math.round(upperBound);
                const roundedPercentage = Math.round(percentages);

                // If player is within normal range or did not play the full game, then don't normal range
                return (roundedPercentage >= roundedLowerBound && roundedPercentage <= roundedUpperBound) || hasInsufficientMatchTime[i]
                    ? null
                    : roundedLowerBound + '%' + ' - ' + roundedUpperBound + '%';
            };

            const turnPercentages = getTurnRatesPercentages(turns);
            leftTurnNormalRange.push(getTurnRange(turnPercentages.leftTurnPercentages[i], leftTurnsLowerBound, leftTurnsUpperBound));
            rightTurnNormalRange.push(getTurnRange(turnPercentages.rightTurnPercentages[i], rightTurnsLowerBound, rightTurnsUpperBound));
        }
    }

    return { leftTurnNormalRange, rightTurnNormalRange };
};


export { getTurnRatesPercentages, getTurnRatesRanges, getTurnRatesLabels };