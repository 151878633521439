<template>
    <div class="section-container">
        <SectionTitle title='Physical Performance Ranking'>
            <MoreInfo :id="'sdn-player-performance-standards-more-info'" title='Physical Performance Ranking'>
                <p>
                    The player's average full-match physical 
                    outputs relative to the SDN database.
                </p>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText :message="'Insufficient data for player'" v-if="!hasData" />
        <div v-else>
            <RainbowLegend :showBlueKey=false keyText='Network' class="mt-4" />
            <div style="margin-top: 2vh;">
                <div class="main-heading-container">
                    <span class="position-label"></span>
                    <span class="column-headings-container">
                        <h6 class="column-heading">Total Distance (<MeasurementUnit :type="LongDistance"/>)</h6> 
                        <h6 class="column-heading">High Speed Running (<MeasurementUnit :type="ShortDistance"/>)</h6> 
                        <h6 class="column-heading">Sprint Distance (<MeasurementUnit :type="ShortDistance"/>)</h6> 
                        <h6 class="column-heading">Turns (N)</h6> 
                        <h6 class="column-heading">Accelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/>
                            <MeasurementUnit :type="Acceleration" /> (N)</h6> 
                        <h6 class="column-heading">Decelerations &gt; <MeasurementValue :type="Acceleration" :value=3 :format="formatCoarse"/>
                            <MeasurementUnit :type="Acceleration" /> (N)</h6> 
                    </span>
                </div>
                <hr>
                <div v-if="hasData">
                    <div class="rainbow-group-container">
                        <p class="chart-player-name">{{ player.name }}</p>
                        <RainbowBarChart class="rainbow-bar-charts" v-for="metric in metrics" :key="metric"
                            :id="metric + player.playerId" :data="data[metric]" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RainbowBarChart from "@/components/RainbowBarChart.vue";
import MissingDataText from "@/components/MissingDataText.vue";
import RainbowLegend from "@/components/RainbowLegend.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import MeasurementUnit from "@/components/MeasurementUnit.vue"; 
import MeasurementValue from "@/components/MeasurementValue.vue"; 
import { MeasurementTypes, MeasurementUnit as MU } from "@/utils/MeasurementSystem";

export default {
    components: {
        RainbowBarChart,
        MissingDataText,
        RainbowLegend,
        SectionTitle,
        MoreInfo,
        MeasurementUnit, 
        MeasurementValue,    
    },
    props: {
        player: Object,
        data: Object,
        hasData: Boolean
    },
    data() {
        return {
            Acceleration: MeasurementTypes.Acceleration, 
            ShortDistance: MeasurementTypes.ShortDistance, 
            LongDistance: MeasurementTypes.LongDistance, 
            formatFine: MU.prototype.formatFine,
            formatCoarse: MU.prototype.formatCoarse,
            metrics: ['distanceData', 'hsdData', 'sprintDistanceData', 'turnsData', 'accelerationsData', 'decelerationsData'],
        };
    },
};
</script>

<style scoped>
.main-heading-container {
    display: flex;
    width: 85vw;
}

.rainbow-group-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: auto;
    height: 7vh;
    align-items: center;
    width: 13vw;
    justify-content: center;
}
.rainbow-bar-charts {
    padding: 0 0.1em;
}

.position-label {
    font-size: 13px;
    width: 5vw;
}

.column-headings-container {
    display: flex;
    padding-left: 10%;
    width: 80vw;
    margin: auto;
    justify-content: space-between
}

.column-heading {
    width: 8vw;
    text-align: center;
}

.chart-player-name {
    width: 11vw;
    font-size: 12px;
    font-weight: 400;
}

hr {
    border: none;
    border-top: 1px solid #5a5e6280;
    margin: 0.7em 0;
}
</style>