import { Chart } from "chart.js";
import Colours from "../utils/Colours";

// a number significantly lower than any other that will be passed to this function.
const NEGATIVE_INFINITY = -99999;

function bwPlotLabels(chartLabelCtx, data, options) {
    return new Chart(chartLabelCtx, {
        type: 'horizontalBar',
        options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: options.hasTitle,
                text: '',
                maintainAspectRatio: false,
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: options.hasTicks === true,
                    ticks: {
                        fontColor: Colours.PRIMARY_GREY,
                        max: 1,
                        min: 0,
                    },
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    stacked: true,
                    position: "right",
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        display: true,
                        mirror: true,
                    }
                }]
            }
        },
        data: {
            labels: data.labels
        }

    });
}

function bwPlotValues(chartCtx, data, options) {
    const formatValues = (type, metric, dp) => {
        if (metric === null) {
            return null;
        } else {
            const formattedValue = metric.toFixed(dp);
            return type === 'label' ? metric.toFixed(dp) : parseFloat(formattedValue);
        }
    };

    const sessionValue = [];
    const sessionLabel = [];
    const min = [];
    const avg = [];
    const max = [];
    const minAvg = [];
    const stdAvg = [];
    const avgMax = [];
    const matchColourIndicator = [];
    const maxValue = [];
    const minBorderWidth = [];
    const maxBorderWidth = [];
    const minValues = [];

    for (let i = 0; i < data.kpi.length; i++) {
        const metric = data.kpi[i];
        const kpiValue = formatValues('value', metric.value, options.decimalPlaces);
        const minimum = formatValues('value', metric.minimum, options.decimalPlaces) || 0;
        const standardDeviation = metric.standardDeviation || 0;
        const average = formatValues('value', metric.average, options.decimalPlaces) || 0;

        /*
           what's going on here? Sometimes, the min, avg, stddev are all null, but the max is set.
           If that happens, we want the max value to be null/0 too.
           Why does this happen? We use different criteria for getting the min/avg/stddev value and the max value.
           Sessions between 85 and 110 mins are eligible for contributing to the min, avg, stdev value,
           but any session less than 110 mins can contribute to the max value. If a player has played
           a few games, but only less than 85 mins per game, then their data might fit this pattern (
               min, avg, stddev == null but max and value set.
           )
        */
        const maximum = metric.minimum === null ? 0 : formatValues('value', metric.maximum, options.decimalPlaces) || 0;


        const lowerBounds = average - standardDeviation;
        const upperBounds = average + standardDeviation;

        minAvg.push([minimum, lowerBounds]);
        stdAvg.push([lowerBounds, upperBounds]);
        avgMax.push([upperBounds, maximum]);
        min.push(minimum);
        avg.push([average, average]);
        max.push(maximum);

        const nullChecker = metric.minimum === null || metric.average === null || metric.standardDeviation === null;
        minValues.push(nullChecker);

        sessionValue.push([kpiValue, kpiValue + (options.valueWidth ?? (options.padding / 25))]);
        sessionLabel.push(kpiValue === null ? '' :
            (options.labelIncludesDecimal ?
                formatValues('label', kpiValue, options.decimalPlaces) :
                [Math.ceil(kpiValue).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(" ")])); //convert value to nearest whole number & use a white space as the thousand separator

        if (options.chartType === 'player') {
            if (kpiValue > upperBounds && nullChecker !== true) {
                matchColourIndicator.push(Colours.BRIGHT_GREEN);
            } else if (kpiValue < lowerBounds && nullChecker !== true && data.activeTime[i] > 85) {
                matchColourIndicator.push(Colours.RED);
            } else {
                matchColourIndicator.push(Colours.SPORTLIGHT_TEAL);
            }
        } else {
            if (kpiValue > upperBounds && nullChecker !== true) {
                matchColourIndicator.push(Colours.BRIGHT_GREEN);
            } else if (kpiValue < lowerBounds && nullChecker !== true) {
                matchColourIndicator.push(Colours.RED);
            } else {
                matchColourIndicator.push(Colours.SPORTLIGHT_TEAL);
            }
        }

        maxValue.push(Math.max(kpiValue, maximum));
        const barHeight = options.chartSize === 'S' ? 0.1 : 0.2;
        minimum <= lowerBounds ? minBorderWidth.push(barHeight) : minBorderWidth.push(0);
        maximum >= lowerBounds ? maxBorderWidth.push(barHeight) : maxBorderWidth.push(0);
    }

    //Determine the maximum value and add right amount of padding
    const maxPadding = Math.ceil(Math.max(...maxValue) / options.padding) * options.padding;
    const roundingValue = maxPadding % (options.padding * 2) === 0 ? maxPadding + (options.padding * 2) : maxPadding + options.padding;
    const roundMax = maxPadding > 0 ? roundingValue : maxPadding + options.padding;
    const barHeight = options.chartSize === 'S' ? 0.5 : 0.7;

    if (options.hasRank) sessionLabel.push(options.rank);

    if (!options.tooltipLabels) {
        const sessionLabel = options.sessionIsAMatch ? 'Current Match' : 'Current Session';
        options.tooltipLabels = {
            'Value': options.hasRank ? 'Teams Total' : sessionLabel,
            'Min' : 'Min',
            'Max' : 'Max',
            'Average' : 'Average'
        };
    }

    const tooltipLabel = (tooltipItem, data) => {
        const barLabel = data.datasets[tooltipItem.datasetIndex].label;

        // barValue is an array 2 items representing the lower bound and upper bound of the bar
        const barValue = JSON.parse(tooltipItem.xLabel);

        // never show a tooltip for the thicker 'bar' part of the boxwhisker (labelled as 'Lower') plot or for the 'Null' plot
        if (barLabel === 'Lower' || barLabel === 'Null') {
            return null;
        }
        // sometimes, the minimum, average, or stddev can be missing from the data. In these cases the
        // plot should still be drawn but with just a value - no box or whisker.
        // The 'Null' dataset (at index 5) is used to identify these cases. In those instances the tooltip should
        // not contain any text for the min, max or average values.
        if (data.datasets[5].data[tooltipItem.index][0] === NEGATIVE_INFINITY) {
            if (barLabel === 'Min' || barLabel === 'Max' || barLabel === 'Average') {
                return  null;
            }
        }
        const tooltipLabel = options.tooltipLabels[barLabel];
        if (barLabel === 'Max') {
            // for the 'Max' bar, we want to display the value for the upper bound of the bar
            return tooltipLabel + ": " + formatValues('label', barValue[1], options.decimalPlaces);
        } else {
            return tooltipLabel + ": " + formatValues('label', barValue[0], options.decimalPlaces);
        }
    };

    const minTick = options.minXTick ?? 0;
    const maxTick = options.maxXTick ?? roundMax;
    const stepSize = options.padding * 2;
    // need to add + 1 to handle the case where minTick = 9, maxTick = 27 and stepSize = 2
    // max - min / stepSize  = 9 . but there will be lines for 9, 10, 12... 26, 28 - 11 in total
    const verticalGridLineCount = Math.round((maxTick - minTick) / stepSize ) + 1;

    // first vertical line is white. The rest are the same colour as the background (so invisible).
    const gridLineColours = [Colours.WHITE].concat(Array(verticalGridLineCount).fill(Colours.PRIMARY_GREY));
    return new Chart(chartCtx, {
        type: 'horizontalBar',
        options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: options.hasTitle,
                text: options.title,
                maintainAspectRatio: false,
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: true,
                position: 'nearest',
                callbacks: {
                    title: () => {},
                    label: tooltipLabel,
                    afterLabel: (tooltipItem, data) => {
                        if (data.datasets[tooltipItem.datasetIndex].label === 'Max') {
                            return options.additionalLabel;  // Return the additional label only after the last item
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    stacked: false,
                    gridLines: {
                        display: true,
                        zeroLineColor: 'white',
                        color: options.minXTick ? gridLineColours : null,
                        lineWidth: 1,
                        drawBorder: false
                    },
                    ticks: {
                        display: options.hasTicks === true,
                        beginAtZero: true,
                        min: minTick,
                        max: maxTick,
                        stepSize: stepSize,
                        callback:  value => {
                            return value;
                        },
                        lineAtIndex: 5
                    }
                }],
                yAxes: [{
                    stacked: true,
                    afterFit: (scaleInstance) => scaleInstance.width = 60,
                    gridLines: {
                        display: false,
                        lineWidth: 1,
                    },
                    ticks: {
                        display: true,
                        padding: 0,
                        suggestedMin: 0,
                        beginAtZero: false,
                        stepSize: 10,
                        fontStyle: 'bold italic'
                    }
                }]
            }
        },
        data: {
            labels: sessionLabel,
            datasets: [{
                label: 'Value',
                data: sessionValue,
                backgroundColor: matchColourIndicator,
                barPercentage: barHeight,
                categoryPercentage: barHeight,
            },
                {
                    label: 'Min',
                    data: minAvg,
                    backgroundColor: Colours.SECONDARY_LIGHT_GREY,
                    borderColor: Colours.SECONDARY_LIGHT_GREY,
                    borderWidth: 1,
                    barPercentage: minBorderWidth,
                    categoryPercentage: minBorderWidth,
                },
                {
                    label: 'Lower',
                    data: stdAvg,
                    backgroundColor: Colours.PRIMARY_GREY,
                    borderColor: Colours.SECONDARY_LIGHT_GREY,
                    borderWidth: 1,
                    barPercentage: barHeight,
                    categoryPercentage: barHeight,
                },
                {
                    label: 'Average',
                    data: avg,
                    backgroundColor: Colours.PRIMARY_GREY,
                    borderColor: Colours.PRIMARY_GREY,
                    borderWidth: 1,
                    barPercentage: 0,
                    categoryPercentage: 0,
                },
                {
                    label: 'Max',
                    data: avgMax,
                    backgroundColor: Colours.SECONDARY_LIGHT_GREY,
                    borderColor: Colours.SECONDARY_LIGHT_GREY,
                    borderWidth: 1,
                    barPercentage: maxBorderWidth,
                    categoryPercentage: maxBorderWidth,
                },
                {
                    // the values here are deliberately low so that they should always be invisble as they will be
                    // off the left hand side of the plot
                    label: 'Null',
                    data: minValues.map(v => v === true ? [NEGATIVE_INFINITY, NEGATIVE_INFINITY] : [v, v]),
                }
            ],
        }
    });
}

const plots = {
    bwPlot: (chartLabelCtx, chartCtx, data, options) => {
        const labelChart = bwPlotLabels(chartLabelCtx, data, options);
        const valuesChart = bwPlotValues(chartCtx, data, options);
        return [labelChart, valuesChart];
    },
    bwPlotLabels: bwPlotLabels,
    bwPlotValues: bwPlotValues

};

export { plots };