<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="padding-left: 90px">
        <SessionHeader />
        <PageLayout>
            <KPIAnalysis class="section-container" />
            <TurnRates :customerId='customerId' class="section-container" />
            <SessionKPICount class="section-container"/>
        </PageLayout>
    </div>
</template>

<script>
import TurnRates from "./TurnRates.vue";
import KPIAnalysis from "./KPIAnalysis.vue";
import { UserData } from "@/components/UserData";
import SessionKPICount from "./SessionKPICount.vue";
export default {
    components: {
        TurnRates,
        KPIAnalysis,
        SessionKPICount
    },
    data () {
        return {
            customerId: UserData.customerId(),
            isWIP: UserData.isWIP()
        };
    },
    async mounted() {
        console.log("SessionAnalysis.vue mounted");
        this.$root.newPageView("Session Analysis Page", UserData.userName());
    }
};
</script>