<template>
    <PageHeader>
        <PageHeaderTitle>
            <h1 class="d-flex align-content-center">
                Wellness
                <MoreInfo id="wellness-info-text" title="Wellness"> 
                    <p>Wellness dashboard shows the fatigue flag, game demand score, hamstring risk and local intensity metrics for the team’s last three games. 
                        It also shows a player/fixture view of all fatigue flags across the squad and displays which players will still be 
                        flagged in upcoming fixtures.</p>
                </MoreInfo> 
            </h1>
        </PageHeaderTitle>
        <ul class="nav nav-tabs" id="sub-page" role="tablist">
            <li class="nav-item">
                <router-link 
                    class="nav-link"
                    :class="{ 'active': 'wellness' === this.$root.activePath }"
                    id="wellness-tab" data-toggle="tab" to="/wellness" role="tab">
                    Dashboard
                </router-link>
            </li>
            <li class="nav-item">
                <router-link
                    class="nav-link"
                    :class="{ 'active': 'fatigueflag' === this.$root.activePath, 'disabled': !this.hasFatigueFlag }"
                    :aria-disabled="!this.hasFatigueFlag"
                    id="wellness-tab" data-toggle="tab"
                    to="/fatigueflag" role="tab">
                    Fatigue Flag
                    </router-link>
                </li>
        </ul>
    </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageHeaderTitle from "@/components/PageHeaderTitle.vue";
import MoreInfo from '@/components/MoreInfo.vue';
import { UserData } from "@/components/UserData";

export default {
    components: {
        PageHeader,
        PageHeaderTitle,
        MoreInfo
    },
    data() {
        return {
            hasFatigueFlag: UserData.hasFatigueFlag()
        };
    },
};
</script>