<template>
    <div class="force-velocity-plot">
        <div class="labels">
            <label class="label-top-left">High Acceleration <br> Low Speed</label>
            <label class="label-top-right">High Acceleration <br> High Speed</label>
            <label class="label-bottom-left">Low Acceleration <br> Low Speed</label>
            <label class="label-bottom-right">Low Acceleration <br> High Speed</label>
        </div>
        <div :id="'parent' + id" class="chart-container">
            <canvas :id="id" class="chart"></canvas>
        </div>
    </div>
</template>
  
<script>
import percentile from "percentile";
import { Chart } from "chart.js";
import Colours from "@/utils/Colours";

export default {
    props: {
        id: String,
        data: Object,
    },
    data() {
        return {
            chartOptions: {
                player: {
                    colour: Colours.ORANGE,
                    pointStyle: 'cross',
                    order: 0,
                    width: 6,
                    radius: 9,
                },
                team: {
                    colour: Colours.SPORTLIGHT_TEAL,
                    pointStyle: 'circle',
                    order: 1,
                    width: 4,
                    radius: 4,
                },
                league: {
                    colour: Colours.SECONDARY_DARK_GREY,
                    pointStyle: 'triangle',
                    order: 2,
                    width: 2,
                    radius: 3,
                },
            },
        };
    },
    async mounted() {
        await this.drawForceVelocityPlot();
        this.$watch("data", async () => {
            this.replaceCanvas();
            await this.drawForceVelocityPlot();
        });
    },
    methods: {
        getFixedDataset(chartLabel, values, options) {
            return {
                type: 'scatter',
                label: chartLabel,
                data: values,
                fill: false,
                borderColor: options.colour,
                backgroundColor: options.colour,
                borderWidth: options.width,
                pointStyle: options.pointStyle,
                order: options.order,
                pointRadius: options.radius,
            };
        },
        replaceCanvas() {
            document.getElementById(this.id).remove();
            const canvas = document.createElement('canvas');
            canvas.id = this.id;
            canvas.width = 65 * window.innerWidth / 100; //approximately 65vw
            canvas.height = 60 * window.innerHeight / 100; //approximately 65vh
            canvas.className = 'chart';
            document.getElementById("parent" + this.id).appendChild(canvas);
        },
        tickDisplayText(value, percentiles) {
            if (this.valuesAreEqual(value, percentiles.average15)) {
                return '15%'
            } else if (this.valuesAreEqual(value, percentiles.average35)) {
                return '35%'
            } else if (this.valuesAreEqual(value, percentiles.average65)) {
                return '65%'
            } else if (this.valuesAreEqual(value, percentiles.average85)) {
                return '85%'
            } else {
                return null
            }
        },
        valuesAreEqual(valueA, valueB) {
            return Math.abs(valueA - valueB) < 0.005;
        },
        extractFVData(data) {
            return data.map(item => ({
                name: item.name,
                x: item.velocity,
                y: item.force
            }));
        },
        async drawForceVelocityPlot() {
            const { playerData, teamData, leagueData, tickValues } = this.data;
            const { averageForce, minimumForce, maximumForce, averageVelocity, maximumVelocity,  minimumVelocity } = tickValues;

            const playerFV = this.extractFVData(playerData);
            const teamFV = this.extractFVData(teamData);
            const leagueFV = this.extractFVData(leagueData);

            // Combine team, league & player fv values & get fv values 
            const combinedPlayersFV = teamFV.concat(playerFV, leagueFV);
            const velocityValues = combinedPlayersFV.map(t => parseFloat(t.x)); // Get velocity values
            const forceValues = combinedPlayersFV.map(t => parseFloat(t.y)); // Get force values

            const getAxesData = (average, min, max, values) => {
                const array = [];
                // Get values between min and max increase by 0.01
                for (let i = min; i <= max; i += 0.01) {
                    array.push(i);
                }
                // Get fv averages and 15, 35,... percentile values
                const average15 = percentile(15, values);
                const average35 = percentile(35, values);
                const average65 = percentile(65, values);
                const average85 = percentile(85, values);
                const percentiles = { average15, average35, average65, average85 };

                // If point on chart is equal to average & percentiles values, draw grid lines
                const gridLineColours = array.map(t =>
                    this.valuesAreEqual(average, t) ? Colours.WHITE : this.valuesAreEqual(average15, t) || this.valuesAreEqual(average35, t)
                        || this.valuesAreEqual(average65, t) || this.valuesAreEqual(average85, t) ? Colours.SECONDARY_DARK_GREY_1 : null);
                return { gridLineColours, percentiles };
            }

            // Get min & max fv values and add padding
            const minF = Math.ceil(minimumForce - 1);
            const maxF = Math.ceil(maximumForce + 1);
            const minV = Math.ceil(minimumVelocity - 2); // -2 here rather than -1 to deal with lopsidedness & points covering text
            const maxV = Math.ceil(maximumVelocity + 1);

            //Get axes data
            const fAxes = getAxesData(averageForce, minF, maxF, forceValues);
            const vAxes = getAxesData(averageVelocity, minV, maxV, velocityValues);
            const f0Percentiles = fAxes.percentiles;
            const f0Colours = fAxes.gridLineColours.reverse();
            const v0Percentiles = vAxes.percentiles;
            const v0Colours = vAxes.gridLineColours;

            const datasets = [
                this.getFixedDataset(playerFV[0].name, playerFV, this.chartOptions.player),
                this.getFixedDataset('Team', teamFV, this.chartOptions.team),
                this.getFixedDataset('Network', leagueFV, this.chartOptions.league)
            ];

            Chart.Tooltip.positioners.custom = () => { return { x: 300, y: 90 } };

            const chartCtx = document.getElementById(this.id).getContext("2d");
            new Chart(chartCtx, {
                data: {
                    datasets: datasets
                },
                options: {
                    plugins: {
                        tooltip: {
                            position: 'myCustomPositioner'
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    spanGaps: true,
                    animation: false,
                    hover: { mode: null },
                    legend: {
                        position: 'bottom',
                        onClick: (e) => e.stopPropagation()
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: minF,
                                stepSize: 0.01,
                                max: maxF,
                                maxRotation: 0,
                                autoSkip: false,
                                callback: value => { return value % 1 === 0 ? value : '' }
                            },
                            gridLines: {
                                display: true,
                                color: f0Colours,
                                lineWidth: 1.5,
                                zeroLineWidth: 0,
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "Average F0",
                                fontColor: Colours.WHITE,
                                fontSize: 14,
                            },
                        },
                        {
                            position: 'right',
                            ticks: {
                                min: minF,
                                stepSize: 0.01,
                                max: maxF,
                                maxRotation: 0,
                                autoSkip: false,
                                padding: 20,
                                fontColor: Colours.SECONDARY_DARK_GREY,
                                callback: value => this.tickDisplayText(value, f0Percentiles)
                            },
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: false,
                            },
                        }
                        ],
                        xAxes: [{
                            type: 'linear',
                            position: 'bottom',
                            ticks: {
                                min: minV,
                                max: maxV,
                                stepSize: 0.01,
                                maxRotation: 0,
                                autoSkip: false,
                                callback: value => { return value % 1 === 0 ? value : '' }
                            },
                            gridLines: {
                                display: true,
                                color: v0Colours,
                                lineWidth: 1.5,
                                zeroLineWidth: 0,
                                drawBorder: false,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "Average V0",
                                fontColor: Colours.WHITE,
                                fontSize: 14,
                            },
                        },
                        {
                            type: 'linear',
                            position: 'top',
                            ticks: {
                                min: minV,
                                stepSize: 0.01,
                                max: maxV,
                                padding: 0,
                                minRotation: 90,
                                autoSkip: false,
                                fontColor: Colours.SECONDARY_DARK_GREY,
                                callback: value => this.tickDisplayText(value, v0Percentiles)
                            },
                            gridLines: {
                                display: false,
                            },
                            scaleLabel: {
                                display: false,
                            },
                        }]
                    },
                    tooltips: {
                        enabled: true,
                        position: 'custom',
                        caretSize: 0,
                        filter: (tooltipItem) => {
                            return !datasets[tooltipItem.datasetIndex].data[tooltipItem.index].isMostRecent
                        },
                        callbacks: {
                            title: () => { },
                            label: (tooltipItem) => {
                                return ("(F0: " + tooltipItem.yLabel.toFixed(2) + ", V0: " + tooltipItem.xLabel.toFixed(2) + ")")
                            },
                            afterLabel: (tooltipItem) => {
                                return datasets[tooltipItem.datasetIndex].data[tooltipItem.index].name
                            }
                        },
                    },
                }
            })
        },
    },
};
</script>

<style scoped>
.force-velocity-plot {
    position: relative;
}

.label-top-left {
    position: absolute;
    top: 0;
    left: 70px;
    font-size: 10px;
    color: var(--white);
}

.label-top-right {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: var(--white);
}

.label-bottom-left {
    position: absolute;
    bottom: 80px;
    left: 70px;
    font-size: 10px;
    color: var(--white);
}

.label-bottom-right {
    position: absolute;
    bottom: 80px;
    right: 0;
    font-size: 10px;
    color: var(--white);
}

.chart-container {
    position: relative;
    height: 60vh;
    width: 65vw;
}

.chart {
    height: 100%;
    width: 100%;
}
</style>