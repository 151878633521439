<template>
    <div class="section-container">
        <SectionTitle :title="'Acceleration Speed Profiling'">
            <MoreInfo :id="'sdn-fvp-more-info'" :title="'Acceleration Speed Profiling'">
                <p>
                    Acceleration Speed Profiling displays the selected player’s sprinting abilities (orange cross). 
                    Players further to the right of the visual display greater top speeds, and players more vertical on 
                    the plot have better acceleration abilities. To provide context to the data, your own club’s players
                    are shown as the blue dots and the rest of the players in the data network are the grey triangles. 
                    The thick and thin white/grey lines represent the percentiles (detailed on the plot) of all players 
                    in the network. Therefore, for example, if a player is above the white line, their acceleration 
                    ability is above the average of the network.
                </p>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText message="Insufficient data for player " v-if="!hasData" />
        <div v-else>
            <div class="force-velocity-container">
                <ForceVelocityScatters :data="data" id="scatters" />
                <div class="kpi-summary">
                    <table style="width:18vw; font-size:12px">
                        <thead>
                            <tr>
                                <td></td>
                                <td>F0</td>
                                <td>V0</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Player's All Time Average </td>
                                <td>{{ data.playerData[0].force.toFixed(2) }}</td>
                                <td>{{ data.playerData[0].velocity.toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td>Team Average </td>
                                <td>{{ data.teamAverages.force.toFixed(2) }}</td>
                                <td>{{ data.teamAverages.velocity.toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td>Network Average </td>
                                <td>{{ data.leagueAverages.force.toFixed(2) }}</td>
                                <td>{{ data.leagueAverages.velocity.toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import MissingDataText from '@/components/MissingDataText.vue';
import ForceVelocityScatters from '@/components/ForceVelocityScatters';
import MoreInfo from "@/components/MoreInfo.vue";

export default {
    props: {
        data: Object,
        hasData: Boolean
    },
    components: {
        SectionTitle,
        MissingDataText,
        ForceVelocityScatters,
        MoreInfo
    },
}
</script>