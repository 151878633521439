<template>
<div>
  <div class="shadow-primary main-login-container">
    <div class="centre-logo-container" id="svg-container">
        <img src="../assets/images/svg/login-logo.svg" style="height: 100%; width: 100%; padding-left: 1vw;" alt="">
    </div>
    <!-- prettier-ignore -->
    <div class="align-horizontal">
        <div id="login-box">
        <form method="post" id="login-form" class="pt-lg-2 pb-2 pr-4 pl-4 mt-4 form-login"
        onsubmit="return false;">
            <h2>Sign In</h2>
            <fieldset>
            <div class="alert alert-success" v-if="this.$root.loginWarning === 1">
                You are now logged out
            </div>
            <div class="alert alert-danger" id="incorrect-warning" v-if="this.$root.loginWarning === 2">
                <span v-if="mfaCodeRequired">Incorrect username, password or code </span>
                <span v-if="!mfaCodeRequired">Incorrect username or password </span>
            </div>
            <div class="alert alert-warning" v-if="this.$root.loginWarning === 3">
                Your session has expired - please log in again
            </div>
            <div class="alert alert-danger" v-if="this.$root.loginWarning === 4">
                The server is not responding = please try again later
            </div> 
            <div class="alert alert-danger" v-if="this.$root.loginWarning === 5">
                An unexpected error has occurred
            </div>
            <div class="alert alert-danger" v-if="this.$root.loginWarning === 6">
                User is locked out. Please contact support.
            </div>

            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" id="username" name="username" class="form-control" placeholder="Username" v-model="userName" />
            </div>

            <div>
                <label for="password">Password</label>
                <div style="position: relative;">
                    <PasswordInputBox id="password" :modal="password"
                        @input="password = $event" placeholder="Password" />
                </div>
            </div>

            </fieldset>

            <div class="form-group form-group--last" style="display: flex; justify-content: space-between">
                <Button 
                id="'sign-in'" 
                :type="'primary'"
                :title="'Sign In'"
                :onClick="signIn"
                :disabled=isSignInDisabled
                class="custom-button"
                name="sign-in"
                />

            <!-- Privacy Policy -->
            <div style="margin-top: auto;">
                <h5 style="font-weight: bolder;"> <a href="../doc/Sportlight_platform_privacy_notice.pdf" target="_blank"> Privacy Notice
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>
                    </a> 
                </h5>
            </div>
            </div>

            <div class="mt-4">
                <hr />
                <div class="image-container" :style="{ opacity: isSignInDisabled ? 0.5 : 1 }">
                    <img src="@/assets/images/svg/ms-symbollockup_signin_dark.svg"/>
                    <b-button :disabled=isSignInDisabled class="ms-login-button custom-button" @click="sso" style="background:none; border:none;"/>
                </div>
            </div>
        </form>
    </div>
  </div>

    <div v-if="mfaCodeRequired" id="mfa-code-container" class="mx-auto mfa-pop-up">
        
            <span style="position:absolute; right: 5%; top:0;">
                <span @click=closePopOver()>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"
                    stroke="var(--red)" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" cursor="pointer"
                    class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </span>
            </span>
        <label for="code">Enter <strong>6-digit code</strong> in authenticator app </label>
        <input type="number" 
        id="code" 
        name="code"
        class="form-control" 
        pattern="/^-?\d+\.?\d*$/" 
        onKeyPress="if(this.value.length===6) return false;" 
        v-model="code" 
        @input="handleInput"/>

        <div style="margin-top: 1vh; color:var(--red)" v-if="this.$root.loginWarning === 2">
            Incorrect code, please try again
        </div>
    </div>

    <div v-if="mfaSetupRequired" id="qr-code-container" class="mx-auto mfa-pop-up">
            <span style="color:white; font-weight: 200;">
                <h5>Enable two-factor authentication</h5> 
            <ul>
                <li>Download a two-factor authenticator app</li>
                <li>Open authenticator app and scan QR code</li>
                <li>Enter 6-digit code when prompted</li>
            </ul>
            
            </span>
            <span @click=closePopOver() style="position:absolute; right: 0; top:0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"
                    stroke="var(--red)" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" cursor="pointer"
                    class="feather feather-x">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </span>
        <br>
        <img v-bind:src="qrCode" id="qr-code" width="50%" height="50%" />
    </div>
  </div>

  <cookie-law class="cookie" v-on:accept="cookieConsentAccept()" v-on:decline="cookieConsentReject()">
    <div slot-scope="props">
      <p class="cookie-message">
        SPORTLIGHT uses necessary cookies to make our site function and additional cookies to analyze site usage, which we use to improve the performance of our website and provide a better user experience. 
        By clicking 'Accept', you consent to the use of these additional cookies. You can change your preference at any time under 'Privacy Settings'.
      </p>
      <button class="cookie-accept-button" @click="props.accept" name="accept"><span>Accept</span></button>
      <button class="cookie-reject-button" @click="props.decline" name="reject"><span>Reject</span></button>
    </div>
  </cookie-law>

  </div>
</template>

<script>
import { UserData } from "@/components/UserData";
import CookieLaw from 'vue-cookie-law';
import Button from "@/components/Button.vue";
import AuthHelper from "@/utils/MsalAuthHelper";
import PasswordInputBox from '@/components/PasswordInputBox.vue';
import { storeSelectedSquadIds } from "@/utils/Squad";
import configStore from "@/store/config";

export default {
    data() {
        return {
            userName: "",
            password: "",
            code:"",
            keepLoggedIn: false,
            mfaSetupRequired: null,
            qrCode: "",
            mfaCodeRequired:null,
            isSignInDisabled: false,
            msalUser: null
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => {});
        },
        async signIn() {
            
            const json = {
                username: this.userName,
                password: this.password
            };

            const mfaPopOverStyling = container => {
                const ids = ['login-form', 'svg-container'];
                ids.forEach(id => {
                    const element = document.getElementById(id);
                    if (element) {
                        element.style.filter = "brightness(30%) blur(20px)";
                    }
                });
                const qrCodeContainer = document.getElementById(container);
                if (qrCodeContainer) {
                    qrCodeContainer.style.display = "";
                }
            };
            
            //add mfa code to response body if required
            if (this.mfaCodeRequired) {
                json.mfaCode = this.code;
                mfaPopOverStyling('mfa-code-container');
            }
            const response = await this.$root.webApiPost("/login", json);

            if (response.status === 200) {
                this.mfaSetupRequired = response.data.mfaSetupRequired;
                this.mfaCodeRequired = response.data.mfaCodeRequired;
                
                if (this.mfaSetupRequired) {
                    this.qrCode = response.data.qrCodeString;
                    //Add blur effect to login form & add qr code
                    mfaPopOverStyling('qr-code-container');
                }   

                if (this.mfaCodeRequired) {
                    mfaPopOverStyling('mfa-code-container');
                    mfaPopOverStyling('qr-code-container');                    
                }        
                
                await this.loginSuccess(response);
            } else {
                switch (response.status) {
                    case 401:
                        this.$root.loginWarning = 2; // invalid credentials
                        break;
                    case 403:
                        this.$root.loginWarning = 6; // user locked out
                        break;
                    case 404:
                        this.$root.loginWarning = 4; // invalid response from server
                        break;
                    default:
                        this.$root.loginWarning = 5; // unexpected error
                        break;
                }
            }
        },
        handleInput(event) {
            if (event.target.value.length === 6) {
                this.signIn(); //attempt to sign in once the user enters 6 digit code
                this.code = "";
            }
        },
        closePopOver() {
            //Remove blur effect from login form, svg & remove pop over element
            const ids = ['login-form', 'svg-container'];
            ids.forEach(id => {
                const element = document.getElementById(id);
                if (element) {
                    element.style.filter = "none";
                }
            });
            const qrCodeContainer = document.getElementById('qr-code-container');
            const mfaCodeContainer = document.getElementById('mfa-code-container');
            if (qrCodeContainer) { qrCodeContainer.style.display = "none"; }
            if (mfaCodeContainer) { mfaCodeContainer.style.display = "none"; }
        },
        cookieConsentAccept() {
          this.isSignInDisabled = false;
          this.$root.newPageView("Login Page");
          this.$root.cookieConsent = true;
        },
        cookieConsentReject() {
          localStorage.setItem('cookieRejectTimestamp', new Date().getTime());
          this.isSignInDisabled = false;
        },
        async sso() {
            await AuthHelper.configure("c8a2b56b-4868-40af-95c9-60dc3c4b54b3");
            await AuthHelper.login();
            this.msalUser = AuthHelper.getUser();
            
            if (this.msalUser) {
                //MSAL login successful
                const json = { username: this.msalUser.username, token: this.msalUser.idToken };
                const response = await this.$root.webApiPost("/msallogin", json);
                await this.loginSuccess(response);
            } else {
                //MSAL login failed/cancelled
                this.$root.loginWarning = 2;
            }
        },
        async loginSuccess(response) {
            const authToken = response.headers.authorization;
            if (authToken) {
                
                // NOTE: this needs to happen _before_ the authtoken is added to the global state.
                // When the authToken is added to the global state, the TopNav component becomes visible reactively and
                // attempts to load the selectedSquadIds which haven't been set yet, or may be set to a  value from a 
                // previous login.
                await configStore.dispatch('loadConfig', { 'root': this.$root, 'authToken': authToken });
                storeSelectedSquadIds([configStore.state.defaultSquadId]);
                
                // we've received a response with an auth token - so update the global user object
                console.log("GOT AUTH TOKEN");
                this.$root.updateGlobal("userData", {
                    userName: this.userName,
                    token: authToken,
                    primaryCustomer: response.data.primaryCustomer,
                    additionalCustomer: response.data.additionalCustomer,
                    customerOptions: response.data.primaryCustomer.customerOptions,
                    passwordChangeOptionEnabled: response.data.passwordChangeOptionEnabled,
                    passwordChangeRequired: response.data.passwordChangeRequired
                });
                UserData.data = this.$root.userData;

                this.$root.loginWarning = 0; // No error
                localStorage.setItem('peakSpeedUnit', 1);
                
                this.$root.newEvent("New Login", this.userName);

                if (this.userName === "sportlight-admin") {
                    this.navigateTo("/admin");
                } else if (response.data.passwordChangeRequired) {
                    this.navigateTo("/changepassword");
                } else {
                    this.navigateTo("/calendar");
                }
            }
            
        },
    },
    components: { 
      CookieLaw,
      Button,
        PasswordInputBox
    },
    async mounted() {
        const twoWeeks = 1209600000;
        console.log("Login.vue mounted");
        if (localStorage.getItem('cookie:accepted') === null) {
          this.isSignInDisabled = true;
        } else if (localStorage.getItem('cookie:accepted') === 'true') {
          this.$root.newPageView("Login Page");
          this.$root.cookieConsent = true;
        } else if (localStorage.getItem('cookieRejectTimestamp') <= (new Date().getTime() - twoWeeks)) {
          localStorage.removeItem('cookie:accepted');
          localStorage.removeItem('cookieRejectTimestamp');
        }
    }
};
</script>
<style scoped>
.cookie {
  background: #27292B;
}

.cookie-accept-button {
  background: var(--sportlight-teal);
  color: #ffffff;
  position: relative;
  left: 35%;
  font-size: 20px;
  width: 200px;
}

.cookie-reject-button {
  background: #383E42;
  color: #ACADAE;
  position: relative;
  left: 40%;
  font-size: 20px;
  width: 200px;
}

.cookie-message {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}
.form-login {
  max-width: 400px;
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0;
  right: 0;
}
.mfa-pop-up {
    max-width: 500px;
    width: 500px;
    position: absolute;
    top: 50%;
    right: 10%;
}

.main-login-container {
    position: relative;
    margin: auto;
    display: flex;
    max-width: 1000px;
    width: 1000px;
    height: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    overflow: hidden;

}
.centre-logo-container {
    position: absolute;
    background-image: url(../assets/images/svg/lidar-background.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: max-content;
    background-size: 200%;
    width: 600px;
    max-width: 600px;
    height: 100%
}
#login-box {
    width: 25vw; 
    height: 100%;
    right: 0;
    position: absolute;
}
.custom-button[disabled] {
    cursor: not-allowed;
}
.image-container {
    position: relative;
    width: 215px;
    height: 41px;
    margin-top: 4px;
}

.ms-login-button {
    position: absolute;
    width: 215px;
    height: 41px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border: none;
    cursor: pointer;
}
</style>