<template>
    <div class="col-12 col-lg-3">
        <div :id="id + '-main-container'">
            <canvas :id="id + '-container'" class="player-development-kpi-plot"></canvas>
        </div>
        <div class="player-development-kpi-rank-container text-center">
            <p>Match Average: {{ kpiAverage }} ({{ kpiPercentage }})</p>
            <span class="ranking-label">
                <p>{{ ranking }}{{ rankingTotal }} {{ leagueRankingPercentage }}</p>
                <span :class="rankingHoverClass">Player requires minimum of 4 matches</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        kpiPercentage: String,
        kpiAverage: Number,
        rankingTotal: Number,
        leagueRankingPercentage: String,
        ranking: String,
        rankingHoverClass: String
    },
};
</script>
<style scoped>
.player-development-kpi-rank-container{
  font-size: 12px; 
  line-height: 2px
}
</style>