import {
  getMeasurementUnit,
  MeasurementTypes
} from "../utils/MeasurementSystem";

function getThresholdString(thresholdMetres, sdUnit) {
  return `${sdUnit.formatStandard(thresholdMetres)} ${sdUnit.unitShort}/min`;
}

function getEpochCountThresholdsTable() {
  const sdUnit = getMeasurementUnit(MeasurementTypes.ShortDistance);

  return [
    {
      'KPI': 'Distance', 'Epoch': '30s',
      "Men's Threshold": getThresholdString(250, sdUnit),
      "Women's Threshold": getThresholdString(240, sdUnit)
    },
    {
      'KPI': 'Distance', 'Epoch': '60s',
      "Men's Threshold": getThresholdString(200, sdUnit),
      "Women's Threshold": getThresholdString(190, sdUnit)
    },
    {
      'KPI': 'Distance', 'Epoch': '120s',
      "Men's Threshold": getThresholdString(170, sdUnit),
      "Women's Threshold": getThresholdString(165, sdUnit)
    },
    {
      'KPI': 'HSR', 'Epoch': '30s',
      "Men's Threshold": getThresholdString(115, sdUnit),
      "Women's Threshold": getThresholdString(90, sdUnit)
    },
    {
      'KPI': 'HSR', 'Epoch': '60s',
      "Men's Threshold": getThresholdString(65, sdUnit),
      "Women's Threshold": getThresholdString(50, sdUnit)
    },
    {
      'KPI': 'HSR', 'Epoch': '120s',
      "Men's Threshold": getThresholdString(40, sdUnit),
      "Women's Threshold": getThresholdString(30, sdUnit)
    },
    {
      'KPI': 'Sprint', 'Epoch': '30s',
      "Men's Threshold": getThresholdString(65, sdUnit),
      "Women's Threshold": getThresholdString(30, sdUnit)
    },
    {
      'KPI': 'Sprint', 'Epoch': '60s',
      "Men's Threshold": getThresholdString(35, sdUnit),
      "Women's Threshold": getThresholdString(15, sdUnit)
    },
    {
      'KPI': 'Sprint', 'Epoch': '120s',
      "Men's Threshold": getThresholdString(20, sdUnit),
      "Women's Threshold": getThresholdString(10, sdUnit)
    },
    {
      'KPI': 'Turns', 'Epoch': '30s',
      "Men's Threshold": '4 /min',
      "Women's Threshold": '4 /min'
    },
    {
      'KPI': 'Turns', 'Epoch': '60s',
      "Men's Threshold": '3 /min',
      "Women's Threshold": '2 /min'
    },
    {
      'KPI': 'Turns', 'Epoch': '120s',
      "Men's Threshold": '2 /min',
      "Women's Threshold": '1.5 /min'
    }
  ];
}

export { getEpochCountThresholdsTable };
