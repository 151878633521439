<template>
    <div v-if="metric === 'fatigueFlag'" :class="['fatigue-flag', fatigueFlagColour]">
        {{ blankCell }}
    </div>
    <div v-else-if="metric === 'hamstringRisk'" :style="{ backgroundColor: hamstringRisk.colour, color: blackColourText }">
        {{ hamstringRisk.value || blankCell }}
    </div>
    <div v-else-if="metric === 'gameDemandScore'" :style="{ backgroundColor: gameDemand.colour, color: blackColourText }">
        {{ gameDemand.value || blankCell }}
    </div>
    <div v-else-if="metric === 'matchTime'">
        {{ value }}
    </div>
    <div v-else-if="metric === 'workRate'">
        {{ value.value }}
    </div>
    <div v-else :style="{ backgroundColor: percentileColour, color: textColour }">
        {{ value.value }}
    </div>
</template>

<script>
import Colours from "@/utils/Colours";
import getHamstringRisk from "@/utils/HamstringRisk";
import { getFlagColour } from "@/utils/FatigueFlagsValues";

export default {
    data() {
        return {
            blankCell: '\u00A0', // non-breaking space unicode
            blackColourText: Colours.BLACK
        };
    },
    props: {
        value: [String, Number, Boolean, Object],
        metric: String
    },
    computed: {
        fatigueFlagColour() {
            return getFlagColour(this.value);
        },
        hamstringRisk() {
            return getHamstringRisk(this.value);
        },
        percentileColour() {
            const percentile = this.value && this.value.percentile;
            if (percentile === null || isNaN(percentile)) {
                return '';
            }
            if (percentile <= 10) {
                return Colours.DARK_GREEN;
            } else if (percentile > 10 && percentile <= 30) {
                return Colours.BRIGHT_GREEN;
            }
            else if (percentile > 30 && percentile <= 70) {
                return Colours.SPORTLIGHT_TEAL;
            }
            else if (percentile > 70 && percentile <= 90) {
                return Colours.ORANGE;
            } else {
                return Colours.RED;
            }
        },
        textColour() {
            const percentile = this.value && this.value.percentile;
            if (percentile === null || isNaN(percentile)) {
                return Colours.WHITE;
            }
            return this.blackColourText;
        }
    },
};
</script>

<style scoped>
.fatigue-flag {
    width: 100%;
    height: 100%;
}
</style>