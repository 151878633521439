
const METRIC_ID = 'metric';
const IMPERIAL_ID = 'imperial';

// Use Javascript enum technique from here: https://stackoverflow.com/questions/44447847/enums-in-javascript-with-es6
const MeasurementTypes = Object.freeze({
	LongDistance: Symbol("longDistance"),
	ShortDistance: Symbol("shortDistance"),
	Speed: Symbol("speed"),
	PeakSpeed: Symbol("peakSpeed"),
	Acceleration: Symbol("acceleration"),
	Count: Symbol("count"),
});

class MeasurementUnit {
    // 1 mile is not exactly 1600m. It's actually 1609...
    static Mile = new MeasurementUnit('miles', 0.0006213712, 3, 2, 2,'miles' ,'Miles');
    static Metre = new MeasurementUnit('m', 1, 1, 0, 0,'m' ,'Metres');
    static Feet = new MeasurementUnit('ft', 3.280839895, 1, 0, 0,'ft' , 'Feet');
    static MetresPerSecond = new MeasurementUnit('m/s', 1, 1, 1, 0, 'm/s' , 'Metres per second');
    static MilesPerHour = new MeasurementUnit('mph', 2.236936, 1, 1, 1, 'mph', 'Miles per hour');
    static KilometresPerHour = new MeasurementUnit('km/h', 3.6, 1, 1, 1, 'km/h', 'Kilometres per hour');
    static MetresPerSecond2 = new MeasurementUnit('m/s\u00B2', 1, 1, 1, 0, 'm/s2', 'Metres per second squared');
    static FeetPerSecond2 = new MeasurementUnit('ft/s\u00B2', 3.280839895, 1, 1, 1, 'ft/s2', 'Feet per second squared');
    static Count = new MeasurementUnit('N', 1, 1, 0, 0, 'N', 'N');
    unitShort;
    #metricConversion;
    decimalPlacesCoarse;
    decimalPlacesStandard;
    decimalPlacesFine;

    // metricConversion - multiply the metric equivalent of this unit to get the value in terms of this unit.
    //      e.g. for a unit of 'Miles' the metric equivalent is 'Metres' (in this application!) and 1metre is equal to 0.0006... miles,
    //      so the metricConversion value will be 0.0006...
    // decimalPlaces fine/Standard/Coarse is the number of decimal places to use when formatting the number in different contexts.
    //      e.g. if you need a details value (e.q. you are looking at distance over a short timespan where detailed values are important) then decimalPlacesFine should be used.
    //          conversely if you are using the value in a label, or the fine-grained details are not relevant e.g. 0-10m, or distance in the match then decimalPlacesCoarse should be used instead
    //      decimalPlacesStandard is a halfway house between the 2 values and should be appropriate in the majority of cases.
    constructor(unitShort, metricConversion, decimalPlacesFine, decimalPlacesStandard, decimalPlacesCoarse, unitShortPlain, unitLong){
        this.unitShort = unitShort;
        this.#metricConversion = metricConversion;
        this.decimalPlacesStandard = decimalPlacesStandard;
        this.decimalPlacesFine = decimalPlacesFine;
        this.decimalPlacesCoarse = decimalPlacesCoarse;
        this.unitShortPlain = unitShortPlain;
        this.unitLong = unitLong;
    }
    convert(metricValue){
        if (metricValue) {
            return metricValue * this.#metricConversion;
        } else {
            return metricValue; // return null/undefined when metricValue is null or undefined.
        }
    }
    reverseConvert(convertedValue) {
        //since the sportlight database is in metric units. When adding data, 
        //imperial values need to be converted back to metric values
        if (convertedValue) {
            return convertedValue / this.#metricConversion;
        } else {
            return convertedValue; // return null/undefined when convertedValue is null or undefined.
        }
    }
    // utility method to reduce the amount of code needed to create a label e.g. '10m' or '33ft'
    formatCoarseUnit(metricValue) {
        return this.formatCoarse(metricValue) + this.unitShort;
    }

    formatFine(metricValue) {
        return this.convert(metricValue).toFixed(this.decimalPlacesFine);
    }
    formatCoarse(metricValue) {
        return this.convert(metricValue).toFixed(this.decimalPlacesCoarse);
    }
    formatStandard(metricValue) {
        return this.convert(metricValue).toFixed(this.decimalPlacesStandard);
    }

}

function getMeasurementUnit(measurementType){
    const value = getMeasurementSystemId();
    if (value === IMPERIAL_ID) {
        switch (measurementType) {
            case MeasurementTypes.LongDistance: return MeasurementUnit.Mile;
            case MeasurementTypes.ShortDistance: return MeasurementUnit.Feet;
            case MeasurementTypes.Speed: return MeasurementUnit.MilesPerHour;
            case MeasurementTypes.PeakSpeed: return MeasurementUnit.MilesPerHour;
            case MeasurementTypes.Acceleration: return MeasurementUnit.FeetPerSecond2;
            case MeasurementTypes.Count: return MeasurementUnit.Count;
            default: {
                console.log("unrecognized measurementType: " + measurementType);
                throw "unrecognized measurementType: " + measurementType;
            }
        }
    } else {
        switch (measurementType) {
            case MeasurementTypes.LongDistance:
            case MeasurementTypes.ShortDistance: return MeasurementUnit.Metre;
            case MeasurementTypes.Speed: return MeasurementUnit.MetresPerSecond;
            case MeasurementTypes.PeakSpeed: {
                const peakSpeedUnit = localStorage.getItem('peakSpeedUnit');
                if (peakSpeedUnit === "3.6") {
                    return MeasurementUnit.KilometresPerHour;
                } else {
                    return MeasurementUnit.MetresPerSecond;
                }

            }
            case MeasurementTypes.Acceleration: return MeasurementUnit.MetresPerSecond2;
            case MeasurementTypes.Count: return MeasurementUnit.Count;
            default: {
                console.log("unrecognized measurementType: " + measurementType);
                throw "unrecognized measurementType: " + measurementType;
            }
        }
    }
}

function convertBwData(data, measurementUnit){
    const { minimum, maximum, average, standardDeviation, value } = data;
    return {
        minimum: measurementUnit.convert(minimum),
        maximum: measurementUnit.convert(maximum),
        average: measurementUnit.convert(average),
        standardDeviation: measurementUnit.convert(standardDeviation),
        value: measurementUnit.convert(value),
    };
}

function convertRainbowComparisonData(data, measurementUnit) {

    const { epochMaxValues, epochMinValues, epochPercentile15Values, epochPercentile35Values, epochPercentile65Values, epochPercentile85Values } = data;
    return {
        epochMaxValues: epochMaxValues.map(v => measurementUnit.convert(v)),
        epochMinValues: epochMinValues.map(v => measurementUnit.convert(v)),
        epochPercentile15Values: epochPercentile15Values.map(v => measurementUnit.convert(v)),
        epochPercentile35Values: epochPercentile35Values.map(v => measurementUnit.convert(v)),
        epochPercentile65Values: epochPercentile65Values.map(v => measurementUnit.convert(v)),
        epochPercentile85Values: epochPercentile85Values.map(v => measurementUnit.convert(v)),
    };
}

function convertRainbowPercentileComparisonData(data, measurementUnit) {
    const { maxValue, minValue, percentile15Value, percentile35Value, percentile65Value,  percentile85Value, } = data;
    return {
        maxValue: measurementUnit.convert(maxValue),
        minValue: measurementUnit.convert(minValue),
        percentile15Value: measurementUnit.convert(percentile15Value),
        percentile35Value: measurementUnit.convert(percentile35Value),
        percentile65Value: measurementUnit.convert(percentile65Value),
        percentile85Value: measurementUnit.convert(percentile85Value),
    };
} 

function getMeasurementSystemId(){
    const DEFAULT_MEASUREMENT_SYSTEM = METRIC_ID;
    let value = localStorage.getItem('measurementSystem');
    if (!value){
        localStorage.setItem('measurementSystem', DEFAULT_MEASUREMENT_SYSTEM);
        value = DEFAULT_MEASUREMENT_SYSTEM;
    }
    return value;
}

function setMeasurementSystemId(measurementSystem) {
    localStorage.setItem('measurementSystem', measurementSystem);
}

export { MeasurementTypes, MeasurementUnit, convertBwData, convertRainbowComparisonData, getMeasurementUnit,
    convertRainbowPercentileComparisonData, getMeasurementSystemId, setMeasurementSystemId, METRIC_ID, IMPERIAL_ID };
