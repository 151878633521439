<template>
    <div class="info-text">
        <li>Green line: The {{ currentDataText }} is &gt; 1 s.d. above the {{ referenceDataText }} historical data</li>
        <li>Red line: The {{ currentDataText }} is &lt; 1 s.d. below the {{ referenceDataText }} historical data</li>
    </div>
</template>

<script>
export default {
    name: 'BoxWhiskerAdditionalInfoText',
    props: {
        currentDataText: String,
        referenceDataText: String
    }
};
</script>