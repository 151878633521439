<template>
    <span>
        <input :type="showPassword ? 'text' : 'password'" :id="id" name="password" class="form-control" v-model="inputValue"
            @input="updateModal" :placeholder="placeholder" />
        <span class="button" @click="toggleShowPassword">
            <div :class="showPassword ? 'icon eye-off-icon' : 'icon eye-icon'"></div>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        id: String,
        modal: String,
        placeholder: String
    },
    data() {
        return {
            showPassword: false,
            inputValue: this.modal
        };
    },
    methods: {
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },
        updateModal(event) {
            this.$emit('input', event.target.value);
        }
    },
    watch: {
        modal(newVal) {
            this.inputValue = newVal;
        }
    },
};
</script>

<style>

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-100%);
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: var(--white);
}
.eye-icon {
    mask: url('../assets/feather/eye.svg') no-repeat center / contain;
    -webkit-mask: url('../assets/feather/eye.svg') no-repeat center / contain;
}

.eye-off-icon {
    mask: url('../assets/feather/eye-off.svg') no-repeat center / contain;
    -webkit-mask: url('../assets/feather/eye-off.svg') no-repeat center / contain;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--input-box-bg) inset;
    -webkit-text-fill-color: var(--white);
    border: none;
    color: var(--white);
    caret-color: var(--white);
}

</style>