import Vue from "vue";
import Vuex from "vuex";
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import { loadSelectedSquadIds } from "@/utils/Squad";

Vue.use(Vuex);

// Vuex store state
const state = {
    selectedPlayerIdx: 0,
    selectedPlayer: null,
    players: null,
    rehabSessions: null
};

// Vuex store mutations
const mutations = {
    setSelectedPlayer(state, player) {
        state.selectedPlayer = player;
    },

    setSelectedPlayerIdx(state, index) {
        state.selectedPlayerIdx = index;
    },

    setPlayers(state, players) {
        state.players = players;
    },

    setRehabSessions(state, rehabSessions) {
        state.rehabSessions = rehabSessions;
    },

    resetState(state) {
        state.selectedPlayerIdx = 0;
        state.selectedPlayer = null;
        state.players = null;
        state.rehabSessions = null;
    }
};

// Vuex store actions
const actions = {
    /**
     * Store the selected player and index in their states
     * @param {Object} context - Vuex store context
     * @param {number} index - Index of the selected player
     */
    updateSelectedPlayer({ commit, state }, index) {
        commit("setSelectedPlayer", state.players[index]);
        commit("setSelectedPlayerIdx", index);
    },

    /**
     * Fetch the rehab sessions from the backend and update the state
     * @param {Object} context - Vuex store context
     */
    async getRehabSessions({ commit }, context) {
        const customerId = UserData.customerId();
        const response = await context.$root.webApiGet(
            `/rehabsessions?customerId=${customerId}`
        );

        if (response.status === 200) {
            const playersRehabSessions = response.data.playerRehabSessions;
            commit("setRehabSessions", playersRehabSessions);
        } else {
            errorHandler.error(response, context);
        }
    },

    /**
     * Fetch players from the backend and update the state
     * @param {Object} context - Vuex store context
     */
    async getPlayers({ commit, state }, context) {
        const customerId = UserData.customerId();
        const response = await context.$root.webApiGet(
            `/players?customerId=${customerId}`
        );

        if (response.status === 200) {
            // Check local storage for user's squad selection and filter get players from selected squad
            const selectedSquads = loadSelectedSquadIds();
            const selectedSquadPlayers = response.data.filter(t => selectedSquads.includes(t.squadId));
            
            selectedSquadPlayers.sort((a, b) => {
                const aSessions = state.rehabSessions[a.playerId];
                const bSessions = state.rehabSessions[b.playerId];
                const aMostRecentSessionDate = aSessions ? new Date(aSessions[0].sessionDate) : null;
                const bMostRecentSessionDate = bSessions ? new Date(bSessions[0].sessionDate) : null;

                // Players with no rehab sessions are ordered by their names to maintain some consistency
                if (!aMostRecentSessionDate && !bMostRecentSessionDate) {
                    return a.name.localeCompare(b.name);
                } else if (!aMostRecentSessionDate) {
                    return 1;
                } else if (!bMostRecentSessionDate) {
                    return -1;
                } else {
                    return bMostRecentSessionDate - aMostRecentSessionDate;
                }
            });

            commit("setPlayers", selectedSquadPlayers);
            this.dispatch("updateSelectionBasedOnPlayers");
        } else {
            errorHandler.error(response, context);
        }
    },

    // Updates selected player and index based on the current players list
    updateSelectionBasedOnPlayers({ commit, state }) {
        if (state.players) {
            // Find the current selected player in the new list to keep the selection
            const newIndex = state.selectedPlayer ? state.players.findIndex(player => player.playerId === state.selectedPlayer.playerId) : 0;
            // Reset index and selected player if the current selected player is not in the new list
            commit("setSelectedPlayerIdx", newIndex !== -1 ? newIndex : 0);
            commit("setSelectedPlayer", newIndex !== -1 ? state.players[newIndex] : state.players[0]);
        } else {
            commit("resetState");
        }
    }
};

// Vuex store instance
export default new Vuex.Store({ state, mutations, actions });
