<template>
    <div class="section-container" style="justify-content: start;" v-if="!$root.isLoadingData">
        <SectionTitle :title="title">
            <MoreInfo :id="'peak-threshold-effort-counts-more-info'" :title="title">
                <p>
                    Peak Threshold Counts completed above a set absolute peak threshold, for three epoch lengths.
                    The data displayed is a player’s average number completed in matches compared with a box
                    and whisker plot representing the {{ getLeagueText }} data.
                </p>
                <ul class="info-text">
                    <BoxWhiskerAdditionalInfoText currentDataText="selected player" :referenceDataText="getLeagueText" />
                </ul>
                <p>Thresholds are set to determine a peak period within each epoch, derived from {{ getLeagueText }}
                    representative data.</p>
                <b-table hover :items="epochCountThresholdsTable" dark="dark"> </b-table>
            </MoreInfo>
        </SectionTitle>
        <div v-if="hasData">
            <PeakThresholdCountRow /> <!-- this is the header -->
            <PeakThresholdCountRow :counts="distanceCounts" label="Distance" />
            <PeakThresholdCountRow :counts="hsrCounts" label="HSR" />
            <PeakThresholdCountRow :counts="sprintCounts" label="Sprints" />
            <PeakThresholdCountRow :counts="turnCounts" label="Turns" />
        </div>
        <MissingDataText v-else :message="'Insufficient data for player'" />
    </div>
</template>

<script>
import MoreInfo from "../components/MoreInfo.vue";
import MissingDataText from "../components/MissingDataText.vue";
import PeakThresholdCountRow from "./PeakThresholdCountRow.vue";
import SectionTitle from "../components/SectionTitle.vue";
import { getEpochCountThresholdsTable } from "../utils/EpochCountThresholdsTable";

export default {
    props: {
        distanceCounts: Object,
        hsrCounts: Object,
        sprintCounts: Object,
        turnCounts: Object,
        leagueText: String,
        title: String
    },
    components: {
        PeakThresholdCountRow,
        MoreInfo,
        MissingDataText,
        SectionTitle
    },
    computed: {
        hasData: function() {
            if (this.distanceCounts === undefined) {
                return false;
            } else {
                return (this.distanceCounts !== null && this.distanceCounts.countData30s.value !== null);
            }
        },
        epochCountThresholdsTable() {
            return getEpochCountThresholdsTable();
        },
        getLeagueText: function() {
            return this.leagueText === 'League' ? 'league-wide' : this.leagueText;
        }
    }
};
</script>

<style scoped>
hr {
    border: none;
    border-top: 1px solid #5a5e6280;
    margin: 1em 0;
}
.left-align {
    text-align: left;
}
</style>
