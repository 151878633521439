<template>
    <canvas :id=id> </canvas>
</template>

<script>
import { Chart } from "chart.js";
import { RainbowColours } from "../components/RainbowColours";

export default {
    props: {
        data: Object,
        id: String
    },
    methods: {
        drawRainbowBarChart(canvasId, kpi) {
            const { value, comparisonData } = kpi;
            const { minValue, percentile15Value, percentile35Value, 
                    percentile65Value, percentile85Value, maxValue } = comparisonData;

            const createDataset = (percentileValue, backgroundColor) => ({
                data: [value.average >= percentileValue ? percentileValue : value.average],
                backgroundColor: backgroundColor,
                barPercentage: 1,
                borderWidth: 1,
            });

            const datasets = [
                createDataset(percentile15Value, RainbowColours.red),
                createDataset(percentile35Value, RainbowColours.orange),
                createDataset(percentile65Value, RainbowColours.yellow),
                createDataset(percentile85Value, RainbowColours.lime),
                createDataset(maxValue, RainbowColours.green),
                //background colour for the following datasets are the standard rainbow colours with an opacity of 0.1
                {
                    data: [percentile15Value],
                    backgroundColor: 'rgba(245, 64, 76, 0.1)',
                    barPercentage: 1
                },
                {
                    data: [percentile35Value],
                    backgroundColor: 'rgba(250, 164, 77, 0.1)',
                    barPercentage: 1
                },
                {
                    data: [percentile65Value],
                    backgroundColor: 'rgba(250, 248, 127, 0.1)',
                    barPercentage: 1
                },
                {
                    data: [percentile85Value],
                    backgroundColor: 'rgba(119, 237, 130, 0.1)',
                    barPercentage: 1
                },
                {
                    data: [maxValue],
                    backgroundColor: 'rgba(33, 158, 46, 0.1)',
                    barPercentage: 1
                }
            ];

            const getOrdinal = number => {
                const roundedNumber = (number - Math.floor(number)) < 0.5 ? Math.floor(number) : Math.ceil(number) 
                const suffixes = ['th', 'st', 'nd', 'rd'];
                const lastDigit = roundedNumber % 10;
                const specialCases = [11, 12, 13];
                const suffix = specialCases.includes(roundedNumber % 100)
                    ? suffixes[0]
                    : suffixes[lastDigit] || suffixes[0];
                return `${roundedNumber}${suffix}`;
            };

            if (this.existingCharts !== null) {
                this.existingCharts.destroy();
            }
            
            const chartCtx = document.getElementById(canvasId);
            this.existingCharts = new Chart(chartCtx, {
                type: 'horizontalBar',
                options: {
                    maintainAspectRatio: false,
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: true,
                        position: 'nearest',
                        yAlign: 'right',
                        mode: 'single',
                        callbacks: {
                            title: () => {
                                return null
                            },
                            label: () => {
                                return null;
                            },
                            afterLabel: () => {
                                return [`Average: ` + value.average.toFixed(1),
                                `Percentile: ` + getOrdinal(value.percentile)];
                            },
                        }
                    },
                    layout: {
                        padding: {
                            right: 25
                        }
                    },
                    scales: {
                        xAxes: [{
                            stacked: false,
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false,
                                min: minValue,
                                max: maxValue
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            gridLines: {
                                display: false,
                                lineWidth: 1
                            },
                            ticks: {
                                display: true
                            }
                        }]
                    }
                },
                data: {
                    labels: '',
                    datasets
                }
            });
            this.existingCharts.update()
        },
    },
    data() {
        return {
            existingCharts: null,
        }
    },
    watch: {
        data(newData) {
            this.drawRainbowBarChart(this.id, newData);
        },
    },
    async mounted() {
        this.drawRainbowBarChart(this.id, this.data);
    }
}
</script>