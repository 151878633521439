<template>
    <div id="content-wrap" style="margin-left:90px" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <MissingDataText v-if="!players" :message="'Selected squad is empty'" />
        <div v-else>
            <Header :updateSelection="selectPlayer" />
            <div id="chart-container"></div>
            <div class="update-rehab">
                <Button :id="'edit-button'" :type="'primary'" :title="getTitle" v-b-modal.edit-rehab-edit-modal
                    :onClick="editRehab" />

            </div>
            <EditModal :id="'edit-rehab-edit-modal'" :title="getTitle" :ok="acceptEditRehab" :cancel="cancelEditRehab"
                :okDisabled="disableEditButton" :cancelVariant="'secondary standard-btn'">
                <div class="alert alert-danger" v-if="this.editWarningText">
                    {{ this.editWarningText }}
                </div>

                <div class="form-group">
                    <p>Injury date</p>
                    <b-calendar placeholder="Select Injury Date" v-model="editInjuryDate" for="edit-type"
                        class="btn-outline-secondary" required></b-calendar>
                    <br>
                    <br>
                    <label for="edit-type">Peak Speed Target (<MeasurementUnit :type="PeakSpeed" />)
                    </label>
                    <b-form-input v-model="editPeakSpeed"></b-form-input>
                    <label for="edit-type">HSR Distance Target (<MeasurementUnit :type="ShortDistance" />)</label>
                    <b-form-input v-model="editHSRDistance"></b-form-input>
                    <label for="edit-type">High Accelerations Target</label>
                    <b-form-input v-model="editHighAccelerations" required></b-form-input>
                    <label for="edit-type">Sprint Distance Target (<MeasurementUnit :type="ShortDistance" />)</label>
                    <b-form-input v-model="editSprintDistance" required></b-form-input>
                    <div style="margin-top:1vh">
                        <Button v-if="hasTargetsReferences" :id="'average-button'" :type="'secondary'"
                            :title="'Add Average'" :onClick="populateAverageTargets" />
                        <Button v-if="hasTargetsReferences" :id="'max-button'" :type="'secondary'"
                            :title="'Add Maximum'" :onClick="populateMaximumTargets" />
                    </div>
                </div>
            </EditModal>
        </div>
    </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";
import "@svgdotjs/svg.filter.js";
import { rehabChartWeekly } from "./RehabChartWeekly.js";
import { rehabChartDaily } from "./RehabChartDaily.js";
import { rehabChartMini } from "./RehabChartMini.js";
import { rehabChartStages } from "./RehabChartStages.js";
import { rehabChartTurnRates } from "./RehabChartTurnRates.js";
import { rehabChartTargets } from "./RehabChartTargets.js";
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import UpdateSetting from "@/utils/UpdateSetting";
import EditModal from "@/components/EditModal.vue";
import MissingDataText from '@/components/MissingDataText.vue';
import Button from "@/components/Button.vue";
import Header from "./Header.vue";
import store from './store';
import MeasurementUnit from "@/components/MeasurementUnit.vue";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

export default {
    components: {
        EditModal,
        MissingDataText,
        Button,
        Header,
        MeasurementUnit
    },
    data() {
        return {
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            peakSpeedUnit: getMeasurementUnit(MeasurementTypes.PeakSpeed),
            svg: null,
            rehabData: null,
            editModal: false,
            editWarningText: "",
            injuryDate: null,
            editInjuryDate: null,
            editPeakSpeed: null,
            editHSRDistance: null,
            editHighAccelerations: null,
            editSprintDistance: null,
            rehabType: null,
            playerId: null,
            customerId: UserData.customerId(),
            targetsReferences: null,
            hasTargetsReferences: false,
            targets: null,
            PeakSpeed: MeasurementTypes.PeakSpeed,
            ShortDistance: MeasurementTypes.ShortDistance,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage);
        },
        initSvg() {
            // set up the svg and add to the chart-container element - will fill available width and scale with the browser
            this.svg = SVG()
                .addTo("#chart-container")
                .size("100%", "100%")
                .viewbox(0, 0, 1830, 881)
                .attr("preserveAspectRatio", "xMinYMin");

            rehabChartWeekly.init(this.svg, this.rehabData);
            rehabChartDaily.init(this.svg, this.rehabData);
            rehabChartMini.init(this.svg, this.rehabData);
            rehabChartStages.init(this.svg, this.rehabData);
            rehabChartTurnRates.init(this.svg, this.rehabData);
            rehabChartTargets.init(this.svg, this.rehabData);
        },
        render() {
            rehabChartWeekly.render();
            rehabChartDaily.render();
            rehabChartMini.render();
            rehabChartStages.render();
            rehabChartTurnRates.render();
            rehabChartTargets.render();
        },
        async getRehabSessions() {
            await store.dispatch('getRehabSessions', this);
        },
        async getPlayers() {
            await store.dispatch('getPlayers', this);
        },
        async getRehab() {
            // get rehab data for the specified player from the API
            const response = await this.$root.webApiGet(
                `/rehab?customerId=${this.customerId}&playerId=${this.selectedPlayer.playerId}`
            );

            if (response.status === 200) {
                this.rehabData = response.data;
                this.injuryDate = response.data.rehabDate;
                this.targets = response.data.targets;
                this.rehabType = response.data.rehabType;
                this.targetsReferences = response.data.targetsReferences;

                //Player needs targetsReferences for user to access Add Average/Maximum buttons. If one value is null
                //we can assume they have no targetReferences at all
                this.hasTargetsReferences = this.targetsReferences.highAccelerations.average !== null;

                //If new player has existing rehab, fill in their values, else null out fields
                if (this.targets) {
                    this.editInjuryDate = response.data.rehabDate;
                    this.editPeakSpeed = this.peakSpeedUnit.formatFine(this.targets.peakSpeedMPerS);
                    this.editHSRDistance = this.sdUnit.formatFine(this.targets.highSpeedDistanceM);
                    this.editHighAccelerations = this.targets.highAccelerations;
                    this.editSprintDistance = this.sdUnit.formatFine(this.targets.sprintDistanceM);
                } else {
                    this.editInjuryDate = null;
                    this.editPeakSpeed = null;
                    this.editHSRDistance = null;
                    this.editHighAccelerations = null;
                    this.editSprintDistance = null;
                }

            } else {
                errorHandler.error(response, this);
            }
        },
        async selectPlayer() {
            await this.$root.executeTaskWithProgressBar(async () => {
                    await this.getRehab();
                    this.svg.remove();
                    this.initSvg();
                    this.render();
            });
        },
        async editRehab() {
            if (this.players) {
                this.editModal = true;
            }
        },
        async acceptEditRehab() {
            this.editWarningText = "";  
            const highSpeedDistanceM = this.sdUnit.reverseConvert(this.editHSRDistance);
            const sprintDistanceM = this.sdUnit.reverseConvert(this.editSprintDistance);
            const peakSpeedMPerS = this.peakSpeedUnit.reverseConvert(this.editPeakSpeed);

            const json = {
                playerId: this.selectedPlayer.playerId, customerId: this.customerId, injuryDate: this.editInjuryDate,
                peakSpeedMPerS, highSpeedDistanceM, sprintDistanceM, highAccelerations: this.editHighAccelerations
            };

            const response = await this.$root.webApiPost(`/editrehab`, json);

            if (response.status === 200) {
                this.editModal = false;
                await store.dispatch('getPlayers', this);
                await this.getRehab();
                this.svg.remove();
                this.initSvg();
                this.render();
            } else {
                errorHandler.error(response, this);
            }
        },
        getConvertedPeakSpeed(value) {
            return (value * this.peakSpeedUnit).toFixed(1);
        },
        async cancelEditRehab() {
            this.editModal = false;
        },
        async populateAverageTargets() {
            this.editPeakSpeed = this.peakSpeedUnit.formatFine(this.targetsReferences.peakSpeedMPerS.average) || null;
            this.editHSRDistance = this.sdUnit.formatFine(this.targetsReferences.highSpeedDistanceM.average) || null;
            this.editHighAccelerations = this.targetsReferences.highAccelerations.average.toFixed(1) || null;
            this.editSprintDistance = this.sdUnit.formatFine(this.targetsReferences.sprintDistanceM.average) || null;

        },
        async populateMaximumTargets() {
            this.editPeakSpeed = this.peakSpeedUnit.formatFine(this.targetsReferences.peakSpeedMPerS.maximum) || null;
            this.editHSRDistance = this.sdUnit.formatFine(this.targetsReferences.highSpeedDistanceM.maximum) || null;
            this.editHighAccelerations = this.targetsReferences.highAccelerations.maximum.toFixed(1) || null;
            this.editSprintDistance = this.sdUnit.formatFine(this.targetsReferences.sprintDistanceM.maximum) || null;
        }
    },
    computed: {
        disableEditButton() {
            const isInvalidInput = field => {
                return (typeof field === 'string') && (isNaN(field) || field.replace(/\s/g, "") === "") || field === null;
            };

            return (
                this.editInjuryDate === null ||
                isInvalidInput(this.editPeakSpeed) ||
                isInvalidInput(this.editHSRDistance) ||
                isInvalidInput(this.editHighAccelerations) ||
                isInvalidInput(this.editSprintDistance)
            );
        },
        getTitle: function () {
            return !this.targets ? 'Add Rehab' : 'Edit Rehab';
        },
        players() {
            return store.state.players;
        },
        selectedPlayer() {
            return store.state.selectedPlayer;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            console.log("Rehab.vue mounted");
            this.$root.newPageView("Rehab Longitudinal Analysis Page", UserData.userName());
            UpdateSetting();
            await this.getRehabSessions();
            await this.getPlayers();
            if (this.players && this.selectedPlayer) {
                await this.getRehab();
                this.initSvg();
                this.render();
            }
        });

    }
};
</script>
<style scoped>
#content-wrap,
#chart-container {
    height: 100%;
    background: var(--bg-primary);
}

#edit-button {
    position: absolute;
    right: 20px;
    top: 178px;
    width: auto;
}

#player-dropdown-container {
    position: absolute;
    right: 20px;
    top: 70px;
    width: auto;
}

#player-dropdown {
    min-width: 10vw !important;
}

#player-dropdown /deep/ .dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}
</style>