import { convertRainbowPercentileComparisonData } from "@/utils/MeasurementSystem";

const convertValues = (values, measurementUnit) => {
    return values.map(t =>
        t !== null ? measurementUnit.formatFine(t) : null
    );
};

const getSessionDates = sessions => {
    return sessions.map(t => t.date);
};

const getSessionTitles = sessions => {
    return sessions.map(t => t.title);
};

const convertRainbowChart = (kpi, sessions, measurementUnit) => {
    return {
        sessionValues: convertValues(kpi.sessionValues, measurementUnit),
        rollingAverage: convertValues(kpi.rollingAverage, measurementUnit),
        comparisonData: convertRainbowPercentileComparisonData(
            kpi.epochValues,
            measurementUnit
        ),
        sessionDateStrings: getSessionDates(sessions),
        sessionTitles: getSessionTitles(sessions),
        type: "rainbow",
        yAxisLabel: `Peak (${measurementUnit.unitShort}/min)`
    };
};

const convertChart = (kpi, sessions, measurementUnit) => {
    return {
        sessionValues: convertValues(kpi.sessionValues, measurementUnit),
        rollingAverage: convertValues(kpi.rollingAverage, measurementUnit),
        sessionDateStrings: getSessionDates(sessions),
        sessionTitles: getSessionTitles(sessions),
        comparisonData: {
            upperBound: convertValues(kpi.upperBound, measurementUnit),
            lowerBound: convertValues(kpi.lowerBound, measurementUnit),
            average: convertValues(kpi.average, measurementUnit)
        },
        yAxisLabel: measurementUnit.unitLong
    };
};

export { convertChart, convertRainbowChart };