<template>
    <div class="section-container">
        <SectionTitle title="KPI Overview" />
        <table class="mt-4 mx-auto">
            <thead>
                <tr class="labels">
                    <th> Distance (<MeasurementUnit :type="LongDistance"/>) </th>
                    <th> HSR (<MeasurementUnit :type="ShortDistance"/>) </th>
                    <th> Sprint (<MeasurementUnit :type="ShortDistance"/>) </th>
                    <th> Turns (n) </th>
                    <th> Acceleration (<MeasurementUnit :type="ShortDistance"/>) </th>
                    <th> Deceleration (<MeasurementUnit :type="ShortDistance"/>) </th>
                    <th> Peak Speed (<MeasurementUnit :type="PeakSpeed"/>) </th>
                </tr>
                <tr class="labels" style="font-size: 10px;">
                    <td></td>
                    <td> Over <MeasurementValue :type="Speed" :value=5.5 /><MeasurementUnit :type="Speed" /> </td>
                    <td> Over <MeasurementValue :type="Speed" :value=7 /><MeasurementUnit :type="Speed" /> </td>
                    <td></td>
                    <td> Greater than <MeasurementValue :type="Acceleration" :value=2 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /> </td>
                    <td> Less than -<MeasurementValue :type="Acceleration" :value=2 :format="formatCoarse"/><MeasurementUnit :type="Acceleration" /> </td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td> 
                        <h2> <MeasurementValue :type="LongDistance" :value="sessionValues.DISTANCE" /> </h2> 
                    </td>
                    <td> 
                        <h2> <MeasurementValue :type="ShortDistance" :value="sessionValues.HSR" /> </h2> 
                    </td>
                    <td> 
                        <h2> <MeasurementValue :type="ShortDistance" :value="sessionValues.SPRINT" /> </h2> 
                    </td>
                    <td> 
                        <h2> {{ sessionValues.TURNS }} </h2> 
                    </td>
                    <td> 
                        <h2> <MeasurementValue :type="Acceleration" :value="sessionValues.ACCEL" /> </h2> 
                    </td>
                    <td> 
                        <h2> <MeasurementValue :type="Acceleration" :value="sessionValues.DECEL" /> </h2> 
                    </td>
                    <td> 
                        <h2> <MeasurementValue :type="PeakSpeed" :value="sessionValues.PEAK_SPEED" /> </h2> 
                    </td>
                </tr>
                <tr class="labels" style="font-size: 12px;">
                    <td> {{ getDisplayText(sessionPercentages.DISTANCE) }} </td>
                    <td> {{ getDisplayText(sessionPercentages.HSR) }} </td>
                    <td> {{ getDisplayText(sessionPercentages.SPRINT) }} </td>
                    <td> {{ getDisplayText(sessionPercentages.TURNS) }} </td>
                    <td> {{ getDisplayText(sessionPercentages.ACCEL) }} </td>
                    <td> {{ getDisplayText(sessionPercentages.DECEL) }} </td>
                    <td>
                        {{ sessionPercentages.PEAK_SPEED !== null
                                ? calculatePercentageCap(sessionPercentages.PEAK_SPEED).toFixed(0) + '% of match maximum'
                                : '-'
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import MeasurementUnit from "@/components/MeasurementUnit.vue";
import MeasurementValue from "@/components/MeasurementValue.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import { MeasurementTypes, MeasurementUnit as MU } from "@/utils/MeasurementSystem";
export default {
    props: {
        sessionPercentages: Object,
        sessionValues: Object
    },
    components: {
        MeasurementUnit,
        MeasurementValue,
        SectionTitle
    },
    data() {
        return {
            Acceleration: MeasurementTypes.Acceleration,
            ShortDistance: MeasurementTypes.ShortDistance,
            LongDistance: MeasurementTypes.LongDistance,
            PeakSpeed: MeasurementTypes.PeakSpeed,
            Speed: MeasurementTypes.Speed,
            formatCoarse: MU.prototype.formatCoarse,
        };
    },
    methods: {
        getDisplayText(value) {
            return value === null ? "-" : this.calculatePercentageCap(value.toFixed(0)) + '% of match average';
        },
        calculatePercentageCap(value) {
            return value >= 120 ? 120 : value; // percentages are capped at 120%
        }
    }
};
</script>

<style scoped>
.labels {
    color: var(--bg-secondary-light);
}
table {
    width: 60vw; 
    border-collapse: collapse; 
    white-space: nowrap;
}
th, td {
    padding: 0px 10px;
}
th:not(:last-child),
td:not(:last-child) {
    border-right: 1px solid var(--bg-secondary-dark);
}
</style>