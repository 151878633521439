
function getTurnEntrySpeedClassifications(turnEntrySpeedBoundaries, speedUnit) {

    const bounds = turnEntrySpeedBoundaries.bounds;
    const unit = speedUnit.unitShort;
    const turnEntrySpeedClassifications = [];
    turnEntrySpeedClassifications[0] = {
        name: turnEntrySpeedBoundaries.names[0],
        text: `<${speedUnit.formatStandard(bounds[0]) + unit}`,
        turnEntrySpeedFilter: t => t.entrySpeedMPerS <= bounds[0],
        speedValueRange: { min: 0, max: speedUnit.convert(bounds[0]) }
    };
    const lastBoundsIndex = bounds.length-1;
    for (let i = 1; i <= lastBoundsIndex; i++) {
        turnEntrySpeedClassifications[i] = {
            name: turnEntrySpeedBoundaries.names[i],
            text: `>${speedUnit.formatStandard(bounds[i-1])} & <${speedUnit.formatStandard(bounds[i]) + unit}`,
            turnEntrySpeedFilter: t => t.entrySpeedMPerS > bounds[i-1] && t.entrySpeedMPerS <= bounds[i],
            speedValueRange: { min: speedUnit.convert(bounds[i-1]), max: speedUnit.convert(bounds[i]) }
        };
    }
    turnEntrySpeedClassifications[lastBoundsIndex + 1] = {
        name: turnEntrySpeedBoundaries.names[lastBoundsIndex + 1],
        text: `>${speedUnit.formatStandard(bounds[lastBoundsIndex]) + unit}`,
        turnEntrySpeedFilter: t => t.entrySpeedMPerS > bounds[lastBoundsIndex],
        speedValueRange: { min: speedUnit.convert(bounds[lastBoundsIndex]) }
    };
    return turnEntrySpeedClassifications;
}

export { getTurnEntrySpeedClassifications };