<template>
    <div style="margin-top:5vh" v-if="sessionIsAMatch">
        <p style="font-size: 12px; margin-left: 5vw;">(*) - played &lt;90mins </p>
    </div>
</template>

<script>
import store from "../store/session";

export default {
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        }
    },
};
</script>