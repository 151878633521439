import configStore from "@/store/config";
class SquadOption {
	squadId;
	text;
	value;
	description;
	checked;

	constructor(squadId, description) {
		this.squadId = squadId;
		this.value = squadId;
		this.text = description;
		this.description = description;
		this.checked = squadId === configStore.state.defaultSquadId;
	}
}

const ARCHIVE_SQUAD_ID = 3;
const UNIDENTIFIED_SQUAD_ID = 4;

function getSquadDescription(squadId) {
	const squad = configStore.state.squads.find((s) => s.squadId === squadId);
	return squad ? squad.name : null;
}

function storeSelectedSquadIds(squadIds) {
	localStorage.setItem("squads", JSON.stringify(squadIds));
}

function loadSelectedSquadIds() {
	return JSON.parse(localStorage.getItem("squads"));
}

function toSquadOptions(squads) {
	return squads.map(s => new SquadOption(s.squadId, s.name));
}

function getSelectableSquads() {

	// do not include the 'unidentified' squad as a selectable option.
	const selectableSquads = configStore.state.squads.filter(s => s.squadId !== UNIDENTIFIED_SQUAD_ID);

	// return an Array with the archive squad as the last element
	const archiveSquad = selectableSquads.find(s => s.squadId === ARCHIVE_SQUAD_ID);
	return [...selectableSquads.filter(a => a !== archiveSquad), archiveSquad];
}

export {
	loadSelectedSquadIds,
	storeSelectedSquadIds,
	getSquadDescription,
	getSelectableSquads,
	toSquadOptions
};