<template>
    <div v-if="data">
        <canvas :id="id" :style="{ width: width, height: height }" :width="viewport !== 0 ? '100%' : null"></canvas>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import Colours from '../utils/Colours';
export default {
    props: {
        id: String,
        data: Object,
        title: String,
        width: String,
        height: String,
        viewport: {
            default: 0,
            type: Number
        }
    },
    data() {
        return {
            turnRatesChart: null
        };
    },
    methods: {
        async getTurnRates() {
            if (this.data) {
                const { labels, classificationNames, colours, turns, leftTurnPercentages, rightTurnPercentages,
                    leftTurnNormalRange, rightTurnNormalRange, type, tooltipTitles } = this.data;

                const leftCounts = turns.map(t => t.leftCount);
                const rightCounts = turns.map(t => t.rightCount);
                const totalCounts = turns.map(t => t.totalCount);

                const dataCount = labels.length;
                const getDatasets = (title, category, other, colour) => {
                    return {
                        label: title,
                        data: category, ...other,
                        backgroundColor: colour,
                        barPercentage: dataCount === 1 ? 0.6 : 0.9,
                    };
                };

                const sharedLeftTurnData = { leftCounts, totalCounts, leftTurnPercentages, leftTurnNormalRange };
                const sharedRightTurnData = { rightCounts, totalCounts, rightTurnPercentages, rightTurnNormalRange };

                const leftClassifications =  classificationNames.map(c =>
                    getDatasets(
                        'Left ' + c + ' Turns',
                        turns.map(t => -1 * t.leftClassificationCounts[c]),
                        sharedLeftTurnData,
                        colours[c.toLowerCase()]
                    )
                );
                const rightClassifications =  classificationNames.map(c =>
                    getDatasets(
                        'Right ' + c + ' Turns',
                        turns.map(t => t.rightClassificationCounts[c]),
                        sharedRightTurnData,
                        colours[c.toLowerCase()]
                    )
                );
                const countClassifications = classificationNames.map(c =>
                    getDatasets(
                        c + ' Turns',
                        turns.map(t => t.combinedClassificationCounts[c]),
                        { totalCounts },
                        colours[c.toLowerCase()]
                    )
                );

                const types = {
                    DEFAULT: leftClassifications.concat(rightClassifications),
                    SPLIT_TOTAL: [
                        //total left/right turns split
                        getDatasets('Left Turns', turns.map(t => -1 * t.leftCount), { totalCounts }, colours.low),
                        getDatasets('Right Turns', rightCounts, { totalCounts }, colours.low),
                    ],
                    GROUPED_TOTAL: countClassifications,
                    TOTAL: [
                        //total turns
                        getDatasets('Total Turns', totalCounts, { totalCounts }, colours.low),
                    ]
                };
                const datasets = types[type];
                const isDefaultSplitTurns = datasets === types.DEFAULT;

                const maxTurnValue = isDefaultSplitTurns || datasets === types.SPLIT_TOTAL 
                    ? Math.max(...leftCounts, ...rightCounts) : 0;
                const roundedMaxTurn = (Math.ceil(maxTurnValue / 5) * 5);

                if (this.turnRatesChart !== null) {
                    this.turnRatesChart.destroy();
                }

                const ctx = document.getElementById(this.id);
                if (this.viewport !== 0) { ctx.height = this.viewport; }

                this.turnRatesChart = new Chart(ctx.getContext('2d'), {
                    type: 'horizontalBar',
                    options: {
                        title: {
                            display: true,
                            text: this.title,
                            maintainAspectRatio: false,
                        },
                        layout: {
                            padding: {
                                bottom: dataCount === 1 ? 15 : 45
                            }
                        },
                        legend: {
                            display: false
                        },
                        tooltips: {
                            yAlign: 'top',
                            enabled: true,
                            mode: 'nearest',
                            callbacks: {
                                title: function (data, tooltipItem) { return tooltipItem.tooltipTitle[data[0].index]; },
                                label: function (tooltipItem, data) {
                                    const barLabel = data.datasets[tooltipItem.datasetIndex].label;
                                    const barValue = Number(tooltipItem.xLabel);
                                    const negativeBarValue = Math.abs(barValue);
                                    if (barValue < 0) {
                                        return (barLabel + ": " + negativeBarValue);
                                    } else {
                                        return barLabel + ": " + barValue;
                                    }
                                },
                                afterLabel: function (tooltipItem, data) {
                                    const barValue = Number(tooltipItem.xLabel);
                                    const ttiIndex = tooltipItem.index;
                                    const dataset = data.datasets[tooltipItem.datasetIndex];
                                    if (!isDefaultSplitTurns) { return null; }
                                    if (isDefaultSplitTurns) {
                                        if (barValue < 0) {
                                            let leftTurnRange;
                                            if (dataset.leftTurnNormalRange.length !== 0) {
                                                leftTurnRange = dataset.leftTurnNormalRange[ttiIndex] !== null ? '\n' +
                                                    'Average Left Turn Range: ' + dataset.leftTurnNormalRange[ttiIndex] : ' ';
                                            } else {
                                                leftTurnRange = '';
                                            }
                                            return (
                                                'Left Turns: ' + dataset.leftCounts[ttiIndex] + '\n' +
                                                'Total Turns: ' + dataset.totalCounts[ttiIndex] + '\n' +
                                                'Percentage: ' + dataset.leftTurnPercentages[ttiIndex] + '%' +
                                                leftTurnRange);
                                        } else {
                                            let rightTurnRange;
                                            if (dataset.rightTurnNormalRange.length !== 0) {
                                                rightTurnRange = dataset.rightTurnNormalRange[ttiIndex] !== null ? '\n' +
                                                    'Average Right Turn Range: ' + dataset.rightTurnNormalRange[ttiIndex] : ' ';
                                            } else {
                                                rightTurnRange = '';
                                            }
                                            return (
                                                'Right Turns: ' + dataset.rightCounts[ttiIndex] + '\n' +
                                                'Total Turns: ' + dataset.totalCounts[ttiIndex] + '\n' +
                                                'Percentage: ' + dataset.rightTurnPercentages[ttiIndex] + '%' +
                                                rightTurnRange);
                                        }
                                    } else {
                                        return 'Total Turns: ' + dataset.totalCounts[ttiIndex];
                                    }
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: {
                                    display: true,
                                    zeroLineColor: Colours.WHITE,
                                    color: Colours.SECONDARY_DARK_GREY_1,
                                    lineWidth: 1
                                },
                                ticks: {
                                    display: true,
                                    padding: 9,
                                    callback: value => { return (value < 0) ? Math.abs(value) : value; },
                                    suggestedMin: -roundedMaxTurn,
                                    suggestedMax: roundedMaxTurn
                                }
                            }],
                            yAxes: [{
                                stacked: true,
                                gridLines: {
                                    display: false,
                                    lineWidth: 1
                                },
                                ticks: {
                                    display: true,
                                    padding: 9
                                }
                            }]
                        }
                    },
                    data: {
                        labels: labels,
                        datasets: datasets,
                        tooltipTitle: tooltipTitles
                    }
                });

                this.turnRatesChart.update();
            }
        },
    },
    async mounted() {
        await this.getTurnRates();
    },
    watch: {
        async data() {
            await this.getTurnRates();
        }
    }
};
</script>

<style></style>