<template>
    <div :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" id="content-wrap" style="margin-left:90px;">
        <SessionHeader />
        <div id="content" class="py-2 px-3">
            <div style="display: flex; align-items: center">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h1 id="session-header" class="pb-4" v-if="session">
                                <span v-if="sessionIsAMatch">{{ customerName }} vs {{ oppositionName }} </span>
                                <span v-else>{{ sessionTitle }}</span>
                                <div class="pill ml-4" :style="{ borderColor: sessionTypeColour }">
                                    {{ sessionType }}
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-around mx-auto" style="width:25vw">
                    <RadioButton :id="'data-overview-type-radio-group'" :modal="selectedDataOverviewType"
                        :options="dataOverviewOptions" :name="'kpi-options'"
                        @update-modal="selectedDataOverviewType = $event" label="Data Overview" :task="updateTableData"
                        v-if="false" />

                    <RadioButton :id="'team-radio-group'" :modal="selectedTeam" :options="teamOptions" label="Team"
                        :name="'team-options'" @update-modal="selectedTeam = $event" :task="updateTableData" />

                    <RadioButton :id="'data-type-radio-group'" :modal="selectedDataType" :options="dataTypeOptions"
                        label="Data Type" :name="'kpi-options'" @update-modal="selectedDataType = $event"
                        :task="updateTableData" />
                </div>
            </div>
            <MoreInfo :id="'session-reporting-more-info'" :title="title">
                <p>
                    The Session Reporting screen offers an overview of the team’s and the opposition’s current session
                    Key Performance Indicators (KPIs) compared to reference datasets. Each participating player is
                    evaluated against the reference dataset average, with color-coded boxes indicating the percentile
                    each player falls into:
                <ul>
                    <li>
                        <div :style="{ backgroundColor: Colours.DARK_GREEN }" class="colour-description mr-2">
                        </div> &lt;= 10th percentile
                    </li>
                    <li>
                        <div :style="{ backgroundColor: Colours.BRIGHT_GREEN }" class="colour-description mr-2">
                        </div> &gt; 10th &amp; &lt;= 30th percentile
                    </li>
                    <li>
                        <div :style="{ backgroundColor: Colours.SPORTLIGHT_TEAL }" class="colour-description mr-2">
                        </div> &gt; 30th &amp; &lt;= 70th percentile
                    </li>
                    <li>
                        <div :style="{ backgroundColor: Colours.ORANGE }" class="colour-description mr-2">
                        </div> &gt; 70th &amp; &lt;= 90th percentile
                    </li>
                    <li>
                        <div :style="{ backgroundColor: Colours.RED }" class="colour-description mr-2">
                        </div> &gt; 90th percentile
                    </li>
                </ul>
                <br> As well as some widespread metrics related to distance covered at specific thresholds for speed,
                acceleration and deceleration, the screen also displays the Fatigue Flag (FF), Hamstring Risk (HR), and
                Work Rate. FF and HR are unique to Sportlight, and the documentation related to these metrics can be
                found
                <router-link class="learn-more" to="/whitepaper"> <span> here</span> </router-link>. <br> <br>
                Work Rate has been developed by Sportlight in conjunction with the Saudi Pro League. It is calculated
                using speed and acceleration fused using Osgnach’s (2010) calculation for energy cost. It provides an
                input into an equation that decays over time and is designed to be a close surrogate for heart rate.
                </p>
            </MoreInfo>
            <MissingDataText v-if="!hasData" message="KPI Reporting Unavailable" />
            <div class="px-3 mx-auto" v-else>
                <KPITable :data="tableData" columnKey="playerId" headingKey="name" :isPlayerTable=true
                    :kpiGroups="kpiGroups" />
            </div>
        </div>
    </div>
</template>

<script>
import KPITable from './KPITable.vue';
import { UserData } from "@/components/UserData";
import { errorHandler } from "@/components/ErrorHandler";
import ReportingKpiGroups from '@/utils/ReportingKpiGroups';
import store from '@/store/session';
import ReportingTableDataGenerator from '@/utils/ReportingTableDataGenerator';
import Colours from "@/utils/Colours";

export default {
    components: {
        KPITable
    },
    data() {
        return {
            customerId: UserData.customerId(),
            sessionId: this.$route.params.id, 
            selectedDataOverviewType: 'total',
            dataOverviewOptions: [
                { text: 'Total', value: 'total' },
                { text: 'In Play', value: 'inPlay' },

            ],
            selectedDataType: 'data',
            dataTypeOptions: [
                { text: 'Data', value: 'data' },
                { text: 'Percentile', value: 'percentile' }
            ],
            selectedTeam: 'current',
            teamOptions: [
                { text: 'Current', value: 'current' },
                { text: 'Opposition', value: 'opposition' }
            ],
            data: [],
            hasData: false,
            matchType: 'FULL_MATCH',
            tableData: [],
            kpiGroups: ReportingKpiGroups,
            Colours: Colours,
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/sessionreporting?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.data = response.data;
            } else {
                errorHandler.error(response, this);
            }
        },
        updateTableData() {
            const data = this.selectedTeam === 'current' ? this.data.ourData : this.data.oppositionData;
            this.hasData = Array.isArray(data) && data.length > 0;
            if (this.hasData) {
                this.tableData = ReportingTableDataGenerator.createTableData(data, this.selectedDataType, this.matchType);
            }
        },
        generateRandomKPI() {
            const maxValue = this.selectedDataType === 'percentile' ? 100 : 15000;
            return {
                value: Math.floor(Math.random() * maxValue),
                percentile: Math.floor(Math.random() * 101),
            };
        },
    },
    computed: {
        session() {
            return store.state.session?.session;
        },
        customerName() {
            return this.session.customerName;
        },
        oppositionName() {
            return this.session.opposition.customerName;
        },
        sessionType() {
            return this.session.type;
        },
        sessionTypeColour() {
            return this.session.typeColour;
        },
        sessionTitle() {
            return this.session.title;
        },
        sessionIsAMatch() {
            return this.session.sessionType.sessionIsAMatch;
        }
    },
    async mounted() {
        await this.$root.executeTaskWithProgressBar(async () => {
            await this.getData();
            this.updateTableData();
            console.log("Reporting.vue mounted");
            this.$root.newPageView("Reporting Page", UserData.userName());
        });
    },
};
</script>

<style scoped>
li {
    display: flex;
    width: 100%;
    align-items: center;

}
.colour-description{ 
    width: 20px; 
    height: 15px;
}
a {
    color: var(--sportlight-teal);
}
</style>