<template>
    <div id="content-wrap" style="margin-left:90px" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <div>
        <PageHeader>
            <PageHeaderTitle>
                <h1>
                    League-wide Team Comparisons 
                </h1>
            </PageHeaderTitle>
        </PageHeader>
        <PageLayout>
        <div class="full-width-radio-button-container" style="width: 35vw">
            <div class="dropdown-container">
                <span>Season: </span>
                <b-dropdown v-if="seasonOptions" style="width: 8vw" variant="outline-primary" :text="seasonOptions[this.selectedSeasonIndex].text">
                    <b-dropdown-item v-for="(option, index) in seasonOptions" :key="index"
                        @click="updateSeasonSelector(index)" class="pt-2">
                        {{ option.text }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            </div>
            <div class="section-container">    
                <SectionTitle :title="'KPI Totals'">
                    <MoreInfo :id="'kpi-totals-info-modal'" :title="'KPI Totals'">
                        The KPI Totals provide league-wide team comparisons from matches this season for the total and per 90 minute values. 
                        The box plot shows the distribution of the average/maximum performance of each team, 
                        with the coloured line showing your team's value. 
                        For each metric, the team total includes data from every player in the match excluding goalkeepers. 
                        This comparison excludes all non-league games including cup games and non-competitive matches.
                    </MoreInfo>
                </SectionTitle>
                <MissingDataText v-if="!hasSeasonComparisonData" :message="'No Data For This Season'"/>
                <div v-else>
                    <div  class="toggle-btn-container">
                        <RadioButton :id="'kpi-analysis-radio-group'" :modal="displayMaxData" :options="avgMaxOptions"
                            :name="'kpi-options'" @update-modal="displayMaxData = $event" :task="updateTeamComparisonPlots" />
                        <RadioButton :id="'kpi-rates-radio-group'" :modal="displayPer90Data" :options="rateOptions"
                            :name="'kpi-options'" @update-modal="displayPer90Data = $event" :task="updateTeamComparisonPlots" />
                    </div>
                    <TeamModuleChart :kpi="distance" :title="maxAverageText + ' Total Distance (' + ldUnit.unitShort  + per90Text+')'"
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-distance-label-chart'"
                        :chartContainerId="'distance-main-container'" :chartId="'total-distance-chart'"
                        :chartClass="'team-kpi-plot'" />

                    <TeamModuleChart :kpi="hsr" :title="maxAverageText + ' Total HSR (' + sdUnit.unitShort + per90Text+')'"
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-hsr-label-chart'"
                        :chartContainerId="'hsr-main-container'" :chartId="'total-hsr-chart'" :chartClass="'team-kpi-plot'" />

                    <TeamModuleChart :kpi="sprint" :title="maxAverageText + ' Total Sprint Distance (' + sdUnit.unitShort + per90Text+')'"
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-sprint-label-chart'"
                        :chartContainerId="'sprint-main-container'" :chartId="'total-sprint-chart'"
                        :chartClass="'team-kpi-plot'" />

                    <TeamModuleChart :kpi="turns" :title="maxAverageText + ' Total Turns (N' + per90Text +')'" 
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-turns-label-chart'"
                        :chartContainerId="'turns-main-container'" :chartId="'total-turns-chart'"
                        :chartClass="'team-kpi-plot'" />

                    <TeamModuleChart :kpi="inPlayMPOCombined" :title="maxAverageText + ' MPO (In Play Combined)'"
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-inPlayMPOCombined-label-chart'"
                        :chartContainerId="'inPlayMPOCombined-main-container'" :chartId="'total-inPlayMPOCombined-chart'"
                        :chartClass="'team-kpi-plot'" />

                    <TeamModuleChart :kpi="inPlayMPOIntensive" :title="maxAverageText + ' MPO (In Play Intensive)'"
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-inPlayMPOIntensive-label-chart'"
                        :chartContainerId="'inPlayMPOIntensive-main-container'" :chartId="'total-inPlayMPOIntensive-chart'"
                        :chartClass="'team-kpi-plot'" />

                    <TeamModuleChart :kpi="inPlayMPOExtensive" :title="maxAverageText + ' MPO (In Play Extensive)'"
                        :hover-text="'Team averaged (all players) Totals this season *'" :labelId="'total-inPlayMPOExtensive-label-chart'"
                        :chartContainerId="'inPlayMPOExtensive-main-container'" :chartId="'total-inPlayMPOExtensive-chart'"
                        :chartClass="'team-kpi-plot'" />
                </div>
            </div> 
            <div class="section-container">
                <SectionTitle :title="'Peak Periods'">
                    <MoreInfo :id="'peak-periods-info-modal'" :title="'Peak Periods'">
                        The Peak Periods provide league-wide team comparisons from Premier League matches for the selected season. Individual box plots 
                        show the distribution of the average performance of each team, with the coloured boxes showing your team's value. 
                        For each metric, the team average includes data from every player in the match excluding goalkeepers. This comparison excludes 
                        all non-league games such as cup and non-competitive matches.
                    </MoreInfo>
                </SectionTitle>
                <div class="full-width-radio-button-container" style="width: 35vw">
                    <RadioButton :id="'kpi-radio-group'" :modal="kpiSelected" :options="kpiOptions"
                        :name="'kpi-options'" @update-modal="kpiSelected = $event" :task="toggleKpiGroup" />
                </div>
                <MissingDataText v-if="!hasSeasonPeakDemands" :message="'No Data For This Season'"/>
                <div v-else>
                    <div v-if="peakPeriodsData">
                        <div>
                            <VerticalBoxWhisker :id="'team-peak-30'" :data="peakPeriodsData.epoch30s" :labels="peakPeriodsData.blankLabels" :options="epoch30sOptions">
                            </VerticalBoxWhisker>
                        </div>
                        <div style="margin-top: 5vh;">
                            <VerticalBoxWhisker :id="'team-peak-60'" :data="peakPeriodsData.epoch60s" :labels="peakPeriodsData.blankLabels" :options="epoch60sOptions">
                            </VerticalBoxWhisker>
                        </div>
                        <div style="margin-top: 5vh;">
                            <VerticalBoxWhisker :id="'team-peak-120'" :data="peakPeriodsData.epoch120s" :labels="peakPeriodsData.blankLabels" :options="epoch120sOptions">
                            </VerticalBoxWhisker>
                        </div>
                    </div>
                </div>
            </div> 
        </PageLayout>
        </div>
    </div>
</template>

<script>

import { plots } from "../components/Plots";
import { UserData } from "../components/UserData";
import { errorHandler } from "../components/ErrorHandler";
import GetChartContext from "../components/GetChartContext";
import TeamModuleChart from "../components/TeamModuleChart.vue";
import PageLayout from '../components/PageLayout.vue';
import PageHeader from '../components/PageHeader.vue';
import PageHeaderTitle from '../components/PageHeaderTitle.vue';
import MissingDataText from '../components/MissingDataText.vue';
import MoreInfo from '../components/MoreInfo.vue';
import RadioButton from '../components/RadioButton.vue';
import Colours from '../utils/Colours';
import VerticalBoxWhisker from "../components/VerticalBoxWhisker.vue";
import SectionTitle from "../components/SectionTitle.vue";
import { getSeasons } from "../utils/Seasons";
import UpdateSetting from "@/utils/UpdateSetting";
import {
    convertBwData,
    getMeasurementUnit,
    MeasurementTypes,
} from '../utils/MeasurementSystem';

export default {
    components: {
        TeamModuleChart,
        PageHeader,
        PageLayout,
        MissingDataText,
        MoreInfo,
        RadioButton,
        VerticalBoxWhisker,
        SectionTitle,
        PageHeaderTitle
    },
    computed: {
        defaultOptions() {
            return {
                tooltipLabel: "Current Match",
                showTicks: false,
                showTitle: true,
                yAxisLabel: this.kpiSelected === 'TURNS' ? 'Turns (N/min)' : `Distance (${this.sdUnit.unitShort}/min)`,
                precision: 1,
            };
        },
        epoch30sOptions() {
            return {
                ...this.defaultOptions,
                title: '30s',
            };
        },
        epoch60sOptions() {
            return {
                ...this.defaultOptions,
                title: '60s',
            };
        },
        epoch120sOptions() {
            return {
                ...this.defaultOptions,
                title: '120s',
            };
        },
    },
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            data: null,
            distance: null,
            hsr: null,
            sprint: null,
            turns: null,
            inPlayMPOCombined: null,
            inPlayMPOIntensive: null,
            inPlayMPOExtensive: null,
            customerId: UserData.customerId(),
            displayMaxData: false,
            maxAverageText: 'Average',
            hasSeasonComparisonData: false,
            avgMaxOptions: [
                { text: 'Average', value: false },
                { text: 'Max', value: true }
            ],
            kpiSelected: 'DISTANCE',
            kpiOptions: [
                { text: 'Distance', value: 'DISTANCE' },
                { text: 'HSR', value: 'HSR' },
                { text: 'Sprint', value: 'SPRINT' },
                { text: 'Turns', value: 'TURNS' }
            ],
            peakPeriodsData: null,
            hasTeamPeakDemandsData: null,
            selectedSeasonIndex: 0,
            seasonSelected: null,
            seasonOptions: null,
            hasSeasonPeakDemands: false,
            earliestSession: null,
            displayPer90Data: false,
            per90Text:'',
            rateOptions: [
                { text: 'Total', value: false },
                { text: 'Per 90 Min', value: true },
            ],
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
            console.log(newPage);
        },
        async getEarliestSessionDate() {
                const response = await this.$root.webApiGet(`/teamstartdate?customerId=${this.customerId}`);
                if (response.status === 200) {
                    this.earliestSession = response.data;
                } else {
                    errorHandler.error(response, this);
                }
        },
        async getData() {
            const seasonStart = this.seasonSelected.startDate.toISOString().substring(0, 10);
            const seasonEnd = this.seasonSelected.endDate.toISOString().substring(0, 10);
            const response = await this.$root.webApiGet(`/teamcomparison?customerId=${this.customerId}&startDate=${seasonStart}&endDate=${seasonEnd}`);

            if (response.status === 200) {
                this.data = response.data;
                this.hasSeasonComparisonData = this.data.teamComparisonData.averageData.kpis.distance.value;
                // Check any graph to see if we were present and data exists for that season
                this.hasSeasonPeakDemands = this.data.teamPeakDemands.dataByEpochByKpi[this.kpiSelected] &&
                    this.data.teamPeakDemands.dataByEpochByKpi[this.kpiSelected]["_30S"].customerRank !== 0;
            } else {
                errorHandler.error(response, this);
            }
        },
        async getTeamComparisonPlots() {
            if (this.hasSeasonComparisonData) {
                const { maxData, averageData, maxSessions } = this.data.teamComparisonData;
                const teamData = this.displayMaxData ? maxData : averageData;
                const maxSessionTitles = this.displayMaxData ? maxSessions : {};
                const { kpis, rankings } = teamData;
                const { distanceRank, hsrRank, sprintRank, turnRank, distancePer90Rank, hsrPer90Rank,
                    sprintPer90Rank, turnPer90Rank, inPlayMPOCombinedRank, inPlayMPOIntensiveRank, inPlayMPOExtensiveRank, totalTeams } = rankings;
                const { distance, hsr, sprint, turns, distancePer90, hsrPer90, sprintPer90, turnsPer90,
                    inPlayMPOCombined, inPlayMPOIntensive, inPlayMPOExtensive } = kpis;
                const {
                    maxDistanceSession = null, maxHsrSession = null, maxSprintSession = null, maxTurnSession = null,
                    maxInPlayMPOCombinedSession = null, maxInPlayMPOExtensiveSession = null,
                    maxInPlayMPOIntensiveSession = null, maxDistancePer90Session = null,
                    maxHsrPer90Session = null, maxSprintPer90Session = null, maxTurnPer90Session = null
                } = maxSessionTitles;

                this.per90Text = this.displayPer90Data ? ' per 90 min' : '';
                this.maxAverageText = this.displayMaxData ? 'Max' : 'Average';
                
                //create ranking system
                const createTeamRank = rank => new Array(totalTeams).fill().map((_, i) => ({ color: i + 1 === rank ? Colours.SPORTLIGHT_TEAL : Colours.SECONDARY_DARK_GREY, position: i + 1 })).reverse();

                const checkPer90Value = (value, valuePer90) => this.displayPer90Data ? valuePer90 : value;
                const distanceRankValue = checkPer90Value(distanceRank, distancePer90Rank);
                const hsrRankValue = checkPer90Value(hsrRank, hsrPer90Rank);
                const sprintRankValue = checkPer90Value(sprintRank, sprintPer90Rank);
                const turnsRankValue = checkPer90Value(turnRank, turnPer90Rank);

                const formatSessionText = (value, valuePer90) => {
                    const v = valuePer90 ? checkPer90Value(value, valuePer90) : value;
                    return v ? `Opposition: ${v}` : null;
                };

                const distanceSession = formatSessionText(maxDistanceSession, maxDistancePer90Session);
                const hsrSession = formatSessionText(maxHsrSession, maxHsrPer90Session);
                const sprintSession = formatSessionText(maxSprintSession, maxSprintPer90Session);
                const turnsSession = formatSessionText(maxTurnSession, maxTurnPer90Session);

                this.distance = createTeamRank(distanceRankValue);
                this.hsr = createTeamRank(hsrRankValue);
                this.sprint = createTeamRank(sprintRankValue);
                this.turns = createTeamRank(turnsRankValue);
                this.inPlayMPOCombined = createTeamRank(inPlayMPOCombinedRank);
                this.inPlayMPOIntensive = createTeamRank(inPlayMPOIntensiveRank);
                this.inPlayMPOExtensive = createTeamRank(inPlayMPOExtensiveRank);
                
                const averageTooltipLabels = {'Value': 'Team Average', 'Min': 'League Min', 'Average': 'League Average', 'Max': 'League Max'};
                const maxTooltipLabels = {'Value': 'Team Average Max', 'Min': 'League Min Max', 'Average': 'League Average Max', 'Max': 'League Max Max'};
                const mpoTooltipLabels = this.displayMaxData ? maxTooltipLabels : averageTooltipLabels;
                
                //Add chart settings
                const getPadding = value => this.displayPer90Data ? value/10 : value;
                const teamChartOptions = { 'chartType': 'team', 'hasTitle': false, 'hasTicks': false, 'chartSize': 'L', 'hasRank': true };
                const distanceOptions = {
                    'decimalPlaces': 1, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${distanceRankValue}/${totalTeams}`, 'padding': getPadding(this.ldUnit.convert(15000)),
                    'additionalLabel': distanceSession, ...teamChartOptions,
                };
                const hsrOptions = {
                    'decimalPlaces': 0, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${hsrRankValue}/${totalTeams}`, 'padding': getPadding(this.sdUnit.convert(1000)),
                    'additionalLabel': hsrSession, ...teamChartOptions
                };
                const sprintOptions = {
                    'decimalPlaces': 0, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${sprintRankValue}/${totalTeams}`, 'padding': getPadding(this.sdUnit.convert(200)),
                    'additionalLabel': sprintSession,...teamChartOptions
                };
                const turnOptions = {
                    'decimalPlaces': 0, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${turnsRankValue}/${totalTeams}`, 'padding': getPadding(30),
                    'additionalLabel': turnsSession, ...teamChartOptions
                };
                const mpoCombinedOptions = {
                    'tooltipLabels': mpoTooltipLabels, 'decimalPlaces': 0, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${inPlayMPOCombinedRank}/${totalTeams}`, 'padding': 10,
                    'additionalLabel': formatSessionText(maxInPlayMPOCombinedSession), ...teamChartOptions
                };
                const mpoIntensiveOptions = {
                    'tooltipLabels': mpoTooltipLabels, 'decimalPlaces': 0, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${inPlayMPOIntensiveRank}/${totalTeams}`, 'padding': 10,
                    'additionalLabel': formatSessionText(maxInPlayMPOIntensiveSession), ...teamChartOptions
                };
                const mpoExtensiveOptions = {
                    'tooltipLabels': mpoTooltipLabels, 'decimalPlaces': 0, 'title': '', 'label': ['Total', 'Rank'],
                    'rank': `${inPlayMPOExtensiveRank}/${totalTeams}`, 'padding': 10,
                    'additionalLabel': formatSessionText(maxInPlayMPOExtensiveSession), ...teamChartOptions
                };

                const getChartDetails = (kpi, chartId, labelId, chartContainer, options) => {
                    const className = 'team-kpi-plot';
                    const chartCtx = GetChartContext(chartId, chartContainer, className);
                    const labelCtx = document.getElementById(labelId).getContext("2d");
                    const chartData = { 'kpi': [kpi], labels: options.label };
                    plots.bwPlot(labelCtx, chartCtx, chartData, options);
                };

                const distanceValue = checkPer90Value(distance, distancePer90);
                const hsrValue = checkPer90Value(hsr, hsrPer90);
                const sprintValue = checkPer90Value(sprint, sprintPer90);
                const turnsValue = checkPer90Value(turns, turnsPer90);
                getChartDetails(convertBwData(distanceValue, this.ldUnit), 'total-distance-chart', 'total-distance-label-chart', 'distance-main-container', distanceOptions);
                getChartDetails(convertBwData(hsrValue, this.sdUnit), 'total-hsr-chart', 'total-hsr-label-chart', 'hsr-main-container', hsrOptions);
                getChartDetails(convertBwData(sprintValue, this.sdUnit), 'total-sprint-chart', 'total-sprint-label-chart', 'sprint-main-container', sprintOptions);
                getChartDetails(turnsValue, 'total-turns-chart', 'total-turns-label-chart', 'turns-main-container', turnOptions);
                getChartDetails(inPlayMPOCombined, 'total-inPlayMPOCombined-chart', 'total-inPlayMPOCombined-label-chart', 'inPlayMPOCombined-main-container', mpoCombinedOptions);
                getChartDetails(inPlayMPOIntensive, 'total-inPlayMPOIntensive-chart', 'total-inPlayMPOIntensive-label-chart', 'inPlayMPOIntensive-main-container', mpoIntensiveOptions);
                getChartDetails(inPlayMPOExtensive, 'total-inPlayMPOExtensive-chart', 'total-inPlayMPOExtensive-label-chart', 'inPlayMPOExtensive-main-container', mpoExtensiveOptions);
            }
        },
        async getTeamPeakDemandsPlots() {
            if (this.hasSeasonPeakDemands) {

                const generateDecoratedMetricStatsForKpiAndEpoch = epoch => {
                    
                    const dataEntry = this.data.teamPeakDemands.dataByEpochByKpi[this.kpiSelected][epoch];

                    return dataEntry.metricStatsInDescendingValueOrder.map((metricStat, index) => {
                        if (this.kpiSelected !== 'TURNS') {
                            metricStat = convertBwData(metricStat, this.sdUnit);
                        }
                        const isCurrentCustomer = dataEntry.customerRank === index + 1;
                        metricStat.whiskersColour = isCurrentCustomer ? Colours.SPORTLIGHT_TEAL : Colours.SECONDARY_LIGHT_GREY;
                        metricStat.boxColour = isCurrentCustomer ? Colours.TRANSLUCENT_SPORTLIGHT_TEAL : Colours.TRANSLUCENT_SECONDARY_LIGHT_GREY;
                        return metricStat;
                    });
                };

                const epochData30s = generateDecoratedMetricStatsForKpiAndEpoch("_30S");
                
                this.peakPeriodsData = {
                    epoch30s: epochData30s,
                    epoch60s: generateDecoratedMetricStatsForKpiAndEpoch("_60S"),
                    epoch120s: generateDecoratedMetricStatsForKpiAndEpoch("_120S"),
                    blankLabels: Array(epochData30s.length).fill('')
                };
            }
        },
        async getSeasonOptions(){
            this.seasonOptions = getSeasons(new Date(this.earliestSession), false);
        },
        updateTeamComparisonPlots() {
            this.resetPlots();
            this.getTeamComparisonPlots();
        },
        resetPlots() {
            Array.from(document.getElementsByClassName('team-kpi-plot')).forEach(e => e.remove()); //remove existing kpi plots
        },
        toggleKpiGroup() {
            this.getTeamPeakDemandsPlots();
        },
        async updateSeasonSelector(p) {
            this.selectedSeasonIndex = p;
            this.seasonSelected = this.seasonOptions[p];
            this.$root.executeTaskWithProgressBar(async () => {
                await this.getData();
                await this.getTeamPeakDemandsPlots();
                this.updateTeamComparisonPlots();
            });
        }
    },
    async mounted() {
        this.$root.executeTaskWithProgressBar(async () => {
            console.log("Team.vue mounted");
            this.$root.newPageView("Team Page", UserData.userName());
            await this.getEarliestSessionDate();
            await this.getSeasonOptions();
            this.seasonSelected = this.seasonOptions[0];
            await this.getData();
            await this.getTeamComparisonPlots();
            await this.getTeamPeakDemandsPlots();
            UpdateSetting();
        });
    },
};
</script>
<style>
.toggle-btn-container {
    width: 20vw; 
    display: flex; 
    align-items: center; 
    justify-content: space-around; 
    margin: auto;
}
.dropdown-container {
    margin: auto;
    min-width: 12vw;
    display: flex;
    height: 5vh;
    justify-content: space-between;
    align-items: center;
}
</style>