<template>
    <div>
        <canvas :id="id" :style="[{height: height} , {width: width}]"></canvas>
    </div>
</template>

<script>
import {UserData} from "./UserData";
import {Chart} from "chart.js";
import Colours from "@/utils/Colours";
import {getMeasurementUnit, MeasurementTypes} from "@/utils/MeasurementSystem";


export default {
    data() {
        return {
            chart: null,
            measurementUnit: getMeasurementUnit(MeasurementTypes.Speed),
        };
    },
    props: {
        id: {
            default: "scatters-chart",
            type: String
        },
        turns: Array,
        additionalTooltipText: {
            default: (tooltipItem, data) => {
                const timestamp = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].matchTime;
                return timestamp && UserData.hasWipTurnScattersMatchTimes() ? ("Match Time: " + timestamp) : [];
            },
            type: Function
        },
        minSpeed: {
            default: 0,
            type: Number
        },
        maxSpeed: Number,
        displayYAxis: {
            default: true,
            type: Boolean
        },
        maintainAspectRatio: {
            default: false,
            type: Boolean
        },
        minAngle: {
            default: 0,
            type: Number
        },
        maxAngle: {
            default: 180,
            type: Number
        },
        height: {
            type: String
        },
        width: {
            default: "23vw",
            type: String
        },
    },
    watch: {
        turns: function () {
            if (this.chart !== undefined) {
                this.chart.destroy();
            }
            this.refreshData();
        }
    },
    methods: {
        async refreshData() {
            const chartCtx = document.getElementById(this.id).getContext("2d");
            this.chart = this.displayChart(chartCtx, this.turns, this.additionalTooltipText, this.minSpeed, this.maxSpeed,
                this.displayYAxis, this.maintainAspectRatio, this.minAngle, this.maxAngle);
            this.chart.update();
        },
        displayChart: function (chartCtx, turns, additionalTooltipText, minSpeed, maxSpeed, displayYAxis,
            maintainAspectRatio = false, minAngle = 0, maxAngle = 180) {

            //Add colour to the scatters bubble depending on condition
            let colourSelector = turns.map(tt => {
                if (tt.time <= 0.3) {
                    return Colours.BLUE;
                } else if (tt.time > 0.3 && tt.time <= 0.5) {
                    return Colours.RED;
                } else if (tt.time > 0.5 && tt.time <= 1) {
                    return Colours.YELLOW;
                } else {
                    return null;
                }
            });
            return new Chart(chartCtx, {
                type: "scatter",
                data: {
                    datasets: [{
                        data: turns,
                        borderWidth: 5,
                        fill: true,
                        backgroundColor: colourSelector,
                        borderColor: colourSelector
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: maintainAspectRatio,
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: displayYAxis,
                                    labelString: "Turn Angle (°)",
                                    fontSize: 12,
                                },
                                gridLines: {
                                    display: true,
                                    color: Colours.WHITE,
                                    lineWidth: 1,
                                    zeroLineColor: Colours.WHITE,
                                    zeroLineWidth: 1
                                },
                                ticks: {
                                    max: maxAngle > 180 ? 360 : 180,
                                    min: minAngle,
                                    stepSize: 60,
                                    padding: 10
                                }
                            }
                        ],

                        xAxes: [
                            {
                                scaleLabel: {
                                    display: false,
                                },
                                gridLines: {
                                    display: true,
                                    color: Colours.WHITE,
                                    lineWidth: 1,
                                    zeroLineColor: Colours.WHITE,
                                    zeroLineWidth: 1
                                },
                                ticks: {
                                    beginAtZero: true,
                                    max: maxSpeed,
                                    min: minSpeed,
                                    stepSize: this.measurementUnit.convert(0.5),
                                    padding: 4,
                                    callback: this.ticksCallback,
                                    maxRotation: 0,
                                    autoSkip: false
                                }
                            }
                        ]
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'nearest',
                        callbacks: {
                            label: function (tooltipItem) {
                                const barLabel = tooltipItem.label;
                                return ("Entry Speed: " + barLabel + getMeasurementUnit(MeasurementTypes.Speed).unitShort);
                            },
                            afterLabel: function (tooltipItem, data) {
                                const barValue = tooltipItem.value;

                                const additionalText = additionalTooltipText(tooltipItem, data);
                                return (["Turn Angle: " + barValue + "°", ...additionalText]);

                            }
                        }
                    },
                }
            });
        },
        ticksCallback: function (value) {
            const drawLineAt = UserData.getTurnEntrySpeedBoundaries().bounds.map(b => this.measurementUnit.convert(b));
            return drawLineAt.includes(value) ? value.toFixed(1) : null;
        },
    },
    async mounted() {
        console.log("TurnScattersChart.vue mounted");
        await this.refreshData();
    }
};
</script>