<template>
    <div id="content-wrap">
        <div class="subnav shadow-1">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <slot>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style>

</style>