<template>
    <span>{{ formattedShirtNumber }}</span>
</template>

<script>
import DoubleZeroShirtNumber from "@/utils/DoubleZeroShirtNumber";

export default {
    props: {
        shirtNumber: {
        type: [Number, String],
        default: null,
        },
    },
    computed: {
        formattedShirtNumber() {
            if (this.shirtNumber !== null) {
                return this.shirtNumber === DoubleZeroShirtNumber ? '#00' : `#${this.shirtNumber}`;
            }
            return 'Unknown shirt number';
        },
    },
    };
</script>
