/**
 * This class controls the options on the player dev screen for changing the data set being viewed, both for the player
 * data and the reference data to build comparisons.
 * NOTE: All changes to this must be reflected in the back end, see PlayerDevelopmentCriteria.java
 */
class PlayerDevelopmentCriteriaOption {

    static ALL = new PlayerDevelopmentCriteriaOption(1, "All Competitions");
    static EPL = new PlayerDevelopmentCriteriaOption(2, "EPL");
    static WSL = new PlayerDevelopmentCriteriaOption(2, "WSL");
    static ACADEMY = new PlayerDevelopmentCriteriaOption(3, "Academy");

    value;
    text;

    constructor(value, text) {
        this.value = value;
        this.text = text;
    }

    static getCriteria(teamType) {
        return [(teamType === 'm' ? this.EPL : this.WSL), this.ACADEMY, this.ALL];
    }
}

export { PlayerDevelopmentCriteriaOption };