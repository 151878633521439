import Colours from "./Colours";

const hamstringRisk = hamstringRiskData => {

    const hamstringRiskColour = hamstringRiskData.hamstringRisk === null ? null : 
        hamstringRiskData.hamstringRisk ? Colours.RED : Colours.BRIGHT_GREEN;
    
    const hamstringRiskScore = hamstringRiskData.hamstringRiskScore === null ? null :
        `${hamstringRiskData.hamstringRiskScore}x`;

	return { colour: hamstringRiskColour, value: hamstringRiskScore };
};

export default hamstringRisk;
