<template>
    <div style="position: relative">
        <div class="chart-container">
            <h1 class="label-container">
                <span class="metric-tooltips">
                    {{ title }}
                    <span class="metric-definition"> {{ hoverText }} </span>
                </span>
            </h1>
            <div>
                <div class="chart-label">
                    <canvas :id="labelId" ></canvas>
                </div>
            </div>
            <div class="chart" :id="chartContainerId">
                <canvas :id="chartId" :class="chartClass" ></canvas>
            </div>
        </div>
        <div class="container" id="battery">
            <span style="margin-right:4px;font-size: x-small;">20</span>
            <div v-for="(k, index) in kpi" :key="index" class="battery" :style="{ backgroundColor: k.color }"></div>
            <span style="margin-left:4px; font-size: x-small;">1</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["kpi", "title", 'hover-text', 'labelId', 'chartContainerId', 'chartId', 'chartClass']
}
</script>

<style scoped>
.chart-container {
    display: flex;
    position: relative;
    height: 20vh;
    margin: auto;
    width: 85vw;
}

.label-container {
    position: absolute;
    font-size: 14px;
    top: 42.6%;
}

.chart {
    height: 20vh;
    width: 65vw;

}

.chart-label {
    height: 20vh;
    width: 50vw;
    margin-left: -35vw
}

.container {
    display: flex;
    position: absolute;
    top: 63.4%;
    left: 35%;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;

}
.set-flex {
    display: flex;
}

.battery {
    width: 1vw;
    height: 3vh;
    background-color: grey;
    margin: 1px;
}
</style>