<template>
    <div class="radial-bar" :style="{ '--percentage': hasFixedRadial ? '100%' : percentage + '%', '--radial-colour': colour }">
        <div class="percentage-container">
            <div class="percentage">{{ percentage }} </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        percentage: String,
        colour: String,
        hasFixedRadial: Boolean
    },
};
</script>

<style scoped>
.radial-bar {
    width: 2.4em;
    height: 2.4em;
    background: conic-gradient(var(--radial-colour) 0% var(--percentage), transparent 0% var(--percentage), transparent 100%);
    border-radius: 50%;
    position: relative;
    margin: auto;
    overflow: hidden;
}
.radial-bar::before {
    content: '';
    width: 80%;
    height: 80%;
    border-radius: 50%;
    position: absolute;
    background-color: var(--bg-primary);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.percentage-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0.6em;
    font-size: smaller;
}

</style>