<template>
  <span>{{convertedValue}}</span>
</template>
<script>
import {getMeasurementUnit, MeasurementUnit} from "../utils/MeasurementSystem";

export default {
  data() {
    return {
      measurementUnit: getMeasurementUnit(this.type),
      convertedValue: null,
    }
  },
  props: {
    type: Symbol,
    value: Number,
    isLabel: {
      default: false,
      type: Boolean
    },
    nullString: {
      default: '-',
      type: String
    },
    format: {
      type: Function,
      default: MeasurementUnit.prototype.formatStandard
    }
  },
  methods: {
    updateConvertedValue(newValue) {
      //ensure we're displaying the null strings in place of null values
      this.convertedValue = (newValue !== null && newValue !== undefined) ? this.format.bind(this.measurementUnit)(newValue) : this.nullString;
    },
  },
  watch: {
    value(newValue) {
      this.updateConvertedValue(newValue);
    },
  },
  async mounted() {
    this.updateConvertedValue(this.value);
  }
}
</script>