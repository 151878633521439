<template>
    <h3 class="missing-data-text"> {{ message }} </h3>
</template>
<script>
export default {
    props: ['message']
}
</script>
<style scoped>
.missing-data-text {
    text-align: center;
    padding-top: 2vh;
    margin: 0 auto;
    }
</style>