<template>
    <div id="content" class="pt-4 pb-4 pl-3 pr-3">
        <div class="container-fluid" >
            <div class="row">
                <div class="col-12">
                    <slot>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>