<template>
    <div>
        <TurnRatesTitle :customerId="customerId" />
        <TurnRatesCharts :customerId="customerId" />
    </div>
</template>

<script>

import TurnRatesTitle from "./TurnRatesTitle";
import TurnRatesCharts from "./TurnRatesCharts";

export default {
    components: {
        TurnRatesTitle,
        TurnRatesCharts,
    },

    props: {
        customerId: String,
    }
};
</script>