import Colours from "../../utils/Colours"; 
const rehabChartTurnRates = {
    svg: null,
    rehabData: null,
    top: 390,
    left: 1300,
    width: 500,
    xOrigin: 1350,
    yOrigin: 580,
    yOrigin1: 800,
    height: 448,
    rowHeight: 112,
    xAxisOffset: 40,
    xAxisWidth: 410,
    yAxisHeight: 140,
    fontFamily: "Barlow",
    init: function (svg, rehabData) {
        this.svg = svg.group();
        this.rehabData = rehabData;
        this.rehabData = rehabData;
    },
    render: function () {
        this.svg.clear(); // clear any existing chart contents 
        this.turnRates()

    },
    turnRates: function () {
        // X/Y axis values for the lines. The final value in each array is used to add padding to the top/right of the chart
        const yValues = [0, 60, 120, 180, ''];
        const xValues = [0, 3, 5.5, 7, 8.5];

        // Get the left turn data
        const turnData = this.rehabData.sessionTurns
        const leftTurns = this.rehabData.sessionTurns.filter(l => l.turnSide == 'L')
        const rightTurns = this.rehabData.sessionTurns.filter(l => l.turnSide == 'R')

        // Determine the maximum values in the data
        let maxX = xValues[xValues.length - 1];
        let maxY = yValues[yValues.length - 1];
        this.svg.text("Left Side Turns").font({ family: this.fontFamily, size: 12, anchor: "start", weight: "bold" }).attr({ x: this.left + 200, y: this.top + 30, fill: Colours.WHITE });
        this.svg.text("Right Side Turns").font({ family: this.fontFamily, size: 12, anchor: "start", weight: "bold" }).attr({ x: this.left + 200, y: this.top + 250, fill: Colours.WHITE });
        turnData.forEach(k => {
            const xValue = k["entrySpeedMPerS"];
            const yValue = k["angleDegrees"];
            if (xValue > maxX)
                maxX = xValue;

            if (yValue > maxY)
                maxY = yValue;
        });

        const xInterval = (this.xAxisWidth / maxX); // Calc x axis interval based on the maximum X value
        const yInterval = (this.yAxisHeight / maxY); // Calc y axis interval based on the maximum Y value

        yValues.forEach((yValue, yIndex) => {
            if (yIndex != yValues.length - 1) { // Don't draw a line for the final y axis value
                const y = this.yOrigin - (yValue * yInterval); // Calculate y position for the line
                const y1 = this.yOrigin1 - (yValue * yInterval)
                this.svg.line(this.xOrigin, y, this.xOrigin + this.xAxisWidth, y).attr({ stroke: Colours.WHITE, "stroke-width": 1.5 });
                this.svg.text(yValue).font({ family: this.fontFamily, size: 12, anchor: "end" }).attr({ x: this.xOrigin - 12, y: y + 4, fill: Colours.WHITE });

                this.svg.line(this.xOrigin, y1, this.xOrigin + this.xAxisWidth, y1).attr({ stroke: Colours.WHITE, "stroke-width": 1.5 });
                this.svg.text(yValue).font({ family: this.fontFamily, size: 12, anchor: "end" }).attr({ x: this.xOrigin - 12, y: y1 + 4, fill: Colours.WHITE });
            }
        });

        xValues.forEach((xValue, xIndex) => {
            if (xIndex != xValues.length - 1) { // Don't draw a line for the final x axis value
                const x = this.xOrigin + (xValue * xInterval);  // Calculate x position for the line
                    this.svg.line(x, this.yOrigin, x, this.yOrigin - this.yAxisHeight).attr({ stroke: Colours.WHITE, "stroke-width": 1.5 });
                    this.svg.line(x, this.yOrigin1, x, this.yOrigin1 - this.yAxisHeight).attr({ stroke: Colours.WHITE, "stroke-width": 1.5 });
                
                
                if (xValue != 0){ // If it's the first value then don't display the text
                    this.svg.text(xValue).font({ family: this.fontFamily, size: 12, anchor: "middle" }).attr({ x: x, y: this.yOrigin + 20, fill: Colours.WHITE });
                    this.svg.text(xValue).font({ family: this.fontFamily, size: 12, anchor: "middle" }).attr({ x: x, y: this.yOrigin1 + 20, fill: Colours.WHITE });
                }
            }
        });

        [leftTurns, rightTurns].forEach(turns => {
            turns.forEach(k => {
                const sessionId = k.sessionId;
                const sessionDate = new Date(k.sessionDate);
                const rehabDate = new Date(this.rehabData.rehabDate);
                const bubbleColour = sessionDate < rehabDate ? Colours.SECONDARY_DARK_GREY : Colours.SPORTLIGHT_TEAL;
                const xValue = k["entrySpeedMPerS"];
                const yValue = k["angleDegrees"];
                const x = this.xOrigin + (xValue * xInterval);
                const y = turns === leftTurns ? this.yOrigin - (yValue * yInterval) : this.yOrigin1 - (yValue * yInterval);
                this.svg.circle(8, 8).attr({ class: "session-turn", fill: bubbleColour, cx: x, cy: y, sessionId });
            });
        });
    },
    highlightSessions(week) {
        // highlight all sessions for the specified week no

        // build a list of session ids for the specified week
        const sessionIds = [];
        this.rehabData.weeklyKpis.forEach(k => {
            if (k.weekNumber == week)
                Array.prototype.push.apply(sessionIds, k.sessionIds);
        });


        // now loop through each line element and determine if it is in the list
        const allSessions = document.getElementsByClassName("session-turn");
        Array.from(allSessions).forEach(s => {
            const sessionId = s.getAttribute("sessionId");
            if (sessionIds.includes(sessionId)) {

                // draw a new line in orange over the top
                const cx = s.getAttribute("cx");
                const cy = s.getAttribute("cy");
                this.svg.circle(10, 10).attr({ class: "highlight-turn", fill: "#ffa343", cx: cx, cy: cy});
            }
        });
    },
    unHighlightSessions() {
        // remove all highlight elements
        const elements = document.getElementsByClassName("highlight-turn");
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }    
}


export { rehabChartTurnRates }