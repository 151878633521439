<template>
    <ul class="info-text">
        <li>Significant Turn Summary provides an overview of the Significant Turns completed during a
            session relative to their Turn Direction, Left-Side or Right-Side relative to the y-axis.
            A Significant Turn is defined as a deceleration (&gt;<MeasurementValue :type="Acceleration" :value=2 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />), followed by an acceleration
            (&gt;<MeasurementValue :type="Acceleration" :value=2 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />),
            that starts within 1s of the end of the deceleration, with an angle change greater than 20°. Outputs are
            displayed by key constituent elements of the Significant Turns:
        </li>

        <li>Turn Angle: The angle between the entry deceleration and exit acceleration vectors.
            (Low: &lt;60°, Medium: &gt;60° & &lt;120°, High: &gt;120°)
        </li>

        <li>Peak Deceleration: The peak deceleration attained during the deceleration phase ahead of a
            Significant Turn.
            (Low: &lt;<MeasurementValue :type="Acceleration" :value=4 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />,
            Medium: &gt;<MeasurementValue :type="Acceleration" :value=4 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" /> &
            &lt;<MeasurementValue :type="Acceleration" :value=5 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />,
            High: &gt;<MeasurementValue :type="Acceleration" :value=5 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />)
        </li>

        <li>Peak Acceleration: The peak acceleration attained during the acceleration phase following a
            Significant Turn.
            (Low: &lt;<MeasurementValue :type="Acceleration" :value=4 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />,
            Medium: &gt;<MeasurementValue :type="Acceleration" :value=4 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" /> &
            &lt;<MeasurementValue :type="Acceleration" :value=5 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />,
            High: &gt;<MeasurementValue :type="Acceleration" :value=5 :format="formatStandard" />
            <MeasurementUnit :type="Acceleration" />)
        </li>

        <li v-if="includeEntrySpeed">Entry Speed: The entry speed into a Significant Turn. (
            <span v-for="(classification) in turnEntrySpeedClassifications" :key="classification.name" >
                {{ classification.name }}: {{ classification.text }}
            </span>
            )
        </li>

        <li v-if="includeEffortClassification">Effort Classification: The effort classification is an in-house metric that combines the severity of
            angle and deceleration within a Significant Turn</li>

        <li v-if="includeAbnormalTurn">(*) - abnormal turn asymmetry</li>
    </ul>
</template>

<script>
import { MeasurementTypes, getMeasurementUnit, MeasurementUnit as MU } from "@/utils/MeasurementSystem";
import { getTurnEntrySpeedClassifications } from "@/utils/TurnEntrySpeedBoundaryHelpers";
import { UserData } from "@/components/UserData";

export default {
    name:'SignificantTurnSummaryInfoText',
    props: {
        includeAbnormalTurn: {
            type: Boolean,
            default: false,
        },
        includeEffortClassification: {
            type: Boolean,
            default: false,
        },
        includeEntrySpeed: {
            type: Boolean,
            default: false,
        },
        turnEntrySpeedBoundaries: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            formatStandard: MU.prototype.formatStandard,
            Acceleration: MeasurementTypes.Acceleration,
            Speed: MeasurementTypes.Speed,
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            turnEntrySpeedClassifications: []
        };
    },
    mounted() {
        const entrySpeedBoundaries = this.turnEntrySpeedBoundaries ? this.turnEntrySpeedBoundaries : UserData.getTurnEntrySpeedBoundaries();
        this.turnEntrySpeedClassifications = getTurnEntrySpeedClassifications(entrySpeedBoundaries, this.speedUnit);
    }

};
</script>

<style>
.info-text > li {
    margin-bottom: 1vh;
}
</style>