<template>
    <div style="height: 40vh;" class="pb-4">
        <div class="d-flex justify-content-center align-items-center"> {{ title }} </div>
        <canvas :id="id"></canvas>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import { RainbowColours } from "@/components/RainbowColours";
import Colours from "@/utils/Colours";
export default {
    props: {
        data: Object,
        id: String,
        title: String
    },
    data() {
        return {
            existingCharts: null,
        };
    },
    methods: {
        async drawLongitudinalChart(canvasId, data) {
            if (data) {
                const { sessionValues, rollingAverage, sessionDateStrings, comparisonData, type, yAxisLabel } = await data;
                const datasets = [
                    {
                        data: sessionValues.map((y, i) => ({ x: sessionDateStrings[i], y })),
                        fill: false,
                        borderColor: Colours.SPORTLIGHT_TEAL,
                        backgroundColor: Colours.SPORTLIGHT_TEAL,
                        borderWidth: 2,
                        pointRadius: 2,
                    },
                    {
                        data: rollingAverage.map((y, i) => ({ x: sessionDateStrings[i], y })),
                        fill: false,
                        borderColor: Colours.SPORTLIGHT_TEAL,
                        backgroundColor: Colours.SPORTLIGHT_TEAL,
                        borderWidth: 5,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                    }
                ];

                if (type === 'rainbow') {
                    const createRainbowDataset = (fill, colour, value) => {
                        return {
                            data: sessionDateStrings.map((i) => ({ x: sessionDateStrings[i], y: value })),
                            fill,
                            backgroundColor: colour,
                            borderWidth: 0,
                            pointRadius: 0,
                            hoverRadius: 0,
                            pointHoverRadius: 0,
                        };
                    };

                    datasets.push(
                        createRainbowDataset('2', RainbowColours.dark_green, comparisonData.maxValue),
                        createRainbowDataset('-1', RainbowColours.dark_green, comparisonData.percentile85Value),
                        createRainbowDataset('-1', RainbowColours.dark_lime, comparisonData.percentile65Value),
                        createRainbowDataset('-1', RainbowColours.dark_yellow, comparisonData.percentile35Value),
                        createRainbowDataset('-1', RainbowColours.dark_orange, comparisonData.percentile15Value),
                        createRainbowDataset('-1', RainbowColours.dark_red, comparisonData.minValue),
                    );
                } else {
                    const { upperBound, lowerBound, average } = comparisonData;
                    datasets.push(
                        {
                            data: sessionDateStrings.map((i) => ({ x: sessionDateStrings[i], y: upperBound })),
                            fill: '+1',
                            borderColor: Colours.SECONDARY_DARK_GREY,
                            backgroundColor: Colours.SECONDARY_DARK_GREY,
                            pointRadius: 0,
                            hoverRadius: 0,
                            pointHoverRadius: 0,
                        },
                        {
                            data: sessionDateStrings.map((i) => ({ x: sessionDateStrings[i], y: lowerBound })),
                            fill: '-1',
                            borderColor: Colours.SECONDARY_DARK_GREY,
                            backgroundColor: Colours.SECONDARY_DARK_GREY,
                            pointRadius: 0,
                            hoverRadius: 0,
                            pointHoverRadius: 0,
                        },
                        {
                            data: sessionDateStrings.map((i) => ({ x: sessionDateStrings[i], y: average })),
                            fill: false,
                            borderColor: Colours.WHITE,
                            borderDash: [5, 5],
                            borderWidth: 2,
                            pointRadius: 0,
                            hoverRadius: 0,
                            pointHoverRadius: 0,
                        }
                    );
                }
                if (this.existingCharts !== null) {
                    this.existingCharts.destroy();
                }
                const chartCtx = document.getElementById(canvasId).getContext('2d');
                this.existingCharts = new Chart(chartCtx, {
                    type: 'line',
                    data: {
                        labels: sessionDateStrings,
                        datasets: datasets
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        spanGaps: true,
                        title: {
                            display: false,
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    padding: 0,
                                    min: 0
                                },
                                gridLines: {
                                    display: true,
                                    color: Colours.PRIMARY_GREY,
                                    lineWidth: 0,
                                    zeroLineColor: '#fff',
                                    zeroLineWidth: 1,
                                    drawBorder: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: yAxisLabel
                                },
                            }],
                            xAxes: [{
                                type: 'category',
                                position: 'bottom',
                                gridLines: {
                                    display: true,
                                    color: Colours.PRIMARY_GREY,
                                    lineWidth: 0.2,
                                    zeroLineColor: '#fff',
                                    zeroLineWidth: 1,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Date'
                                },
                                ticks: {
                                    source: 'auto',
                                    autoSkip: true,
                                    maxTicksLimit: 5,
                                    maxRotation: 180
                                },
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                title: function (tooltipItems) {
                                    const index = tooltipItems[0].index;
                                    return [data.sessionTitles[index], sessionDateStrings[index]];
                                },
                            },
                        }
                    },
                });
            }
        }
    },
    watch: {
        async data(newData) {
            await this.drawLongitudinalChart(this.id, newData);
        },
    },
    async mounted() {
        await this.drawLongitudinalChart(this.id, this.data);
    
    },
};
</script>