<template>
  <div :id="'parent' + id">
    <canvas :width="width" :height="height" :id="id"> </canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";
import Colours from "../utils/Colours";

export default {
  props: {
    id: String,
    width: Number,
    height: Number, // should be no less than 73 to ensure that the tooltip will always fit on the canvas
    values: Object,
    options: Object,

    /*
    Sometimes the avg +/- stddev extends beyond the minimum or maximum value for the box plot. This can look strange
    to the user. If normalize box is set to true, then the box will only extend as far as the minimum / maximum values.
    */
    normalizeBox: Boolean,
    /*
    The number of decimal places that should be displayed for the data.
     */
    precision: Number,
  },
  computed: {
    // provide some sensible defaults when the minTick, maxTick and stepSize values aren't provided.
    fullOptions: function () {
      const minTk = minTick(this.values);
      const stepSz = stepSize(this.values);
      const maxTk = maxTick(this.values) + stepSz; //add padding to max ticks to prevent plots cutting off

      return {minTick: minTk, maxTick: maxTk, stepSize: stepSz, ...this.options};
    }
  },

  mounted: async function() {
    let height = this.height;
    /**
     * Position the tooltip at the top left of the plot. This is to ensure that there is less chance of it extending
     * beyond the bounds of the plot (anything outside the bounds of the plot will be hidden).
     * @function Chart.Tooltip.positioners.topLeft
     * @returns {{x: number, y: (number)}} the tooltip position
     */
    Chart.Tooltip.positioners.topLeft = function() {
      const y = (height < 90) ? 0 : 20;

      return {
        x: 20,
        y: y
      };
    };
    await drawSingleBwPlot(this.id, this.values, this.fullOptions, this.normalizeBox, this.precision);

    /*
    Ensure that the Chart is reactively updated when the values update. For some reason this doesn't happen
    automatically. This could be because the properties that change reactively aren't used in the template, so vue might
    not think that this component needs to be re-rendered.
     */
    this.$watch("values", async function () {
      replaceCanvas(this.id, this.width, this.height);
      await drawSingleBwPlot(this.id, this.values, this.fullOptions);
    });
  }
};
function minTick(bwData) {
  return Math.min(bwData.minimum, bwData.value) - bwData.standardDeviation;
}
function maxTick(bwData) {
  return Math.max(bwData.maximum, bwData.value) + bwData.standardDeviation;
}
function stepSize(bwData) {
  const min = minTick(bwData);
  const max = maxTick(bwData);
  const remainder = (max-min) % 5;
  return (max - min + 5 - remainder) / 5;
}
function replaceCanvas(id, width, height){
  document.getElementById(id).remove();
  const canvas = document.createElement('canvas');
  canvas.id = id;
  canvas.width = width;
  canvas.height = height;
  document.getElementById("parent" + id).appendChild(canvas);
}


/*
Draws a single box whisker plot on the specified canvas id.
The plot is implemented as a set of horizontal bar charts stacked on top of each other. The charts are:

  * minimum to average - 1 standard Deviation. shown as a thin bar
  * average - 1 standard Deviation  to average + 1 standard Deviation. This is shown as a thick bar
  * average + 1 standard Deviation to max. This is shown as a thin bar
  * value to value + small amount. This is shown as a thick coloured bar.

The widths and heights in the chart are percentages so it should scale nicely to fill whatever space is provided for it.
The data should look like:

data: an object with the following numeric properties: minimum, maximum, average, standardDeviation and value.
  None of them should be null.

options: an object with the following properties that control the display of the plot.
  units: a string that is appended to the values in the tooltip e.g. "m/s"
  tooltipLabel: a String to be pre-pended to the 'value' in the tooltip e.g. "Player Average"
  showTicks: true if values on the x-axis should be displayed
  minTick: the minimum value to be shown on the x-axis
  maxTick: the maximum value to be shown on the x-axis
  stepSize: the size of the 'steps' that should be on the x-axis i.e. a value of 5 would mean that ticks on the x-axis
    would increment in steps of 5.
 */
function drawSingleBwPlot(id, values, options, normalizeBox, precision) {
  const chartCtx = document.getElementById(id).getContext("2d");

  const standardDeviation = values.standardDeviation;
  const average = values.average;

  // If options.normalizeBox is set, then the lower bound of the 'box' should not be below the minimum value
  const lowerBounds = normalizeBox && (average - standardDeviation) < values.minimum ?
      values.minimum :
      average - standardDeviation;

  // If options.normalizeBox is set, then the upper bound of the 'box' should not be above the maximum value
  const upperBounds = normalizeBox && (average + standardDeviation) > values.maximum ?
      values.maximum :
      average + standardDeviation;

  const minLower = [[values.minimum, lowerBounds]];
  const stdDev = [[lowerBounds, upperBounds]];
  const upperMax = [[upperBounds, values.maximum]];
  const min = [values.minimum];
  const avg = [[average, average]];
  const max = [values.maximum];

  const thickBar = 0.8;
  const thinBar = 0.15;
  const minWidth = min <= lowerBounds ? thinBar : 0;
  const maxWidth = max >= lowerBounds ? thinBar : 0;
  const kpiValue = values.value;
  const thickness = (options.maxTick - options.minTick) / 150;
  const plotValue = [[kpiValue, kpiValue + thickness]];
  const valueColour = (() => {
    if (kpiValue > upperBounds) {
      return Colours.BRIGHT_GREEN;
    } else if (kpiValue < lowerBounds) {
      return Colours.RED;
    } else {
      return Colours.SPORTLIGHT_TEAL;
    }
  })();

  /*
  Parses the details of the item in the plot. and returns the text that should be displayed as the tooltip.
  This function is called on all the datasets with the non-null results appended together.
  */
  let tooltipLabelText = function (tooltipItem, data) {
    const barLabel = data.datasets[tooltipItem.datasetIndex].label;

      const units = options.units ?? "";
      const getTooltipValue = value => {
        if (value) {
            return barLabel + ": " + value.toFixed(precision) + " " + units;
        }
      };
      if (barLabel === options.tooltipLabel) {
          return getTooltipValue(values.value);
      } else if (barLabel === "Max") {
          return getTooltipValue(values.maximum);
      } else if (barLabel === "Min") {
          return getTooltipValue(values.minimum);
      } else if (barLabel === "Average") {
          return getTooltipValue(values.average);
    } else {
      return null;
    }
  };
  new Chart(chartCtx, {
    type: "horizontalBar",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
        maintainAspectRatio: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: true,
        position: "topLeft",
        callbacks: {
          title: () => {}, // no title
          label: tooltipLabelText,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: false,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: options.showTicks,
              beginAtZero: false,
              min: options.minTick,
              max: options.maxTick,
              stepSize: options.stepSize,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              display: false,
            },
          },
        ],
      },
    },
    data: {
      datasets: [
        {
          label: options.tooltipLabel,
          data: plotValue,
          backgroundColor: valueColour,
          barPercentage: thickBar,
          categoryPercentage: thickBar,
        },
        {
          label: "Min",
          data: minLower,
          backgroundColor: Colours.SECONDARY_LIGHT_GREY,
          borderColor: Colours.SECONDARY_LIGHT_GREY,
          borderWidth: 1,
          barPercentage: minWidth,
          categoryPercentage: minWidth,
        },
        {
          label: "stdDev",
          data: stdDev,
          backgroundColor: Colours.PRIMARY_GREY,
          borderColor: Colours.SECONDARY_LIGHT_GREY,
          borderWidth: 1,
          barPercentage: thickBar,
          categoryPercentage: thickBar,
        },
        {
          // by definition this should always be within the stdDev bar so make it invisible
          label: "Average",
          data: avg,
          backgroundColor: Colours.PRIMARY_GREY,
          borderColor: Colours.PRIMARY_GREY,
          borderWidth: 1,
          barPercentage: 0,
          categoryPercentage: 0,
        },
        {
          label: "Max",
          data: upperMax,
          backgroundColor: Colours.SECONDARY_LIGHT_GREY,
          borderColor: Colours.SECONDARY_LIGHT_GREY,
          borderWidth: 1,
          barPercentage: maxWidth,
          categoryPercentage: maxWidth,
        },
      ],
    },
  });
}

</script>
<style scoped>

div {
    display:flex; 
    background-color: var(--bg-primary);
}
</style>