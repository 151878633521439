<template>
    <div class="section-container w-100 mt-4">
        <SectionTitle title="KPI Over Time">
            <MoreInfo :id="'longitudinal-kpi-analysis-more-info'" title='KPI Over Time'>
                <div class="longitudinal-analysis-list">
                    <ul>
                        <li> KPI Over Time displays each player's selected KPI longitudinally compared to the
                            {{ selectedReferenceGroupText }} data. </li>
                        <SharedInfoText />
                    </ul>
                </div>
            </MoreInfo>
        </SectionTitle>
        <div class="d-flex justify-content-center mx-auto">
            <RadioButton :id="'kpi-radio-group'" :modal="kpiSelected" :options="kpiOptions"
                :name="'kpi-epoch-radio-options'" @update-modal="updateKpi" :task="updateKpiData" />
        </div>
        <MissingDataText v-if="!hasData" message="Insufficient data for player" />
        <div v-else>
            <ChartLegend />
            <LongitudinalChart :id="kpiSelected + '-id-chart'" :title="selectedKpiTitle" :data="kpiData" />
        </div>
    </div>
</template>

<script>
import { convertChart } from './ChartDataConverter';
import LongitudinalChart from './LongitudinalChart';
import filterStore from './filterStore';
import MissingDataText from "@/components/MissingDataText.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import RadioButton from "@/components/RadioButton.vue";
import ChartLegend from "./ChartLegend.vue";
import SharedInfoText from "./SharedInfoText";
import KpiOption from "@/utils/KpiOption";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

export default {
    props: {
        sessions: Array,
        data: Object,
    },
    components: {
        MissingDataText,
        SectionTitle,
        LongitudinalChart,
        MoreInfo,
        RadioButton,
        ChartLegend,
        SharedInfoText
    },
    data() {
        return {
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            countUnit: getMeasurementUnit(MeasurementTypes.Count),
            kpiData: null,
            hasData: false,
            kpiSelected: KpiOption.defaultSelection
        };
    },
    methods: {
        updateKpiData() {
            if (this.data) {
                this.hasData = this.data[this.kpiSelected] !== undefined;
                if (this.hasData) {
                    const distance = convertChart(this.data.DISTANCE, this.sessions, this.ldUnit);
                    const hsr = convertChart(this.data.HSR, this.sessions, this.sdUnit);
                    const sprint = convertChart(this.data.SPRINT, this.sessions, this.sdUnit);
                    const turns = convertChart(this.data.TURNS, this.sessions, this.countUnit);

                    const data = {
                        DISTANCE: distance,
                        HSR: hsr,
                        SPRINT: sprint,
                        TURNS: turns
                    };
                    this.kpiData = data[this.kpiSelected];
                }
            }
        },
        updateKpi(event) {
            this.kpiSelected = event;
        }
    },
    computed: {
        selectedKpiTitle() {
            return this.kpiOptions.find(t => t.value === this.kpiSelected).text;
        },
        kpiOptions() {
            return filterStore.state.kpiOptions;
        },
        selectedReferenceGroupText() {
            return filterStore.state.selectedReferenceGroup.text;
        },
    },
    watch: {
        data() {
            this.updateKpiData();
        },
    },
    async mounted() {
        this.updateKpiData();
    },
};
</script>