import "core-js/stable";
import "regenerator-runtime/runtime";
import "intersection-observer"; // Optional
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/styles/site.css";
import "./assets/styles/dashboard.css";
import "./assets/styles/header.css";
import axios from "axios";
import VueAppInsights from "vue-application-insights";

// Views
import Login from "./views/Login.vue";
import Calendar from "./views/Calendar.vue";
import Session from "./views/Session/Session.vue";
import Data from "./views/Data.vue";
import Rehab from "./views/Rehab/Rehab.vue";
import SessionOverview from "./views/Rehab/SessionOverview.vue";
import TurnAnalysis from "./views/Session/TurnAnalysis.vue";
import Player from "./views/Player.vue";
import NotFound from "./views/NotFound.vue";
import SessionAnalysis from "./views/Session/SessionAnalysis.vue";
import FatigueFlag from "./views/FatigueFlag.vue";
import Admin from "./views/Admin.vue";
import PlayerDevelopment from "./views/PlayerDevelopment/PlayerDevelopment.vue";
import LongitudinalPlayerDevelopment from "./views/PlayerDevelopment/LongitudinalAnalysis.vue";
import VCalendar from "v-calendar";
import OppositionAnalysis from "./views/Session/OppositionAnalysis.vue";
import ErrorPage from "./views/ErrorPage.vue";
import Team from "./views/Team.vue";
import PeakAnalysis from "./views/Session/PeakAnalysis.vue";
import Squad from "./views/Squad.vue";
import DataNetwork from "./views/DataNetwork/DataNetwork.vue";
import Wellness from "./views/Wellness/Wellness.vue";
import ChangePassword from "./views/ChangePassword.vue";
import WhitePaper from "./views/WhitePaper.vue";
import SessionReporting from "./views/Session/SessionReporting.vue";
import PlayerReporting from "./views/Reporting/PlayerReporting.vue";

// Sidebars
import DummySidebar from "./sidebars/Dummy.vue";
import DefaultSidebar from "./sidebars/Default.vue";

import { Chart } from "chart.js";

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VCalendar, { componentPrefix: "vc" });
Vue.use(VueAppInsights, {
	id: "f335e731-15c7-4881-9539-b567c8015b24",
});

Vue.config.productionTip = false;

Chart.defaults.global.defaultFontFamily = "'Barlow', sans-serif";
Chart.defaults.global.defaultFontColor = "white";
Chart.defaults.global.title.fontStyle =
	navigator.userAgent.indexOf("Firefox") !== -1 ? "normal" : "bold";
	navigator.userAgent.indexOf("Firefox") !== -1 ? "normal" : "bold";
Chart.defaults.global.tooltips.footerFontStyle = "normal";
Chart.defaults.global.title.fontSize = 16;

// root instance data
const appData = {
	webApi: "", // now configurable at runtime in config.json,
	lastApiError: null,
	userData: window.sessionStorage.userData
		? JSON.parse(window.sessionStorage.getItem("userData"))
		: { userName: "", token: "", additionalCustomer: [], primaryCustomer: [] },
	nextPath: null,
	previousPath: null,
	isLoadingData: false,
	loginWarning: 0,
	activePath: "calendar",
	unfinishedExecutionCount: 0,
	cookieConsent: false,
};

// URL paths to sidebar and main panel routes
const router = new VueRouter({
	mode: "history",
	routes: [
		{
			path: "/",
			components: { default: Calendar, sidebar: DefaultSidebar },
		},
		{
			path: "/login",
			components: { default: Login, sidebar: DummySidebar },
		},
		{
			path: "/calendar",
			components: { default: Calendar, sidebar: DefaultSidebar },
		},
		{
			path: "/player",
			components: { default: Player, sidebar: DefaultSidebar },
		},
		{
			path: "/session/:customerId/:id",
			name: "kpioverview",
			components: { default: Session, sidebar: DefaultSidebar },
		},
		{
			path: "/sessionreporting/:customerId/:id",
			name: "sessionreporting",
			components: { default: SessionReporting, sidebar: DefaultSidebar },
		},
		{
			path: "/data",
			components: { default: Data, sidebar: DefaultSidebar },
		},
		{
			path: "/session/:customerId/turnanalysis/:id",
			name: "turnanalysis",
			components: { default: TurnAnalysis, sidebar: DefaultSidebar },
		},
		{
			path: "/session/:customerId/sessionanalysis/:id",
			name: "sessionanalysis",
			components: { default: SessionAnalysis, sidebar: DefaultSidebar },
		},
		{
			path: "/session/:customerId/oppositionanalysis/:id",
			name: "oppositionanalysis",
			components: { default: OppositionAnalysis, sidebar: DefaultSidebar },
		},
		{
			path: "/session/:customerId/peakanalysis/:id",
			name: "peakanalysis",
			components: { default: PeakAnalysis, sidebar: DefaultSidebar },
		},
		{
			path: "/fatigueflag",
			components: { default: FatigueFlag, sidebar: DefaultSidebar },
		},
		{
			path: "/rehab",
			name: "rehab",
			components: { default: Rehab, sidebar: DefaultSidebar },
		},
		{
			path: "/sessionoverview",
			name: "sessionoverview",
			components: { default: SessionOverview, sidebar: DefaultSidebar },
		},
		{
			path: "/playerdevelopment",
			name: "playerdevelopment",
			components: { default: PlayerDevelopment, sidebar: DefaultSidebar },
		},
		{
			path: "/longitudinalanalysis",
			name: "longitudinalanalysis",
			components: {
				default: LongitudinalPlayerDevelopment,
				sidebar: DefaultSidebar,
			},
		},
		{
			path: "/team",
			components: { default: Team, sidebar: DefaultSidebar },
		},
		{
			path: "/squad",
			components: { default: Squad, sidebar: DefaultSidebar },
		},
		{
			path: "/datanetwork",
			components: { default: DataNetwork, sidebar: DefaultSidebar },
		},
		{
			path: "/wellness",
			components: { default: Wellness, sidebar: DefaultSidebar },
		},
		{
			path: "/errorpage",
			components: { default: ErrorPage, sidebar: DefaultSidebar },
		},
		{
			path: "/whitepaper",
			components: { default: WhitePaper, sidebar: DefaultSidebar },
		},
		{
			path: "/admin",
			components: { default: Admin, sidebar: null },
		},
		{
			path: "/changepassword",
			components: { default: ChangePassword, sidebar: null },
		},
		{
			path: "/playerreporting",
			components: { default: PlayerReporting, sidebar: DefaultSidebar },
		},
		{
			path: "/:catchAll(.*)",
			components: { default: NotFound, sidebar: DummySidebar },
		},
	],
});

// router guards to prevent access to pages without a valid session
router.beforeEach((to, from, next) => {
	appData.previousPath = from.path.toLowerCase();
	try {
		if (to.path.toLowerCase() !== "/login" && !appData.userData.token) {
			console.log("Missing user when navigating to " + to.path);
			appData.nextPath = to.path.toLowerCase();
			appData.activePath = "login";
			next("/login");
		} else if (
			(to.path.toLowerCase() === "/" || to.path.toLowerCase() === "/login") &&
			appData.userData.token
		) {
			appData.activePath = "calendar";
			next("/calendar");
		} else {
			appData.activePath = to.path.toLowerCase().substring(1);
			const qsPos = appData.activePath.indexOf("?");
			while (qsPos !== -1) {
				appData.activePath = appData.activePath.substring(0, qsPos - 1);
			}
			next(); // go to wherever I'm going
		}
	} catch (err) {
		console.log(err);
	}
});

// Automatically register all base components globally
const requireComponent = require.context(
	"@/components", //relative path of the components folder
	true, // whether to look in subfolders or not
	/[A-Z]\w+\.(vue|js)$/ //regex used to match base component file names
);
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);   // get component config
    const componentName = fileName.split('/').pop().split('.').slice(0, -1).join('.'); // get name of component in PascalCase
    Vue.component(componentName, componentConfig.default || componentConfig); // register component globally
});

// init vue
new Vue({
	router,
	render: (h) => h(App),
	data: appData,
	methods: {
		updateGlobal(key, value) {
			// updates a global variable (and stores it in the session storage)
			appData[key] = value;
			window.sessionStorage.setItem(key, JSON.stringify(this.$root[key]));
		},
		// get runtime config
		async getRuntimeConfig() {
			const runtimeConfig = await fetch("/config.json");
			const json = await runtimeConfig.json();
			return json;
		},
		logOut(warning) {
			console.log("logging out");
			// clear the userdata object that is stored in session storage
			this.updateGlobal("userData", {
				userName: "",
				token: "",
				additionalCustomer: [],
				primaryCustomer: [],
			});

			sessionStorage.removeItem("sessionYear");
			sessionStorage.removeItem("sessionMonth");

			// clear down other global vars
			this.loginWarning = warning;
			this.nextPath = null;

			//bounce back to login
			this.$router.push("/login").catch(() => {});
		},
		async webApiGet(url) {
			return this.webApiGetWithAuthToken(url, this.userData.token);
		},
		async webApiGetWithAuthToken(url, authToken) {
			const config = await this.getRuntimeConfig();
			axios.defaults.baseURL = config.apiBase;
			axios.defaults.headers["Content-Type"] = "application/json";
			axios.defaults.headers["Accept"] = "*/*";
			if (authToken) {
				axios.defaults.headers["Authorization"] =
					"Bearer " + authToken;
			}

			try {
				const response = await axios.get(url);
				return response;
			} catch (error) {
				if (error.response) {
					return error.response;
				} else {
					console.log("Unexpected error on API GET");
					return null;
				}
			}
		},
		async webApiPost(url, data) {
			const config = await this.getRuntimeConfig();
			axios.defaults.baseURL = config.apiBase;
			axios.defaults.headers["Content-Type"] = "application/json";
			axios.defaults.headers["Accept"] = "*/*";
			if (this.userData.token)
				axios.defaults.headers["Authorization"] =
					"Bearer " + this.userData.token;

			try {
				const response = await axios.post(url, data);
				return response;
			} catch (error) {
				if (error.response) {
					return error.response;
				} else {
					console.log("Unexpected error on API POST");
					return null;
				}
			}
		},
		async executeTaskWithProgressBar(task) {
			if (this.unfinishedExecutionCount === 0) this.isLoadingData = true;
			++this.unfinishedExecutionCount;
			try {
				await task();
			} finally {
				--this.unfinishedExecutionCount;
				if (this.unfinishedExecutionCount === 0) this.isLoadingData = false;
			}
		},
		newEvent(event, user) {
			if (this.$root.cookieConsent === true) {
				this.$appInsights.trackEvent({
					name: event,
					properties: {
						User: user,
						ScreenWidth: screen.width,
						ScreenHeight: screen.height,
					},
				});
			}
		},
		newPageView(page, user) {
			if (this.$root.cookieConsent === true) {
				this.$appInsights.trackPageView({
					name: page,
					properties: {
						User: user,
					},
				});
			}
		},
	},
}).$mount("#app");
