<template>
  <div id="app">
    <nav
      id="top-nav-container"
      class="navbar navbar-dark fixed-top bg-primary flex-md-nowrap p-0 shadow" 
    >
      <Brand />
      <TopNav  v-if="this.$root.userData.token"/>
    </nav>

    <div class="container-fluid">
      <div class="row">
        <nav class="sidebar" v-if="this.$root.userData.token">
          <div class="sidebar-sticky">
            <router-view name="sidebar"></router-view>
          </div>
        </nav>
        <div id="main-panel">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <b-modal
      id="wait"
      title="Please Wait..."
      v-model="this.$root.isLoadingData"
      hide-header-close
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <b-progress :value="100" :max="100" class="custom-progress" animated></b-progress>
    </b-modal>
  </div>
</template>
<script>
import TopNav from "./components/TopNav.vue";
import Brand from "./components/Brand.vue";
export default {
  name: "app",
  components: {
    TopNav,
    Brand,
  },
};
</script>
<style>
.custom-progress .progress-bar {
  background: repeating-linear-gradient( -120deg, var(--sportlight-teal), var(--sportlight-teal) 10px,  var(--bg-primary) 10px, var(--bg-primary) 20px); 
  border: 1px solid var(--sportlight-teal);
}
</style>