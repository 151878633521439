<template>
    <div v-if="items">
        <b-dropdown class="dropdown" :text="dropdownText" right variant="outline-primary">
            <b-dropdown-item v-for="(item, i) in items" v-bind:key="i" @click="handleClick(i)">
                {{ getItemName(item) }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        getItemName: Function,
        index: { type: Number, default: 0 }
    },
    methods: {
        handleClick(index) {
            this.$emit('item-selected', index);
        }
    },
    computed: {
        dropdownText() {
            return this.items && this.items.length > 0 ? this.getItemName(this.items[this.index]) : '';
        }
    },
    watch: {
        index: {
            handler(newIndex) {
                return newIndex;
            }
        }
    },
};
</script>

<style scoped>
.dropdown /deep/ .dropdown-menu {
    max-height: 80vh; /* Limit dropdown menu height to 80% of viewport. */
    overflow-y: auto;
}
</style>