import { rehabChartDaily } from "./RehabChartDaily";
import { rehabChartMini } from "./RehabChartMini";
import { rehabChartTurnRates } from "./RehabChartTurnRates";
import Colours from "@/utils/Colours";
import DateUtils from "@/utils/DateUtils";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

const rehabChartWeekly = {
    ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
    sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
    svg: null,
    rehabData: null,
    displayType: "total",
    controls: null,
    distance: {
        xOrigin: 140,
        yOrigin: 160,
        width: 1320,
        height: 100,
        xValues: [],
        yValues: [],
        renderXValues: false,
        renderYValues: true,
        xLabel: "",
        yLabel: "Distance",
        yMax: 0,
        data: [],
        dataMax: [],
        dataMean: [],
        markerLine: null
    },
    hsrDistance: {
        xOrigin: 140,
        yOrigin: 310,
        width: 1320,
        height: 100,
        xValues: [],
        yValues: [],
        renderXValues: true,
        renderYValues: true,
        xLabel: "Weeks pre/post",
        yLabel: "HSR Dist",
        yMax: 0,
        dataTotal: [],
        dataMax: [],
        dataMean: [],
        markerLine: null
    },
    init: function (svg, rehabData) {
        this.svg = svg.group();
        this.rehabData = rehabData;
    },
    render: function () {
        this.svg.clear(); // clear any existing chart contents

        this.parseData();
        this.renderHoverStrips();
        this.drawAxes(this.distance, this.ldUnit);
        this.drawAxes(this.hsrDistance, this.sdUnit);

        if(this.rehabData.sessionKpis.length > 0) {
            this.renderData(this.distance);
            this.renderData(this.hsrDistance);
            this.renderRehabWeek();
        }
        this.renderControls();
    },
    parseData: function () {
        // sort the weekly KPIs by week number
        this.rehabData.weeklyKpis.sort((a, b) => (a.weekNumber > b.weekNumber) ? 1 : -1);

        const injuryDate = this.rehabData.rehabDate;
        const dayOfWeek = DateUtils.getDay(injuryDate);
        const dateToString = injuryDate.replaceAll("-", "");

        if (this.rehabData.weeklyKpis.length !== 0) {
            const lastWeekData = this.rehabData.weeklyKpis[
                this.rehabData.weeklyKpis.length - 1
            ];
            const lastSession = lastWeekData.sessionIds.length - 1;
            const lastSessionId = lastWeekData.sessionIds[lastSession];
            const dateDifference = dateToString - lastSessionId;
            
            
            if (dateDifference > 1 && dayOfWeek < 6) {
                this.rehabData.weeklyKpis.push({
                    averageActiveDistanceM: 0,
                    averageHighSpeedDistanceM: 0,
                    maxActiveDistanceM: 0,
                    maxHighSpeedDistanceM: 0,
                    sessionIds: 0,
                    totalActiveDistanceM: 0,
                    totalHighSpeedDistanceM: 0,
                    weekNumber: 0
                });
            }
        } 

        // shortcuts
        const w = this.rehabData.weeklyKpis;
        const d = this.distance;
        const h = this.hsrDistance;

        // clear down the data and x axis arrays
        d.xValues = [];
        d.dataTotal = [];
        d.dataMax = [];
        d.dataMean = [];
        h.xValues = [];
        h.dataTotal = [];
        h.dataMax = [];
        h.dataMean = [];

        for (var i = 0; i < w.length; i++) {
            if ((i > 0 && w[i - 1].weekNumber < 0 && w[i].weekNumber > 0) || (i === 0 && w[i].weekNumber > 0)) {
                // insert week 0 column into the data and x axis arrays
                d.xValues.push("0");
                h.xValues.push("0");
                d.dataTotal.push(null);
                h.dataTotal.push(null);
                d.dataMax.push(null);
                h.dataMax.push(null);
                d.dataMean.push(null);
                h.dataMean.push(null);
            } else if (i > 0 && w[i].weekNumber !== w[i - 1].weekNumber + 1) {
                // insert gap column into the data and x axis arrays
                d.xValues.push("...");
                h.xValues.push("...");
                d.dataTotal.push(null);
                h.dataTotal.push(null);
                d.dataMax.push(null);
                h.dataMax.push(null);
                d.dataMean.push(null);
                h.dataMean.push(null);
            }

            // insert data and week numbers into the data and x axis arrays
            d.xValues.push(w[i].weekNumber.toString());
            h.xValues.push(w[i].weekNumber.toString());
            d.dataTotal.push(this.ldUnit.convert(w[i].totalActiveDistanceM));
            h.dataTotal.push(this.sdUnit.convert(w[i].totalHighSpeedDistanceM));
            d.dataMax.push(this.ldUnit.convert(w[i].maxActiveDistanceM));
            h.dataMax.push(this.sdUnit.convert(w[i].maxHighSpeedDistanceM));
            d.dataMean.push(this.ldUnit.convert(w[i].averageActiveDistanceM));
            h.dataMean.push(this.sdUnit.convert(w[i].averageHighSpeedDistanceM));
        }

        // now set the y axis scale for the charts
        this.setYAxisScale(d, this.ldUnit);
        this.setYAxisScale(h, this.sdUnit);
    },
    setYAxisScale(d, unit) {
        // determine the y axis values from the supplied data
        let yIntervals = [100, 200, 250, 500, 1000, 5000, 10000]; // possible y-axis intervals
        yIntervals = yIntervals.map(value => parseInt(unit.convert(value)));

        // determine the highest value that we will need to display
        let maxValue = 0;
        d.dataTotal.forEach(e => { if (e > maxValue) maxValue = e; });
        d.dataMax.forEach(e => { if (e > maxValue) maxValue = e; });
        d.dataMean.forEach(e => { if (e > maxValue) maxValue = e; });

        let yInterval;
        for (var j = 0; j < yIntervals.length; j++) {
            if (maxValue / yIntervals[j] < 6) {
                yInterval = yIntervals[j];
                break;
            }
        }
        // build the y axis values array based on the interval
        d.yValues = [];
        let yValue = 0;
        while (yValue < maxValue) {
            d.yValues.push(yValue);
            yValue += yInterval;
        }
        d.yValues.push(yValue + yInterval);
        d.yMax = yValue + yInterval;
    },
    drawAxes: function (d, unit) {
        // y axis
        this.svg.line(d.xOrigin, d.yOrigin, d.xOrigin, d.yOrigin - d.height).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
        const yInterval = (d.height / (d.yValues.length - 1));
        let y = d.yOrigin;
        d.yValues.forEach(yValue => {
            this.svg.line(d.xOrigin, y, d.xOrigin - 10, y).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
            if (d.renderYValues)
                this.svg.text(yValue).font({ family: "Barlow", size: 12, anchor: "end" }).attr({ x: d.xOrigin - 12, y: y + 4, fill: Colours.WHITE });
            y = y - yInterval;
        });

        // y axis label
        this.svg.text(d.yLabel + `(${unit.unitShort})`).font({ family: "Barlow", size: 12, anchor: "start" }).attr({ x: 20, y: d.yOrigin - (d.height / 2), fill: Colours.WHITE });

        // x axis
        this.svg.line(d.xOrigin, d.yOrigin, d.xOrigin + d.width, d.yOrigin).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
        const xInterval = (d.width / (d.xValues.length - 1));
        let x = d.xOrigin;
        d.xValues.forEach(xValue => {
            this.svg.line(x, d.yOrigin, x, d.yOrigin + 10).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
            if (d.renderXValues)
                this.svg.text(xValue).font({ family: "Barlow", size: 12, anchor: "middle" }).attr({ x: x, y: d.yOrigin + 20, fill: Colours.WHITE });
            x = x + xInterval;
        });

        // x axis label
        this.svg.text(d.xLabel).font({ family: "Barlow", size: 12, anchor: "start" }).attr({ x: 20, y: d.yOrigin + 20, fill: Colours.WHITE });
    },
    renderData: function (d) {
        // select the correct dataset based on the display type
        let data;
        switch (this.displayType) {
            case "total":
                data = d.dataTotal;
                break;
            case "max":
                data = d.dataMax;
                break;
            case "mean":
                data = d.dataMean;
                break;
        }

        const xInterval = (d.width / (d.xValues.length - 1)); // determine the x axis interval
        const unit = d.height / d.yMax; // determine the amount that a single pixel in the y direction represents

        let leftIdx = 0;
        let rightIdx = 1;

        while (rightIdx < data.length) {
            let dotted = false;
            let leftValue = data[leftIdx];

            // loop until the leftmost value has data
            while (!leftValue && leftIdx < data.length - 1) {
                leftValue = data[++leftIdx];
            }

            // loop until the rightmost value has data
            let rightValue = data[rightIdx];
            while (!rightValue && rightIdx < data.length - 1) {
                rightValue = data[++rightIdx];
                dotted = true; // we need to make the line dotted, as we have column weeks with no data
            }

            // calculate x and y co-ords for start and end of the line
            const leftValueY = d.yOrigin - (leftValue * unit);
            const rightValueY = d.yOrigin - (rightValue * unit);
            const leftValueX = d.xOrigin + (leftIdx * xInterval);
            const rightValueX = d.xOrigin + (rightIdx * xInterval);

            // draw the line
            if (rightValue) {
                if (dotted) {
                    this.svg.line(leftValueX, leftValueY, rightValueX, rightValueY).attr({ stroke: Colours.WHITE, "stroke-width": 1, "stroke-dasharray": "4 4" });
                } else {
                    this.svg.line(leftValueX, leftValueY, rightValueX, rightValueY).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
                }
            }

            // the next line will start where the current line ends
            leftIdx = rightIdx;
            rightIdx++;
        }
    },
    renderHoverStrips: function () {
        // render the SVG elements that will appear when hovering the mouse over the chart
        const yMin = this.distance.yOrigin - this.distance.height;
        const yMax = this.hsrDistance.yOrigin + 10;
        const xInterval = (this.distance.width / (this.distance.xValues.length - 1));
        const stripWidth = xInterval;

        const self = this;
        for (var i = 0; i < this.distance.xValues.length; i++) {
            const xPos = this.distance.xOrigin + (i * xInterval);
            let rect;
            if (i === 0) {
                rect = this.svg.rect(stripWidth/2, yMax - yMin).attr({ class: "hoverbar", dataIdx: i, fill: Colours.SECONDARY_LIGHT_GREY, x: xPos, y: yMin, opacity: 0 });
            } else if (i === this.distance.xValues.length - 1) {
                rect = this.svg.rect(stripWidth/2, yMax - yMin).attr({ class: "hoverbar", dataIdx: i, fill: Colours.SECONDARY_LIGHT_GREY, x: xPos - (stripWidth / 2), y: yMin, opacity: 0 });
            } else {
                rect = this.svg.rect(stripWidth, yMax - yMin).attr({ class: "hoverbar", dataIdx: i, fill: Colours.SECONDARY_LIGHT_GREY, x: xPos - (stripWidth / 2), y: yMin, opacity: 0 });
            }
            // add the hover events
            rect.mouseover(function () {
                self.mouseOverHoverBar(this);
            });
            rect.mouseout(function () {
                self.mouseOutHoverBar(this);
            });
        }
    },
    renderRehabWeek: function () {
        // render a line showing on which week the injury or season start occurred
        const yMin = this.distance.yOrigin - this.distance.height;
        const yMax = this.hsrDistance.yOrigin + 10;

        const xInterval = (this.distance.width / (this.distance.xValues.length - 1));

        // find week 0 in the x axis values to get the index into the data
        let rIdx = 0;
        while (rIdx < this.distance.xValues.length && this.distance.xValues[rIdx] !== "0") {
            rIdx++;
        }

        const lineX = this.distance.xOrigin + (rIdx * xInterval); // calc the x position for the line

        const lineCol = this.rehabData.rehabType === "injury" ? "#eb8181" : "#5a5afd"; // set line colour based on rehab type

        // render the line and text
        this.svg.line(lineX, yMax, lineX, yMin).attr({ stroke: lineCol, "stroke-width": 1, "stroke-dasharray": "1 1" });
        this.svg.text(this.rehabData.rehabType).font({ family: "Barlow", size: 14, anchor: "start" }).attr({ x: lineX + 5, y: yMin + 10, fill: Colours.WHITE });

    },
    mouseOverHoverBar(bar) {
        bar.attr({ opacity: 0.30 }); // set the bar opacity so that it is visible

        // remove any existing marker lines from the SVG
        if (this.distance.markerLine)
            this.distance.markerLine.remove();
        if (this.hsrDistance.markerLine)
            this.hsrDistance.markerLine.remove();

        var dataIdx = bar.attr("dataIdx"); // get the index into the data from the attribute on the hover bar
        rehabChartDaily.highlightSessions(this.distance.xValues[dataIdx]); // highlight sessions for this week on the daily chart
        rehabChartMini.highlightSessions(this.distance.xValues[dataIdx]); // highlight sessions for this week on the mini chart
        rehabChartTurnRates.highlightSessions(this.distance.xValues[dataIdx]); // highlight sessions for this week on the turn rates chart

        let distanceData;
        let hsrDistanceData;
        switch (this.displayType) {
            case "total":
                distanceData = this.distance.dataTotal;
                hsrDistanceData = this.hsrDistance.dataTotal;
                break;
            case "max":
                distanceData = this.distance.dataMax;
                hsrDistanceData = this.hsrDistance.dataMax;
                break;
            case "mean":
                distanceData = this.distance.dataMean;
                hsrDistanceData = this.hsrDistance.dataMean;
                break;
        }

        var distanceValue = distanceData[dataIdx];
        var hsrDistanceValue = hsrDistanceData[dataIdx];

        // determine x and y positioning
        const distanceUnit = this.distance.height / this.distance.yMax;
        const hsrDistanceUnit = this.hsrDistance.height / this.hsrDistance.yMax;
        const xInterval = (this.distance.width / (this.distance.xValues.length - 1));

        // calculate line start co-ordinates
        const distanceLineY = this.distance.yOrigin - (distanceValue * distanceUnit);
        const hsrDistanceLineY = this.hsrDistance.yOrigin - (hsrDistanceValue * hsrDistanceUnit);
        const lineX = this.distance.xOrigin + (dataIdx * xInterval);

        // render the lines
        if (distanceValue)
            this.distance.markerLine = this.svg.line(this.distance.xOrigin, distanceLineY, lineX, distanceLineY).attr({ stroke: Colours.WHITE, "stroke-width": 1, "stroke-dasharray": "1 1" });

        if (hsrDistanceValue)
            this.hsrDistance.markerLine = this.svg.line(this.hsrDistance.xOrigin, hsrDistanceLineY, lineX, hsrDistanceLineY).attr({ stroke: Colours.WHITE, "stroke-width": 1, "stroke-dasharray": "1 1" });


    },
    mouseOutHoverBar(bar) {
        bar.attr({ opacity: 0 }); // set hover bar opacity to 0 (i.e. invisible)
        rehabChartDaily.unHighlightSessions(); // clear any highlighted sessions on the daily chart
        rehabChartMini.unHighlightSessions(); // clear any highlighted sessions on the mini chart
        rehabChartTurnRates.unHighlightSessions();
    },
    renderControls() {
        const renderButton = (buttonName, x, displayType) => {
            const buttonGroup = this.svg.group(); // create a group so that we can add an event to the whole button
            const fill = this.displayType === displayType ? Colours.SPORTLIGHT_TEAL : "none";
            const textCol = this.displayType === displayType ? Colours.PRIMARY_GREY : Colours.WHITE;
            
            buttonGroup.rect(45, 20).attr({ x: x, y: 15, fill: fill, stroke: Colours.SPORTLIGHT_TEAL, "stroke-width": 1, cursor: "pointer" });
            buttonGroup.text(buttonName).font({ family: "Barlow", size: 12, anchor: "middle", "weight": "light", cursor: "pointer" }).attr({ x: x + 22, y: 30, fill: textCol });
            buttonGroup.click(function () {
            // set display type and re-render the chart
            self.displayType = displayType;
            self.render();
            });
        };
    
    this.svg.text("Weekly").font({ family: "Barlow", size: 12, anchor: "start", weight: "bold" }).attr({ x: 20, y: 20, fill: Colours.WHITE }); // chart title
    
    const self = this;
    renderButton("TOTAL", 142, "total");
    renderButton("MAX", 192, "max");
    renderButton("MEAN", 242, "mean");
    }
    
};

export { rehabChartWeekly };