export default [
	{
		title: '',
		metrics: [
			{ label: 'Match Time (mins)', key: 'matchTime' },
			{ label: 'Work Rate', key: 'workRate' },
			{ label: 'Fatigue Flag', key: 'fatigueFlag' },
			{ label: 'Hamstring Risk', key: 'hamstringRisk' },
		],
	},
	{
		title: 'Work Rate Distances (m)',
		metrics: [
			{ label: 'Distance > 85WR', key: 'distanceOver85WR' },
			{ label: 'Distance > 100WR', key: 'distanceOver100WR' },
			{ label: 'Distance > 110WR', key: 'distanceOver110WR' },
			{ label: 'Distance > 115WR', key: 'distanceOver115WR' },
			{ label: 'Distance > 120WR', key: 'distanceOver120WR' },
		],
	},
	{
		title: 'Speed Distances (m)',
		metrics: [
			{ label: 'Total Distance', key: 'totalDistance' },
			{ label: 'Distance > 17 km/h', key: 'distanceOver17kmph' },
			{ label: 'Distance > 20 km/h', key: 'distanceOver20kmph' },
			{ label: 'Distance > 23 km/h', key: 'distanceOver23kmph' },
			{ label: 'Distance > 25.5 km/h', key: 'distanceOver25_5kmph' },
			{ label: 'Distance > 27.5 km/h', key: 'distanceOver27_5kmph' },
			{ label: 'Distance > 29 km/h', key: 'distanceOver29kmph' },
			{ label: 'Distance > 30.5 km/h', key: 'distanceOver30_5kmph' },
			{ label: 'Distance > 31.5 km/h', key: 'distanceOver31_5kmph' },
		],
	},
	{
		title: 'Acceleration Distances (m)',
		metrics: [
			{ label: 'Distance > 2 m/s²', key: 'accelerationOver2mps2' },
			{ label: 'Distance > 2.5 m/s²', key: 'accelerationOver2_5mps2' },
			{ label: 'Distance > 3 m/s²', key: 'accelerationOver3mps2' },
			{ label: 'Distance > 3.5 m/s²', key: 'accelerationOver3_5mps2' },
			{ label: 'Distance > 4 m/s²', key: 'accelerationOver4mps2' },
			{ label: 'Distance > 6 m/s²', key: 'accelerationOver6mps2' },
		],
	},
	{
		title: 'Deceleration Distances (m)',
		metrics: [
			{ label: 'Distance < -2 m/s²', key: 'decelerationUnder2mps2' },
			{ label: 'Distance < -2.5 m/s²', key: 'decelerationUnder2_5mps2' },
			{ label: 'Distance < -3.25 m/s²', key: 'decelerationUnder3_25mps2' },
			{ label: 'Distance < -4 m/s²', key: 'decelerationUnder4mps2' },
			{ label: 'Distance < -4.75 m/s²', key: 'decelerationUnder4_75mps2' },
			{ label: 'Distance < -5.5 m/s²', key: 'decelerationUnder5_5mps2' },
		],
	}
];
