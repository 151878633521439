<template>
    <div id="content-wrap" style="margin-left:80px" :style="{opacity: !$root.isLoadingData ? 1 : 0}">
        <PageHeader>
            <div class="player-header" v-if="selectedPlayer">
                <div class="player-details">
                    <div class="club-logo-container">
                        <img :src="selectedPlayer.teamAvatarUrl" class="club-logo" :alt="selectedPlayer.teamName + ' logo'">
                    </div>
                    <div class="ml-2">
                        <h2 class="player-name"> {{ selectedPlayer.name }} </h2>
                        <span> {{ selectedPlayer.teamName + " - " + selectedPlayer.position }} </span>
                        <span v-if="kpiBenchMarkPlayerValues"> - Matches: {{kpiBenchMarkPlayerValues.validMatchCount}}</span>
                    </div>
                </div>
                <DropdownSelector @item-selected="handlePlayerSelected" :items="players" :getItemName="selectorDisplayText" />
            </div>
        </PageHeader>
        <PlayerSquadRainbow :player="selectedPlayer" :data="rainbowBarData" :hasData="hasRainbowData" />
        <PlayerKpiBenchmarking v-if="kpiBenchMarkPlayerValues && kpiBenchMarkComparisonValues" 
                               :playerId="selectedPlayer.playerId" :values="kpiBenchMarkPlayerValues" 
                               :teamValues="kpiBenchMarkTeamValues" :comparisonValues="kpiBenchMarkComparisonValues"/>
        <PeakThresholdCounts
            :distanceCounts="distanceCounts[0]"
            :hsrCounts="hsrCounts[0]"
            :sprintCounts="sprintCounts[0]"
            :turnCounts="turnCounts[0]"
            :leagueText="leagueText"
            :title="'Peak Physical Counts'"
        ></PeakThresholdCounts>
        <ForceVelocity :data="fvData" :hasData="hasFVData" />
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import {UserData} from "@/components/UserData";
import {errorHandler} from "@/components/ErrorHandler";
import DropdownSelector from "@/components/DropdownSelector";
import PlayerKpiBenchmarking from './PlayerKpiBenchmarking';
import PeakThresholdCounts from "../PeakThresholdCounts.vue";
import PlayerSquadRainbow from "./PlayerSquadRainbow.vue";
import UpdateSetting from "@/utils/UpdateSetting";
import {KpiTypes} from "@/utils/KpiTypes";
import EpochTypes from "@/utils/EpochTypes";
import { MeasurementTypes, convertRainbowPercentileComparisonData, getMeasurementUnit } from "@/utils/MeasurementSystem"; 
import ForceVelocity from "./ForceVelocity.vue";

export default {
    components: {
        PageHeader,
        PlayerKpiBenchmarking,
        DropdownSelector,
        PeakThresholdCounts,
        PlayerSquadRainbow,
        ForceVelocity
    },
    data() {
        return {
            players: null,
            customerId: UserData.customerId(),
            kpi: null,
            selectedPlayer: null,
            kpiBenchMarkPlayerValues: null,
            kpiBenchMarkTeamValues: null,
            kpiBenchMarkComparisonValues: null,
            peakThresholdCountValues: null,
            peakThresholdCountComparisonValues: null,
            distanceCounts: [],
            hsrCounts: [],
            sprintCounts: [],
            turnCounts: [],
            leagueText: 'SDN',
            playerRainbowBarValues: null,
            comparisonRainbowBarValues: null,
            rainbowBarData: null,
            hasRainbowData: false,
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            fvData: {},
            hasFVData: false,
            playerAverageForceVelocity: null,
            teamPlayersForceVelocities: null,
            teamAverageForceVelocity: null,
            networkAverageForceVelocity: null,
            networkForceVelocities: null,
            minMaxForceVelocities: null
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async getPlayers() {
            const response = await this.$root.webApiGet(`/datanetworkplayers`);
            if (response.status === 200) {
                this.players = response.data;
            } else {
                errorHandler.error(response, this);
            }
        },
        async handlePlayerSelected(p) {
            this.$root.executeTaskWithProgressBar(async () => {
                this.resetPlayerData();
                this.selectedPlayer = await this.players[p];
                await this.getPlayerValues();
                this.getRainbowBarData();
                await this.getForceVelocityData();
                this.getCounts();
            });
        },
        async getPlayerValues() {
            const response = await this.$root.webApiGet(`/datanetworkplayer?userCustomerId=${this.customerId}&playerCustomerId=${this.selectedPlayer.customerId}&playerId=${this.selectedPlayer.playerId}`);
            if (response.status === 200) {
                this.kpiBenchMarkPlayerValues = response.data.dataNetworkPlayerKpis;
                this.kpiBenchMarkTeamValues = response.data.dataNetworkTeamPositionKpis;
                this.peakThresholdCountValues = response.data.peakThresholdCountPlayerValues;
                this.playerRainbowBarValues = response.data.playerRainbowBarValues;
                this.playerAverageForceVelocity = response.data.playerAverageForceVelocity;
            } else {
                errorHandler.error(response, this);
            }
        },
        async getComparisonValues() {
            const response = await this.$root.webApiGet(`/datanetworkcomparison`);
            if (response.status === 200) {
                this.kpiBenchMarkComparisonValues = response.data.dataNetworkReferenceKpis;
                this.peakThresholdCountComparisonValues = response.data.peakThresholdCountComparisonStats;
                this.comparisonRainbowBarValues = response.data.comparisonRainbowBarValues;
                this.teamPlayersForceVelocities = response.data.teamPlayersForceVelocities;
                this.teamAverageForceVelocity = response.data.teamAverageForceVelocity;
                this.networkAverageForceVelocity = response.data.networkAverageForceVelocity;
                this.networkForceVelocities = response.data.networkForceVelocities;
                this.minMaxForceVelocities = response.data.minMaxForceVelocities;
            } else {
                errorHandler.error(response, this);
            }
        },
        getCounts() {
            try {
                if (this.peakThresholdCountValues['DISTANCE']) {
                    //Adds player values to comparison value objects
                    for (let kpi in KpiTypes) {
                        for (let epoch in EpochTypes) {
                            this.peakThresholdCountComparisonValues[kpi][EpochTypes[epoch]].value = this.peakThresholdCountValues[kpi][EpochTypes[epoch]];
                        }
                    }

                    this.distanceCounts.push({ 
                        countData30s: this.peakThresholdCountComparisonValues['DISTANCE']['_30S'],
                        countData60s: this.peakThresholdCountComparisonValues['DISTANCE']['_60S'],
                        countData120s: this.peakThresholdCountComparisonValues['DISTANCE']['_120S']
                    });

                    this.hsrCounts.push({ 
                        countData30s: this.peakThresholdCountComparisonValues['HSR']['_30S'],
                        countData60s: this.peakThresholdCountComparisonValues['HSR']['_60S'],
                        countData120s: this.peakThresholdCountComparisonValues['HSR']['_120S']
                    });

                    this.sprintCounts.push({ 
                        countData30s: this.peakThresholdCountComparisonValues['SPRINT']['_30S'],
                        countData60s: this.peakThresholdCountComparisonValues['SPRINT']['_60S'],
                        countData120s: this.peakThresholdCountComparisonValues['SPRINT']['_120S']
                    });

                    this.turnCounts.push({ 
                        countData30s: this.peakThresholdCountComparisonValues['TURNS']['_30S'],
                        countData60s: this.peakThresholdCountComparisonValues['TURNS']['_60S'],
                        countData120s: this.peakThresholdCountComparisonValues['TURNS']['_120S']
                    });
                } else {
                    //When a new player has no data, clear out previous player's data, so it's not displayed
                    this.distanceCounts = [];
                    this.hsrCounts = [];
                    this.sprintCounts = [];
                    this.turnCounts = [];
                }
            } catch (err) {
                console.log(err);
            }
        },
        getRainbowBarData() {
            this.hasRainbowData = this.playerRainbowBarValues.DISTANCE !== null;
            if (this.hasRainbowData) {
                const convertPercentileValues = (data, measurementUnit) => {
                    data.average = measurementUnit.convert(data.average);
                    return data;
                };
                const distanceData = {
                    value: convertPercentileValues(this.playerRainbowBarValues.DISTANCE, this.ldUnit),
                    comparisonData: convertRainbowPercentileComparisonData(this.comparisonRainbowBarValues.DISTANCE, this.ldUnit)
                };
                const hsdData = {
                    value: convertPercentileValues(this.playerRainbowBarValues.HSD, this.sdUnit),
                    comparisonData: convertRainbowPercentileComparisonData(this.comparisonRainbowBarValues.HSD, this.sdUnit)
                };
                const sprintDistanceData = {
                    value: convertPercentileValues(this.playerRainbowBarValues.SPRINT, this.sdUnit),
                    comparisonData: convertRainbowPercentileComparisonData(this.comparisonRainbowBarValues.SPRINT, this.sdUnit)
                };
                const turnsData = { value: this.playerRainbowBarValues.TURNS, comparisonData: this.comparisonRainbowBarValues.TURNS };
                const accelerationsData = { value: this.playerRainbowBarValues.ACCEL, comparisonData: this.comparisonRainbowBarValues.ACCEL };
                const decelerationsData = { value: this.playerRainbowBarValues.DECEL, comparisonData: this.comparisonRainbowBarValues.DECEL };
                this.rainbowBarData = { player: this.selectedPlayer, distanceData, hsdData, sprintDistanceData, turnsData, accelerationsData, decelerationsData };
            }
        },
        async getForceVelocityData() {
            this.hasFVData = this.playerAverageForceVelocity !== null;
            if (this.hasFVData) {
                this.playerAverageForceVelocity.name = this.selectedPlayer.name;
                const playerData = [this.playerAverageForceVelocity];
                //Ignore team values in network list
                const leagueData = this.networkForceVelocities.filter(t => t.customerId !== this.customerId)
                                    .map(t => t.forceVelocity);
                //Ignore selected player values in network list
                const teamData = this.teamPlayersForceVelocities.filter(t => t.playerId !== this.selectedPlayer.playerId)
                                    .map(t => ({ name: t.playerName, ...t.averageForceVelocity }));
                const teamAverages = this.teamAverageForceVelocity;
                const leagueAverages = this.networkAverageForceVelocity;
                this.networkAverageForceVelocity.averageForce = this.networkAverageForceVelocity.force;
                this.networkAverageForceVelocity.averageVelocity = this.networkAverageForceVelocity.velocity;
                const tickValues = { ...this.networkAverageForceVelocity, ...this.minMaxForceVelocities };
                this.fvData = { playerData, teamData, leagueData, teamAverages, leagueAverages, tickValues };
            } else {
                this.fvData = {};
            }
        },
        selectorDisplayText(player) {
            return player.name;
        },
        resetPlayerData() {
            this.kpiBenchMarkPlayerValues = null;
            this.kpiBenchMarkTeamValues = null;
            this.peakThresholdCountValues = null;
            this.playerRainbowBarValues = null;
            this.playerAverageForceVelocity = null;
        }
    },
    async mounted() {
        this.$root.executeTaskWithProgressBar(async () => {
            await this.getPlayers();
            this.selectedPlayer = this.players[0];
            await this.getPlayerValues();
            await this.getComparisonValues();
            this.getCounts();
            this.getRainbowBarData();
            await this.getForceVelocityData();
            console.log("DataNetwork.vue mounted");
            UpdateSetting();
        });
        this.$root.newPageView("Sportlight Data Network Page", UserData.userName());
    }
};
</script>

<style scoped>
.player-details {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    align-content: center;
}
.club-logo-container {
    height: 60px; 
    width: 50px; 
    display: flex; 
    justify-content: center;
}
.club-logo {
    margin: auto;
    max-width: 50px;
    max-height: 50px;
}

</style>
