const UserData = {
    data: JSON.parse(window.sessionStorage.getItem("userData")),
    customerId: function () {
        return this.data.primaryCustomer.customerId;
    },
    isWIP: function () {
        return this.data.primaryCustomer.customerOptions.hasWip === 'true';
    },
    hasOppositionAnalysis: function () {
        return this.data.primaryCustomer.additionalModules.includes('oppositionanalysis');
    },
    hasFullOppositionAnalysis: function () {
        return this.data.primaryCustomer.additionalModules.includes('fulloppositionanalysis');
    },
    hasSessionAnalysis: function () {
        return this.data.primaryCustomer.additionalModules.includes('sessionanalysis');
    },
    hasTurnAnalysis: function () {
        return this.data.primaryCustomer.additionalModules.includes('turnanalysis');
    },
    hasPeakAnalysis: function () {
        return this.data.primaryCustomer.additionalModules.includes('peakanalysis');
    },
    hasRehab: function () {
        return this.data.primaryCustomer.additionalModules.includes('rehab');
    },
    hasFatigueFlag: function () {
        if (this.data.primaryCustomer.additionalModules.includes('fullfatigueflag') ) {
            return true;
        } else {
            return this.data.primaryCustomer.wellnessPlayers.length > 0;
        }
    },
    // This function can be called with no args or with the playerId arg.
    // When called with no args, it returns true if the customer has access to the Player Development page - either
    // because they have the Full Player Development module in their permissions, or they have at least 1 player that
    // they can view Player Development for.
    //
    // When called with a playerId, it returns true if the customer has the Full Player Development module permission, or if
    // they are allowed to view Player Development for that particular player. False otherwise.
    hasPlayerDevelopment: function (playerId) {
        if (this.data.primaryCustomer.additionalModules.includes('fullplayerdevelopment') ) {
            return true;
        } else {
            const pdPlayers = this.data.primaryCustomer.playerDevelopmentPlayers;
            if (playerId) {
                return pdPlayers.includes(playerId);
            } else {
                return pdPlayers.length > 0;
            }
        }
    },
    hasPlayerModule: function(playerId) {
        if (this.data.primaryCustomer.additionalModules.includes('fullplayermodule') ) {
            return true;
        } else {
            const pmPlayers = this.data.primaryCustomer.playerModulePlayers;
            if (playerId) {
                return pmPlayers.includes(playerId);
            } else {
                return pmPlayers.length > 0;
            }
        }
    },
    hasWellnessModule: function() {
        if (this.data.primaryCustomer.additionalModules.includes('fullfatigueflag') ) {
            return true;
        } else {
            return this.data.primaryCustomer.wellnessPlayers.length > 0;
        }
    },
    displayName: function () {
        return this.data.primaryCustomer.customerDisplayName;
    },
    clubBadge: function () {
        return this.data.primaryCustomer.customerAvatarUrl;
    },
    userName: function () {
        return this.data.userName;
    },
    teamType: function () {
        return this.data.primaryCustomer.teamType;
    },
    hasTeamModule: function () {
        return this.data.primaryCustomer.additionalModules.includes('teammodule');
    },
    hasExportData: function() {
        return this.data.primaryCustomer.additionalModules.includes('exportdata');
    },
    hasSquadModule: function() {
        return this.data.primaryCustomer.additionalModules.includes('squadmodule');
    },
    //Turn scatter match times are WIP only for foreseeable future
    hasWipTurnScattersMatchTimes: function () {
        return this.isWIP();
    },
    hasDataNetwork: function () {
        return this.customerId() === 'soton' || (this.customerId() === 'goztepe' && this.isWIP());
    },
    useShirtNumber: function () {
        return this.data.primaryCustomer.customerOptions.kpiOverviewPlayerInfo === 'useShirtNumber';
    },
    useAllPlayers: function () {
        return this.data.primaryCustomer.customerOptions.peakAnalysisRainbowReferenceGroup === 'useAllPlayers';
    },
    showPeakAnalysisSprintRainbow: function () {
        return this.data.primaryCustomer.customerOptions.peakAnalysisSprintRainbow === 'showPeakAnalysisSprintRainbow';
    },
    hasAllSessionsAsDefault: function () {
        return this.data.primaryCustomer.customerOptions.defaultSessionType === 'allSessions';
    },
    hasCriteriaOptionsWIP: function () {
        return this.isWIP();
    },
    hasSMRDownload: function () {
        return this.data.primaryCustomer.additionalModules.includes('matchreportdownload');
    },
    passwordChangeOptionEnabled: function () {
        return this.data.passwordChangeOptionEnabled;
    },
    getTurnEntrySpeedBoundaries: function() {
        return this.data.primaryCustomer.turnEntrySpeedBoundaries;
    },
    hideInaccessibleModules: function () {
        return this.data.primaryCustomer.customerOptions.otherModuleBehaviour === 'hidden';
    },
    hasReporting: function () {
        return this.data.primaryCustomer.additionalModules.includes('reporting');
    },
    useReportingForSession: function () {
        return this.data.primaryCustomer.customerOptions.defaultSessionScreen === 'reporting';
    },
    hasPlayerReporting: function () {
        return this.hasReporting();
    },
};

export { UserData };