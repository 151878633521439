<template>
    <span>
        <span v-if="isCountType">
            {{value}}
        </span>
        <span v-else>
            <MeasurementValue :type="type" :value="value"/>
        </span>
    </span>
</template>

<script>
import MeasurementValue from "./MeasurementValue";
export default {
    props: {
        type: [Symbol, String],
        value: Number,
    },
    components: {
        MeasurementValue,
    },
    computed: {
        isCountType() {
            return this.type === 'Count';
        },
    }
};
</script>