import store from "../store/session";
class BoxWhiskerKpiInfoGenerator {

    #sortedKpis;
    #targetKpi;
    #valueConverter;
    constructor(sortedKpis, targetKpi, valueConverter) {
        this.#sortedKpis = sortedKpis;
        this.#targetKpi = targetKpi;
        this.#valueConverter = valueConverter;
    }

    hasPlayedFullMatchFunction() {
        const matchTimesMissing = this.#sortedKpis.some(p => p.matchTimeM === null);
        // Subtract 5 mins from the highest session total time to accommodate instances where players played full match but didn't reach the max session total time.
        const adjustment = matchTimesMissing ? 5: 0;
        // Determine the match time type available
        const matchTimeProperty = matchTimesMissing ? 'sessionTotalTimeM' : 'matchTimeM';
        const fullMatchThreshold =  Math.max(...this.#sortedKpis.map(p => p[matchTimeProperty])) - adjustment;

        return player => player[matchTimeProperty] >= fullMatchThreshold;
    }
    generateLabels(addAsterisk) {
        return this.#sortedKpis.map(player => (addAsterisk(player) ? '* ' : '') + player.player.name);
    }

    calculateKpiValues(includeBelowBound) {
        const blankKpiData = { value: null, minimum: null, maximum: null, standardDeviation: null, average: null };

        return this.#sortedKpis.map((player) => {
            // Get the KPI data for the player or use blank data if unavailable
            const kpiData = player[this.#targetKpi] ?? blankKpiData;
            const convertedKpi = this.#valueConverter(kpiData);
            // Calculate if the KPI value is below 1s.d from the average and if the 'includeBelowBound' flag for the player is true.
            const { average, standardDeviation, value } = convertedKpi;
            convertedKpi.belowBounds = value < (average - standardDeviation) && includeBelowBound(player);
            convertedKpi.aboveBounds = value > (average + standardDeviation);
            return convertedKpi;
        });
    }

    checkValidKpiValues() {
        // Check that there are values present for target kpi
        return this.#sortedKpis.every(player => player[this.#targetKpi].value !== null);
    }
    generateMatchKpiInfo() {

        const hasPlayedFullMatch = this.hasPlayedFullMatchFunction();

        // Add asterisk to player names if they did not play full match
        const labels = this.generateLabels(this.not(hasPlayedFullMatch));
        // Only include the 'belowBound' flag if the player played the full game.
        const kpi = this.calculateKpiValues(hasPlayedFullMatch);
        const hasValues = this.checkValidKpiValues();

        return { kpi, labels, hasValues };
    }

    // generates kpi info for non-match sessions
    generateSessionKpiInfo() {
        // do not add asterisk to player names
        const labels = this.generateLabels(() => false);

        // Include the 'belowBound' flag.
        const kpi =  this.calculateKpiValues(() => true);

        const hasValues = this.checkValidKpiValues();

        return { kpi, labels, hasValues };
    }

    not(predicate) {
        return (...args) => !predicate.apply(this, args);
    }
}

function sortKpis(data, targetKpi) {
    // Sort kpi values in descending order
    return data.sort((a, b) => b[targetKpi].value - a[targetKpi].value);
}

/**
 * Takes an array of Player data (typically 'SessionPlayerKpi' data), the name of a desired kpi and produces a sorted array of
 * data corresponding to that KPI for each of the players in the input dataset along with labels and a boolean valueCheck
 * to indicate whether the data is valid or not.
 *
 * The output 'kpi' data will contain additional fields - belowBounds, aboveBounds etc... that the BoxWhiskerChart
 * component will use.
 *
 * Similarly, the labels output will be prefixed with '*' if the player did not play the full match.
 *
 * @param {Array} data - An array of player Kpi data. Each record represents 1 player and contains fields such as
 *          name, sessionTotalTimeM, matchTimeM as well as 'kpi' values e.g. 'activeDistanceM, totalTurns etc... The 'kpi'
 *          values should contain an object - {value, minimum, maximum, standardDeviation, average }
 * @param {String} targetKpi - The name of the kpi e.g. 'activeDistanceM', 'totalTurns' etc...
 * @param {Function} valueConverter - a function to convert the numeric data to the correct number of decimal places
 * @return {Object} An Object containing 3 properties:
 *  - kpi : a sorted array of data in the correct format for passing into the BoxWhiskerChart component
 *  - labels : labels for the data suitable for passing into the BoxWhiskerLabel component
 *  - hasValues : a boolean true if every kpi in the data array has a non-null value property
 */
function getSortedPlayerKpiBwData(data, targetKpi, valueConverter) {
    const sortedKpis = sortKpis(data, targetKpi);
    return getPlayerKpiBwData(sortedKpis, targetKpi, valueConverter);
}

function getPlayerKpiBwData(kpiData, targetKpi, valueConverter) {
    const generator = new BoxWhiskerKpiInfoGenerator(kpiData, targetKpi, valueConverter);
    if (store.state.sessionIsAMatch) {
        return generator.generateMatchKpiInfo();
    } else {
        return generator.generateSessionKpiInfo();
    }
}

function getTeamKpiBwData(kpis, labels) {
    for (const i in kpis) {
        const { average, standardDeviation, value } = kpis[i];
        kpis[i].belowBounds = value < (average - standardDeviation);
        kpis[i].aboveBounds = value > (average + standardDeviation);
    }
    const hasValues = kpis.every(kpi => kpi.value !== null);
    return { 'kpi': kpis, labels, hasValues };
}


export { getSortedPlayerKpiBwData, getPlayerKpiBwData, getTeamKpiBwData };