import Colours from "../../utils/Colours";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";
const rehabChartTargets = {
    sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
    peakSpeedUnit: getMeasurementUnit(MeasurementTypes.PeakSpeed),
    svg: null,
    rehabData: null,
    top: 180,
    left: 1480,
    colHeaderHeight: 40,
    rowHeaderWidth: 65,
    dataRowHeight: 30,
    columnWidth: 65,
    fontFamily: "Barlow",
    init: function (svg, rehabData) {
        this.svg = svg.group();
        this.rehabData = rehabData;
    },
    render: function () {
        this.svg.clear(); // clear any existing chart contents

        const drawLine = (svg, x1, y1, x2, y2) => {
            return svg.line(x1, y1, x2, y2).attr({ stroke: Colours.WHITE, "stroke-width": 1 });
        };

        const xStart = this.left + this.rowHeaderWidth;
        const xEnd = this.left + this.rowHeaderWidth + this.columnWidth + (this.columnWidth * 3);
        const yStart = this.top;
        const yEnd = this.top + this.colHeaderHeight + (this.dataRowHeight * 3);

        // draw horizontal lines
        drawLine(this.svg, xStart, yStart, xEnd, yStart);
        drawLine(this.svg, this.left, this.top + this.colHeaderHeight, xEnd, this.top + this.colHeaderHeight);
        drawLine(this.svg, this.left, this.top + this.colHeaderHeight + this.dataRowHeight, xEnd, this.top + this.colHeaderHeight + this.dataRowHeight);
        drawLine(this.svg, this.left, this.top + this.colHeaderHeight + (this.dataRowHeight * 2), xEnd, this.top + this.colHeaderHeight + (this.dataRowHeight * 2));
        drawLine(this.svg, this.left, this.top + this.colHeaderHeight + (this.dataRowHeight * 3), xEnd, this.top + this.colHeaderHeight + (this.dataRowHeight * 3));

        // draw vertical lines
        drawLine(this.svg, this.left, this.top + this.colHeaderHeight, this.left, yEnd);
        drawLine(this.svg, xStart, this.top, xStart, yEnd);
        drawLine(this.svg, xStart + this.columnWidth, this.top, xStart + this.columnWidth, yEnd);
        drawLine(this.svg, xStart + this.columnWidth + this.columnWidth, this.top, xStart + this.columnWidth + this.columnWidth, yEnd);
        drawLine(this.svg, xStart + this.columnWidth + (this.columnWidth * 2), this.top, xStart + this.columnWidth + (this.columnWidth * 2), yEnd);
        drawLine(this.svg, xStart + this.columnWidth + (this.columnWidth * 3), this.top, xStart + this.columnWidth + (this.columnWidth * 3), yEnd);

        const peakSpeedX = this.left + this.rowHeaderWidth + (this.columnWidth / 2);
        const hsrX = this.left + this.rowHeaderWidth + this.columnWidth + (this.columnWidth/2);
        const highAccelerationsX = this.left + this.rowHeaderWidth + this.columnWidth + this.columnWidth + (this.columnWidth/2);
        const sprintDistanceX = this.left + this.rowHeaderWidth + this.columnWidth + (this.columnWidth*2) + (this.columnWidth/2);
        const colHeaderY1 = this.top + (this.colHeaderHeight/3);
        const colHeaderY2 = this.top + ((this.colHeaderHeight/3)*2)+5;
        const rowHeaderX = this.left + (this.rowHeaderWidth/2);
        const targetsY = this.top + this.colHeaderHeight + (this.dataRowHeight /2)+5;
        const averageY = this.top + this.colHeaderHeight + this.dataRowHeight + (this.dataRowHeight /2)+5;
        const maximumY = this.top + this.colHeaderHeight + this.dataRowHeight + this.dataRowHeight + (this.dataRowHeight /2)+5;
        
        const injuryDate = this.rehabData.rehabDate;
        const rehabType = this.rehabData.rehabType;

        if (rehabType === "injury") {
            this.svg.text("Latest injury date: ").font({ family: this.fontFamily, size: 11.5, anchor: "middle"}).attr({ x: rowHeaderX + 180, y:  targetsY - 70, fill: Colours.WHITE });
            this.svg.text(injuryDate).font({ family: this.fontFamily, size: 11.5, anchor: "middle" }).attr({ x: rowHeaderX + 260, y:  targetsY - 70, fill: Colours.WHITE });
        }

        const createHeaderText = (svg, textValue, x, y) => {
            return svg.text(textValue).font({ family: this.fontFamily, size: 10, anchor: "middle" }).attr({ x: x, y: y, fill: Colours.WHITE });
        };
        // column headers
        createHeaderText(this.svg, "Peak", peakSpeedX, colHeaderY1);
        createHeaderText(this.svg, `Speed (${this.peakSpeedUnit.unitShort})`, peakSpeedX, colHeaderY2);
        createHeaderText(this.svg, "HSR", hsrX, colHeaderY1);
        createHeaderText(this.svg, `Distance (${this.sdUnit.unitShort})`, hsrX, colHeaderY2);
        createHeaderText(this.svg, "High", highAccelerationsX, colHeaderY1);
        createHeaderText(this.svg, "Accelerations", highAccelerationsX, colHeaderY2);
        createHeaderText(this.svg, "Sprint", sprintDistanceX, colHeaderY1);
        createHeaderText(this.svg, `Distance (${this.sdUnit.unitShort})`, sprintDistanceX, colHeaderY2);
        
        // row headers
        createHeaderText(this.svg, "Targets", rowHeaderX, targetsY);
        createHeaderText(this.svg, "Average", rowHeaderX, averageY);
        createHeaderText(this.svg, "Maximum", rowHeaderX, maximumY);
        
        const targets = this.rehabData.targets == null ? { highAccelerations: null, highSpeedDistanceM: null, peakSpeedMPerS: null, sprintDistanceM: null } : this.rehabData.targets;
        const targetsReferences = this.rehabData.targetsReferences;
        
        const peakSpeedTarget = this.peakSpeedUnit.convert(targets.peakSpeedMPerS);
        const averagePeakSpeed = this.peakSpeedUnit.convert(targetsReferences.peakSpeedMPerS.average);
        const maximumPeakSpeed = this.peakSpeedUnit.convert(targetsReferences.peakSpeedMPerS.maximum);
        
        const hsrTarget = this.sdUnit.convert(targets.highSpeedDistanceM);   
        const averageHsr = this.sdUnit.convert(targetsReferences.highSpeedDistanceM.average);
        const maximumHsr = this.sdUnit.convert(targetsReferences.highSpeedDistanceM.maximum);
        
        const sprintDistanceTarget = this.sdUnit.convert(targets.sprintDistanceM);   
        const averageSprintDistance = this.sdUnit.convert(targetsReferences.sprintDistanceM.average);
        const maximumSprintDistance = this.sdUnit.convert(targetsReferences.sprintDistanceM.maximum); 
        
        const highAccelerationsTarget = targets.highAccelerations;   
        const averageHighAccelerations = targetsReferences.highAccelerations.average;
        const maximumHighAccelerations = targetsReferences.highAccelerations.maximum;
        
        const renderText = (svg, textValue, x, y) => {
            return svg.text(textValue == null ? "No Data" : textValue.toFixed(1))
            .font({ family: this.fontFamily, size: 10, anchor: "middle" })
            .attr({ x: x, y: y, fill: Colours.WHITE });
        };
        //render targets
        renderText(this.svg, peakSpeedTarget, peakSpeedX, targetsY);
        renderText(this.svg, hsrTarget, hsrX, targetsY);
        renderText(this.svg, highAccelerationsTarget, highAccelerationsX, targetsY);
        renderText(this.svg, sprintDistanceTarget, sprintDistanceX, targetsY);
        //render averages
        renderText(this.svg, averagePeakSpeed, peakSpeedX, averageY);
        renderText(this.svg, averageHsr, hsrX, averageY);
        renderText(this.svg, averageHighAccelerations, highAccelerationsX, averageY);
        renderText(this.svg, averageSprintDistance, sprintDistanceX, averageY);
        //render maximums
        renderText(this.svg, maximumPeakSpeed, peakSpeedX, maximumY);
        renderText(this.svg, maximumHsr, hsrX, maximumY);
        renderText(this.svg, maximumHighAccelerations, highAccelerationsX, maximumY);
        renderText(this.svg, maximumSprintDistance, sprintDistanceX, maximumY);
    },


};

export { rehabChartTargets };