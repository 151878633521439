<template>
    <div class="match-observations-container" v-if="matchObservationsData">
        <h5 v-for="(player, id) in matchObservationsData" :key=id class="metric-tooltips match-observations-names">
            {{ player.playerName }}
            <span class="metric-definition match-observations-definition">
                <table v-if="player.fatigueFlag" class="fatigue-flag-container">
                    <tr>
                        <td><em style="font-weight: 700 !important;">Fatigue Flag:</em></td>
                        <td><div class="fatigue-flag" :class="player.fatigueFlagColour"></div></td>
                    </tr>
                </table>
                <table class="match-observations-table">
                    <tbody v-for="(observation, key) in player.observations" :key="key">
                        <tr>
                            <td>
                                <em style="font-weight: 700 !important;">
                                {{  observation.convertedObservationName }}
                                </em> = <ObservationMeasurementValue :type="observation.valueType" :value="observation.value"/> 
                                <span :class="{ 'no-space-before': observation.valueType === 'Count' }">,</span>
                            </td>
                            <td>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                                fill="var(--green)" stroke="var(--green)" stroke-width="2" stroke-linecap="round" 
                                stroke-linejoin="round" class="feather feather-triangle">
                                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 
                                2 0 0 0-3.42 0z"></path>
                                </svg>
                                {{ observation.percentChange }}%,
                            </td>
                            <td>
                                avg = <ObservationMeasurementValue :type="observation.valueType" :value="observation.average" />
                                <span :class="{ 'no-space-before': observation.valueType === 'Count' }">,</span>
                            </td>
                            <td>
                                max = <ObservationMeasurementValue :type="observation.valueType" :value="observation.max" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </span>
        </h5> 
    </div> 
</template>

<script>
import ObservationMeasurementValue from "./ObservationMeasurementValue";
import { getFlagColour } from "@/utils/FatigueFlagsValues";
import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

export default {
    props: {
        data: Array,
    },
    components: {
        ObservationMeasurementValue
    },
    data() {
        return {
            matchObservationsData: null,
        };
    },
    methods: {
        async getMatchObservations() {
            const matchObservationsData = this.data;
            for (const playerKey in this.data) {
                const playerData = this.data[playerKey];
                playerData.fatigueFlagColour = getFlagColour(playerData.fatigueFlag);
                for (const observationKey in playerData.observations) {
                    const observation = playerData.observations[observationKey];
                    observation.valueType = this.matchObservationKpiType(observation.observationName);
                    observation.convertedObservationName = this.convertedMatchObservationName(observation.observationName);
                }
            }
            this.matchObservationsData = matchObservationsData;
        },
        matchObservationKpiType(observationName) {
            const kpiTypes = {
                "Significant Turns": "Count",
                "High Deceleration Turns": "Count",
                "High Entry Speed Turns": "Count",
                "Sprint Entry Speed Turns": "Count", //this replaces "High Entry Speed Turns" but we'll keep for backwards compatibility
                "Sprint Distance": MeasurementTypes.ShortDistance,
                "High Speed Distance": MeasurementTypes.ShortDistance,
                "High Angle Turns": "Count",
                "Peak Speed": MeasurementTypes.PeakSpeed,
                "High Decelerations": "Count",
                "High Accelerations": "Count",
                "Distance": MeasurementTypes.LongDistance,
                "10-20m Sprint Efforts": "Count",
                "20-30m Sprint Efforts": "Count",
                "30m+ Sprint Efforts": "Count",
                "30s Distance Peak Period": MeasurementTypes.ShortDistance,
                "60s Distance Peak Period": MeasurementTypes.ShortDistance,
                "30s HSR Peak Period": MeasurementTypes.ShortDistance,
                "60s HSR Peak Period": MeasurementTypes.ShortDistance,
                "30s Sprinting Peak Period": MeasurementTypes.ShortDistance,
                "60s Sprinting Peak Period": MeasurementTypes.ShortDistance,
                "30s Turns Peak Period": "Count",
                "60s Turns Peak Period": "Count",
                "Total Sprint Efforts": "Count"
            };
            return kpiTypes[observationName];
        },
        convertedMatchObservationName(observationName) {
            const sdUnit = getMeasurementUnit(MeasurementTypes.ShortDistance);
            const convert10m = sdUnit.formatStandard(10);
            const convert20m = sdUnit.formatStandard(20);
            const convert30m = sdUnit.formatStandard(30);

            const conversionMap = {
                "10-20m Sprint Efforts": `${convert10m}-${convert20m}${sdUnit.unitShort} Sprint Efforts`,
                "20-30m Sprint Efforts": `${convert20m}-${convert30m}${sdUnit.unitShort} Sprint Efforts`,
                "30m+ Sprint Efforts": `${convert30m}${sdUnit.unitShort}+ Sprint Efforts`,
            };
            return conversionMap[observationName] || observationName;
        }
    },
    async mounted() {
        await this.getMatchObservations();
    }
};
</script>
<style scoped>
.match-observations-container {
    width: 30vw; 
    display: grid; 
    grid-template-columns: repeat(3,1fr); 
    gap: 0.5em;
    margin-bottom: 1vh;
}
.match-observations-names {
    border-bottom: 2px solid var(--sportlight-teal); 
    color: white; 
    padding: 0.1em;
}
.match-observations-definition {
    min-width: 25vw; 
    text-align:left; 
    left: 0; 
    padding: 0.2em;
    box-sizing: border-box;
    overflow-x: auto;
}
.match-observations-table {
    width: 100%; 
    border-collapse: separate; 
    border-spacing: 0 0.5em; 
    white-space: nowrap;
}
.fatigue-flag-container{
    display: flex; 
    width: 23%;
    white-space: nowrap;
}
.fatigue-flag {
    width: 1em;
    height: 1em;
    border-radius: 50%;
}
.fatigue-flag .red-flag {
    background: var(--red);
}
.fatigue-flag .green-flag {
    background: var(--green);
}
.fatigue-flag .amber-flag {
    background: var(--amber-flag);
}
.no-space-before {
    margin-left: -0.25em;
}
</style>