<template>
    <div>
        <div class="row w-100 d-none d-lg-flex" v-if="titles && !data">
            <div class="row col-12 col-lg-3 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 row-label"></div>
                <div class="col-1 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">{{ titles.total }}</div>
            </div>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label"></div>
                <div class="col-1 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">{{ titles._0_to_10 }}</div>
            </div>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label"></div>
                <div class="col-1 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">{{ titles._10_to_20 }}</div>
            </div>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label"></div>
                <div class="col-1 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">{{ titles._20_to_30 }}</div>
            </div>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label"></div>
                <div class="col-1 col-lg-2 value"></div>
                <div class="col-10 col-lg-8 plot-heading">{{ titles._30_plus }}</div>
            </div>
        </div>
        <div class="row w-100" v-if="titles && data">
            <span class="d-block d-lg-none text-center w-100 plot-heading">{{ titles.total }}</span>
            <div class="row col-12 col-lg-3 pb-2 mx-auto pb-lg-0">
                <div class="col-1 col-lg-2 row-label">{{ label }}</div>
                <div class="col-1 col-lg-2 value">{{ data.TOTAL.value.toFixed(this.precision) }}</div>
                <div class="col-10 col-lg-8">
                    <BoxWhiskerPlot :id="label + 'total'" :width="columnWidth" :height="columnHeight"
                        :values="data.TOTAL" :options="sprintCountOptions" :normalizeBox="true"
                        :precision="precision" />
                </div>
            </div>
            <span class="d-block d-lg-none text-center w-100 plot-heading">{{ titles._0_to_10 }}</span>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto w-100">
                <div class="col-1 col-lg-1 d-lg-none row-label">{{ label }}</div>
                <div class="col-1 col-lg-2 value">{{ data._0_TO_10.value.toFixed(this.precision) }}</div>
                <div class="col-10 col-lg-10">
                    <BoxWhiskerPlot :id="label + '0to10'" :width="columnWidth" :height="columnHeight"
                        :values="data._0_TO_10" :options="sprintCountOptions" :normalizeBox="true"
                        :precision="precision" />
                </div>
            </div>
            <span class="d-block d-lg-none text-center w-100 plot-heading">{{ titles._10_to_20 }}</span>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label">{{ label }}</div>
                <div class="col-1 col-lg-2 value">{{ data._10_TO_20.value.toFixed(this.precision) }}</div>
                <div class="col-10 col-lg-10">
                    <BoxWhiskerPlot :id="label + '10to20'" :width="columnWidth" :height="columnHeight"
                        :values="data._10_TO_20" :options="sprintCountOptions" :normalizeBox="true"
                        :precision="precision" />
                </div>
            </div>
            <span class="d-block d-lg-none text-center w-100 plot-heading">{{ titles._20_to_30 }}</span>
            <div class="row col-12 col-lg-2 pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label">{{ label }}</div>
                <div class="col-1 col-lg-2 value">{{ data._20_TO_30.value.toFixed(this.precision) }}</div>
                <div class="col-10 col-lg-10">
                    <BoxWhiskerPlot :id="label + '20to30'" :width="columnWidth" :height="columnHeight"
                        :values="data._20_TO_30" :options="sprintCountOptions" :normalizeBox="true"
                        :precision="precision" />
                </div>
            </div>
            <span class="d-block d-lg-none text-center w-100 plot-heading">{{ titles._30_plus }}</span>
            <div class="row col-12 col-lg-2 row pb-2 pb-lg-0 mx-auto">
                <div class="col-1 col-lg-2 d-lg-none row-label">{{ label }}</div>
                <div class="col-1 col-lg-2 value">{{ data.ABOVE_30.value.toFixed(this.precision) }}</div>
                <div class="col-10 col-lg-10">
                    <BoxWhiskerPlot :id="label + '30plus'" :width="columnWidth" :height="columnHeight"
                        :values="data.ABOVE_30" :options="sprintCountOptions" :normalizeBox="true"
                        :precision="precision" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BoxWhiskerPlot from "@/components/BoxWhiskerPlot.vue";
export default {
    props: {
        titles: Object,
        label: String,
        data: Object
    },
    components: {
        BoxWhiskerPlot,
    },
    data() {
        return {
            columnWidth: 400,
            columnHeight: 73,
            precision: 0
        };
    },
    computed: {
        sprintCountOptions: function () {
            return {
                tooltipLabel: "Player Average",
                showTicks: false,
            };
        }
    }
};
</script>

<style scoped>
.row-container {
    display: flex;
}
.row-label {
    width: 6vw;

    /* vertically align the text in the centre of the div */
    text-align: center;
    justify-content: center;
    display:flex;
    align-items:center;

    font-size: 12px;
}
.plot-heading {
    text-align: center;
    width: 18vw;
    font-weight: bold;
    font-size: 12px;
}
.plot-container {
    width: 14vw;
}
.value {
    border-right: 1px solid var(--white);

    /* ensure that there is a small gap between the value and the box whisker plot. */
    padding-right: 3px;

    /* ensure that the previous box whisker plot does not sit too close to the value of the next one. */
    padding-left: 1vw;
    width: 6em;

    /* vertically align the text in the centre of the div */
    text-align: center;
    justify-content: center;
    display:flex;
    align-items:center;

    font-style: italic;
    font-size: 12px;
}
</style>