<template>
    <EditModal :id="id" :ok="changePassword" :cancelVariant="cancelVariant || 'secondary standard-btn'"
        title="Change Password" :okDisabled="isOkDisabled" :persistOnClickBackdrop="persistOnClickBackdrop"
        :cancel="cancel" :cancelTitle="cancelTitle">
        <b-form>
            <div class="form-group">
                <div class="alert alert-danger" v-if="errorMessage !== ''">
                    {{ errorMessage }}
                </div>

                <label for="current-password">Current password</label>
                <div class="control" style="position: relative">
                    <PasswordInputBox id="current-password" placeholder="Current password" :modal="currentPassword"
                        @input="currentPassword = $event" />
                </div>

                <label for="new-password" class="mt-1">New password</label>
                <div class="control" style="position: relative">
                    <PasswordInputBox id="new-password" placeholder="New password" :modal="newPassword"
                        @input="newPassword = $event" />
                    <div v-if="newPasswordSameAsCurrent" class="invalid invalid-text"> New password
                        cannot be the same as old password</div>
                </div>

                <label for="confirm-password" class="mt-1">Re-enter new password</label>
                <div class="control" style="position: relative">
                    <PasswordInputBox id="confirm-password" placeholder="Re-enter new password" :modal="confirmPassword"
                        @input="confirmPassword = $event" />
                    <span v-if="newPasswordsNotMatching" class="invalid invalid-text"> Passwords don't match </span>
                </div>

                <div class="mt-2">New password must contain: </div>
                <div :class="{ 'valid': isMinLength, 'invalid': !isMinLength }">At least 12 characters</div>
            </div>
        </b-form>
    </EditModal>
</template>

<script>
import EditModal from './EditModal.vue';
import { errorHandler } from './ErrorHandler';
import { UserData } from './UserData';
import PasswordInputBox from './PasswordInputBox.vue';

export default {
    props: {
        id: String,
        persistOnClickBackdrop: Boolean,
        cancelTitle: String,
        cancel: Function,
        cancelVariant: String
    },
    components: {
        EditModal,
        PasswordInputBox
    },
    data() {
        return {
            currentPassword: null,
            newPassword: null,
            confirmPassword: null,
            errorMessage: '',
            username: UserData.userName(),
        };
    },
    methods: {
        async changePassword(bvModalEvt) {
            this.errorMessage = '';
            bvModalEvt.preventDefault();
            try {
                const json = {
                    username: this.username,
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword
                };

                const response = await this.$root.webApiPost("/updatepassword", json);

                if (response.status === 200) {
                    alert('Password changed successfully. Please log in again.');
                    this.$root.logOut(1);
                } else if (response.status === 401) {
                    this.errorMessage = 'Incorrect password.';
                } else {
                    errorHandler.error(response.this);
                }
            } catch (error) {
                this.errorMessage = 'An error occurred while changing the password.';
            }

        }
    },
    computed: {
        isMinLength() {
            return this.newPassword !== null && this.newPassword.length >= 12;
        },
        newPasswordSameAsCurrent() {
            return (this.currentPassword === this.newPassword) && this.newPassword !== null;
        },
        newPasswordsNotMatching() {
            return this.newPassword !== this.confirmPassword;
        },
        isOkDisabled() {
            return !this.isMinLength || !this.currentPassword || !this.newPassword || !this.confirmPassword
                || this.newPasswordSameAsCurrent || this.newPasswordsNotMatching;
        }
    },
};
</script>

<style scoped>
.invalid-text {
    position: absolute;
    left: 0;
}

.invalid {
    color: var(--red);
}

.valid {
    color: var(--green);
}
</style>