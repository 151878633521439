
<template>
    <span>
        <li> Comparison data consists of all previous {{ selectedReferenceGroupText }} matches with at
            least 85 minutes of match time. </li>
        <li>A player requires at least 4 full matches to have comparison data.</li>
    </span>
</template>

<script>
import filterStore from './filterStore';

export default {
    computed: {
        selectedReferenceGroupText() {
            return filterStore.state.selectedReferenceGroup.text;
        },
    }
};
</script>
