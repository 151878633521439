<template>
    <div id="content-wrap" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }" style="margin-left:90px">
        <PageHeader>
            <PageHeaderTitle>
                <h1>
                    White Papers
                </h1>
            </PageHeaderTitle>
        </PageHeader>

        <div class="section-container">
            <SectionTitle title='Match Reporting' />
            <div class="white-paper-container">
                <a href=" ../doc/Sportlight_Match_Report.pdf" target="_blank"> Sportlight Match Report </a>
                <a href=" ../doc/Change_Of_Direction_Metrics.pdf" target="_blank"> Change Of Direction Metrics </a>
            </div>
        </div>

        <div class="section-container">
            <SectionTitle title='Wellness' />
            <div class="white-paper-container">
                <a href=" ../doc/Fatigue_Flag.pdf" target="_blank"> Fatigue Flag </a>
                <a href="../doc/Game_Demand.pdf" target="_blank"> Game Demand </a>
                <a href="../doc/Hamstring_Risk.pdf" target="_blank"> Hamstring Risk </a>
                <a href="../doc/Local_Intensity.pdf" target="_blank"> Local Intensity </a>
            </div>
        </div>

    </div>
</template>
<script>
import { UserData } from '@/components/UserData';
import PageHeader from '@/components/PageHeader.vue';
import PageHeaderTitle from '@/components/PageHeaderTitle.vue';
import SectionTitle from '@/components/SectionTitle.vue';

export default {
    components: {
        PageHeader,
        PageHeaderTitle,
        SectionTitle
    },
    async mounted() {
        console.log("Whitepaper.vue mounted");
        this.$root.newPageView("Whitepaper Page", UserData.userName());
    }
};
</script>

<style>
.white-paper-container {
    display: flex;
    color: var(--sportlight-teal);
    margin: auto;
    width: 100%;
}

.white-paper-container a {
    padding: 0 1em;
    color: var(--sportlight-teal);
}
</style>