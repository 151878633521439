<template>
    <span>
        <b-button v-b-modal="id + '-modal'" id="info-button" v-b-tooltip.hover title="Click For More Info">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-info">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
        </b-button>
        <b-modal hide-header-close centered :id="id + '-modal'" :title=title ok-variant="primary standard-btn" ok-title="Close" ok-only size="lg">
            <slot>
            </slot>
        </b-modal>
    </span>
</template>

<script>
export default {
    props: {
        id: String,
        title: String
    }
}
</script>

<style scoped>
#info-button {
    border-radius: 0;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 2px;
}

#info-button:focus {
    box-shadow: none !important;
    outline: none !important;
}
</style>