<template>
        <b-modal hide-header-close centered  
        :id=id
        v-model="isModalOpen"
        :size="size"
        :no-close-on-backdrop="persistOnClickBackdrop"
        :title=title ref="modal" scrollable>
        <slot>
        </slot>
        <template #modal-footer>
            <b-button :variant="cancelVariant" @click="cancelModal">
                {{ cancelTitle || 'Cancel' }}
            </b-button>
            <b-button v-if="!cancelOnly" variant="primary standard-btn" @click="okModal" :disabled="okDisabled">
                {{ okTitle || 'Ok' }}
            </b-button>
        </template>
        </b-modal>
</template>

<script>
export default {
    props: {
        id: String,
        cancelVariant: String,
        ok: Function,
        cancel: Function,
        title: String,
        cancelTitle: String,
        okTitle: String,
        okDisabled: Boolean,
        showModal: Boolean,
        size: String,
        persistOnClickBackdrop: Boolean,
        cancelOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isModalOpen: this.showModal
        };
    },
    watch: {
        showModal(newValue) {
            this.isModalOpen = newValue;
        },
    },
    methods: {
        cancelModal() {
            if (this.cancel) this.cancel();
            this.$refs.modal.hide();
        },
        okModal(event) {
            if (this.ok) this.ok(event);
            this.$refs.modal.hide();
        }
    }
};
</script>