<template>
    <div :id="'parent' + id" style="width:90vw; height:30vh">
        <canvas :id="id"> </canvas>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import Colours from "../utils/Colours";

export default {
    props: {
        id: String,
        data: Array,
        options: Object,
        labels: Array
    },
    data() {
        return {
            chartInstance: null // Add a property to hold the chart instance
        };
    },
    computed: {
        // provide some sensible default values when options values aren't provided.
        fullOptions() {
            const { minTick, stepSize, maxTick } = calculateTicks(this.data);
            const xAxisColour = null;
            const displayTicksAt = null;
            const lineThickness = calculateLineThickness(stepSize)
            return { minTick, maxTick, stepSize, xAxisColour, displayTicksAt, lineThickness, ...this.options };
        }
    },
    async mounted() {
        await this.drawBWPlot(this.id, this.data, this.fullOptions, this.labels);
        this.$watch("data", async function () {
            this.replaceCanvas();
            await this.drawBWPlot(this.id, this.data, this.fullOptions, this.labels);
        })
    },
    methods: {
        drawBWPlot(id, data, options, labels) {
            const chartCtx = document.getElementById(id).getContext("2d");
            const plotValue = [];
            const plotLabel = [];
            const minLower = [];
            const stdDev = [];
            const upperMax = [];
            const avg = [];
            const thinBar = 0.01;
            const thickBar = 0.5;
            const minHeight = [];
            const maxHeight = [];
            const whiskersColours = [];
            const boxColours = [];

            for (const i in data) {
                let { value, minimum, average, standardDeviation, maximum, whiskersColour, boxColour } = data[i];
                maximum = minimum === null ? null : maximum; //set max value to null when min is null to prevent single grey line
                const kpiValue = value;
                const lowerBounds = average - standardDeviation;
                const upperBounds = average + standardDeviation;
                const thickness = options.lineThickness || 1;

                plotLabel.push((kpiValue?.toFixed(options.labelPrecision)) || '');
                plotValue.push([kpiValue, kpiValue + thickness]);
                minHeight.push(minimum <= lowerBounds ? thinBar : 0);
                maxHeight.push(maximum >= upperBounds ? thinBar : 0);
                minLower.push([minimum, lowerBounds]);
                stdDev.push([lowerBounds, upperBounds]);
                upperMax.push([upperBounds, maximum]);
                avg.push([average, average]);
                whiskersColours.push(whiskersColour);
                boxColours.push(boxColour);
            }

            const tooltipLabelText = (tooltipItem) => {
                const index = tooltipItem.index
                const minimum = minLower[index][0]
                const average = avg[index][0]
                const maximum = upperMax[index][1]

                return ["Min: " + minimum.toFixed(options.precision),
                "Average: " + average.toFixed(options.precision), "Max: " + maximum.toFixed(options.precision)]
            };

            this.chartInstance = new Chart(chartCtx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Value',
                            data: plotValue,
                            backgroundColor: whiskersColours,
                            borderColor: whiskersColours,
                            borderWidth: 1,
                            barPercentage: thickBar
                        },
                        {
                            label: "Min",
                            data: minLower,
                            backgroundColor: whiskersColours,
                            borderColor: whiskersColours,
                            borderWidth: 1,
                            barPercentage: minHeight,
                        },
                        {
                            label: "stdDev",
                            data: stdDev,
                            backgroundColor: boxColours,
                            borderColor: whiskersColours,
                            borderWidth: 1,
                            barPercentage: thickBar,
                        },
                        {
                            label: "Average",
                            data: avg,
                            backgroundColor: Colours.PRIMARY_GREY,
                            borderColor: Colours.PRIMARY_GREY,
                            borderWidth: 1,
                            barPercentage: 0,
                            categoryPercentage: 0,
                        },
                        {
                            label: "Max",
                            data: upperMax,
                            backgroundColor: whiskersColours,
                            borderColor: whiskersColours,
                            borderWidth: 1,
                            barPercentage: maxHeight,

                        }
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: options.showTitle,
                        text: options.title,
                        maintainAspectRatio: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        enabled: true,
                        position: 'nearest',
                        callbacks: {
                            title: () => { }, // no title
                            label: tooltipLabelText
                        },
                        yAlign: 'right'
                    },
                    scales: {
                        xAxes: [
                            {
                                stacked: true,
                                gridLines: {
                                    display: false,
                                    drawBorder: false
                                }
                            },
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    display: true,
                                    beginAtZero: false,
                                    min: options.minTick,
                                    max: options.maxTick,
                                    stepSize: options.stepSize,
                                },
                                stacked: false,
                                gridLines: {
                                    display: false,
                                    drawBorder: false,
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: options.yAxisLabel
                                },
                            }
                        ]
                    },
                }
            });
        },
        replaceCanvas() {
            // Check if a previous chart instance exists
            if (this.chartInstance) {
                // Destroy the previous chart instance
                this.chartInstance.destroy();
            }
        }
    }
}

/**
 * Calculates tick values for the x-axis of a chart based on the provided input data.
 * - Retrieves the minimum and maximum values from the data.
 * - Determines an initialInterval: 10 for values <= 20, 20 otherwise to round values to the nearest multiple of 10 or 20.
 * - The 'roundValue' function takes a value to be rounded and rounds it to the nearest multiple of the initialInterval.
 * - Establishes rounded minimum and maximum tick values for a sensible display and necessary padding.
 * - Computes the stepSize (gap between individual ticks); if the minValue is <= 5, uses 1 as the stepSize, otherwise,
 *   it takes the rounded difference between the minimum and maximum tick values. 
 *   The stepSize is also used to calculate line thickness function so the check is necessary.
 *
 * @param {Array} data - Input data containing value, maximum, and minimum properties.
 * @returns {Object} An object containing minTick, stepSize, and maxTick values.
 */

const calculateTicks = data => {
    const values = data.flatMap(t => [t.value, t.maximum, t.minimum]);
    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);
    const initialInterval = minValue <= 20 ? 10 : 20;
    const roundValue = value => Math.ceil(value / initialInterval) * initialInterval;
    const minTicks = roundValue(minValue);
    const maxTicks = roundValue(maxValue);
    const stepSize = minValue <= 5 ? 1 : roundValue(maxTicks - minTicks);
    return { minTicks, stepSize, maxTicks };
}

const calculateLineThickness = stepSize => {
    return (stepSize * 15 / 1000)
}

</script>