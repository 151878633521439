<template>
    <div>
        <MissingDataText v-if="!hasTurns" :message="'Turn Data Unavailable'" />
        <div class="charts-container" v-else>
            <div class="chart-container" v-if="angleData">
                <TurnRatesPlot :id="this.customerId + forOpposition + 'angle-chart'" :data="angleData" title="Angle" :width="width" :height="height" />
                <TurnRatesLegend type="angle" />
            </div>
            <div class="chart-container" v-if="decelerationsData">
                <TurnRatesPlot :id="this.customerId + forOpposition + 'peak-deceleration-chart'" :data="decelerationsData" :width="width" :height="height"
                    title="Peak Deceleration" />
                <TurnRatesLegend type="deceleration" />
            </div>
            <div class="chart-container" v-if="accelerationsData">
                <TurnRatesPlot :id="this.customerId + forOpposition + 'peak-acceleration-chart'" :data="accelerationsData" :width="width" :height="height"
                    title="Peak Acceleration" />
                <TurnRatesLegend type="acceleration" />
            </div>
            <div class="chart-container" v-if="effortData">
                <TurnRatesPlot :id="this.customerId + forOpposition + 'effort-classification-chart'" :data="effortData" :width="width" :height="height"
                    title="Effort Classification" />
                <TurnRatesLegend type="effort" />
            </div>
        </div>
    </div>
</template>
<script>

import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import Colours from "@/utils/Colours";
import { getTurnRatesPercentages, getTurnRatesRanges, getTurnRatesLabels } from "@/utils/TurnRateHelpers";

export default {
    data() {
        return {
            turnData: null,
            hasTurns: null,
            isWIP: UserData.isWIP(),
            angleData: null,
            accelerationsData: null,
            decelerationsData: null,
            effortData: null,
            width: '42vw',
            height: null
        };
    },
    props: {
        customerId: String,
        forOpposition: {
            default: false,
            type: Boolean
        },
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            // get query string here
            this.sessionId = this.$route.params.id;
            await this.$root.executeTaskWithProgressBar(async () => {
                await this.getTurnData();
                if (this.hasTurns) { await this.getTurns(); }
            });
        },
        async getTurnData() {
            if (this.customerId) {
                const response = await this.$root.webApiGet(
                    `/turnrates?customerId=${this.customerId}&sessionId=${this.sessionId}&forOpposition=${this.forOpposition}`
                );
                if (response.status === 200) {
                    this.turnData = response.data;
                    this.hasTurns = this.turnData.length > 0;
                } else {
                    errorHandler.error(response, this);
                }
            }
        },
        async getTurns() {
            const turnsByAngleClass = [];
            const turnsByAccelerationPeakClass = [];
            const turnsByDecelerationPeakClass = [];
            const turnsByEffortClass = [];
            const zScores = [];
            const hasInsufficientMatchTime = [];
            const playerNames = [];
            const sortedTurnsData = this.turnData.sort((a, b) => b.turnClassCounts.byAngleClass.totalCount - a.turnClassCounts.byAngleClass.totalCount);

            for (let i = 0; i < sortedTurnsData.length; i++) {
                turnsByAngleClass.push(sortedTurnsData[i].turnClassCounts.byAngleClass);
                turnsByAccelerationPeakClass.push(sortedTurnsData[i].turnClassCounts.byAccelerationPeakClass);
                turnsByDecelerationPeakClass.push(sortedTurnsData[i].turnClassCounts.byDecelerationPeakClass);
                turnsByEffortClass.push(sortedTurnsData[i].turnClassCounts.byEffortClass);
                zScores.push(sortedTurnsData[i].turnsZScores);
                hasInsufficientMatchTime.push(sortedTurnsData[i].activeTimeM < 85);
                playerNames.push(sortedTurnsData[i].player.name);
            }

            const turnRatesPercentages = getTurnRatesPercentages(turnsByAngleClass);
            const turnRatesRanges = getTurnRatesRanges(turnsByAngleClass, zScores, hasInsufficientMatchTime);
            const turnRatesLabels = getTurnRatesLabels(playerNames, turnRatesRanges.leftTurnNormalRange, true, true);
            const sharedData = { ...turnRatesRanges, ...turnRatesPercentages, labels: turnRatesLabels, type: 'DEFAULT', tooltipTitles: playerNames };
            const classificationNames = ['Low', 'Medium', 'High'];
            this.angleData = { turns: turnsByAngleClass, classificationNames, colours: Colours.TURN_RATES.byAngleClass, ...sharedData };
            this.accelerationsData = { turns: turnsByAccelerationPeakClass, classificationNames, colours: Colours.TURN_RATES.byAccelerationPeakClass, ...sharedData };
            this.decelerationsData = { turns: turnsByDecelerationPeakClass, classificationNames, colours: Colours.TURN_RATES.byDecelerationPeakClass, ...sharedData };
            this.effortData = { turns: turnsByEffortClass, classificationNames, colours: Colours.TURN_RATES.byEffortClass, ...sharedData };
            this.height = `${Math.max(20, Math.min(75, 15 * turnRatesLabels.length))}vh`;
        }
    },
    async mounted() {
        console.log("TurnRatesCharts.vue mounted");
        this.$root.newPageView("Turn Rates Page", UserData.userName());
        this.refreshData();
    }
};
</script>

<style scoped>
.charts-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90vw;
    margin: auto
}
.chart-container {
    width: 42vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
</style>