<template>
    <div style="margin-top:2vh" v-if="sessionHasEnhancedTurns">
        <p style="margin-left: 1vw; font-size: 12px;">Body rotation update applied</p>
    </div>
</template>

<script>
import store from "@/store/session";

export default {
    computed: {
        sessionHasEnhancedTurns() {
            return store.state.sessionHasEnhancedTurns;
        },
    },
};
</script>